import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_AWS_INTEGRATIONS = "GET_AWS_INTEGRATIONS";
export const GET_ALL_BUCKETS = "GET_ALL_BUCKETS";
export const GET_ALL_S3_OBJECTS = "GET_ALL_S3_OBJECTS";
export const GET_TFC_WORKSPACES = "GET_TFC_WORKSPACES";

export const GET_SLACK_INTEGRATIONS = "GET_SLACK_INTEGRATIONS";
export const GET_TEAMS_INTEGRATIONS = "GET_TEAMS_INTEGRATIONS";

export const UPDATE_AWS_INTEGRATION = "UPDATE_AWS_INTEGRATION";

const getAwsIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws`,
      "GET"
    );

    let hasError = null;
    let integrations = null;
    if (!integrationsReq.ok) {
      hasError = "An error occurred while trying to get AWS integrations";
    } else {
      integrations = await integrationsReq.json();
    }

    dispatch({
      type: GET_AWS_INTEGRATIONS,
      payload: { hasError, integrations },
    });
  });
};

const updateAwsIntegration = (id, fetchable) => {
  return action(async dispatch => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(`${BACKEND_URL}/integrations/aws/${id}/fetchable`, "PUT", {
      fetchable,
    });
    dispatch({
      type: UPDATE_AWS_INTEGRATION,
      payload: {}
    });
    return req;
  });
}

const getAllBuckets = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration}/buckets`,
      "GET"
    );
    dispatch({
      type: GET_ALL_BUCKETS,
      payload: {},
    });

    if (!req.ok) {
      return { ok: false, buckets: null };
    }

    const buckets = await req.json();
    return { ok: true, buckets };
  });
};

const getAllS3Objects = (integration, bucket) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration}/bucket/${bucket}/objects`,
      "GET"
    );

    dispatch({
      type: GET_ALL_S3_OBJECTS,
      payload: {},
    });

    let objects = null;
    if(res.ok){
      objects = await res.json();
    }
    return {objects, ok: res.ok, status: res.status};
  });
};

const callTfcAPI = (payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/tfc/call_api`,
      "POST",
      payload
    );

    dispatch({
      type: GET_TFC_WORKSPACES,
      payload,
    });

    return await res.json();
  });
};

const testSlackIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/test`,
      "POST",
      { webhookUrl }
    );

    return res.ok;
  });
};

const createSlackIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/`,
      "POST",
      { webhookUrl }
    );

    return res.ok;
  });
};

const deleteSlackIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/${id}`,
      "DELETE"
    );

    return res.ok;
  });
};

const getSlackIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_SLACK_INTEGRATIONS,
      payload: { integrations },
    });
  });
};

const testTeamsIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/teams/test`,
        "POST",
        { webhookUrl }
    );

    return res.ok;
  });
};

const createTeamsIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/teams/`,
        "POST",
        { webhookUrl }
    );

    return res.ok;
  });
};

const deleteTeamsIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/teams/${id}`,
        "DELETE"
    );

    return res.ok;
  });
};

const getTeamsIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/teams`,
        "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_TEAMS_INTEGRATIONS,
      payload: { integrations },
    });
  });
};

export {
  getAwsIntegrations,
  getAllBuckets,
  getAllS3Objects,
  callTfcAPI,
  updateAwsIntegration,

  testSlackIntegration,
  createSlackIntegration,
  getSlackIntegrations,
  deleteSlackIntegration,

  testTeamsIntegration,
  createTeamsIntegration,
  getTeamsIntegrations,
  deleteTeamsIntegration,
};
