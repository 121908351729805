import React from "react";
import _ from "lodash";
import moment from "moment";
import "./timeline.scss";

class Timeline extends React.Component {

    render() {
        /* badge could be [success, warning, danger, primary] */
        const { children } = this.props;

        return <div className="timeline">
            {children}
        </div>
    }
}

class Point extends React.Component {

    render() {
        /* badge could be [success, warning, danger, primary] */
        const { time, badge, text, onClick } = this.props;

        return <div className="point-box">
            <div className="items">
                <span className="time">{moment(time).format("DD/MM/YYYY HH:mm")}</span>
                <div className={`circle ${_.isUndefined(onClick) ? "" : "circle-pointer"}`} onClick={_.isUndefined(onClick) ? () => { } : onClick}>
                    <p className={`badge-dot-${badge || "primary"} big-dot`}></p>
                </div>
                <div className="item-text">{text}</div>
            </div>
        </div>
    }
}

class ShowMore extends React.Component {

    render() {
        const { onClick } = this.props;

        return <small id="timeline-showmore" className="text-muted show-more">
            <a href="#timeline-showmore" onClick={onClick}>show more</a>
        </small>
    }
}

export { Timeline, Point, ShowMore }