import _ from 'lodash';
import React from 'react';
import ReactTooltip from 'react-tooltip';

export default class Tooltip extends React.Component{

    render() {
        const {id} = this.props;
        return <ReactTooltip
        id={id}
        place="left"
        type='info'
        effect='float'
        backgroundColor="#ffffff"
        textColor="#663399"
        arrowColor="#663399">
            {this.props.children}
        </ReactTooltip>
    }
}