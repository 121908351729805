import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAllRuns } from '../../../../redux/actions/orchestratorActions';
import { getAllProfiles } from '../../../../redux/actions/profilesActions';
import Loading from '../../../../shared/loading';
import AnonymousUserImage from '../../../../public/images/anonymousUser.jpeg';
import { TimeParser } from '../../../../shared/parsers';
import RunHistory from './runHistory';

class Runs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            runs: [],
            isLoading: true,
            profiles: [],
            selectedRun: null
        };
    }

    async componentDidMount() {
        const {stack} = this.props;
        this.setState({isLoading: true});

        const profiles = await this.props.getAllProfiles();
        const runs = await this.props.getAllRuns(stack.id);

        this.setState({profiles, runs, isLoading: false});
    }

    moveToPlanPage = () => {
        const {stack} = this.props;
        const basePath = `/environment/${stack?.environmentId}/stack/${stack?.id}`;
        this.props.history.push(`${basePath}/terraformWizard`);
        window.location.reload();
    }

    selectRun = (selectedRun) => {
        this.setState({selectedRun});
    }

    back = () => {
        this.setState({selectedRun: null});
    }

    render() {
        const { runs, isLoading } = this.state;
        const {stack} = this.props;

        if (isLoading) {
            return (<Loading />);
        }

        const { profiles, selectedRun } = this.state;
        const profilesById = _.keyBy(profiles, "id");

        return (
            _.isEmpty(selectedRun)?
            <>
                <div className="mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <h4 className="card-title mb-3">Workspace runs</h4>
                            <p>These are the stacks created in the current workspace. A stack is an entity representing a deployment managed by InfraLight.</p>

                            <div className="row">
                                <div className="col-md-10"></div>
                                <div className="col-md-2">
                                    <button className="btn btn-success btn-block mb-3" onClick={this.moveToPlanPage} id="alert-success" type="button">Plan</button>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Branch</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Status</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(runs, (run, index) => {
                                            const profile = profilesById[run.userId];
                                            const name = _.isEmpty(profile) ? "N/A" : `${profile.firstName} ${profile.lastName}`;
                                            const image = _.isEmpty(profile) || _.isEmpty(profile.image) ? AnonymousUserImage : profile.image;
                                            return (
                                                <tr key={run.id}>
                                                    <th scope="row">{runs.length - index}</th>
                                                    <td><img className="rounded-circle m-0 avatar-sm-table" src={image} alt="" /> {name}</td>
                                                    <td>{run.description || "N/A"}</td>
                                                    <td>{run.branch} {run.commit ? `(${run.commit.substr(0, 8)})` : null}</td>
                                                    <td>{<TimeParser value={run.createdAt} />}</td>
                                                    <td>
                                                        {run.hasError ? <span className="badge badge-warning">{run.errorMessage}</span> :
                                                            run.isDestroy ? <span className="badge badge-danger">Destroyed</span> :
                                                                run.isApply ? <span className="badge badge-success">Applied</span> :
                                                                    run.isPlan ? <span className="badge badge-info">Planned</span> :
                                                                        <span className="badge badge-light">Not run</span>}
                                                    </td>
                                                    <td>
                                                        <a className="text-success mr-2" href="#" onClick={()=>this.selectRun(run.id)}><i className="nav-icon i-Arrow-Next font-weight-bold"></i></a>
                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>:
            <>
                <a href="#" onClick={()=>this.back()}><i className="text-20 i-Back1"></i></a>
                <RunHistory id={selectedRun} />
            </>)
    }
}

const mapStateToProps = (state) => {
    return {
        runs: state.orchestratorReducer.runs,
        profiles: state.profilesReducer.profiles
    };
};

const mapDispatchToProps = {
    getAllRuns,
    getAllProfiles
};


Runs = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Runs);
export default withRouter(Runs);