import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getStacks } from '../../redux/actions/stackActions';
import EchartPie from '../../shared/charts/echartPie';
import { onStackFiltered, onStackFilteredOut, initStackFilters, onChangeUnmanagedFilterStatus } from '../../redux/actions/homePageActions';
import ActiveFeatures from '../../consts/activeFeatures';

class StackAggregation extends React.Component {
    constructor(props) {
        super(props);

        this.state = { labels: [], series: [], hasData: false };
    }

    async componentDidMount() {
        await this.props.getStacks();
        const { stacks } = this.props;
        const stackIds = _.map(stacks, x => x.id);
        await this.props.initStackFilters(stackIds);
    }
    async componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.loadedCategories, nextProps.loadedCategories) ||
            !_.isEqual(this.props.filteredResourceTypes, nextProps.filteredResourceTypes) ||
            !_.isEqual(this.props.filteredAwsAccounts, nextProps.filteredAwsAccounts)) {

            const { inventory, stacks, excludedByResourceType } = this.props;
            const { filteredAwsAccounts } = nextProps;

            const stacksById = _.groupBy(stacks, 'id');

            //union all object to the same data structure
            const union = [];
            _.keys(inventory).forEach(key => {

                //Handling filters
                if (!_.isEmpty(nextProps.filteredResourceTypes) && !_.includes(nextProps.filteredResourceTypes, key)) {
                    return;
                }

                const excludedResources = excludedByResourceType[key];
                inventory[key].resources.forEach(resource => {

                    let excluded = false;

                    if (_.includes(excludedResources, resource.id)) {
                        excluded = true;
                    }

                    if (!_.isEmpty(filteredAwsAccounts) && !_.includes(filteredAwsAccounts, resource.awsAccountId)) {
                        excluded = true;
                    }

                    //not excluded
                    if (!excluded) {
                        union.push(resource);
                    }
                })
            });

            //Nothing to show
            if (_.isEmpty(union)) {
                this.setState({ hasData: false });
                return;
            }

            //grouping by stack
            const aggregatedByStack = _.groupBy(union, 'iacObject[0].stackId');

            const labels = [];
            const series = [];

            labels.push('Unmanaged');
            //itemStyle: { borderColor: "#000", shadowBlur: 10 }
            series.push({value: (aggregatedByStack[undefined] || []).length, selected: true})

            _.forEach(aggregatedByStack, (entities, key) => {
                if (_.isEmpty(key) || key == "undefined") {
                    return;
                }

                //stack exists - this check used to avoid bug, that someone removed the stack
                if (!_.isEmpty(stacksById[key])) {
                    const name = stacksById[key][0].name;

                    labels.push(name);
                    series.push({ value: entities.length });
                } else {
                    //Adding to the unmanaged
                    series[0].value += entities.length
                }


            });

            this.setState({ labels, series, hasData: true });
        }
    }

    onLegendSelectChanged = async (legendEvent) => {
        //console.log(legendEvent)
        const stackName = legendEvent.name;

        const { stacks } = this.props;
        const stacksByName = _.groupBy(stacks, 'name');

        if (stackName == "Unmanaged") {
            const filtered = _.isUndefined(legendEvent.selected['Unmanaged']) ? true : legendEvent.selected['Unmanaged'];
            this.props.onChangeUnmanagedFilterStatus(filtered);
        }
        else {
            let stack = stacksByName[stackName];
            if (_.isEmpty(stack) || stack.length > 1) {
                console.warn(`got an error in stack ${stack}`);
                return;
            }

            stack = _.first(stack);
            const filtered = _.isUndefined(legendEvent.selected[stackName]) ? true : legendEvent.selected[stackName];
            if (filtered) {
                this.props.onStackFiltered(stack.id);
            } else {
                this.props.onStackFilteredOut(stack.id);
            }
        }
    }

    render() {
        const { hasData, labels, series } = this.state;
        const { activeFeatures } = this.props;
        const isIacAggregationDisabled = _.filter(activeFeatures, x => ActiveFeatures.iacAggregationDisabled.startsWith(x.path)).length > 0;
        return <>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="ul-widget__head">
                        <div className="ul-widget__head-label">
                            {
                                !isIacAggregationDisabled?
                                <h3 className="ul-widget__head-title">Managed/Unmanaged IaC</h3>:
                                <h3 className="ul-widget__head-title">IaC By Stacks</h3>
                            }
                        </div>
                    </div>

                    {hasData ?
                        !isIacAggregationDisabled?
                        <EchartPie name="Managed/Unmanaged IaC" colors={["#f77066", "#0bc978"]} series={[series[0].value, _.sum(_.map(series.slice(1), x=>x.value))]} labels={["Unmanaged", "Managed"]} onLegendSelectChanged={this.onLegendSelectChanged} labelFormat="{d}%" /> :
                        <EchartPie name="IaC By Stacks" series={series} labels={labels} onLegendSelectChanged={this.onLegendSelectChanged} labelFormat="{d}%" /> :
                        <EchartPie name="IaC By Stacks" series={[1]} labels={['No Data']} labelFormat="{b}" />
                    }

                </div>
            </div>
        </>
    }
}



const mapStateToProps = (state) => {
    return {
        stacks: state.stackReducer.data,
        excludedByResourceType: state.exclusionsReducer.excludedByResourceType,
        inventory: state.inventoryReducer,
        loadedCategories: state.homePageReducer.loaded,
        filteredResourceTypes: state.homePageReducer.filtered,
        filteredAwsAccounts: state.homePageReducer.filteredAwsAccounts,

        activeFeatures: state.activeFeaturesReducer.activeFeatures || []
    };
};

const mapDispatchToProps = {
    getStacks,
    onStackFiltered,
    onStackFilteredOut,
    initStackFilters,
    onChangeUnmanagedFilterStatus
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(StackAggregation);