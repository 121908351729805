import React from "react";
import InfraLightLogo from "../public/images/infralight-logo.png";
import { withAuth0 } from "@auth0/auth0-react";
import RequestWrapper from "../utils/requestWrapper";
import { BACKEND_URL } from "../consts/config";
import "../public/css/create_account.css";

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "" };

    this.requestWrapper = new RequestWrapper();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ name: event.target.value });
  }

  async handleSubmit(event) {
    const { loginWithRedirect } = this.props.auth0;

    event.preventDefault();
    await this.requestWrapper.sendRequest(`${BACKEND_URL}/account`, "PUT", {
      companyName: this.state.name,
    });

    loginWithRedirect();
  }

  render() {
    const { logout } = this.props.auth0;

    return (
      <div id="create_account">
        <form className="card o-hidden" onSubmit={this.handleSubmit}>
          <img src={InfraLightLogo} alt="Infralight" />
          <p>
            Thank you for signing up to Infralight! To continue, please enter
            the name of your company or business.
          </p>
          <div className="form-group">
            <input
              type="text"
              id="companyName"
              className="form-control form-control-rounded"
              value={this.state.value}
              onChange={this.handleChange}
              placeholder="Business/Company Name"
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block btn-rounded mt-3"
          >
            Continue
          </button>
          <button
            type="button"
            className="btn btn-danger btn-block btn-rounded mt-3"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Logout
          </button>
        </form>
      </div>
    );
  }
}

export default withAuth0(CreateAccount);
