import _ from 'lodash';

import {
    CATEGORY_LOADED,
    CATEGORY_FILTERED,
    CATEGORY_FILTERED_OUT,
    AWS_ACCOUNT_FILTERED,
    AWS_ACCOUNT_FILTERED_OUT,
    INIT_STACK_FILTERS,
    STACK_FILTERED,
    STACK_FILTERED_OUT,
    UNMANAGED_CHANGE_FILTER_STATUS
} from '../actions/homePageActions';

const initialState = {
    loaded: [],
    filtered: [],
    filteredAwsAccounts: [],
    filteredStacks: [],
    filteredUnmanaged: true
};

export default (state = initialState, { type, payload }) => {

    switch (type) {

        case CATEGORY_LOADED:
            const loaded = state.loaded.slice();
            loaded.push(payload.categoryId);

            return { ...state, loaded };

        case CATEGORY_FILTERED:
            const filteredBeforeAdd = state.filtered.slice();
            return { ...state, filtered: _.sortedUniq(_.union(filteredBeforeAdd, payload.resourceTypes)) };

        case CATEGORY_FILTERED_OUT:
            const filteredBeforeRemove = state.filtered.slice();
            return { ...state, filtered: _.difference(filteredBeforeRemove, payload.resourceTypes) };

        case AWS_ACCOUNT_FILTERED:
            const filteredAwsAccountsBeforeAdd = state.filteredAwsAccounts.slice();
            return { ...state, filteredAwsAccounts: _.sortedUniq(_.union(filteredAwsAccountsBeforeAdd, [payload.awsAccount])) };

        case AWS_ACCOUNT_FILTERED_OUT:
            const filteredAwsAccountsBeforeRemove = state.filteredAwsAccounts.slice();
            return { ...state, filteredAwsAccounts: _.difference(filteredAwsAccountsBeforeRemove, [payload.awsAccount]) };

        case INIT_STACK_FILTERS:
            return { ...state, filteredStacks: payload.stackIds }

        case STACK_FILTERED:
            const filteredStacksBeforeAdd = state.filteredStacks.slice();
            return { ...state, filteredStacks: _.sortedUniq(_.union(filteredStacksBeforeAdd, [payload.stackId])) };

        case STACK_FILTERED_OUT:
            const filteredStacksBeforeRemove = state.filteredStacks.slice();
            return { ...state, filteredStacks: _.difference(filteredStacksBeforeRemove, [payload.stackId]) };

        
        case UNMANAGED_CHANGE_FILTER_STATUS: 
            return {...state, filteredUnmanaged: payload.filteredUnmanaged}
        default:
            return state;
    }
}
