import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../shared/loading';
import ExclusionMenu from './exclusionMenu';
import PageLayout from '../../shared/layouts/pageLayout';
import PageSeperator from '../../shared/layouts/pageSeperator';
import PageButtonsLine from '../../shared/layouts/pageButtonsLine';
import ListGroup from '../../shared/lists/listGroup';
import ListItem from '../../shared/lists/listItem';
import { getCustomExclusions, deleteCustomExclusions, customExclusionSelected } from '../../redux/actions/customExclusionsActions';

import CustomExclustionModal from './customExclusionModal';
import InvenroryLoader from '../inventory/inventoryLoader';
import { Card, CardBody, CardHeader } from '../../shared/layouts/cards';

class CustomExclusions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isModalOpen: false,
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
        await this.props.getCustomExclusions();
    }

    /*
    in this function we load all resources before showing the Modal
    */
    onInventoryLoad = async (resourcesCategories) => {

        this.setState({ isLoading: true });
        for (const i in resourcesCategories) {
            const onLoad = resourcesCategories[i].onLoad;
            await onLoad();
        }
        this.setState({ resourcesCategories, isLoading: false });
    }

    onOpenModal = async (selectedCustomExclusion) => {
        this.props.customExclusionSelected(selectedCustomExclusion);
        this.setState({ isModalOpen: true });
    }

    onCloseModal = () => {
        this.props.customExclusionSelected(null);
        this.setState({ isModalOpen: false });
    }

    onDeleteExclusion = async (exclusion) => {
        this.setState({ isLoading: true });

        await this.props.deleteCustomExclusions(exclusion.id);
        await this.props.getCustomExclusions();

        this.setState({ isLoading: false });
    }

    RightComponent = (props) => {
        const { exclusion } = props;

        return <span>
            <a className="text-success mr-2" href="#" onClick={() => this.onOpenModal(exclusion)}><i className="nav-icon i-Pen-2 font-weight-bold"></i></a>
            <a className="text-danger mr-2" href="#" onClick={() => this.onDeleteExclusion(exclusion)}><i className="nav-icon i-Close-Window font-weight-bold"></i></a>
        </span>
    }

    render() {
        const { isLoading, isModalOpen } = this.state;
        const { customExclusions } = this.props;

        return <>
            {/*
            customExclusions configured to false, because we do not want to filter them out in the custom exclusion management page
            If we filter them out, we will not be about to control them!
             */}
            <InvenroryLoader onLoadInventory={this.onInventoryLoad} customExclusions={false} />
            {isLoading ? <Loading text="Loading Resources..." /> : <>
                <CustomExclustionModal
                    isOpen={isModalOpen}
                    onClose={this.onCloseModal} />

                <ExclusionMenu />
                <PageSeperator />

                <PageLayout>
                    <Card textAlign="left">
                        <CardBody>

                            <PageButtonsLine>
                                <button className="btn btn-primary btn-block mb-3" id="alert-primary" type="button" onClick={() => this.onOpenModal(null)}>Create Exclusion</button>
                            </PageButtonsLine>

                            <CardHeader>Exclusion Rules</CardHeader>

                            <ListGroup>
                                {
                                    _.map(customExclusions, exclusion => <ListItem key={exclusion.id} id={exclusion.id} header={exclusion.name} rightComponent={<this.RightComponent exclusion={exclusion} />}>
                                        {exclusion.description}
                                    </ListItem>
                                    )
                                }
                            </ListGroup>
                        </CardBody>
                    </Card>
                </PageLayout>
            </>}
        </>
    }
}


const mapStateToProps = (state) => {
    return {
        customExclusions: state.customExclusionsReducer.data
    };
};

const mapDispatchToProps = {
    getCustomExclusions,
    deleteCustomExclusions,
    customExclusionSelected
};


CustomExclusions = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(CustomExclusions);
export default withRouter(CustomExclusions);
