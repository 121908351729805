
import React from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';

class FormField extends React.Component {
    render() {
        const { htmlFor, header, name, id, placeholder, component, onChange, disabled } = this.props;
        return (
            <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor={htmlFor}>{header}</label>
                <div className="col-sm-10">
                    <Field
                        className={"form-control"}
                        name={name}
                        id={id}
                        component={component}
                        placeholder={placeholder}
                        onChange={onChange}
                        disabled={disabled || false}
                    />
                </div>
            </div>
        )
    }
}

class ValidateForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isEnabled: false
        };
    }
    onArnChanged = (e) => {
        this.setState({ isEnabled: !_.isEmpty(e.target.value) });
    }

    render() {
        const { handleSubmit } = this.props;
        const { isEnabled } = this.state;

        return (
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="text-center"><h4>Validate</h4></div>
                    <div className="card mb-5">
                        <div className="card-body">
                            <form className="aws-validate" onSubmit={handleSubmit}>

                                <FormField htmlFor="inputName" header="name" name="name" id="name" component="input" placeholder="Please fill the name" />
                                <FormField htmlFor="inputExternalId" header="External ID" name="externalId" id="externalId" component="input" placeholder="Please fill the externalId" disabled={true} />
                                <FormField htmlFor="inputArn" header="Role ARN" name="roleArn" id="roleArn" component="input" placeholder="Please fill the role Arn" onChange={this.onArnChanged} />
                              
                                <div className="form-group row">
                                    <div className="form-group row">
                                        <div className="col-sm-10">
                                            <button className="btn btn-primary" type="submit" disabled={!isEnabled}>Validate</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default reduxForm({
    form: 'aws-validate'
})(ValidateForm);
