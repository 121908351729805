import { GET_VCS_GITHUB, DELETE_SPECIFIC_VCS_GITHUB } from '../actions/vcsActions';

const initialState = {
    githubApps: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_VCS_GITHUB:
            return { ...state, githubApps: payload.githubApps };

        case DELETE_SPECIFIC_VCS_GITHUB:
            return { ...state };

        default:
            return state;
    }
}
