import React from 'react';
import _ from 'lodash';
import Tab from '../../shared/tabs/tab';
import TabMenu from '../../shared/tabs/tabMenu';
import { withRouter } from 'react-router-dom';

class ExclusionMenu extends React.Component{
    render(){
        const pathname = this.props.location.pathname;

        return <TabMenu>
        <Tab pathname={pathname} link="/exclusions/rules" exact={true} name="Default Rules" label="rules-tab" icon="i-File-Cloud" />
        <Tab pathname={pathname} link="/exclusions/custom" name="Custom" label="rules-tab" icon="i-Upload-Window" />
    </TabMenu>
    }
}

export default withRouter(ExclusionMenu);