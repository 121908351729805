import { GET_ACTIVE_FEATURES } from '../actions/activeFeaturesActions';

const initialState = {
    activeFeatures: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_ACTIVE_FEATURES:
            return { ...state, activeFeatures: payload.data };

        default:
            return state;
    }
}
