import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PercentageBar from '../../shared/widget/percentageBar';

class SummaryIacPrecentage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {total: 0, totalExcluded: 0, totalHasIac: 0};
    }

    async componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.loadedCategories, nextProps.loadedCategories) ||
            !_.isEqual(this.props.filteredResourceTypes, nextProps.filteredResourceTypes) ||
            !_.isEqual(this.props.filteredAwsAccounts, nextProps.filteredAwsAccounts)) {

            const { inventory, excludedByResourceType } = this.props;
            const { filteredAwsAccounts } = nextProps;

            let total = 0;
            let totalExcluded = 0;
            let totalHasIac = 0;

            _.keys(inventory).forEach(key => {

                //Handling filters
                if (!_.isEmpty(nextProps.filteredResourceTypes) && !_.includes(nextProps.filteredResourceTypes, key)) {
                    return;
                }

                const excludedResources = excludedByResourceType[key];
                inventory[key].resources.forEach(resource => {

                    let excluded = false;

                    if (_.includes(excludedResources, resource.id)) {
                        excluded = true;
                    }

                    if (!_.isEmpty(filteredAwsAccounts) && !_.includes(filteredAwsAccounts, resource.awsAccountId)) {
                        excluded = true;
                    }

                    //not excluded
                    if (excluded) {
                        totalExcluded++;
                    } else {
                        total++;
                        if (!_.isEmpty(resource.iacObject)) {
                            totalHasIac++;
                        }
                    }
                })
            });
            this.setState({ total, totalExcluded, totalHasIac });
        }
    }

    render() {
        const { total, totalExcluded, totalHasIac } = this.state;

        const percentage = Math.round(totalHasIac / Math.max(total, 1) * 100);
        const badge = percentage < 60 ? "danger" : percentage < 85 ? "warning" : "success";
        const footer = totalExcluded == 0 ? null : `${totalExcluded} resources excluded`;

        return <>
            <PercentageBar header="Total IaC capability" percentage={percentage} badge={badge} footer={footer} />
        </>
    }
}



const mapStateToProps = (state) => {
    return {
        excludedByResourceType: state.exclusionsReducer.excludedByResourceType,
        inventory: state.inventoryReducer,
        loadedCategories: state.homePageReducer.loaded,
        filteredResourceTypes: state.homePageReducer.filtered,
        filteredAwsAccounts: state.homePageReducer.filteredAwsAccounts,
        filteredStacks: state.homePageReducer.filteredStacks
    };
};

const mapDispatchToProps = {
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SummaryIacPrecentage);