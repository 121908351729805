
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../shared/loading';
import { Danger, Success } from '../../../shared/alerts';
import ValidateForm from './validateForm';
import RequestWrapper from '../../../utils/requestWrapper';
import { BACKEND_URL } from '../../../consts/config';
import ConfirmationModal from "../../../shared/confirmationModal";

class AwsValidate extends React.Component {

    constructor(props) {
        super(props);

        this.state = { valid: false, isLoading: false, hasError: null, alias: null, integrationId: null };
        this.requestWrapper = new RequestWrapper();
    }

    onSubmit = async (submittedInfo) => {

        this.setState({ isLoading: true });
        const payload = {
            name: submittedInfo.name.trim(),
            roleArn: submittedInfo.roleArn.trim(),
            externalId: submittedInfo.externalId.trim()
        }

        const awsIntegration = await this.requestWrapper.sendRequest(`${BACKEND_URL}/integrations/aws`, 'POST', payload);
        this.setState({ isLoading: false });

        if (awsIntegration.ok) {
            const response = await awsIntegration.json();
            if (response.integration.alias && !_.isEmpty(response.integration.alias)) {
                this.setState({ alias: response.integration.alias, integrationId: response.integration._id});
            }
            this.setState({ hasError: null, valid: true });
            this.props.updateRoleValid();
            return;
        }

        if (awsIntegration.status == 403) {
            this.setState({ hasError: 'Role or ExternalId is not valid', valid: false });
            return;
        }

        else if (awsIntegration.status == 409) {
            this.setState({ hasError: 'Role already exists', valid: false });
            return;
        }

        else {
            this.setState({ hasError: 'an unhandled error occurred while trying to create role', valid: false });
            return;
        }
    }

    handleStackRename = async () => {
        const payload = {
            name: this.state.alias.trim(),
        }

        const awsIntegrationRename = await this.requestWrapper.sendRequest(`${BACKEND_URL}/integrations/aws/${this.state.integrationId}`, 'PUT', payload);
        this.setState({ alias: null });
    }

    render() {
        const { externalId } = this.props;
        const { isLoading, valid, hasError } = this.state;

        if(valid && _.isEmpty(this.state.alias)){
            return (<Success>Validation has been suceeded!</Success>)
        }

        return (
            <>
                {isLoading ? <Loading /> : null}

                {!_.isEmpty(hasError) ? <Danger closeEnabled={false}>{hasError}</Danger> : null}

                <ValidateForm initialValues={{ name: "AWS", externalId }} onSubmit={this.onSubmit} />

                <ConfirmationModal
                    isOpen={this.state.alias}
                    onYes={this.handleStackRename}
                    onNo={() => this.setState({alias: null})}
                    customText={`we found out that you're using an alias in your aws account. do you want to use '${this.state.alias}' as the stack name?`}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = {
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AwsValidate);
