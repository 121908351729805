import {
  GET_TFC_INTEGRATIONS,
  CREATE_TFC_INTEGRATION,
  DELETE_TFC_INTEGRATION,
} from "../actions/tfcIntegrationActions";

const initialState = {
  integrations: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_TFC_INTEGRATIONS:
      return { ...state, integrations: payload.integrations };

    case CREATE_TFC_INTEGRATION:
      return { ...state };

    case DELETE_TFC_INTEGRATION:
      return { ...state };

    default:
      return state;
  }
}
