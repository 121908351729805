
import React from 'react';
import _ from 'lodash';

export default class PercentageBar extends React.Component {
    render() {
        //badge must be:
        //Primary
        //Secondary
        //Success
        //Danger
        //Warning
        //Info
        //Dark
        const {header, percentage, badge, footer, cardBodyStyle, animated, striped} = this.props;
        const showFooter = !_.isEmpty(footer);

        return <>
            <div className="card mb-4">
                <div className={_.isEmpty(cardBodyStyle) ? "card-body" : cardBodyStyle}>
                    <h6 className="mb-2 text-muted">{header}</h6>
                    <p className={`mb-1 text-22 font-weight-${badge}`}>{percentage}%</p>
                    <div className="progress mb-1" style={{ height: '4px' }}>
                        <div className={`progress-bar bg-${badge} ${animated===true?"progress-bar-animated":""} ${striped===true? "progress-bar-striped": ""}`} style={{ width: `${percentage}%` }} role="progressbar" aria-valuemax="100"></div>
                    </div>
                    {showFooter? <small className="text-muted">{footer}</small> : null}
                </div>
            </div>
        </>
    }
}