import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { getAllStates } from "../../../redux/actions/statesActions";
import Loading from "../../../shared/loading";
import { TimeParser } from "../../../shared/parsers";


class States extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      environmentId: this.props.match.params.environmentId,
      page: 0,
      states: [],
      isLoading: true,
      stack: null,
      isCreationOpen: false,
    };
  }

  async componentDidMount() {
    await this.refreshStates();
  }

  async refreshStates() {
    const states = await this.props.getAllStates(this.props.stack.id);
    this.setState({ states, isLoading: false, stack: this.props.stack });
  }

  openCreationModal = () => {
    this.setState({ isCreationOpen: true });
  };

  closeCreationModal = () => {
    this.setState({ isCreationOpen: false });
  };

  onCreateNewState = async () => {
    console.log("created");
    await this.refreshStates();
    this.closeCreationModal();
  };

  render() {
    const { states, isLoading } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <div className="mb-3">
          <div className="card text-left">
            <div className="card-body">
              <h4 className="card-title mb-3">Workspace states</h4>
              <p>
                These are the stacks created in the current workspace. An
                stack is an entity representing a deployment managed by
                InfraLight.
              </p>

              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">RunId</th>
                      <th scope="col">Time</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {
                    <tbody>
                      {states &&
                        states.length &&
                        _.map(states, (s, index) => {
                          return (
                            <tr key={s.id}>
                              <th scope="row">{states.length - index}</th>
                              <td>{s.runId || "N/A"}</td>
                              <td>{<TimeParser value={s.createdAt} />}</td>
                              <td>
                                <a
                                  className="text-success mr-2"
                                  href={`state/${s.id}`}
                                >
                                  <i className="nav-icon i-Arrow-Next font-weight-bold"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.statesReducer.states,
  };
};

const mapDispatchToProps = {
  getAllStates,
};

States = compose(connect(mapStateToProps, mapDispatchToProps))(States);
export default withRouter(States);
