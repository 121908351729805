import React from 'react';
import _ from 'lodash';

export default class Selector extends React.Component {

    render() {
        const { onSelect, options } = this.props;
        const disabledIfNoOptions = _.isUndefined(this.props.disabledIfNoOptions) ? false : this.props.disabledIfNoOptions;
        const disabled = _.isUndefined(this.props.disabled) ? false : this.props.disabled;

        const selectPlaceholder = _.isEmpty(this.props.selectPlaceholder) ? "Select" : this.props.selectPlaceholder;
        const id = _.isUndefined(this.props.id) ? "dropdown" : this.props.id;

        return <div className="dropdown">
            <a id={id} href="#" className={`btn text-muted dropdown-toggle mr-3 ${disabled || (disabledIfNoOptions && _.isEmpty(options)) ? "disabled" : ""}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{selectPlaceholder}</a>
            <div className="dropdown-menu" aria-labelledby={id} x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(0px, 33px, 0px)', top: '0px', left: '0px', willChange: 'transform' }}>
                {
                    _.map(options, option => {
                        return <a key={option.key} className="dropdown-item" href="#" onClick={() => onSelect(option)}>{option.value}</a>
                    })
                }
            </div>
        </div>
    }
}