import React from 'react';
import _ from 'lodash';
import moment from 'moment';

class TimeParser extends React.Component {

    render() {
        const { value } = this.props;
        
        //In Case that value in Epoc
        if(_.isNumber(value)){
            let num = value;
            //Epoc in seconds ==> Epoc in milliseconds
            if(value.toString().length <= 10){
                num = num * 1000;
            }
            return <>{moment(num).toDate().toLocaleString()}</>
        }

        if (_.isEmpty(value)) {
            return <>N/A</>
        }

        const time = new Date(value);
        if (time instanceof Date && !isNaN(time)) {
            return <>{time.toLocaleString()}</>
        }

        return (
            <>{value}</>
        )
    }
}

export {
    TimeParser
}