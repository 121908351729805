import React from "react";
import Loading from "../shared/loading";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    isAuthenticated && (
      <div className="dropdown">
        <div className="user col align-self-end">
          <img
            src={user.picture}
            alt={user.name}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="userDropdown"
          >
            <div className="dropdown-header">
              <i className="i-Lock-User mr-1"></i> {user.name}
            </div>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Sign out
            </a>
          </div>
        </div>
      </div>
    )
  );
};

export default Profile;
