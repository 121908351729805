import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getStacks } from '../../redux/actions/stackActions';
import { analyzeExcludedRules } from '../../redux/actions/exclusionsActions';
import { getActiveFeatures } from '../../redux/actions/activeFeaturesActions';

import Loading from '../../shared/loading';
import SummaryWindow from './summaryWindow';
import StackAggregation from './stackAggregation';
import UnmanagedResources from './unmanagedResources';
import SummaryDeployments from './summaryDeployments';
import ActiveFeatures from '../../consts/activeFeatures';
import PercentageeBar from '../../shared/widget/percentageBar';
import SummaryIacPrecentage from './summaryIacPrecentage';

class Main extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.fetchDashboardData()
    }

    fetchDashboardData = async () => {

        this.setState({
            isLoading: true
        });

        await Promise.all([
            this.props.getStacks(),
            this.props.analyzeExcludedRules(),
            this.props.getActiveFeatures()
        ]);

        this.setState({
            isLoading: false
        });
    };


    render() {
        const { isLoading } = this.state;
        const { activeFeatures } = this.props;

        const isFakenEnabled = _.filter(activeFeatures, x => ActiveFeatures.fakeDemo.startsWith(x.path)).length > 0;

        //console.log(aws);
        if (isLoading) {
            return <Loading />
        }

        return (<div>

            <div className="separator-breadcrumb border-top"></div>

            <div className="row">
                <div className="col-lg-2 col-md-2">
                    <SummaryWindow />
                </div>

                <div className="col-lg-3 col-md-3">
                    <StackAggregation />

                    {/* FOR DEMO PURPOSES */}
                    {isFakenEnabled ?
                        <div className="row">
                            <div className="col-lg-6 col-md-6"><PercentageeBar header="Prod/Stage parity" percentage={78} badge="success" cardBodyStyle="card-body mt-2 mb-2" /></div>
                            <div className="col-lg-6 col-md-6"><PercentageeBar header="Drifts (last 24h)" percentage={14} badge="warning" cardBodyStyle="card-body mt-2 mb-2" /></div>
                        </div> : null
                    }

                    <SummaryDeployments />

                    {/* FOR REAL CUSTOMERS */}
                    {!isFakenEnabled ?
                        <SummaryIacPrecentage /> : null
                    }


                </div>

                <div className="col-lg-7 col-md-7">
                    <UnmanagedResources handleRefreshDashboard={() => this.fetchDashboardData()}/>
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = (state) => {

    return {
        activeFeatures: state.activeFeaturesReducer.activeFeatures || []
    };
};

const mapDispatchToProps = {
    getStacks,
    analyzeExcludedRules,
    getActiveFeatures
};


Main = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Main);
export default withRouter(Main);