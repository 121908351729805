import {
    GET_DRIFTS,
    UPDATE_RESOURCE_DRIFTS,
    UPDATE_CONFIGURATION_DRIFTS,
    CHANGE_SELECTED_CATEGORY,
    CHANGE_FILTER,
    SELECT_DRIFT
} from '../actions/driftsActions';

const initialState = {
    data: null,
    configurationDrifts: { new: null, resolved: null, drifts: null },
    resourceDrifts: { new: null, resolved: null, drifts: null },
    selectedCategory: 'resource',
    filterQuery: 'SELECT * FROM drifts LIMIT 25',
    selectedDrift: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {

        case GET_DRIFTS:
            return { ...state, data: payload.data };

        case UPDATE_RESOURCE_DRIFTS:
            return { ...state, resourceDrifts: { new: payload.new, resolved: payload.resolved, drifts: payload.drifts } };

        case UPDATE_CONFIGURATION_DRIFTS:
            return { ...state, configurationDrifts: { new: payload.new, resolved: payload.resolved, drifts: payload.drifts } };

        case CHANGE_SELECTED_CATEGORY:
            return { ...state, selectedCategory: payload.category };

        case CHANGE_FILTER:
            return { ...state, filterQuery: payload.filterQuery };

        case SELECT_DRIFT:
            return { ...state, selectedDrift: payload.selectedDrift };
            
        default:
            return state;

    }
}
