import React from "react";

import ActiveSelector from "../../shared/activeSelector/activeSelector";
import "./assetsInventoryHeader.scss";

const AssetsInventoryHeader = ({
  handleSearch,
  searchVal,
  resetSearch,
  handleSetFilter,
}) => {
  return (
    <div className="card assetsInventoryHeader">
      <div className="assetsInventoryHeader__dropDownFilter">
        <ActiveSelector
          options={[
            { key: "allAssets", value: "All Assets" },
            { key: "managed", value: "Managed Assets" },
            { key: "unmanaged", value: "UnManaged Assets" },
          ]}
          onSelect={handleSetFilter}
        />
      </div>

      <div className="assetsInventoryHeader__dropDownFilter">
        <ActiveSelector
          options={[
            { key: "allSeverities", value: "All Severities" },
            { key: "high", value: "High" },
            { key: "medium", value: "Medium" },
            { key: "low", value: "Low" },
          ]}
          onSelect={handleSetFilter}
        />
      </div>

      <div className="assetsInventoryHeader__search">
        {searchVal.length ? (
          <i
            className="assetsInventoryHeader__search-icon i-Close-Window"
            style={{ opacity: "1", cursor: "pointer" }}
            onClick={resetSearch}
          ></i>
        ) : (
          <i className="assetsInventoryHeader__search-icon i-Magnifi-Glass1"></i>
        )}
        <input
          className="form-control"
          type="text"
          placeholder="Search Asset"
          aria-label="Search"
          value={searchVal}
          onChange={handleSearch}
        />
      </div>
    </div>
  );
};

export default AssetsInventoryHeader;
