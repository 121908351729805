import React from 'react';
import _ from 'lodash';

class Danger extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { children } = this.props;
        let closeEnabled = _.isUndefined(this.props.closeEnabled) ? true : this.props.closeEnabled;
        return (
            <div className="alert alert-danger" role="alert">
                {children}
                {closeEnabled?<button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button> : null}
            </div>
        )
    }
}

class Success extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { children } = this.props;
        let closeEnabled = _.isUndefined(this.props.closeEnabled) ? true : this.props.closeEnabled;
        return (
            <div className="alert alert-success" role="alert">
                {children}
                {closeEnabled?<button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button> : null}
            </div>
        )
    }
}

class Warning extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { children } = this.props;
        let closeEnabled = _.isUndefined(this.props.closeEnabled) ? true : this.props.closeEnabled;
        return (
            <div className="alert alert-warning" role="alert">
                {children}
                {closeEnabled?<button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button> : null}
            </div>
        )
    }
}

class Info extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { children } = this.props;
        let closeEnabled = _.isUndefined(this.props.closeEnabled) ? true : this.props.closeEnabled;
        return (
            <div className="alert alert-info" role="alert">
                {children}
                {closeEnabled?<button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button> : null}
            </div>
        )
    }
}

export {
    Danger,
    Success,
    Warning,
    Info
}