import React from 'react';
import _ from 'lodash';

class V extends React.Component {
   
    render() {
        return (
            <span style={{color:'green'}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check text-20"><polyline points="20 6 9 17 4 12"></polyline></svg></span>
        )
    }
}

class X extends React.Component {
    render() {
        return (
            <span style={{color:'red'}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x text-20"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></span>
        )
    }
}

class Slash extends React.Component {
    render() {
        return (
            <span style={{color:'gray'}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-slash text-20"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg></span>
        )
    }
}
export {
    V,
    X,
    Slash
}