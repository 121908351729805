import {
    GET_CUSTOM_EXCLUSIONS,
    DELETE_CUSTOM_EXCLUSIONS,
    UPDATE_CUSTOM_EXCLUSIONS,
    CREATE_CUSTOM_EXCLUSIONS,
    CUSTOM_EXCLUSION_SELECTED,
    ANALYZE_CUSTOM_EXCLUSIONS
    
} from '../actions/customExclusionsActions';

const initialState = {
    data: null,
    hasError: null,
    selectedExclusion: {},
    excludedResources: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_CUSTOM_EXCLUSIONS:
            return { ...state, data: payload.data };

        case DELETE_CUSTOM_EXCLUSIONS:
            return { ...state, hasError: payload.ok == true ? null : "Could not delete exclusion" };

        case UPDATE_CUSTOM_EXCLUSIONS:
            return { ...state, hasError: payload.ok == true ? null : "Could not update exclusion" };

        case CREATE_CUSTOM_EXCLUSIONS:
            return { ...state, hasError: payload.ok == true ? null : "Could not create exclusion" };

        case CUSTOM_EXCLUSION_SELECTED:
            return {...state, selectedExclusion: payload.selectedExclusion};

        default:
            return state;
    }
}
