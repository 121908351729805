import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Danger, Success, Info } from '../../../../../shared/alerts';
import Loading from '../../../../../shared/loading';
import { terraformApply } from '../../../../../redux/actions/terraformActions';
import Ansi from '../../../../../shared/ansi';

class TerraformApply extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            triggered: false,
            hasError: null
        };
    }

    triggerApply = async () => {
        this.setState({ triggered: true, isLoading: true });
        await this.props.terraformApply();
        this.setState({ isLoading: false });

        const {hasError, isApply} = this.props;

        this.setState({ hasError })
        this.props.onFinishToApply(isApply, hasError);
    }

    render() {
        const { triggered, isLoading, hasError } = this.state;
        if (!triggered) {
            return (<button className="btn btn-primary" type="button" onClick={() => this.triggerApply()}>Click here to Apply terraform</button>);
        }

        if (isLoading) {
            return <Loading />
        }

        return (
            <>
                {!_.isEmpty(hasError) ? <Danger>An error occurred while trying to apply changes</Danger> :
                    <Success>Apply has been succeeded!</Success>}

                {_.isEmpty(this.props.apply) || _.isUndefined(this.props.apply.text) ? null :
                    <Ansi>
                        {this.props.apply.text}
                    </Ansi>}

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        hasError: state.terraformReducer.hasError,
        isApply: state.terraformReducer.isApply,
        apply: state.terraformReducer.apply
    };
};

const mapDispatchToProps = {
    terraformApply
};

TerraformApply = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TerraformApply)

export default TerraformApply;