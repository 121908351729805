import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import ActiveFeatures from "../../consts/activeFeatures";
import { CardBody, Card } from "../../shared/layouts/cards";

import SlackIcon from "../../public/images/icons/slack.svg";
import TeamsIcon from "../../public/images/icons/teams.png";
import GithubIcon from "../../public/images/icons/github.svg";
import AwsIcon from "../../public/images/icons/aws.png";
import PagerDutyIcon from "../../public/images/icons/pagerduty.png";
import ServiceNowIcon from "../../public/images/icons/servicenow.png";
import GitLabIcon from "../../public/images/icons/gitlab.png";
import SplunkIcon from "../../public/images/icons/splunk.png";
import WebhookIcon from "../../public/images/icons/webhook.png";
import GcpIcon from "../../public/images/icons/gcp.png";
import AzureIcon from "../../public/images/icons/azure.svg";
import K8SIcon from "../../public/images/icons/k8s.png";
import TfcIcon from "../../public/images/icons/tfc.png";

import "./createIntegration.scss";

class IntegrationCard extends React.Component {
  moveToIntegration = () => {
    const isDisabled = _.isUndefined(this.props.disabled)
      ? false
      : this.props.disabled;
    if (isDisabled) {
      return;
    }

    const { history, url } = this.props;
    history.push(url);
  };
  render() {
    const { icon, header, description } = this.props;
    const isDisabled = _.isUndefined(this.props.disabled)
      ? false
      : this.props.disabled;

    return (
      <div className="col-md-3">
        <div className="card integ-card card-profile-1 mb-4">
          <div className="card-body text-center">
            <div className="avatar box-shadow-2 mb-3">
              <img src={icon} alt="" />
            </div>
            <h5 className="m-0">{header}</h5>
            <p>{description}</p>
            <button
              onClick={this.moveToIntegration}
              className={`btn btn-primary btn-rounded ${
                isDisabled ? "btn-disabled" : ""
              }`}
              disabled={isDisabled}
            >
              {isDisabled ? "Coming Soon" : "Add"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
IntegrationCard = withRouter(IntegrationCard);

class CreateIntegration extends React.Component {
  render() {
    const { activeFeatures } = this.props;
   
    return (
      <Card>
        <CardBody>
          <div className="row">
            <IntegrationCard
              icon={AwsIcon}
              header="AWS Onboarding"
              description="Non-Intrusive Onboarding, using Assume-Role with readonly permission (SecurityAudit)."
              url="/integrations/aws/create"
            />
            <IntegrationCard
              icon={TfcIcon}
              header="Terraform Cloud Integration"
              description="Hashicorp's Terraform SaaS Orchestrator."
              url="/integrations/tfc/create"
            />
            <IntegrationCard
              icon={GithubIcon}
              header="GitHub Integration"
              description="The World's Leading Software Development Platform."
              url="/integrations/vcs/github"
            />
            <IntegrationCard
              icon={K8SIcon}
              header="K8S Onboarding"
              description="Drift Analysis for Kubernetes Clusters"
              url="/integrations/k8s"
            />
            <IntegrationCard
              icon={SlackIcon}
              header="Slack Integration"
              description="Real-time messaging, archiving and search."
              url="/integrations/slack"
            />
            <IntegrationCard
                icon={TeamsIcon}
                header="Teams Integration"
                description="Proprietary business communication platform developed by Microsoft."
                url="/integrations/teams"
            />
            <IntegrationCard
              icon={GcpIcon}
              header="GCP Onboarding"
              description="Non-Intrusive Onboarding, GCP Project Read-Only access (Project Viewer, security Reviewer)."
              url="/integrations/gcp/create"
            />
            <IntegrationCard
              icon={AzureIcon}
              header="Azure Onboarding"
              description="Non-Intrusive Onboarding, Subscription Read-Only access."
              disabled={true}
            />

            <IntegrationCard
              icon={GitLabIcon}
              header="GitLab Integration"
              description="The leading integrated product for modern software development."
              disabled={true}
            />
            <IntegrationCard
              icon={WebhookIcon}
              header="WebHook Integration"
              description="A webhook is a lightweight API that powers one-way data sharing triggered by events."
              disabled={true}
            />
            <IntegrationCard
              icon={PagerDutyIcon}
              header="PagerDuty Integration"
              description="Issue Management Systems."
              disabled={true}
            />
            <IntegrationCard
              icon={ServiceNowIcon}
              header="Service Now Integration"
              description="Workflows for the modern enterprise."
              disabled={true}
            />
            <IntegrationCard
              icon={SplunkIcon}
              header="Splunk Integration"
              description="Operational Intelligence, Log Management, Application Management and Compliance."
              disabled={true}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

CreateIntegration = withRouter(CreateIntegration);

const mapStateToProps = (state) => {
  return {
    activeFeatures: state.activeFeaturesReducer.activeFeatures || [],
  };
};
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateIntegration
);
