import { GET_ALL_RUNS } from '../actions/orchestratorActions';

const initialState = {
    runs: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_ALL_RUNS:
            return { ...state, runs: payload.runs };

        default:
            return state;
    }
}
