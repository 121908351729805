
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../../../shared/loading';
import { Danger, Success } from '../../../../../shared/alerts';
import { terraformInit } from '../../../../../redux/actions/terraformActions';
import InitForm from './initForm';

class TerraformInit extends React.Component {

    constructor(props) {
        super(props);

        this.state = { valid: false, isLoading: false, hasError: null };
    }

    onSubmit = async (submittedInfo) => {
        const { stack } = this.props;

        this.setState({ isLoading: true });
        await this.props.terraformInit(stack.id, submittedInfo.description);
        this.setState({ isLoading: false });


        //Update the state with the redux props
        const { hasError } = this.props;

        if (!_.isEmpty(hasError)) {
            this.setState({ hasError });
            this.props.onFinishToInit(false, hasError);
            return;
        }

        //Update initialization as done!
        this.setState({ valid: true });
        this.props.onFinishToInit(true, null);
    }

    render() {
        const { stack } = this.props;
        const { isLoading, valid, hasError } = this.state;

        if (valid) {
            return (<Success>Initialization has been suceeded!</Success>)
        }

        if (_.isEmpty(stack)) {
            return (<Danger>Stack is not valid</Danger>);
        }

        if (isLoading) {
            return <Loading />
        }

        if (!_.isEmpty(hasError)) {
            return (<Danger>An error occurred while trying to init stack</Danger>)
        }

        return (<>
            <InitForm stack={stack} onSubmit={this.onSubmit} />

        </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        run: state.terraformReducer.run,
        hasError: state.terraformReducer.hasError
    };
};

const mapDispatchToProps = {
    terraformInit
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TerraformInit);
