import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Loading from '../../../shared/loading';
import { getAwsIntegrations } from '../../../redux/actions/integrationsActions';
import OopsPage from '../../../shared/emptyPage/oopsPage';
import {
    Card,
    CardBody
} from "../../../shared/layouts/cards";
import Selector from '../../../shared/selector/selector';
import { getAwsFetchingMetadata } from '../../../redux/actions/inventoryActions'
import { Table } from "../../../shared/tables/tables";
import { TimeParser } from "../../../shared/parsers"
import PercentageBar from "../../../shared/widget/percentageBar";
import { V, X, Slash } from '../../../shared/iconStatus';

class AwsFetchingStatus extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            integration: null,
            integrationId: null,
            filters: [],
            metadata: []
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        //Get all integrations
        await this.props.getAwsIntegrations();
        const { hasError, integrations } = this.props;

        if (!_.isEmpty(hasError)) {
            this.setState({ isLoading: false, hasError });
            return;
        }

        const { integrationId } = this.props.match.params;

        const integration = _.first(_.filter(integrations, x => x.id == integrationId) || []);
        if (_.isEmpty(integration)) {
            this.setState({ isLoading: false, hasError: "Integration is not valid" })
            return;
        }

        const metadataRes = await this.props.getAwsFetchingMetadata(integrationId);
        if (!metadataRes.ok) {
            this.setState({ isLoading: false, hasError: "Fetching metadata is not valid" });
            return;
        }

        this.setState({ isLoading: false, integration, metadata: metadataRes.metadata });
    }

    selectRegion = (region) => {
        const { filters } = this.state;
        filters.push({ type: "region", ...region })
        this.setState({ filters });
    }

    selectService = (service) => {
        const { filters } = this.state;
        filters.push({ type: "service", ...service })
        this.setState({ filters });
    }

    selectResource = (resource) => {
        const { filters } = this.state;
        filters.push({ type: "resource", ...resource })
        this.setState({ filters });
    }

    selectStatusCode = (resource) => {
        const { filters } = this.state;
        filters.push({ type: "metadata.status_code", ...resource });
        this.setState({ filters });
    }

    getSelectorOptions = (filteredMetadata, type) => {
        const { filters } = this.state;
        const options = _.map(_.groupBy(filteredMetadata, type), (val, key) => ({ key: key, value: key }));

        const filtersByType = _.map(_.filter(filters, x => x["type"] == type), y => y.key);
        return _.filter(options, x => !_.includes(filtersByType, x.key));
    }

    removeSelectedFilter = (filter) => {
        const { filters } = this.state;
        this.setState({ filters: _.difference(filters, [filter]) })
    }

    LastUpdatedColumn = (props) => {
        return (
            <>
                {
                    _.isEmpty(props?.item?.metadata) ? <span>N/A</span> :
                        <span><TimeParser value={props.item.metadata.updated_at} /></span>

                }
            </>
        )
    }

    StatusColumn = (props) => {
        return (
            <>
                {
                    _.isUndefined(props?.item?.metadata?.status_code) ? <span><Slash /></span> :
                        props.item.metadata.status_code == 200 ? <span><V /></span> :
                            props.item.metadata.status_code == 403 ? <span><X />Permission Denied</span> :
                                props.item.metadata.status_code == 401 ? <span><X />Authorization Error</span> :
                                    props.item.metadata.status_code == 429 ? <span><X />Rate Exceeded</span> :
                                        <span><X />Unhandled Error ({props.item.metadata.status_code})</span>

                }
            </>
        )
    }

    refresh = async (integrationId) => {

        this.setState({ isLoading: true })
        const metadataRes = await this.props.getAwsFetchingMetadata(integrationId);
        if (!metadataRes.ok) {
            this.setState({ isLoading: false, hasError: "Fetching metadata is not valid" });
            return;
        }

        this.setState({ isLoading: false, metadata: metadataRes.metadata });
    }

    render() {
        const { isLoading, integration, hasError, filters, metadata } = this.state;
        const filteredMetadata = _.isEmpty(filters) ? metadata : _.filter(metadata, row => _.filter(filters, filter => _.get(row, filter["type"]) == filter["value"]).length == filters.length);

        if (isLoading) {
            return (<Loading />);
        }

        if (!_.isEmpty(hasError) || _.isEmpty(integration)) {
            return <OopsPage>Something went wrong</OopsPage>
        }

        const suceeded = _.filter(filteredMetadata, row => row?.metadata?.status_code == 200).length;
        const total = (filteredMetadata || []).length;

        const percentage = Math.round(suceeded / Math.max(1.0, total * 1.0) * 100);
        const badge = percentage < 60 ? "danger" : percentage < 85 ? "warning" : "success";
        return (
            <Card textAlign="left">
                <CardBody>
                    <PercentageBar
                        header={<>
                            <button className="btn bg-gray-100" id="unmanaged-resources-refresh" type="button" onClick={() => this.refresh(integration.id)}><i className="nav-icon i-Repeat-3"></i></button>
                            <span>AWS Account - {integration.accountNumber} ({integration.name})</span>
                        </>}
                        percentage={percentage}
                        animated={true}
                        striped={true}
                        badge={badge} />

                    <div className="row">
                        <div className="col-md-1">
                            <Selector
                                id="region"
                                disabledIfNoOptions={true}
                                options={this.getSelectorOptions(filteredMetadata, "region")}
                                onSelect={this.selectRegion}
                                selectPlaceholder="Region" />
                        </div>
                        <div className="col-md-1">
                            <Selector
                                id="service"
                                disabledIfNoOptions={true}
                                options={this.getSelectorOptions(filteredMetadata, "service")}
                                onSelect={this.selectService}
                                selectPlaceholder="Service" />
                        </div>
                        <div className="col-md-1">
                            <Selector
                                id="resource"
                                disabledIfNoOptions={true}
                                options={this.getSelectorOptions(filteredMetadata, "resource")}
                                onSelect={this.selectResource}
                                selectPlaceholder="Resource" />
                        </div>
                        <div className="col-md-1">
                            <Selector
                                id="metadata.status_code"
                                disabledIfNoOptions={true}
                                options={this.getSelectorOptions(filteredMetadata, "metadata.status_code")}
                                onSelect={this.selectStatusCode}
                                selectPlaceholder="Status Code" />
                        </div>

                        <div className="col-md-8">
                            {_.map(filters, filter => {
                                return <div key={`${filter.type}_${filter.key}`} className="tag"><span>#</span> {filter.value} <a role="button" className="tag-i" onClick={() => this.removeSelectedFilter(filter)}>×</a></div>
                            })}
                        </div>
                    </div>
                    <br />

                    <Table
                        key="fetching-status"
                        isFullWidth={true}
                        isBordeless={true}
                        className="ul-contact-list-table"
                        id="contact_list_table"

                        isHeaderPurple={true}
                        columns={
                            [
                                { id: 'service', value: "AWS Service", component: "text" },
                                { id: 'resource', value: 'Resource', component: "text" },
                                { id: 'region', value: 'Region', component: "text" },
                                { id: 'updatedAt', value: 'Last Updated', component: this.LastUpdatedColumn },
                                { id: 'metadata.status_code', value: 'Status', component: this.StatusColumn }
                            ]}

                        data={filteredMetadata}
                    >
                    </Table>
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        integrations: state.integrationsReducer.aws.integrations,
        hasError: state.integrationsReducer.aws.hasError
    };
}
const mapDispatchToProps = {
    getAwsIntegrations,
    getAwsFetchingMetadata
};

AwsFetchingStatus = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AwsFetchingStatus);


export default withRouter(AwsFetchingStatus);