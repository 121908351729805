import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getActiveFeatures } from "./redux/actions/activeFeaturesActions";
import Environments from "./components/environments/environments";
import ListStacks from "./components/environments/stack/listStacks";
import Stack from "./components/environments/stack/stack";
import StateViewer from "./components/environments/stack/stateViewer";
import Layout from "./shared/layout";
import GitHub from "./components/vcs/github";
import NotFound from "./shared/notFound";
import Users from "./components/users/users";
import IntegrationView from "./components/integrations/view";
import IntegrationAwsCreate from "./components/integrations/aws/awsInstallationWizard";
import IntegrationGcpCreate from "./components/integrations/gcp/gcpInstallationWizard";
import IntegrationTfcCreate from "./components/integrations/tfc/tfcInstallationWizard";
import IntegrationK8sWizard from "./components/integrations/k8s/k8sOnboardingWizard";
import AwsFetchingStatus from "./components/integrations/aws/fetchingStatus";
import Main from "./components/main/main";
import Exclusions from "./components/exclusions/exclusions";
import CustomExclusions from "./components/exclusions/customExclusion";
import DriftsHistory from "./components/drifts/driftsHistory";
import DriftViewer from "./components/drifts/driftViewer";
import SlackIntegration from "./components/integrations/slack/slackIntegration";
import AssetsInventory from './components/assetsInventory/assetsInventory';
import createIntegration from "./components/integrations/createIntegration";
import TeamsIntegration from './components/integrations/teams/teamsIntegration';

const RouteWrapper = function RouteWrapper({
  component: Component,
  id: Id,
  layout: Layout,
  breadcrumb: Breadcrumb,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        _.isEmpty(Layout) ? (
          <Component {...props} />
        ) : (
          <Layout selected={Id} {...props} breadcrumb={Breadcrumb}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

class MainWrapper extends PureComponent {
  async componentDidMount() {
    await this.props.getActiveFeatures();
  }

  render() {
    const breadcrumbDashboard = { text: "Dashboard", link: "/" };
    const breadcrumbStates = { text: "States", link: "/stack/states" };
    const breadcrumbExclusions = {
      text: "Exclusions",
      link: "/exclusions/rules",
    };

    const breadcrumbDrifts = {
      text: "Drifts",
      link: "/drifts",
    };

    return (
      <Router>
        <Switch>
          <RouteWrapper
            id="/"
            exact
            path="/"
            component={Main}
            layout={Layout}
          />

          <RouteWrapper
            id="workspace/states"
            path="/environment/:environmentId/stack/:stackId/state/:stateId"
            breadcrumb={[breadcrumbDashboard, "Environments", "Stacks", "state"]}
            component={StateViewer}
            layout={Layout}
          />

          <RouteWrapper
              id="environment/stack"
              breadcrumb={[breadcrumbDashboard, "Environments", "Stacks"]}
              path="/environment/:environmentId/stack/:stackId/:tab?"
              component={Stack}
              layout={Layout}
          />

          <RouteWrapper
              id="environment/stack"
              breadcrumb={[breadcrumbDashboard, "Environments", "Stacks"]}
              path="/environment/:environmentId"
              component={ListStacks}
              layout={Layout}
          />

          <RouteWrapper
              id="environments"
              breadcrumb={[breadcrumbDashboard, "Environments"]}
              path="/environment"
              component={Environments}
              layout={Layout}
          />

          <RouteWrapper
            id="users"
            breadcrumb={[breadcrumbDashboard, "Users"]}
            path="/users"
            component={Users}
            layout={Layout}
          />

          <RouteWrapper
            id="integrations/create"
            breadcrumb={[breadcrumbDashboard, "Integrations", "Create"]}
            path="/integrations/create"
            component={createIntegration}
            layout={Layout}
          />

          <RouteWrapper
            id="integrations/view"
            breadcrumb={[breadcrumbDashboard, "Integrations"]}
            path="/integrations/view"
            component={IntegrationView}
            layout={Layout}
          />

          <RouteWrapper
            id="integrations/aws/create"
            breadcrumb={[breadcrumbDashboard, "Integrations", "Create"]}
            path="/integrations/aws/create"
            component={IntegrationAwsCreate}
            layout={Layout}
          />

          <RouteWrapper
              id="integrations/gcp/create"
              breadcrumb={[breadcrumbDashboard, "Integrations", "Create"]}
              path="/integrations/gcp/create"
              component={IntegrationGcpCreate}
              layout={Layout}
          />

          <RouteWrapper
              id="integrations/tfc/create"
              breadcrumb={[breadcrumbDashboard, "Integrations", "Create"]}
              path="/integrations/tfc/create"
              component={IntegrationTfcCreate}
              layout={Layout}
          />

          <RouteWrapper
            id="integrations/aws/fetching"
            breadcrumb={[breadcrumbDashboard, "Integrations", "Fetching Status"]}
            path="/integrations/aws/fetching/:integrationId"
            component={AwsFetchingStatus}
            layout={Layout}
          />

          <RouteWrapper
            id="integrations/vcs/github"
            exact
            path="/integrations/vcs/github"
            breadcrumb={[breadcrumbDashboard, "Integrations", "VCS"]}
            component={GitHub}
            layout={Layout}
          />

          <RouteWrapper
            id="integrations/k8s"
            breadcrumb={[breadcrumbDashboard, "Integrations", "Kubernetes"]}
            path="/integrations/k8s"
            component={IntegrationK8sWizard}
            layout={Layout}
          />

          <RouteWrapper
            id="integrations/slack"
            exact
            path="/integrations/slack"
            breadcrumb={[breadcrumbDashboard, "Integrations", "Slack"]}
            component={SlackIntegration}
            layout={Layout}
          />

          <RouteWrapper
            id="integrations/teams"
            exact
            path="/integrations/teams"
            breadcrumb={[breadcrumbDashboard, "Integrations", "Teams"]}
            component={TeamsIntegration}
            layout={Layout}
          />
          <RouteWrapper
            id="exclusions/rules"
            path="/exclusions/rules"
            breadcrumb={[
              breadcrumbDashboard,
              breadcrumbExclusions,
              "Default Rules",
            ]}
            component={Exclusions}
            layout={Layout}
          />
          <RouteWrapper
            id="exclusions/custom"
            path="/exclusions/custom"
            breadcrumb={[breadcrumbDashboard, breadcrumbExclusions, "Custom"]}
            component={CustomExclusions}
            layout={Layout}
          />

          <RouteWrapper
            //MUST BE BEFORE THE "/drifts"
            id="drifts/viewer"
            path="/drifts/:id"
            breadcrumb={[breadcrumbDashboard, breadcrumbDrifts, "View"]}
            component={DriftViewer}
            layout={Layout}
          />

          <RouteWrapper
            id="drifts"
            path="/drifts"
            breadcrumb={[breadcrumbDashboard, breadcrumbDrifts]}
            component={DriftsHistory}
            layout={Layout}
          />

          <RouteWrapper
            id="inventory"
            path="/inventory"
            breadcrumb={[breadcrumbDashboard, "Inventory"]}
            component={AssetsInventory}
            layout={Layout}
          />

          {/* 404 page */}
          <Route
            authRequired={true}
            path="*"
            exact={true}
            component={NotFound}
          />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {
  getActiveFeatures,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MainWrapper
);
