import React, { useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import store from "./store";
import CookiesManager from "./utils/cookiesManager";
import MainWrapper from "./mainWrapper";
import Loading from "./shared/loading";
import CreateAccount from "./components/createAccount";
import { AUTH0_NS } from "./consts/config";
import AuthError from "./components/authError";

//Do not remove this lines - it is mandatory for the UI renedering
import "./public/dist-assets/css/themes/lite-purple.min.css";
import "./public/dist-assets/css/plugins/perfect-scrollbar.min.css";

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    error,
  } = useAuth0();
  // Check whether the client is authenticated by usage of the Auth0 SDK.
  // Set up a state object that will hold the client's access token
  // (if there is one).
  const [token, setToken] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setToken(await getAccessTokenSilently());
      } catch (e) {
        if (e.error === "login_required" || e.error === "consent_required") {
          loginWithRedirect();
        } else {
          throw e;
        }
      }
    })();
  }, [getAccessTokenSilently, loginWithRedirect]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <AuthError error={error} />;
  }

  if (isAuthenticated) {
    // we have an authenticated user, store their access token in a cookie.
    // TODO: find a way to make API requests without having to store the
    // token in a cookie
    const mgr = new CookiesManager();
    mgr.setAccessToken(token);

    // is there an account ID for the user in the app_metadata from
    // Auth0?
    const accountID = user[`${AUTH0_NS}/account_id`];

    if (!accountID)
      // user has no account set up, show the account creation form
      return <CreateAccount />;

    // user has an account, everything is fine, continue to the app
    return (
      <ReduxProvider store={store}>
        <MainWrapper />
      </ReduxProvider>
    );
  }

  // we do not have an authenticated user, redirect client to authenticate
  // via Auth0
  loginWithRedirect();
  return null;
};

export default App;
