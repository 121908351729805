import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from "../../consts/config";

export const GET_ALL_RUNS = "GET_ALL_RUNS";

const ITEMS_IN_PAGE = 50;

const getAllRuns = (stackId, page = 0) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const runsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/orchestrator/stack/${stackId}/runs`, "GET");

        const runs = await runsReq.json();
        dispatch({
            type: GET_ALL_RUNS,
            payload: { runs }
        });

        return runs;
    });
}

export {
    getAllRuns
}
