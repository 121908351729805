import React from 'react';
import _ from 'lodash';

export default class ListItem extends React.Component {

    render() {
        const { id, header, rightComponent, leftComponent } = this.props;
        const textAlign = _.isUndefined(this.props.textAlign) ? "left" : this.props.textAlign;

        const clickable = _.isUndefined(this.props.clickable) ? false : this.props.clickable;
        return <span className={`list-group-item flex-column align-items-start ${clickable ? "list-group-item-action" : ""}`}>
            <div className="d-flex w-100 justify-content-between">
                {leftComponent}
                <div className={textAlign == "left" ? "text-left" : textAlign == "center" ? "text-center" : ""}>
                    <h5 className="mb-1">{header}</h5>
                    {this.props.children}
                </div>
                {rightComponent}
            </div>
        </span>
    }
}