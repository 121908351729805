import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import SummaryWindowCard from './summaryWindowCard';
import InvenroryLoader from '../inventory/inventoryLoader';
import Loading from '../../shared/loading';

class SummaryWindow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resourcesCategories: null,
            isLoading: false
        };
    }

    onInventoryLoad = async (resourcesCategories) => {
        this.setState({ isLoading: true })
        this.setState({ resourcesCategories });
        this.setState({ isLoading: false })
    }

    render() {
        const { resourcesCategories, isLoading } = this.state;
        if (isLoading) return <Loading />
        return <>
            {/* 
                defaultExclusions and customExclusions configured to false because they already been loaded in the main component 
                TODO: check we when we configure them to True, the main page start rendering in loop
            */}
            <InvenroryLoader onLoadInventory={this.onInventoryLoad} defaultExclusions={false} customExclusions={false} />

            <div className="row">
                {_.map(resourcesCategories, category =>
                    <SummaryWindowCard key={category.id}
                        id={category.id}
                        header={category.header}
                        icon={category.icon}
                        resourceTypes={category.resourceTypes}
                        onLoad={category.onLoad}
                        isDisabled={_.isUndefined(category.isDisabled) ? false : category.isDisabled} />)}
            </div>
        </>
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {

};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SummaryWindow);