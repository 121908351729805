import React from 'react';
import _ from 'lodash';

class Wizard extends React.Component {

    render() {
        const { children } = this.props;
        return (
            <div id="smartwizard" className="sw-main sw-theme-default">
                {children}
            </div>
        )
    }
}

class WizardMenu extends React.Component {

    render() {
        const { children } = this.props;
        return (
            <ul className="nav nav-tabs step-anchor">
                {children}
            </ul>
        )
    }
}

class WizardMenuItem extends React.Component {

    render() {
        const { header, description, isActive } = this.props;
        return (
            <li className={isActive ? "nav-item active" : "nav-item done"}><a href={`#`} className="nav-link"><h4>{header}</h4><h6>{description}</h6></a></li>
        )
    }
}

class WizardContainer extends React.Component {

    render() {
        const { children } = this.props;
        return (
            <div className="sw-container tab-content" style={{ minHeight: '124px' }}>
                {children}
            </div>
        )
    }
}

class WizardPage extends React.Component {

    render() {
        const { id, isActive, children } = this.props;
        return (
            <div id={id} className="tab-pane step-content" style={isActive ? { display: 'block' } : { display: 'none' }}>
                {children}
            </div>
        )
    }
}

class WizardBottom extends React.Component {

    render() {
        const { onPrevStep, onNextStep, onComplete, onCancel } = this.props;
        const isPrevEnabled = _.isUndefined(this.props.isPrevEnabled) ? true : this.props.isPrevEnabled;
        const isNextEnabled = _.isUndefined(this.props.isNextEnabled) ? true : this.props.isNextEnabled;
        const isCompleteEnabled = _.isUndefined(this.props.isCompleteEnabled) ? true : this.props.isCompleteEnabled;
        const isCancelEnabled = _.isUndefined(this.props.isCancelEnabled) ? true : this.props.isCancelEnabled;

        const isCancelCompleteDisplay = _.isUndefined(this.props.isCancelCompleteDisplay) ? true : this.props.isCancelCompleteDisplay;


        return (<div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
            <div className="btn-group mr-2 sw-btn-group" role="group">
                <button className={isPrevEnabled ? 'btn btn-secondary sw-btn-prev' : 'btn btn-secondary sw-btn-prev disabled'} onClick={onPrevStep} type="button">Previous</button>
                <button className={isNextEnabled ? 'btn btn-secondary sw-btn-next' : 'btn btn-secondary sw-btn-next disabled'} onClick={onNextStep} type="button">Next</button>
            </div>

            {isCancelCompleteDisplay ?
                <div className="btn-group mr-2 sw-btn-group-extra" role="group">
                    <button className={isCompleteEnabled ? 'btn btn-info' : 'btn btn-info disabled'} onClick={onComplete}>Complete</button>
                    <button className={isCancelEnabled ? 'btn btn-danger' : 'btn btn-danger disabled'} onClick={onCancel}>Cancel</button>
                </div> : null
            }
        </div>)
    }
}

export {
    Wizard,
    WizardMenu,
    WizardMenuItem,
    WizardContainer,
    WizardPage, WizardBottom
};