import _ from 'lodash';
import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from '../../consts/config';

export const GET_EXCLUSIONS = "GET_EXCLUSIONS";
export const UPDATE_EXCLUSION = "UPDATE_EXCLUSION";
export const ANALYZE_EXCLUDED_RULES = "ANALYZE_EXCLUDED_RULES";

const getExclusions = () => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions`, "GET");
        const rules = await req.json();
        dispatch({
            type: GET_EXCLUSIONS,
            payload: { rules }
        });
    });
}

const updateExclusion = (id, name, description, isEnabled) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/${id}`, "PUT", {
            name,
            description,
            isEnabled
        });
        dispatch({
            type: UPDATE_EXCLUSION,
            payload: {}
        });
        return req;
    });
}

const analyzeExcludedRules = (defaultExclusions = true, customExclusions = true) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        let defaultExcludedResources = [];
        let customExcludedResources = [];

        if (defaultExclusions) {
            const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/analyze`, "POST");
            defaultExcludedResources = await req.json();
        }

        if (customExclusions) {
            const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/custom/analyze`, "POST");
            customExcludedResources = await req.json();
        }

        const excludedResources = _.uniqWith(_.union(defaultExcludedResources, customExcludedResources), _.isEqual);
        
        dispatch({
            type: ANALYZE_EXCLUDED_RULES,
            payload: { excludedResources }
        });
    });
}

export {
    getExclusions,
    updateExclusion,
    analyzeExcludedRules
}
