import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getExclusions, updateExclusion } from '../../redux/actions/exclusionsActions';
import Loading from '../../shared/loading';
import ExclusionMenu from './exclusionMenu';
import PageSeperator from '../../shared/layouts/pageSeperator';
import PageLayout from '../../shared/layouts/pageLayout';
import ListGroup from '../../shared/lists/listGroup';
import ListItem from '../../shared/lists/listItem';
import OnOff from '../../shared/toggle/onOffToggle';
import {Card, CardBody, CardHeader} from '../../shared/layouts/cards';
class Exclusions extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isLoading: true, rules: [] };
    }

    async componentDidMount() {
        await this.props.getExclusions();
        this.setState({ isLoading: false, rules: this.props.rules });
    }

    changeEnableStatus = async (rule) => {
        const { rules } = this.state;
        const index = _.findIndex(rules, x => x.id == rule.id);

        rules[index].isLoading = true;
        this.setState({ rules });

        const req = await this.props.updateExclusion(rule.id, rule.name, rule.description, !rule.isEnabled);

        rules[index].isLoading = false;
        this.setState({ rules });

        if (!req.ok) {
            rules[index].hasError = "An error occurred";
            this.setState({ rules });
            return;
        }

        rules[index].isEnabled = !rules[index].isEnabled;
        this.setState({ rules });
    }

    render() {
        const { isLoading, rules } = this.state;

        if (isLoading) {
            return <Loading />
        }

        return <>
            <ExclusionMenu />
            <PageSeperator />

            <PageLayout>
                <Card textAlign="left">
                    <CardBody>
                        <CardHeader>Exclusion Rules</CardHeader>
                        
                        {/* <p>Exclusion rules created to ignore some resources in your cloud footprint.</p> */}
                        <ListGroup>

                            {_.map(rules, rule => {
                                return <ListItem key={rule.id} id={rule.id} header={rule.name} rightComponent={<OnOff hasError={rule.hasError} isLoading={rule.isLoading} isEnabled={rule.isEnabled} changeOnOffStatus={() => this.changeEnableStatus(rule)} />}>
                                    {rule.description}
                                </ListItem>
                            })}
                        </ListGroup>
                    </CardBody>
                </Card>
            </PageLayout>
        </>
    }
}


const mapStateToProps = (state) => {
    return {
        rules: state.exclusionsReducer.rules || [],
    };
};

const mapDispatchToProps = {
    getExclusions,
    updateExclusion
};


Exclusions = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Exclusions);
export default withRouter(Exclusions);
