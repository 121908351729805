import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getAllProfiles,
  deleteUser,
} from "../../redux/actions/profilesActions";
import {
  getAllKeyPairs,
  createKeyPair,
  deleteKeyPair,
} from "../../redux/actions/keyPairsActions";
import Loading from "../../shared/loading";
import CreateUser from "./createUser";
import { TimeParser } from "../../shared/parsers";
import {
  Card,
  CardBody,
  CardDescription,
  CardHeader,
} from "../../shared/layouts/cards";
import BasicModal from "../../shared/basicModal";
import ConfirmationModal from "../../shared/confirmationModal";
import CopyToClipboard from "../../shared/copyToClipboard";
import { Table } from "../../shared/tables/tables";
import "./users.scss";

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profiles: [],
      keyPairs: [],
      isLoading: true,
      creationModalOpen: false,
      keyPairModalOpen: false,
      keyPairModalError: false,
      createdAccessKey: "",
      createdSecretKey: "",
      confirmationModalOpen: false,
      confirmationModalAction: "none",
      confirmationModalHandler: _.noop,
      isCliModalOpen: false
    };
  }

  async componentDidMount() {
    const profiles = await this.props.getAllProfiles();
    const keyPairs = await this.props.getAllKeyPairs();
    this.setState({ profiles, keyPairs, isLoading: false });
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.profiles !== nextProps.profiles) {
      this.setState({ profiles: nextProps.profiles });
    }
    if (this.props.keyPairs !== nextProps.keyPairs) {
      this.setState({ keyPairs: nextProps.keyPairs });
    }
  }

  closeCreationModal = () => {
    this.setState({ creationModalOpen: false });
  };

  openCreationModal = () => {
    this.setState({ creationModalOpen: true });
  };

  createUser = async () => {
    this.setState({ creationModalOpen: false });
  };

  confirmUserRemoval = (id, name) => {
    this.setState({
      confirmationModalOpen: true,
      confirmationModalAction: `delete user ${name}`,
      confirmationModalHandler: () => {
        this.removeUser(id);
      },
    });
  };

  async removeUser(id) {
    const { profiles } = this.state;

    this.setState({ isLoading: true });
    await this.props.deleteUser(id);
    this.setState({
      profiles: _.reject(profiles, { user_id: id }),
      confirmationModalOpen: false,
      isLoading: false,
    });
  }

  async updateUser(id) {
    console.log(id);
  }

  createKeyPair = async () => {
    this.setState({ isLoading: true });
    try {
      const keypair = await this.props.createKeyPair();
      const keyPairs = await this.props.getAllKeyPairs();
      this.setState({
        isLoading: false,
        keyPairs,
        keyPairModalOpen: true,
        keyPairModalError: false,
        createdAccessKey: keypair.accessKey,
        createdSecretKey: keypair.secretKey,
      });
    } catch (ex) {
      this.setState({
        isLoading: false,
        keyPairModalOpen: true,
        keyPairModalError: true,
      });
    }
  };

  closeKeyPairModal = () => {
    this.setState({ keyPairModalOpen: false });
  };

  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };

  confirmKeyPairRemoval = (accessKey) => {
    this.setState({
      confirmationModalOpen: true,
      confirmationModalAction: `delete access key ${accessKey}`,
      confirmationModalHandler: () => {
        this.removeKeyPair(accessKey);
      },
    });
  };

  removeKeyPair = async (accessKey) => {
    const { keyPairs } = this.state;

    this.setState({ isLoading: true });
    await this.props.deleteKeyPair(accessKey);
    this.setState({
      isLoading: false,
      confirmationModalOpen: false,
      keyPairs: _.reject(keyPairs, { access_key: accessKey }),
    });
  };

  openCliModal = async () => {
    this.setState({ isCliModalOpen: true })
  }
  onCloseCliModal = async () => {
    this.setState({ isCliModalOpen: false })
  }

  render() {
    const {
      profiles,
      isLoading,
      creationModalOpen,
      keyPairs,
      keyPairModalOpen,
      keyPairModalError,
      createdAccessKey,
      createdSecretKey,
      confirmationModalOpen,
      confirmationModalAction,
      confirmationModalHandler,
      isCliModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const usersTableColumns = [
      { id: 1, value: "Name" },
      { id: 2, value: "Created at:" },
      { id: 3, value: "Last login:" },
      { id: 4, value: "Status" },
      { id: 5, value: "Action" },
    ];

    const accessKeysTableColumns = [
      { id: 1, value: "Access Key" },
      { id: 2, value: "Created at:" },
      { id: 3, value: "Last login:" },
      { id: 4, value: "Login Count" },
      { id: 5, value: "Action" },
    ];

    // create the baseline for the link to the cli download.
    // by default, use the production cli link.
    // if the running environment is staging, use the staging cli link.
    let cliLinkPrefix = "www"
    if (process.env.NODE_ENV !== 'production'){
      cliLinkPrefix = "stag"
    }

    return (
      <div className="users">
        <Card textAlign="left">
          <button
            className="btn btn-infralight"
            onClick={this.openCreationModal}
            id="alert-success"
            type="button"
          >
            Invite User
          </button>

          <CardBody>
            <CardHeader>User Management</CardHeader>
            <CardDescription>Manage users in the organization</CardDescription>

            <div className="table-responsive">
              <Table columns={usersTableColumns} isMinimialPurple>
                {_.map(profiles, (profile, index) => {
                  return (
                    <tr key={profile.user_id}>
                      <td>{profile.name}</td>
                      <td>{<TimeParser value={profile.created_at} />}</td>
                      <td>{<TimeParser value={profile.last_login} />}</td>
                      <td>
                        {profile.email_verified ? (
                          <span className="badge infralight-badge">
                            Verified
                          </span>
                        ) : (
                          <span className="badge badge-light">
                            Not verified yet
                          </span>
                        )}
                      </td>
                      <td>
                        {/* <a className="text-success mr-2" href="#"  onClick={() => this.updateUser(profile.id)}><i className="nav-icon i-Pen-2 font-weight-bold"></i></a> */}
                        <a
                          className="text-danger mr-2 alert-confirm pl-3"
                          href="#"
                          onClick={() =>
                            this.confirmUserRemoval(
                              profile.user_id,
                              profile.name
                            )
                          }
                        >
                          <i className="nav-icon i-Close-Window font-weight-bold"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </CardBody>
        </Card>
        <CreateUser
          isOpen={creationModalOpen}
          onClose={this.closeCreationModal}
          onCreate={() => {
            this.createUser();
          }}
        />
        <Card textAlign="left">
          <button
            className="btn btn-infralight-second"
            onClick={this.createKeyPair}
            id="alert-success"
            type="button"
          >
            Create Key-Pair
          </button>
          <button
            className="btn btn-infralight"
            onClick={this.openCliModal}
            id="Infralight CLI"
            type="button"
          >
            Download InfraLight CLI
          </button>
          <BasicModal
              isOpen={isCliModalOpen}
              onClose={this.onCloseCliModal}
              header="Infralight CLI"
              isShowSubmitButton={false}
          >
            <div><a className="btn" href={`https://${cliLinkPrefix}.infralight.cloud/files/cli/infralight`} download>Download InfraLight CLI For Linux</a></div>
            <div><a className="btn" href={`https://${cliLinkPrefix}.infralight.cloud/files/cli/infralight-mac`} download>Download InfraLight CLI For Mac</a></div>
          </BasicModal>
          <CardBody>
            <CardHeader>Access Keys</CardHeader>
            <CardDescription>
              Manage access keys in the organization.
              <br />
              Access keys allow programatically accessing the Infralight API,
              and is required by on-premises Infralight components such as the{" "}
              <code>k8s-collector</code> and{" "} <code>InfraLight CLI</code>.
            </CardDescription>

            {keyPairs.length ? <div className="table-responsive">
              <Table columns={accessKeysTableColumns} isMinimialPurple>
                {_.map(keyPairs, (keyPair, index) => {
                  return (
                    <tr key={keyPair.access_key}>
                      <td>{keyPair.access_key}</td>
                      <td>{<TimeParser value={keyPair.created_at} />}</td>
                      <td>{<TimeParser value={keyPair.last_login} />}</td>
                      <td>{keyPair.login_count || 0}</td>
                      <td>
                        <a
                          className="text-danger mr-2 alert-confirm pl-2"
                          href="#"
                          onClick={() =>
                            this.confirmKeyPairRemoval(keyPair.access_key)
                          }
                        >
                          <i className="nav-icon i-Close-Window font-weight-bold"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div> : null}

          </CardBody>
        </Card>
        <BasicModal
          isOpen={keyPairModalOpen}
          onClose={this.closeKeyPairModal}
          isShowSubmitButton={false}
          header={keyPairModalError ? "Error" : "Keypair Successfully Created"}
        >
          {keyPairModalError ? (
            <div className="alert-card alert-danger" role="alert">
              <p>
                Keypair creation failed, please contact Infralight for support.
              </p>
            </div>
          ) : (
            <>
              <p>
                The keypair was successfully created and can now be used to
                access the Infralight API programatically. Please securely store
                the access and secret keys. Once this modal is closed, the
                secret key will not be accessible again.
              </p>

              <p>
                <strong>Access Key:</strong>
              </p>
              <CopyToClipboard text={createdAccessKey} />

              <p>
                <strong>Secret Key:</strong>
              </p>
              <CopyToClipboard text={createdSecretKey} />
            </>
          )}
        </BasicModal>
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          action={confirmationModalAction}
          onYes={confirmationModalHandler}
          onNo={this.closeConfirmationModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profiles: state.profilesReducer.profiles,
    keyPairs: state.keyPairsReducer.keyPairs,
  };
};

const mapDispatchToProps = {
  getAllProfiles,
  deleteUser,
  getAllKeyPairs,
  createKeyPair,
  deleteKeyPair,
};

Users = compose(connect(mapStateToProps, mapDispatchToProps))(Users);
export default withRouter(Users);
