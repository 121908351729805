import React from 'react';
import _ from 'lodash';

export default class OnOff extends React.Component {
    
    render() {
        const {hasError, isLoading, isEnabled, changeOnOffStatus} = this.props;
        return <>
            <label className="switch pr-5 switch-primary mr-3">
            {
                _.isEmpty(hasError) ?
                    isLoading == true ? <span className="spinner-glow spinner-glow-primary"></span> :
                        <span>{isEnabled ? "On" : "Off"}</span> :
                    <span className="text-10 text-center text-danger">An error occurred</span>
            }
            <input type="checkbox" checked={isEnabled ? "checked" : ""} onChange={changeOnOffStatus} /><span className="slider"></span>
        </label>
        </>
    }
}
