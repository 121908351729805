import React from 'react';
import _ from 'lodash';

export default class Tab extends React.Component {
    render() {
        const { exact, link, icon, name } = this.props;

        const isExact = _.isUndefined(exact) ? false : exact;
        const pathname = _.isUndefined(this.props.pathname) ? "" : this.props.pathname;
        const isActive = !_.isUndefined(this.props.isActive) ? this.props.isActive :isExact ? pathname == link : pathname.startsWith(link);
        const onClick = _.isUndefined(this.props.onClick) ? ()=>{} : this.props.onClick;

        return <li className="nav-item">
            <a className={isActive ? "nav-link active show" : "nav-link"} href={link} onClick={onClick} >{_.isEmpty(icon) ? null : _.isString(icon) ? <i className={`nav-icon ${icon || ""} mr-1`}></i> : icon}{name}</a>
        </li>
    }
}