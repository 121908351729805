import action from "../middleware";

import { BACKEND_URL } from '../../consts/config';
import CookiesManager from '../../utils/cookiesManager';
import RequestWrapper from '../../utils/requestWrapper';

export const GET_STACKS = "GET_STACKS";
export const CREATE_STACK = "CREATE_STACK";
export const DELETE_STACK = "DELETE_STACK";

const getStacks = () => {
    return action(async dispatch => {

        const cookieMgr = new CookiesManager();
        const accessToken = cookieMgr.getAccessToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        };

        const response = await fetch(`${BACKEND_URL}/environments/stacks`, requestOptions);

        if (response.status == 401) {
          cookieMgr.setAccessToken(null, 0);
          window.location.reload();
        }

        const data = await response.json();

        dispatch({
            type: GET_STACKS,
            payload: { data }
        });

    });
}

const getStacksByEnvironmentId = (environmentId) => {
    return action(async dispatch => {

        const cookieMgr = new CookiesManager();
        const accessToken = cookieMgr.getAccessToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        };

        const response = await fetch(`${BACKEND_URL}/environments/${environmentId}/stack`, requestOptions);

        if (response.status == 401) {
          cookieMgr.setAccessToken(null, 0);
          window.location.reload();
        }

        const data = await response.json();

        dispatch({
            type: GET_STACKS,
            payload: { data }
        });

    });
}

const createStackByEnvironmentId = (environmentId, name, vcs, awsIntegration, terraformVersion, isTerragrunt, owner) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const payload = {
            name,
            vcs,
            awsIntegration,
            terraformVersion,
            isTerragrunt,
            owner
        };

        const res = await requestWrapper.sendRequest( `${BACKEND_URL}/environments/${environmentId}/stack`, "POST", payload);
        dispatch({
            type: CREATE_STACK,
            payload: {}
        });
        
        if(res.ok){
            const json = await res.json();
            return {ok: res.ok, stack: json};
        }

        return {ok: false, stack: null};
    });
}


const deleteStackByEnvironmentId = (environmentId, stackId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deletedStackReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/environments/${environmentId}/stack/${stackId}`,
      "DELETE"
    );

    if(!deletedStackReq.ok){
      return {ok: false, response: null };
    }
    const data = await deletedStackReq.json();
    return {ok: true, response: data};
  });
};

export {
    getStacks,
    createStackByEnvironmentId,
    deleteStackByEnvironmentId,
    getStacksByEnvironmentId
}
