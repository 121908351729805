import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { changeSelectedCategory } from "../../../redux/actions/driftsActions";
import { Card, CardBody } from "../../../shared/layouts/cards";

class DriftFasset extends React.Component {

    onChangeCategory = async (category) => {
        await this.props.changeSelectedCategory(category);
    }

    render() {
        const Tab = (props) => {
            const { onClick, isActive, icon, text } = props;
            return (<a onClick={onClick} className={`list-group-item list-group-item-action border-0 ${isActive ? "active" : ""}`} data-toggle="list" href="#" role="tab" aria-selected="false"><i className={`nav-icon ${icon}`}></i>{text}</a>)
        }

        const { selectedCategory } = this.props;
        return (
            <div className="ul-contact-left-side">
                <Card>
                    <CardBody>
                        <div className="ul-contact-list">
                            <div className="contact-close-mobile-icon float-right mb-2"><i className="i-Close-Window text-15 font-weight-600"></i></div>
                            {/* <!-- modal--> */}
                            {/* <button className="btn btn-outline-secondary btn-block mb-4" type="button" data-toggle="modal" data-target="#exampleModal">ADD CONTACT</button> */}
                            {/* <!-- end:modal--> */}
                            <div className="list-group" id="list-tab" role="tablist">
                                <Tab onClick={() => this.onChangeCategory("resource")} isActive={selectedCategory == "resource"} icon="i-Gears" text="Resource Drifts" />
                                <Tab onClick={() => this.onChangeCategory("configuration")} isActive={selectedCategory == "configuration"} icon="i-Clouds" text="Configuration Drifts" />
                                {/* <label className="text-muted font-weight-600 py-8">MEMBERS</label> */}

                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedCategory: state.driftsReducer.selectedCategory
    };
};

const mapDispatchToProps = {
    changeSelectedCategory
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(DriftFasset);