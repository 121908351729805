import { GET_ALL_STATES, GET_LATEST_STATE, GET_STATE_POLICY } from '../actions/statesActions';

const initialState = {
    states: [],
    selectedPolicy: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_ALL_STATES:
            return { ...state, states: payload.states };

        case GET_LATEST_STATE:
            return { ...state};

        case GET_STATE_POLICY:
            return {...state, selectedPolicy: payload};
        default:
            return state;
    }
}
