import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from '../../consts/config';

export const SEND_METRIC = "SEND_METRIC";

const sendMetric = (text, authorized = true) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const url = `${BACKEND_URL}/metric${!authorized ? "/not_authorized" : ""}`;
        const req = await requestWrapper.sendRequest(url, "POST", { text }, authorized);
        dispatch({
            type: SEND_METRIC,
            payload: {}
        });
    });
}

export {
    sendMetric
}
