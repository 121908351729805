import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import TooltipLink from '../../../shared/tooltip/tooltipLink';
import AwsIcon from '../../../public/images/aws/Res_AWS-OpsWorks_Resources_48_Dark.png';
import "./assetIcon.scss";

class AssetIcon extends React.Component {
    render() {
        const { item, inventory } = this.props;
        const resourceType = inventory[item.assetType];

        const icon = _.isEmpty(resourceType) ? AwsIcon : resourceType.icon;
        const tooltip = _.isEmpty(resourceType) ? "" : resourceType.tooltip;
        return (
            <div className="icon-card">
                <div>
                    <TooltipLink id={`resource_${item.id}`}>
                        <img id="userDropdown" src={icon} style={{ height: '22px', width: '22px' }} alt={tooltip || ""} />
                    </TooltipLink>
                </div>
            </div>
        )
    }
}
export default compose(connect((state) => ({ inventory: state.inventoryReducer }), ({})))(AssetIcon);
