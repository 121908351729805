import React from 'react';
import _ from 'lodash';

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);
    }

    isPrevEnabled = () => {
        const { currentPage } = this.props;
        return currentPage > 1;
    }

    isNextEnabled = () => {
        const { currentPage, countOfResults, maxItemsInPage } = this.props;
        return currentPage * maxItemsInPage  < countOfResults;
    }

    onClickPrev = () => {
        if (!this.isPrevEnabled()) {
            return;
        }

        const { currentPage } = this.props;
        const currentPageStartedFromZero = currentPage-1;
        this.props.onPageMoved(currentPageStartedFromZero - 1);
    }

    onClickNext = () => {
        if (!this.isNextEnabled()) {
            return;
        }

        const { currentPage } = this.props;
        const currentPageStartedFromZero = currentPage-1;
        this.props.onPageMoved(currentPageStartedFromZero + 1 );
    }

    moveToPage = (page) => {
        if (!_.isNumber(page)) {
            return;
        }

        const { maxItemsInPage, countOfResults } = this.props;
        if (page < 1 || page > Math.ceil(countOfResults / maxItemsInPage)) {
            return;
        }

        this.props.onPageMoved(page - 1);
    }

    getPagination = (c, m) => {
        let current = c,
            last = m,
            delta = 2,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    render() {
        const { maxItemsInPage, currentPage, countOfResults } = this.props;
        const pages = this.getPagination(currentPage, Math.ceil(countOfResults / maxItemsInPage));

        return <>
            <nav aria-label="Page navigation">
                <ul className="pagination" id="pagination">
                    <li className={this.isPrevEnabled() ? 'page-item' : 'page-item disabled'} onClick={this.onClickPrev}><a className="page-link" href="#pagination">Previous</a></li>
                    {
                        _.map(pages, page => {
                            return <li key={page} className={!_.isNumber(page) ? "page-item disabled" : page == currentPage ? "page-item active" : "page-item"} onClick={() => this.moveToPage(page)}><a className="page-link" href="#pagination">{page}</a></li>
                        })
                    }
                    <li className={this.isNextEnabled() ? 'page-item' : 'page-item disabled'} onClick={this.onClickNext}><a className="page-link" href="#pagination">Next</a></li>
                </ul>
            </nav>
        </>
    }
}