import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Wizard, WizardMenu, WizardMenuItem, WizardContainer, WizardPage, WizardBottom } from '../../../../shared/smartWizard/wizard';
import Loading from '../../../../shared/loading';

import TerraformInit from './init/terraformInit';
import TerraformPlan from './plan/terraformPlan';
import TerraformApply from './apply/terraformApply';

class TerraformWizard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            hasError: null,
            isInitialized: false,
            isPlan: false,
            isApply: false
        };
    }

    isNextEnabled = () => {
        const { step, isInitialized, isPlan, isApply } = this.state;
        if (step == 1 && isInitialized) {
            return true;
        }

        if (step == 2 && isPlan) {
            return true;
        }

        if (step == 3 && isApply) {
            return true;
        }

        return false;
    }

    isPrevEnabled = () => {
        const { step } = this.state;

        if (step <= 1) {
            return false;
        }
        return true;
    }

    nextStep = async () => {
        if (!this.isNextEnabled()) {
            return;
        }

        this.setState({ step: this.state.step + 1 });
    }

    prevStep = () => {
        if (!this.isPrevEnabled()) {
            return;
        }

        this.setState({ step: this.state.step - 1 });
    }

    cancel = () => {
        //TODO: clean-up
        this.setState({ step: 1 })
    }

    complete = async () => {
        console.log("Complete");
    }

    onFinishToInit = (isInitialized, hasError) => {
        this.setState({isInitialized, hasError});
    }

    onFinishToPlan = (isPlan, hasError) => {
        this.setState({isPlan, hasError});
    }

    onFinishToApply = (isApply, hasError) => {
        this.setState({isApply, hasError});
    }

    render() {
        const { step, isLoading } = this.state;
        const {stack} = this.props;

        if (isLoading) {
            return (<Loading />);
        }

        if (_.isEmpty(stack)) {
            return (<div>missing stack!</div>);
        }

        return (
            <> 
                <div className="row">
                    <div className="col-md-12">
                        <Wizard>
                            <WizardMenu>
                                <WizardMenuItem isActive={step == 1} header="Fetch" description="Fetching last version from github" />
                                <WizardMenuItem isActive={step == 2} header="Plan" description="Planning changes in the infrastructure" />
                                <WizardMenuItem isActive={step == 3} header="Apply" description="Applying changes in the infrastructure" />
                            </WizardMenu>


                            <WizardContainer>
                                <WizardPage id="step1" isActive={step == 1}>
                                    <TerraformInit stack={stack} onFinishToInit={this.onFinishToInit} />
                                </WizardPage>

                                <WizardPage id="step2" isActive={step == 2}>
                                    <TerraformPlan onFinishToPlan={this.onFinishToPlan} />
                                </WizardPage>

                                <WizardPage id="step3" isActive={step == 3}>
                                    <TerraformApply onFinishToApply={this.onFinishToApply} />
                                </WizardPage>

                                <WizardPage id="step4" isActive={step == 4}>
                                    Completed!
                                </WizardPage>

                            </WizardContainer>

                            <WizardBottom
                                onNextStep={this.nextStep}
                                onPrevStep={this.prevStep}
                                onComplete={this.complete}
                                onCancel={this.cancel}
                                isPrevEnabled={this.isPrevEnabled()}
                                isNextEnabled={this.isNextEnabled()}
                                isCancelCompleteDisplay={false}
                            />
                        </Wizard>

                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(TerraformWizard);