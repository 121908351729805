import _ from 'lodash';
import React, { useState, useEffect, useMemo } from "react";
import {useSelector, useDispatch, connect} from "react-redux";

import Loading from "../../shared/loading";
import AssetsTable from "./assetsTable";
import AssetsInventoryHeader from "./assetsInventoryHeader";
import "./assetsInventory.scss";
import InventoryLoader from "../inventory/inventoryLoader";
import {compose} from "redux";

const AssetsInventory = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadedCategories = useSelector((state) => state.homePageReducer.loaded);
  const excludedByResourceType = useSelector(
    (state) => state.exclusionsReducer.excludedByResourceType
  );
  const filteredResourceTypes = useSelector(
    (state) => state.homePageReducer.filtered
  );
  const filteredAwsAccounts = useSelector(
    (state) => state.homePageReducer.filteredAwsAccounts
  );
  const filteredUnmanaged = useSelector(
    (state) => state.homePageReducer.filteredUnmanaged
  );

  console.log('excludedByResourceType',excludedByResourceType);
  console.log('filteredResourceTypes',filteredResourceTypes);
  console.log('filteredAwsAccounts',filteredAwsAccounts);
  console.log('filteredUnmanaged',filteredUnmanaged);
  console.log('loadedCategories',loadedCategories);

  const arrangeData = (inventory) => {
    let assets = [];

    for (const target_group of _.values(inventory)) {
      console.log("Running in target_group");
      const resources = _.map(target_group.resources || [], (resource) => {
        resource.severity = target_group.severity;
        return resource;
      })
      assets = assets.concat(resources);
    }
    assets = _.orderBy(assets, ["severity", "type", "createdAt"], ["desc", "asc", "desc"]);
    return setFetchedData(assets);
  };

  const onInventoryLoad = async (resourcesCategories)=>{

    setIsLoading(true);

    await Promise.all(_.map(resourcesCategories, (resourceCategory) => {
      return resourceCategory.onLoad();
    }))
    const {inventory} = props;
    setIsLoading(false);
    arrangeData(inventory);
  }

  const handleSearchChange = (e) => {
    setSearchVal(e.target.value);
  };

  const handleSetFilter = (selectedFilter) => {
    let newFilters = null;
    switch (selectedFilter) {
      case "allAssets":
        newFilters = [...filters];
        newFilters = _.filter(newFilters, (filter) =>{
          return filter != "managed" && filter != "unmanaged"
        })
        break;

      case "managed":
        newFilters = [...filters, selectedFilter];
        newFilters = _.filter(newFilters, (filter) =>{
          return filter != "unmanaged"
        })
        break;

      case "unmanaged":
        newFilters = [...filters, selectedFilter];
        newFilters = _.filter(newFilters, (filter) =>{
          return filter != "managed"
        })
        break;

      case "allSeverities":
        newFilters = [...filters];
        newFilters = _.filter(newFilters, (filter) =>{
          return filter != "high" && filter != "medium" && filter != "low"
        })
        break;

      case "high":
        newFilters = [...filters, selectedFilter];
        newFilters = _.filter(newFilters, (filter) =>{
          return filter != "medium" && filter != "low"
        })
        break;

      case "medium":
        newFilters = [...filters, selectedFilter];
        newFilters = _.filter(newFilters, (filter) =>{
          return filter != "high" && filter != "low"
        })
        break;

      case "low":
        newFilters = [...filters, selectedFilter];
        newFilters = _.filter(newFilters, (filter) =>{
          return  filter != "high" && filter != "medium"
        })
        break;
      default:
        break;
    }
    // console.log(newFilters);
    setFilters(newFilters);
  };

  // keeping track of search and filter values to filter the data - locally.
  const tableData = useMemo(() => {
    let tableData = fetchedData;
    if (searchVal) {
      tableData = tableData.filter(
        (item) =>
            (item.id  || '').toLowerCase().includes(searchVal.toLowerCase()) ||
            (item.type || '').toLowerCase().includes(searchVal.toLowerCase()) ||
            (item.name || '').toLowerCase().includes(searchVal.toLowerCase()) ||
            (item.region || '').toLowerCase().includes(searchVal.toLowerCase()) ||
            (_.toString(item.severity) || '').toLowerCase().includes(searchVal.toLowerCase())
      );
    }

    if (filters && filters.length) {
      if (filters.includes("managed")) {
        tableData = tableData.filter((item) => item.iacObject != null);
      }
      if (filters.includes("unmanaged")) {
        tableData = tableData.filter((item) => item.iacObject == null);
      }
      if (filters.includes("high")) {
        tableData = tableData.filter((item) => item.severity === 3);
      }
    if (filters.includes("medium")) {
        tableData = tableData.filter((item) => item.severity === 2);
    }
    if (filters.includes("low")) {
        tableData = tableData.filter((item) => item.severity === 1);
    }
    }

    return tableData;
  }, [fetchedData, searchVal, filters]);

  if (loading) {
    return <Loading />;
  }

  return (
      <>
        <InventoryLoader onLoadInventory={onInventoryLoad} defaultExclusions={false} customExclusions={false} />
    <div className="assetsInventory">
      <AssetsInventoryHeader
        handleSearch={handleSearchChange}
        searchVal={searchVal}
        resetSearch={() => setSearchVal("")}
        handleSetFilter={handleSetFilter}
      />
      <AssetsTable tableData={tableData} />
    </div>
      </>
  );
};

const mapStateToProps = (state) => {
  return {
    inventory: state.inventoryReducer
  };
};

const mapDispatchToProps = {
};


export default connect(mapStateToProps, mapDispatchToProps)(AssetsInventory);
