import React from "react";

class Card extends React.Component {
  render() {
    const { textAlign, classes } = this.props;

    return (
      <div
        className={`card ${textAlign === "left" ? "text-left" : ""} ${
          classes ?? ""
        }`}
      >
        {this.props.children}
      </div>
    );
  }
}

class CardBody extends React.Component {
  render() {
    return <div className="card-body">{this.props.children}</div>;
  }
}

class CardHeader extends React.Component {
  render() {
    return (
      <h4 className="card-title mb-2 font-weight-bold">
        {this.props.children}
      </h4>
    );
  }
}

class CardDescription extends React.Component {
  render() {
    return <div className="mb-4">{this.props.children}</div>;
  }
}

export { Card, CardBody, CardHeader, CardDescription };
