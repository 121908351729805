import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import { SideBar, MenuCategory, MenuItem } from "./menuObjects";
import { BranchIcon } from '../shared/icons';
import ActiveFeatures from '../consts/activeFeatures';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { } = this.state;
    const { selected } = this.props;

    return (
      <div className="side-content-wrap">
        <SideBar>
          <MenuCategory
            id="home"
            icon="nav-icon i-Bar-Chart-2"
            name="Dashboard"
            isActive={selected == "/"}
            link="/"
            dataItem="home"
            exact
          />

          <MenuCategory
            id="state"
            icon="nav-icon i-Clouds"
            name="Environments"
            isActive={selected.toLowerCase().startsWith("environment")}
            link="/environment"
            dataItem="states"
          />

          <MenuCategory
            id="inventory"
            icon="nav-icon i-Big-Data"
            name="Inventory"
            isActive={selected.toLowerCase().startsWith("inventory")}
            link="/inventory"
            dataItem="inventory"
          />

          <MenuCategory
            id="drifts"
            name="Drifts"
            isActive={selected.toLowerCase().startsWith("drifts")}
            link="/drifts"
            dataItem="drifts"
          ><BranchIcon size={30} color="#ffffff" />
          </MenuCategory>

          <MenuCategory
            id="integrations"
            name="Integrations"
            isActive={selected.toLowerCase().startsWith("integrations")}
            link="/integrations/view"
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 452.000000 452.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)"
                fill="#ffffff"
                stroke="none"
              >
                <path d="M2235 4414 c-112 -27 -202 -80 -295 -174 -97 -97 -150 -190 -175 -306 -38 -181 -4 -334 119 -523 94 -145 107 -196 61 -227 -33 -22 -125 -17 -560 32 -176 19 -370 38 -431 41 -105 5 -115 4 -167 -20 -61 -28 -107 -85 -127 -157 -27 -94 -72 -479 -106 -903 -18 -230 -15 -261 33 -326 49 -63 137 -78 240 -39 65 24 122 57 234 135 42 29 107 67 145 85 66 31 75 33 185 33 113 0 116 -1 193 -38 98 -49 173 -123 216 -212 147 -308 -106 -659 -457 -632 -95 7 -159 37 -292 136 -58 43 -135 94 -171 112 -58 30 -74 33 -146 34 -74 0 -84 -2 -118 -29 -80 -61 -89 -124 -56 -416 37 -332 73 -518 119 -608 26 -51 87 -105 142 -126 40 -15 166 -16 1574 -14 l1530 3 50 23 c63 29 119 83 153 147 l27 50 3 1259 c2 1123 1 1265 -13 1307 -36 107 -163 199 -275 199 -32 0 -232 -18 -446 -41 -521 -54 -546 -56 -567 -35 -40 40 -18 110 82 274 77 125 101 193 108 307 11 185 -47 336 -181 470 -139 140 -272 196 -466 194 -58 0 -132 -7 -165 -15z m296 -190 c205 -60 347 -263 325 -467 -6 -61 -21 -94 -130 -282 -57 -98 -76 -159 -76 -244 0 -122 64 -210 175 -241 60 -17 152 -11 635 40 430 45 449 44 489 -7 21 -26 21 -30 21 -1247 0 -1207 0 -1221 -20 -1254 -40 -66 60 -62 -1575 -62 l-1486 0 -25 27 c-30 32 -47 91 -73 243 -25 146 -66 539 -57 547 11 12 87 -32 196 -112 58 -43 137 -93 175 -111 180 -87 389 -84 570 5 173 86 294 233 342 414 24 91 21 243 -7 332 -53 169 -171 310 -321 383 -112 56 -182 72 -305 72 -159 0 -256 -36 -449 -165 -105 -71 -194 -120 -202 -112 -12 13 64 800 93 957 22 117 35 140 76 134 46 -6 550 -60 737 -79 274 -27 368 -17 446 47 56 46 79 100 78 183 0 89 -21 143 -115 288 -39 61 -80 138 -90 170 -67 217 65 456 298 536 73 25 198 28 275 5z" />
              </g>
            </svg>
          </MenuCategory>
          <MenuCategory
            id="users"
            icon="nav-icon i-Business-ManWoman"
            name="Users"
            link="/users"
            isActive={selected.toLowerCase().startsWith("users")}
          />

          <MenuCategory
            id="exclusion"
            icon="nav-icon i-Gears"
            name="Settings"
            isActive={selected.toLowerCase().startsWith("exclusions")}
            link="/exclusions/rules"
            dataItem="exclusions"
          />
        </SideBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeFeatures: state.activeFeaturesReducer.activeFeatures || []
  };
};
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Menu);
