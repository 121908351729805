import React, { Component } from 'react';
import _ from 'lodash';

import {
    Echart,
    SeriesLine,
    Tooltip,
    Toolbox,
    YAxis,
    XAxis,
    Grid
} from 'react-echart';

const tooltipOption = {
    trigger: 'item'
}

const toolboxOption = {
    feature: {
    }
}
export default class SimpleLine extends Component {
    render() {
        const { keys, values, gridOptions } = this.props;
        const axisLine = this.props.axisLine || { show: false };
        const axisTick = this.props.axisTick || { show: false };
        const showYAxis = this.props.showYAxis || false;

        return <Echart showLoading={false} resize={true} resizeOption={{ silent: true }} >
            <Grid options={gridOptions || {}} />
            <SeriesLine options={[{
                data: values,
                type: 'line',
                smooth: true,
                lineStyle: { color: '#663399' },
                itemStyle: { color: '#663399', borderWidth: 2 },
                connectNulls: true
            }]} />

            {showYAxis ? <YAxis options={{ type: 'value' }} /> : null}
            <XAxis options={{ type: 'category', data: keys, axisTick: axisTick, axisLine: axisLine }} />

            <Tooltip options={tooltipOption} />
            <Toolbox options={toolboxOption} />
        </Echart>
    }
}