import React from 'react';
import _ from 'lodash';

export default class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {
        const {text} = this.props;
        return (
            <div className="col-md-12">
                <div className="text-center">
                    <div className="loader-bubble loader-bubble-primary m-5"></div>
                    {_.isEmpty(text)? null : <><br />{text}</>}
                </div>
            </div>
        )
    }
}