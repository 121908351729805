import { GET_STACKS, CREATE_STACK } from '../actions/stackActions';

const initialState = {
    data: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_STACKS:
            return { ...state, data: payload.data };

        case CREATE_STACK:
            return { ...state };
            
        default:
            return state;
    }
}
