
import EksIcon from '../../public/images/aws/Arch_Amazon-Elastic-Container-Kubernetes_48.svg';
import S3Icon from '../../public/images/aws/Res_Amazon-Simple-Storage_Bucket-With-Objects_48_Light.svg';
import InternetGatewayIcon from '../../public/images/aws/Res_Amazon-VPC_Internet-Gateway_48_Light.svg';
import IamUserIcon from '../../public/images/aws/Res_User_48_Dark.svg';
import IamRoleIcon from '../../public/images/aws/Res_AWS-Identity-Access-Management_Add-on_48_Light.svg';
import SsmParameterIcon from '../../public/images/aws/Arch_AWS-Systems-Manager_48.svg';
import Route53HostedZoneIcon from '../../public/images/aws/Res_Amazon-Route-53-Hosted-Zone_48_Light.svg';
import VpcIcon from '../../public/images/aws/VPC.svg';
import NatGatewayIcon from '../../public/images/aws/Res_Amazon-VPC_NAT-Gateway_48_Light.svg';
import VpcEndpointIcon from '../../public/images/aws/Res_Amazon-VPC_Endpoints_48_Light.svg';
import InstanceIcon from '../../public/images/aws/Res_Amazon-EC2_Instance_48_Light.svg';
import LambdaFunctionIcon from '../../public/images/aws/Arch_AWS-Lambda_48.svg';
import TransitGatewayIcon from '../../public/images/aws/AWS-Transit-Gateway.svg';
import TransitGatewayVPCIcon from '../../public/images/aws/Amazon-VPC_light-bg.svg';
import Ec2Icon from '../../public/images/aws/Arch_Amazon-EC2_64.svg';
import ScalingGroup from '../../public/images/aws/Arch_AWS-Auto-Scaling_64.svg';
import LoadBalancerIcon from '../../public/images/aws/Arch_Elastic-Load-Balancing_64.svg';
import LoadBalancerV2Icon from '../../public/images/aws/Res_Elastic-Load-Balancing_Application-Load-Balancer_48_Dark.svg';
import AcmCertificateIcon from '../../public/images/aws/AWS-Certificate-Manager.svg';
import ebsVolumeIcon from '../../public/images/aws/Amazon-Elastic-Block-Store-EBS_light-bg.svg';
import dynamoDbIcon from '../../public/images/aws/Amazon-DynamoDB.svg';
import rdsInstanceIcon from '../../public/images/aws/Amazon-RDS.svg';
import IamPolicyIcon from '../../public/images/aws/Res_Amazon_IoT_Policy_48_Light.png';
import SqsQueueIcon from '../../public/images/aws/Arch_Amazon-Simple-Queue-Service_64.svg';
import SnsTopicIcon from '../../public/images/aws/Arch_Amazon-Simple-Notification-Service_64.svg';
import IamGroupIcon from '../../public/images/aws/iam_users_groups.png'
import ApiGatewayIcon from '../../public/images/aws/api_gateway.svg'
import ElasticsearchServiceIcon from '../../public/images/aws/AmazonElasticsearchService.svg'
import ElasticCacheClusterIcon from '../../public/images/aws/AmazonElastiCache.svg'
import CloudFrontDistributionIcon from '../../public/images/aws/Arch_Amazon-CloudFront_64.svg'
import CRD from '../../public/images/k8s/crd.svg'
import Namespace from '../../public/images/k8s/ns.svg'
import Pod from '../../public/images/k8s/pod.svg'
import ConfigMap from '../../public/images/k8s/cm.svg'
import Node from '../../public/images/k8s/node.svg'
import Service from '../../public/images/k8s/svc.svg'
import ServiceAccount from '../../public/images/k8s/sa.svg'
import PersistVolume from '../../public/images/k8s/pv.svg'
import PersistVolumeClaim from '../../public/images/k8s/pvc.svg'
import Secret from '../../public/images/k8s/secret.svg'
import Deployment from '../../public/images/k8s/deploy.svg'
import DaemonSet from '../../public/images/k8s/ds.svg'
import ReplicaSet from '../../public/images/k8s/rs.svg'
import StatefulSet from '../../public/images/k8s/sts.svg'
import Job from '../../public/images/k8s/job.svg'
import CronJob from '../../public/images/k8s/cronjob.svg'
import Ingress from '../../public/images/k8s/ing.svg'
import ClusterRole from '../../public/images/k8s/c-role.svg'

import {
    GET_AWS_INTERNETGATEWAYS,
    GET_AWS_SUBNETS,
    GET_AWS_VPCS,
    GET_AWS_USERS,
    GET_AWS_ROLES,
    GET_AWS_BUCKETS,
    GET_AWS_SECURITY_GROUPS,
    GET_AWS_ROUTE53_HOSTED_ZONES,
    GET_AWS_EKS_CLUSTERS,
    GET_AWS_SSM_PARAMETERS,
    GET_AWS_INSTANCES,
    GET_AWS_LAMBDA_FUNCTIONS,
    GET_AWS_TRANSIT_GATEWAYS,
    GET_AWS_TRANSIT_GATEWAY_ROUTE_TABLES,
    GET_AWS_TRANSIT_GATEWAY_MULTICAST_DOMAINS,
    GET_AWS_TRANSIT_GATEWAY_VPC_ATTACHMENTS,
    GET_AWS_TRANSIT_GATEWAY_PEERING_ATTACHMENTS,
    GET_AWS_AUTO_SCALING_GROUPS,
    GET_AWS_LOAD_BALANCERS,
    GET_AWS_LOAD_BALANCERS_V2,
    GET_AWS_LAUNCH_CONFIGURATIONS,
    GET_AWS_ACM_CERTIFICATES,
    GET_AWS_EBS_VOLUMES,
    GET_AWS_DYNAMODB_TABLES,
    GET_RDS_INSTANCES,
    GET_AWS_POLICIES,
    GET_AWS_SQS_QUEUE,
    GET_AWS_SNS_TOPIC,
    GET_AWS_GROUPS,
    GET_AWS_API_GATEWAY_REST_API,
    GET_AWS_API_GATEWAY_V2_API,
    GET_AWS_ELASTICSEARCH_DOMAIN,
    GET_AWS_ELASTICACHE_CLUSTER,
    GET_AWS_CLOUDFRONT_DISTRIBUTION,
    GET_K8S_NAMESPACE,
    GET_K8S_POD,
    GET_K8S_CONFIG_MAP,
    GET_K8S_NODE,
    GET_K8S_SERVICE,
    GET_K8S_SERVICE_ACCOUNT,
    GET_K8S_PERSISTENT_VOLUME,
    GET_K8S_PERSISTENT_VOLUME_CLAIM,
    GET_K8S_SECRET,
    GET_K8S_DEPLOYMENT,
    GET_K8S_DAEMON_SET,
    GET_K8S_REPLICA_SET,
    GET_K8S_STATEFUL_SET,
    GET_K8S_JOB,
    GET_K8S_CRON_JOB,
    GET_K8S_INGRESS,
    GET_K8S_CLUSTER_ROLE
} from '../actions/inventoryActions';

const initialState = {
    aws_subnet: { icon: NatGatewayIcon, severity: 1, tooltip: "Subent", resources: [] },
    aws_vpc: { icon: VpcIcon, severity: 1, tooltip: "VPC", resources: [] },
    aws_iam_user: { icon: IamUserIcon, severity: 3, tooltip: "IAM User", resources: [] },
    aws_iam_role: { icon: IamRoleIcon, severity: 2, tooltip: "IAM Role", resources: [] },
    aws_internet_gateway: { icon: InternetGatewayIcon, severity: 2, tooltip: "Internet Gateway", resources: [] },
    aws_s3_bucket: { icon: S3Icon, severity: 2, tooltip: "S3 Bucket", resources: [] },
    aws_security_group: { icon: VpcEndpointIcon, severity: 3, tooltip: "Security Group", resources: [] },
    aws_route53_zone: { icon: Route53HostedZoneIcon, severity: 3, tooltip: "Route53 Hosted Zone", resources: [] },
    aws_eks_cluster: { icon: EksIcon, severity: 1, tooltip: "EKS Cluster", resources: [] },
    aws_ssm_parameter: { icon: SsmParameterIcon, severity: 3, tooltip: "SSM Parameter", resources: [] },
    aws_instance: { icon: InstanceIcon, severity: 3, tooltip: "EC2 Instance", resources: [] },
    aws_lambda_function: { icon: LambdaFunctionIcon, severity: 1, tooltip: "Lambda Function", resources: [] },
    aws_ec2_transit_gateway: { icon: TransitGatewayIcon, severity: 2, tooltip: "Transit Gateway", resources: [] },
    aws_ec2_transit_gateway_route_table: { icon: TransitGatewayVPCIcon, severity: 3, tooltip: "Transit Gateway Route Table", resources: [] },
    aws_ec2_transit_gateway_multicast_domain: { icon: TransitGatewayVPCIcon, severity: 1, tooltip: "Transit Gateway Multicast Domain", resources: [] },
    aws_ec2_transit_gateway_vpc_attachment: { icon: TransitGatewayVPCIcon, severity: 2, tooltip: "Transit Gateway VPC Attachment", resources: [] },
    aws_ec2_transit_gateway_peering_attachment: { icon: TransitGatewayVPCIcon, severity: 2, tooltip: "Transit Gateway Peering Attachment", resources: [] },
    aws_launch_configuration: { icon: Ec2Icon, severity: 2, tooltip: "EC2 Launch Configuration", resources: [] },
    aws_autoscaling_group: { icon: ScalingGroup, severity: 3, tooltip: "EC2 Scaling Group", resources: [] },
    aws_elb: { icon: LoadBalancerIcon, severity: 2, tooltip: "Elastic Load Balancer (Classic)", resources: [] },
    aws_lb: { icon: LoadBalancerV2Icon, severity: 2, tooltip: "Load Balancer V2 (ALB, NLB, GLB)", resources: [] },
    aws_acm_certificate: { icon: AcmCertificateIcon, severity: 3, tooltip: "AWS Certificate Manager", resources: [] },
    aws_ebs_volume: { icon: ebsVolumeIcon, severity: 2, tooltip: "AWS EC2 Volumes (EBS)", resources: [] },
    aws_dynamodb_table: { icon: dynamoDbIcon, severity: 1, tooltip: "AWS Dynamo DB", resources: [] },
    aws_db_instance: { icon: rdsInstanceIcon, severity: 1, tooltip: "AWS Relational DB", resources: [] },
    aws_iam_policy: { icon: IamPolicyIcon, severity: 2, tooltip: "IAM Policy", resources: [] },
    aws_sqs_queue: { icon: SqsQueueIcon, severity: 1, tooltip: "SQS Queue", resources: [] },
    aws_sns_topic: { icon: SnsTopicIcon, severity: 1, tooltip: "SNS Topic", resources: [] },
    aws_iam_group: { icon: IamGroupIcon, severity: 2, tooltip: "IAM Group", resources: [] },
    aws_api_gateway_rest_api: { icon: ApiGatewayIcon, severity: 3, tooltip: "API Gateway", resources: [] },
    aws_apigatewayv2_api: { icon: ApiGatewayIcon, severity: 3, tooltip: "API Gateway V2", resources: [] },
    aws_elasticsearch_domain: { icon: ElasticsearchServiceIcon, severity: 1, tooltip: "Elasticsearch Service Domain", resources: [] },
    aws_elasticache_cluster: { icon: ElasticCacheClusterIcon, severity: 1, tooltip: "ElastiCache Cluster", resources: [] },
    aws_cloudfront_distribution: { icon: CloudFrontDistributionIcon, severity: 1, tooltip: "CloudFront Distribution", resources: [] },
    kubernetes_namespace: { icon: Namespace, severity: 1, tooltip: "Namespace", resources: []},
    kubernetes_pod: { icon: Pod, severity: 2, tooltip: "Pod", resources: [] },
    kubernetes_config_map: { icon: ConfigMap, severity: 2, tooltip: "Config Map", resources: [] } ,
    kubernetes_node: { icon: Node, severity: 2, tooltip: "Node", resources: [] },
    kubernetes_service: {icon: Service, severity: 1, tooltip: "Service", resources: [] },
    kubernetes_service_account: { icon: ServiceAccount, severity: 1, tooltip: "Service Account", resources: [] },
    kubernetes_persistent_volume: { icon: PersistVolume, severity: 1, tooltip: "PersistVolume", resources: [] },
    kubernetes_persistent_volume_claim: { icon: PersistVolumeClaim, severity: 1, tooltip: "PersistVolumeClaim", resources: [] },
    kubernetes_secret: { icon: Secret, severity: 3, tooltip: "Secret", resources: [] },
    kubernetes_deployment: { icon: Deployment, severity: 3, tooltip: "Deployment", resources: [] },
    kubernetes_daemon_set: { icon: DaemonSet, severity: 3, tooltip: "DaemonSet", resources: [] },
    kubernetes_replica_set: { icon: ReplicaSet, severity: 3, tooltip: "ReplicaSet", resources: [] },
    kubernetes_stateful_set: { icon: StatefulSet, severity: 3, tooltip: "StatefulSet", resources: [] },
    kubernetes_job: { icon: Job, severity: 2, tooltip: "Job", resources: [] },
    kubernetes_cron_job: { icon: CronJob, severity: 2, tooltip: "CronJob", resources: [] },
    kubernetes_ingress: { icon: Ingress, severity: 3, tooltip: "Ingress", resources: [] },
    kubernetes_cluster_role: { icon: ClusterRole, severity: 3, tooltip: "ClusterRole", resources: [] },
}


export default (state = initialState, { type, payload }) => {

    switch (type) {

        case GET_AWS_SUBNETS:
            state.aws_subnet.resources = payload.data;
            return state;

        case GET_AWS_VPCS:
            state.aws_vpc.resources = payload.data;
            return state;

        case GET_AWS_INTERNETGATEWAYS:
            state.aws_internet_gateway.resources = payload.data;
            return state;

        case GET_AWS_USERS:
            state.aws_iam_user.resources = payload.data;
            return state;

        case GET_AWS_ROLES:
            state.aws_iam_role.resources = payload.data;
            return state;

        case GET_AWS_BUCKETS:
            state.aws_s3_bucket.resources = payload.data;
            return state;

        case GET_AWS_SECURITY_GROUPS:
            state.aws_security_group.resources = payload.data;
            return state;

        case GET_AWS_ROUTE53_HOSTED_ZONES:
            state.aws_route53_zone.resources = payload.data;
            return state;

        case GET_AWS_EKS_CLUSTERS:
            state.aws_eks_cluster.resources = payload.data;
            return state;

        case GET_AWS_SSM_PARAMETERS:
            state.aws_ssm_parameter.resources = payload.data;
            return state;

        case GET_AWS_INSTANCES:
            state.aws_instance.resources = payload.data;
            return state;

        case GET_AWS_LAMBDA_FUNCTIONS:
            state.aws_lambda_function.resources = payload.data;
            return state;

        case GET_AWS_TRANSIT_GATEWAYS:
            state.aws_ec2_transit_gateway.resources = payload.data;
            return state;

        case GET_AWS_TRANSIT_GATEWAY_ROUTE_TABLES:
            state.aws_ec2_transit_gateway_route_table.resources = payload.data;
            return state;

        case GET_AWS_TRANSIT_GATEWAY_MULTICAST_DOMAINS:
            state.aws_ec2_transit_gateway_multicast_domain.resources = payload.data;
            return state;

        case GET_AWS_TRANSIT_GATEWAY_VPC_ATTACHMENTS:
            state.aws_ec2_transit_gateway_vpc_attachment.resources = payload.data;
            return state;

        case GET_AWS_TRANSIT_GATEWAY_PEERING_ATTACHMENTS:
            state.aws_ec2_transit_gateway_peering_attachment.resources = payload.data;
            return state;

        case GET_AWS_AUTO_SCALING_GROUPS:
            state.aws_autoscaling_group.resources = payload.data;
            return state;

        case GET_AWS_LAUNCH_CONFIGURATIONS:
            state.aws_launch_configuration.resources = payload.data;
            return state;

        case GET_AWS_LOAD_BALANCERS:
            state.aws_elb.resources = payload.data;
            return state;

        case GET_AWS_LOAD_BALANCERS_V2:
            state.aws_lb.resources = payload.data;
            return state;

        case GET_AWS_ACM_CERTIFICATES:
            state.aws_acm_certificate.resources = payload.data;
            return state;

        case GET_AWS_EBS_VOLUMES:
            state.aws_ebs_volume.resources = payload.data;
            return state;

        case GET_AWS_DYNAMODB_TABLES:
            state.aws_dynamodb_table.resources = payload.data;
            return state;

        case GET_RDS_INSTANCES:
            state.aws_db_instance.resources = payload.data;
            return state;

        case GET_AWS_POLICIES:
          state.aws_iam_policy.resources = payload.data;
          return state;

        case GET_AWS_SQS_QUEUE:
          state.aws_sqs_queue.resources = payload.data;
          return state;

        case GET_AWS_SNS_TOPIC:
          state.aws_sns_topic.resources = payload.data;
          return state;

        case GET_AWS_GROUPS:
            state.aws_iam_group.resources = payload.data;
            return state;

        case GET_AWS_API_GATEWAY_REST_API:
            state.aws_api_gateway_rest_api.resources = payload.data;
            return state;

        case GET_AWS_API_GATEWAY_V2_API:
            state.aws_apigatewayv2_api.resources = payload.data;
            return state;

        case GET_AWS_ELASTICSEARCH_DOMAIN:
            state.aws_elasticsearch_domain.resources = payload.data;
            return state;

        case GET_AWS_ELASTICACHE_CLUSTER:
            state.aws_elasticache_cluster.resources = payload.data;
            return state;

        case GET_AWS_CLOUDFRONT_DISTRIBUTION:
            state.aws_cloudfront_distribution.resources = payload.data;
            return state;

        case GET_K8S_NAMESPACE:
            state.kubernetes_namespace.resources = payload.data;
            return state;

        case GET_K8S_POD:
            state.kubernetes_pod.resources = payload.data;
            return state;

        case GET_K8S_CONFIG_MAP:
            state.kubernetes_config_map.resources = payload.data;
            return state;

        case GET_K8S_NODE:
            state.kubernetes_node.resources = payload.data;
            return state;

        case GET_K8S_SERVICE:
            state.kubernetes_service.resources = payload.data;
            return state;

        case GET_K8S_SERVICE_ACCOUNT:
            state.kubernetes_service_account.resources = payload.data;
            return state;

        case GET_K8S_PERSISTENT_VOLUME:
            state.kubernetes_persistent_volume.resources = payload.data;
            return state;

        case GET_K8S_PERSISTENT_VOLUME_CLAIM:
            state.kubernetes_persistent_volume_claim.resources = payload.data;
            return state;

        case GET_K8S_SECRET:
            state.kubernetes_secret.resources = payload.data;
            return state;

        case GET_K8S_DEPLOYMENT:
            state.kubernetes_deployment.resources = payload.data;
            return state;

        case GET_K8S_DAEMON_SET:
            state.kubernetes_daemon_set.resources = payload.data;
            return state;


        case GET_K8S_REPLICA_SET:
            state.kubernetes_replica_set.resources = payload.data;
            return state;


        case GET_K8S_STATEFUL_SET:
            state.kubernetes_stateful_set.resources = payload.data;
            return state;


        case GET_K8S_JOB:
            state.kubernetes_job.resources = payload.data;
            return state;

        case GET_K8S_CRON_JOB:
            state.kubernetes_cron_job.resources = payload.data;
            return state;

        case GET_K8S_INGRESS:
            state.kubernetes_ingress.resources = payload.data;
            return state;

        case GET_K8S_CLUSTER_ROLE:
            state.kubernetes_cluster_role.resources = payload.data;
            return state;

        default:
            return state;
    }
}
