import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

class Step3 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
    }

    render() {
        const { } = this.state;
        const { handleSubmit, children } = this.props;
        return (
            <>
                <h3 className="border-bottom border-gray pb-2">Setup GitHub OAuth authentication</h3>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" htmlFor="client_secret">Name</label>
                            <div className="col-sm-10">
                                <Field
                                    className={"form-control"}
                                    name="name"
                                    id="name"
                                    component="input"
                                    type="text"
                                    placeholder="Please fill the Name"
                                />
                            </div>
                        </div>


                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" htmlFor="client_id">Client Id</label>
                            <div className="col-sm-10">
                                <Field
                                    className={"form-control"}
                                    name="client_id"
                                    id="client_id"
                                    component="input"
                                    type="text"
                                    placeholder="Please fill the Client ID"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" htmlFor="client_secret">Client Secret</label>
                            <div className="col-sm-10">
                                <Field
                                    className={"form-control"}
                                    name="client_secret"
                                    id="client_secret"
                                    component="input"
                                    type="text"
                                    placeholder="Please fill the Client Secret"
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-10">
                                <button className="btn btn-primary" onClick={this.validate}>Validate</button>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-10">
                                {children}
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = {
};


Step3 = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Step3);

Step3 = withRouter(Step3);


export default reduxForm({
    form: 'validate-vcs-github',
    initialValues: { name: "GitHub" }
})(Step3);