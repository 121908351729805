import React from "react";
import SpaceShiftImage from "../../public/images/spaceship.svg";
import './emptyPage.scss';

export default class EmptyPage extends React.Component{
    render(){

        return <div className="no-data">
        <img src={SpaceShiftImage} />
        <div className="description">{this.props.children}</div>
    </div>
    }
}