import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import {BACKEND_URL} from '../../consts/config';
import ActiveFeatures from '../../consts/activeFeatures';
import _ from 'lodash';

export const getAwsFetchingMetadata = (integrationId) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const fetchingMetadataReq = await requestWrapper.sendRequest(
            `${BACKEND_URL}/inventory/fetching/aws/${integrationId}`,
            "GET"
        );

        if (!fetchingMetadataReq.ok) {
            return {ok: false, metadata: null}
        }

        const metadata = await fetchingMetadataReq.json();
        return {ok: true, metadata}
    });
};

const getAwsResources = (resourceType, reduxType) => {
    return action(async (dispatch, getState) => {

        const {activeFeaturesReducer} = getState();
        const {activeFeatures} = activeFeaturesReducer;


        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/inventory/aws/${resourceType}`, "GET");
        const data = await req.json();
        dispatch({
            type: reduxType,
            payload: {data}
        });
    });
}

const getK8sResource = (resourceType, reduxType) => {
    return action(async (dispatch, getState) => {

        const {activeFeaturesReducer} = getState();
        const {activeFeatures} = activeFeaturesReducer;


        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/inventory/k8s/${resourceType}`, "GET");
        const data = await req.json();
        dispatch({
            type: reduxType,
            payload: {data}
        });
    });
};


export const GET_AWS_SUBNETS = "GET_AWS_SUBNETS";
export const getAwsSubnets = () => getAwsResources("aws_subnet", GET_AWS_LAUNCH_CONFIGURATIONS);

export const GET_AWS_VPCS = "GET_AWS_VPCS";
export const getAwsVpcs = () => getAwsResources("aws_vpc", GET_AWS_VPCS);

export const GET_AWS_INTERNETGATEWAYS = "GET_AWS_INTERNETGATEWAYS";
export const getAwsInternetGateways = () => getAwsResources("aws_internet_gateway", GET_AWS_INTERNETGATEWAYS);

export const GET_AWS_USERS = "GET_AWS_USERS";
export const getAwsUsers = () => getAwsResources("aws_iam_user", GET_AWS_USERS);

export const GET_AWS_ROLES = "GET_AWS_ROLES";
export const getAwsRoles = () => getAwsResources("aws_iam_role", GET_AWS_ROLES);

export const GET_AWS_BUCKETS = "GET_AWS_BUCKETS";
export const getAwsBuckets = () => getAwsResources("aws_s3_bucket", GET_AWS_BUCKETS);

export const GET_AWS_SECURITY_GROUPS = "GET_AWS_SECURITY_GROUPS";
export const getAwsSecurityGroups = () => getAwsResources("aws_security_group", GET_AWS_SECURITY_GROUPS);

export const GET_AWS_ROUTE53_HOSTED_ZONES = "GET_AWS_ROUTE53_HOSTED_ZONES";
export const getAwsRoute53HostedZones = () => getAwsResources("aws_route53_zone", GET_AWS_ROUTE53_HOSTED_ZONES);

export const GET_AWS_EKS_CLUSTERS = "GET_AWS_EKS_CLUSTERS";
export const getAwsEksClusters = () => getAwsResources("aws_eks_cluster", GET_AWS_EKS_CLUSTERS);

export const GET_AWS_SSM_PARAMETERS = "GET_AWS_SSM_PARAMETERS";
export const getAwsSsmParameters = () => getAwsResources("aws_ssm_parameter", GET_AWS_SSM_PARAMETERS);

export const GET_AWS_INSTANCES = "GET_AWS_INSTANCES";
export const getAwsInstances = () => getAwsResources("aws_instance", GET_AWS_INSTANCES);

export const GET_AWS_LAMBDA_FUNCTIONS = "GET_AWS_LAMBDA_FUNCTIONS";
export const getAwsLambdaFunctions = () => getAwsResources("aws_lambda_function", GET_AWS_LAMBDA_FUNCTIONS);

export const GET_AWS_TRANSIT_GATEWAYS = "GET_AWS_TRANSIT_GATEWAYS";
export const getAwsTransitGateways = () => getAwsResources("aws_ec2_transit_gateway", GET_AWS_TRANSIT_GATEWAYS);

export const GET_AWS_TRANSIT_GATEWAY_ROUTE_TABLES = "GET_AWS_TRANSIT_GATEWAY_ROUTE_TABLES";
export const getAwsTransitGatewayRouteTables = () => getAwsResources("aws_ec2_transit_gateway_route_table", GET_AWS_TRANSIT_GATEWAY_ROUTE_TABLES);

export const GET_AWS_TRANSIT_GATEWAY_MULTICAST_DOMAINS = "GET_AWS_TRANSIT_GATEWAY_MULTICAST_DOMAINS";
export const getAwsTransitGatewayMulticastDomains = () => getAwsResources("aws_ec2_transit_gateway_multicast_domain", GET_AWS_TRANSIT_GATEWAY_MULTICAST_DOMAINS);

export const GET_AWS_TRANSIT_GATEWAY_VPC_ATTACHMENTS = "GET_AWS_TRANSIT_GATEWAY_VPC_ATTACHMENTS";
export const getAwsTransitGatewayVpcAttachments = () => getAwsResources("aws_ec2_transit_gateway_vpc_attachment", GET_AWS_TRANSIT_GATEWAY_VPC_ATTACHMENTS);

export const GET_AWS_TRANSIT_GATEWAY_PEERING_ATTACHMENTS = "GET_AWS_TRANSIT_GATEWAY_PEERING_ATTACHMENTS";
export const getAwsTransitGatewayPeeringAttachments = () => getAwsResources("aws_ec2_transit_gateway_peering_attachment", GET_AWS_TRANSIT_GATEWAY_PEERING_ATTACHMENTS);

export const GET_AWS_AUTO_SCALING_GROUPS = "GET_AWS_AUTO_SCALING_GROUPS";
export const getAutoScalingGroups = () => getAwsResources("aws_autoscaling_group", GET_AWS_AUTO_SCALING_GROUPS);

export const GET_AWS_LOAD_BALANCERS = "GET_AWS_LOAD_BALANCERS";
export const getLoadBalancers = () => getAwsResources("aws_elb", GET_AWS_LOAD_BALANCERS);

export const GET_AWS_LOAD_BALANCERS_V2 = "GET_AWS_LOAD_BALANCERS_V2";
export const getLoadBalancersV2 = () => getAwsResources("aws_lb", GET_AWS_LOAD_BALANCERS_V2);

export const GET_AWS_LAUNCH_CONFIGURATIONS = "GET_AWS_LAUNCH_CONFIGURATIONS";
export const getLaunchConfigurations = () => getAwsResources("aws_launch_configuration", GET_AWS_LAUNCH_CONFIGURATIONS);

export const GET_AWS_ACM_CERTIFICATES = "GET_AWS_ACM_CERTIFICATES";
export const getAcmCertificates = () => getAwsResources("aws_acm_certificate", GET_AWS_ACM_CERTIFICATES);

export const GET_AWS_EBS_VOLUMES = "GET_AWS_EBS_VOLUMES";
export const getEbsVolumes = () => getAwsResources("aws_ebs_volume", GET_AWS_EBS_VOLUMES);

export const GET_AWS_DYNAMODB_TABLES = "GET_AWS_DYNAMODB_TABLES";
export const getDynamodbTables = () => getAwsResources("aws_dynamodb_table", GET_AWS_DYNAMODB_TABLES);

export const GET_RDS_INSTANCES = "GET_RDS_INSTANCES";
export const getRdsInstances = () => getAwsResources("aws_db_instance", GET_RDS_INSTANCES);

export const GET_AWS_POLICIES = "GET_AWS_POLICIES";
export const getAwsPolicies = () => getAwsResources("aws_iam_policy", GET_AWS_POLICIES);

export const GET_AWS_SQS_QUEUE = "GET_AWS_SQS_QUEUE";
export const getAwsSqsQueue = () => getAwsResources("aws_sqs_queue", GET_AWS_SQS_QUEUE);

export const GET_AWS_SNS_TOPIC = "GET_AWS_SNS_TOPIC";
export const getAwsSnsTopic = () => getAwsResources("aws_sns_topic", GET_AWS_SNS_TOPIC);

export const GET_AWS_GROUPS = "GET_AWS_GROUPS";
export const getAwsGroups = () => getAwsResources("aws_iam_group", GET_AWS_GROUPS);

export const GET_AWS_API_GATEWAY_REST_API = "GET_AWS_API_GATEWAY_REST_API";
export const getAwsApiGatewayRestApis = () => getAwsResources("aws_api_gateway_rest_api", GET_AWS_API_GATEWAY_REST_API);

export const GET_AWS_API_GATEWAY_V2_API = "GET_AWS_API_GATEWAY_V2_API";
export const getAwsApiGatewayV2Apis = () => getAwsResources("aws_apigatewayv2_api", GET_AWS_API_GATEWAY_V2_API);

export const GET_AWS_ELASTICSEARCH_DOMAIN = "GET_AWS_ELASTICSEARCH_DOMAIN";
export const getAwsElasticsearchDomain = () => getAwsResources("aws_elasticsearch_domain", GET_AWS_ELASTICSEARCH_DOMAIN);

export const GET_AWS_ELASTICACHE_CLUSTER = "GET_AWS_ELASTICACHE_CLUSTER";
export const getAwsElastiCacheCluster = () => getAwsResources("aws_elasticache_cluster", GET_AWS_ELASTICACHE_CLUSTER);

export const GET_AWS_CLOUDFRONT_DISTRIBUTION = "GET_AWS_CLOUDFRONT_DISTRIBUTION";
export const getAwsCloudFrontDistribution = () => getAwsResources("aws_cloudfront_distribution", GET_AWS_CLOUDFRONT_DISTRIBUTION);

export const GET_K8S_NAMESPACE = "GET_K8S_NAMESPACE";
export const getK8sNamespace = () => getK8sResource("kubernetes_namespace", GET_K8S_NAMESPACE);

export const GET_K8S_POD = "GET_K8S_POD";
export const getK8sPod = () => getK8sResource("kubernetes_pod", GET_K8S_POD);

export const GET_K8S_CONFIG_MAP = "GET_K8S_CONFIG_MAP";
export const getK8sConfigMap = () => getK8sResource("kubernetes_config_map", GET_K8S_CONFIG_MAP);

export const GET_K8S_NODE = "GET_K8S_NODE";
export const getK8sNode = () => getK8sResource("kubernetes_node", GET_K8S_NODE);

export const GET_K8S_SERVICE = "GET_K8S_SERVICE";
export const getK8sService = () => getK8sResource("kubernetes_service", GET_K8S_SERVICE);

export const GET_K8S_SERVICE_ACCOUNT = "GET_K8S_SERVICE_ACCOUNT";
export const getK8sServiceAccount = () => getK8sResource("kubernetes_service_account", GET_K8S_SERVICE_ACCOUNT);

export const GET_K8S_PERSISTENT_VOLUME = "GET_K8S_PERSISTENT_VOLUME";
export const getK8sPersistentVolume = () => getK8sResource("kubernetes_persistent_volume", GET_K8S_PERSISTENT_VOLUME);

export const GET_K8S_PERSISTENT_VOLUME_CLAIM = "GET_K8S_PERSISTENT_VOLUME_CLAIM";
export const getK8sPersistentVolumeClaim = () => getK8sResource("kubernetes_persistent_volume_claim", GET_K8S_PERSISTENT_VOLUME_CLAIM);

export const GET_K8S_SECRET = "GET_K8S_SECRET";
export const getK8sSecret = () => getK8sResource("kubernetes_secret", GET_K8S_SECRET);

export const GET_K8S_DEPLOYMENT = "GET_K8S_DEPLOYMENT";
export const getK8sDeployment = () => getK8sResource("kubernetes_deployment", GET_K8S_DEPLOYMENT);

export const GET_K8S_DAEMON_SET = "GET_K8S_DAEMON_SET";
export const getK8sDaemonSet = () => getK8sResource("kubernetes_daemon_set", GET_K8S_DAEMON_SET);

export const GET_K8S_REPLICA_SET = "GET_K8S_REPLICA_SET";
export const getK8sReplicaSet = () => getK8sResource("kubernetes_replica_set", GET_K8S_REPLICA_SET);

export const GET_K8S_STATEFUL_SET = "GET_K8S_STATEFUL_SET";
export const getK8sStatefulSet = () => getK8sResource("kubernetes_stateful_set", GET_K8S_STATEFUL_SET);

export const GET_K8S_JOB = "GET_K8S_JOB";
export const getK8sJob = () => getK8sResource("kubernetes_job", GET_K8S_JOB);

export const GET_K8S_CRON_JOB = "GET_K8S_CRON_JOB";
export const getK8sCronJob = () => getK8sResource("kubernetes_cron_job", GET_K8S_CRON_JOB);

export const GET_K8S_INGRESS = "GET_K8S_INGRESS";
export const getK8sIngress = () => getK8sResource("kubernetes_ingress", GET_K8S_INGRESS);

export const GET_K8S_CLUSTER_ROLE = "GET_K8S_CLUSTER_ROLE";
export const getK8sClusterRole = () => getK8sResource("kubernetes_cluster_role", GET_K8S_CLUSTER_ROLE);
