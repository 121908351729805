import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Pagination from '../../shared/pagination';
import Regions from '../../consts/regions';
import { sendMetric } from '../../redux/actions/metricActions';
import { GithubIcon } from '../../shared/icons';
import ResourceImport from './resourceImport';
import TooltipResources from './tooltipResources';
import TooltipLink from '../../shared/tooltip/tooltipLink';
import PullRequest from './pullRequest';

import './unmanagedResourcesTable.scss';


class UnmanagedResourcesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            isResourceImportOpen: false,
            isPullRequestModalOpen: false,
            selectedResource: null,
            searchText: ""
        };
    }

    onPageMoved = (page) => {
        this.setState({ currentPage: page });
    }

    handleChangeChk = (e, item) => {
        const checked = e.target.checked;

        if (checked) {
            this.props.handleResourceSelected(item);
        } else {
            this.props.handleResourceUnselected(item);
        }

    }

    onClickIacImport = (item) => {
        this.setState({ isResourceImportOpen: true, selectedResource: item });
    }

    onCloseIacImportModal = () => {
        this.setState({ isResourceImportOpen: false, selectedResource: null });
    }

    onClickPullRequest = (item) => {
        this.setState({ isPullRequestModalOpen: true, selectedResource: item });
    }

    onClosePullRequest = () => {
        this.setState({ isPullRequestModalOpen: false, selectedResource: null });
    }

    onSearch = (e)=>{
        const searchText = e.target.value;
        this.setState({searchText});
    }

    render() {
        const { unmanaged } = this.props;
        const { isResourceImportOpen, isPullRequestModalOpen, selectedResource, searchText } = this.state;
        const currentPage = Math.max(1, this.state.currentPage + 1);

        const showSeverity = _.isUndefined(this.props.showSeverity) ? true : this.props.showSeverity;
        const showActions = _.isUndefined(this.props.showActions) ? true : this.props.showActions;
        const maxItemsInPage = _.isUndefined(this.props.maxItemsInPage) ? 5 : this.props.maxItemsInPage;

        //Filter table if search box
        let items = _.filter(unmanaged, x => x.hidden !== true);
        if(!_.isEmpty(searchText)){
            items = _.filter(items, item => {
                const keys = _.keys(item)
                for(const i in keys){
                    const key = keys[i];
                    try{
                        if(_.includes((item[key]||"").toString().toLowerCase(), searchText.toLowerCase())){
                            return true;
                        }
                    }catch(ex){
                        console.log(`an error occurred while trying to search resource. searchText=${searchText}, error=${ex}`);
                        return true;
                    }
                }
                return false;
            });
        }

        return <>
            <ResourceImport className="mb-4" isOpen={isResourceImportOpen} onClose={this.onCloseIacImportModal} selectedResources={_.isEmpty(selectedResource) ? [] : [selectedResource]} mode="single" />
            <PullRequest className="mb-4" isOpen={isPullRequestModalOpen} onClose={this.onClosePullRequest} selectedResources={_.isEmpty(selectedResource) ? [] : [selectedResource]} mode="single" />

            <div className="unmanaged-table table-responsive">
                <div className="input-group"><input className="form-control" type="text"  value={searchText} placeholder="Search Resource" aria-label="comment" onChange={this.onSearch} /></div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" className="small-col">#</th>
                                <th scope="col">Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Region</th>
                                {showSeverity ? <th scope="col" className="small-col">Severity</th> : null}
                                {showActions ? <th scope="col" className="small-col">Actions</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(_.slice(items, (currentPage - 1) * maxItemsInPage, (currentPage - 1) * maxItemsInPage + maxItemsInPage), item => {
                                    return <tr key={item.arn}>
                                        <th scope="row">
                                            <label className="checkbox checkbox-outline-info">
                                                <input type="checkbox" onChange={(e) => this.handleChangeChk(e, item)} checked={_.isUndefined(item.checked) ? null : item.checked} /><span className="checkmark"></span>
                                                <div className="ul-widget_user-card">
                                                    <div className="ul-widget4__img resource-img"><TooltipLink id={`resource_${item.id}`}><img id="userDropdown" src={item.icon} style={{ height: '22px', width: '22px' }} alt={item.tooltip || ""} /></TooltipLink></div>
                                                    <TooltipResources id={`resource_${item.id}`} resourceTypes={[item.type]} />
                                                </div>
                                            </label>
                                        </th>
                                        <td>{item.resourceId}</td>
                                        <td>{item.name}</td>
                                        <td><a className="ul-widget4__title d-block">{item.region || "aws-global"}</a><span>{item.region ? Regions[item.region] : "Global"}</span></td>

                                        {
                                            showSeverity ?
                                                <td>
                                                    {
                                                        item.severity == 3 ?
                                                            <span className="badge badge-danger m-2">High</span>
                                                            : item.severity == 2 ?
                                                                <span className="badge badge-warning m-2">Medium</span>
                                                                : <span className="badge badge-secondary m-2">Low</span>
                                                    }
                                                </td> : null
                                        }

                                        {
                                            showActions ?
                                                <td>
                                                    <button className="btn bg-white _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="_dot _inline-dot bg-primary"></span>
                                                        <span className="_dot _inline-dot bg-primary"></span>
                                                        <span className="_dot _inline-dot bg-primary"></span>
                                                    </button>

                                                    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(0px, 33px, 0px)', top: '0px', left: '0px', willChange: 'transform' }}>
                                                        <a className="dropdown-item ul-widget__link--font" href="#unmanaged-resources-table" onClick={() => this.onClickIacImport(item)}><i className="i-Arrow-Right-in-Circle"> </i>  Codify</a>
                                                        <a className="dropdown-item ul-widget__link--font" onClick={this.handleCreatePullRequest} href="#unmanaged-resources-table" onClick={() => this.onClickPullRequest(item)}><GithubIcon /> Create Pull-Request</a>
                                                    </div>
                                                </td> : null
                                        }
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination maxItemsInPage={maxItemsInPage} currentPage={currentPage} countOfResults={items.length} onPageMoved={this.onPageMoved} />

        </>
    }
}

const mapStateToProps = (state) => {
    return {
            };
};

const mapDispatchToProps = {
                sendMetric
            };

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(UnmanagedResourcesTable);