import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { createStackByEnvironmentId, getStacksByEnvironmentId } from '../../../../redux/actions/stackActions';
import { getAllGithubApps } from '../../../../redux/actions/vcsActions';
import { getAwsIntegrations } from '../../../../redux/actions/integrationsActions';
import { Warning } from '../../../../shared/alerts';
import { BACKEND_URL } from '../../../../consts/config';
import RequestWrapper from '../../../../utils/requestWrapper';
import ActiveFeatures from '../../../../consts/activeFeatures';
import './createStack.scss';

const terraformVersions = ['0.15.1', '0.15.0', '0.14.11', '0.14.10', '0.14.9', '0.14.8', '0.14.7', '0.14.6', '0.14.5', '0.14.4', '0.14.3', '0.14.2', '0.14.1', '0.14.0', '0.13.5', '0.13.4', '0.13.3', '0.13.2', '0.13.1', '0.13.0', '0.12.29', '0.12.28', '0.12.27', '0.12.26', '0.12.25', '0.12.24', '0.12.23', '0.12.22', '0.12.21', '0.12.20', '0.12.19', '0.12.18', '0.12.17', '0.12.16', '0.12.15', '0.12.14', '0.12.13', '0.12.12', '0.12.11', '0.12.10', '0.12.9', '0.12.8', '0.12.7', '0.12.6', '0.12.5', '0.12.4', '0.12.3', '0.12.2', '0.12.1', '0.12.0', '0.11.14', '0.11.12'];

export class SelectIacSource extends React.Component {
  constructor(props) {
    super(props);

    this.requestWrapper = new RequestWrapper();

    this.state = {
      environmentId: this.props.environmentId,
      isLoading: true,
      repos: null,
      isVcsValid: false,
      isRepoValid: false,
      isTerraformVersionValid: false,
      isAwsIntegrationValid: false,
      githubApps: null,
      awsIntegrations: null,
      selectedSource: null,
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getAllGithubApps(),
      this.props.getAwsIntegrations(),
    ])

    const {githubApps, awsIntegrations} = this.props;
    this.setState({isLoading: false, githubApps, awsIntegrations});
  }

  onChangeVcs = async (e) => {
    const vcsId = e.target.value;

    const isVcsValid = !_.isEmpty(vcsId);
    if (!isVcsValid) {
      return;
    }

    const reposReq = await this.requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github/${vcsId}/repos`, "GET");
    if (!reposReq.ok) {
      console.log('Could not find repos');
      return;
    }
    const repos = await reposReq.json();
    this.props.handleSelectResourceUpdate("vcs", e.target.value)
    this.setState({isVcsValid, repos});
  }

  onChangeRepo = async (e) => {
    this.props.handleSelectResourceUpdate("repo", e.target.value)
    const isRepoValid = !_.isEmpty(e.target.value);
    this.setState({isRepoValid});
  }

  onChangeTerraformVersion = async (e) => {
    this.props.handleSelectResourceUpdate("terraformVersion", e.target.value)
    const isTerraformVersionValid = !_.isEmpty(e.target.value);
    this.setState({isTerraformVersionValid});
  }

  onChangeAwsIntegration = async (e) => {
    this.props.handleSelectResourceUpdate("awsIntegration", e.target.value)
    const isAwsIntegrationValid = !_.isEmpty(e.target.value);
    this.setState({isAwsIntegrationValid});
  }

  onChangeIsTerragrunt = async (e) => {
    this.props.handleSelectResourceUpdate("isTerragrunt", e.target.checked)
  }

  onChangePath = async (e) => {
    this.props.handleSelectResourceUpdate("path", e.target.value)
  }

  onChangeBranch = async (e) => {
    this.props.handleSelectResourceUpdate("branch", e.target.value)
  }


  onChangeSelectedSource = (value) => {
    this.props.handleSelectResourceUpdate("stackType", value)
    this.setState({ selectedSource: value });
  };

  RadioButton = (props) => {
    const { selectedSource } = this.state;
    const checked = selectedSource == props.value;
    const disabled = _.isUndefined(props.disabled) ? false : props.disabled;

    return (
      <div className={`form-check ${disabled ? "disabled" : ""}`}>
        <input
          className="form-check-input"
          id={props.id}
          type="radio"
          name="Sources"
          value={props.value}
          checked={checked}
          disabled={disabled}
          onChange={() => this.onChangeSelectedSource(props.value)}
        />
        <label className="form-check-label ml-3" htmlFor={props.id}>
          {props.description}
        </label>
      </div>
    );
  };


  render() {
    const {activeFeatures} = this.props;
    const {
      githubApps,
      repos,
      awsIntegrations,
      selectedSource,
    } = this.state;
    const isOrchestrationEnabled = _.filter(activeFeatures, x => ActiveFeatures.orchestrator.createStack.startsWith(x.path)).length > 0;


    return (


      <div className="CreateStack">
        <div>Choose Iac Source</div>
        <div className="form-group CreateStack__form-radioBox">
            <this.RadioButton
              id="managed"
              checked={true}
              value="managed"
              description="Managed Stack"
            />
            {isOrchestrationEnabled &&
            <this.RadioButton
              id="orchestrator"
              value="orchestrator"
              description="Infralight Orchestrator"
            />}
            <this.RadioButton
              id="unmanaged"
              value="unmanaged"
              description="Unmanaged Resources"
              disabled={true}
            />
        </div>

        {isOrchestrationEnabled && selectedSource == "orchestrator" ?
          <div className="accordion" id="accordionCreateStackAdvanced">

            <div>
              <div className="card-header header-elements-inline">
                                        <span className="ul-collapse__icon--size ul-collapse__right-icon mb-0">
                                            <a className="text-default" data-toggle="collapse"
                                               href="#accordion-item-icon-create-stack-advanced" aria-expanded="true">InfraLight Orchestrator configuration</a>
                                        </span>
              </div>
              <div className="collapse" id="accordion-item-icon-create-stack-advanced"
                   data-parent="#accordionCreateStackAdvanced">

                {_.isEmpty(githubApps) ?
                  <Warning closeEnabled={false}><a href="/integrations/vcs/github">Click Here</a> to setup a github
                    application.</Warning>
                  :
                  <div className="card-body">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="terraformVersion">Terraform Version</label>
                      <Field
                        className={"form-control"}
                        name="terraformVersion"
                        id="terraformVersion"
                        component="select"
                        onChange={this.onChangeTerraformVersion}
                      >
                        <option value="">Please choose a Terraform version..</option>
                        {_.map(terraformVersions || [], tfVersion => <option value={tfVersion}
                                                                             key={tfVersion}>{tfVersion}</option>)}
                      </Field>
                      <div className="form-check">
                        <Field
                          className={"form-check-input"}
                          name="isTerragrunt"
                          id="isTerragrunt"
                          component="input"
                          type="checkbox"
                          placeholder="Is Terragrunt?"
                          onChange={this.onChangeIsTerragrunt}
                        />
                        <label className="form-check-label ml-3" htmlFor="isTerragrunt">Is Terragrunt?</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="vcs">VCS</label>
                      <Field
                        className={"form-control"}
                        name="vcs"
                        id="vcs"
                        component="select"
                        onChange={this.onChangeVcs}
                      >
                        <option value="">Please choose a VCS..</option>
                        {_.map(githubApps || [], app => <option value={app.id} key={app.id}>{app.name}</option>)}
                      </Field>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="repo">Repository</label>
                      <Field
                        className={"form-control"}
                        name="repo"
                        id="repo"
                        component="select"
                        disabled={_.isEmpty(repos)}
                        onChange={this.onChangeRepo}
                      >
                        <option value="">Please choose a Repo..</option>
                        {_.map(repos || [], repo => <option value={repo.fullName}
                                                            key={repo.fullName}>{repo.fullName}</option>)}
                      </Field>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="branch">Branch:</label>
                      <Field
                        className={"form-control"}
                        name="branch"
                        id="branch"
                        component="input"
                        type="text"
                        placeholder="Master is default"
                        onChange={this.onChangeBranch}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="path">Path:</label>
                      <Field
                        className={"form-control"}
                        name="path"
                        id="path"
                        component="input"
                        type="text"
                        placeholder="Do not fill if you want to use the root"
                        onChange={this.onChangePath}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="awsIntegration">AWS Account</label>
                      <Field
                        className={"form-control"}
                        name="awsIntegration"
                        id="awsIntegration"
                        component="select"
                        onChange={this.onChangeAwsIntegration}
                      >
                        <option value="">Please choose an AWS account..</option>
                        {_.map(awsIntegrations || [], aws => <option value={aws.id} key={aws.id}>{aws.name}</option>)}
                      </Field>
                    </div>
                  </div>}
              </div>
            </div>


          </div> : null}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    githubApps: state.vcsReducer.githubApps,
    awsIntegrations: state.integrationsReducer.aws.integrations || [],
    activeFeatures: state.activeFeaturesReducer.activeFeatures || [],
  };
};

const mapDispatchToProps = {
  createStackByEnvironmentId,
  getAllGithubApps,
  getAwsIntegrations,
  getStacksByEnvironmentId
};


SelectIacSource = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SelectIacSource);

export default withRouter(SelectIacSource);
