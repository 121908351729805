import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from "../../consts/config";

export const GET_ALL_STATES = "GET_ALL_STATES";
export const GET_LATEST_STATE = "GET_LATEST_STATE";
export const GET_STATE_POLICY = "GET_STATE_POLICY";
export const UPDATE_STATE_POLICY = "UPDATE_STATE_POLICY";

const getAllStates = (stackId, page = 0) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const statesReq = await requestWrapper.sendRequest(`${BACKEND_URL}/states/stack/${stackId}?page=${page}`, "GET");

        const states = await statesReq.json();
        dispatch({
            type: GET_ALL_STATES,
            payload: { states }
        });

        return states;
    });
}

const getLatestState = (stackId) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const statesReq = await requestWrapper.sendRequest(`${BACKEND_URL}/states/stack/${stackId}/latest`, "GET");

        const latest = await statesReq.json();
        dispatch({
            type: GET_LATEST_STATE,
            payload: { latest }
        });

        return latest;
    });
}

const upload = (stackId, tfState) => {
    return action(async dispatch => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/states/stack/${stackId}/upload`, "POST", {
            tfState
        });

        return req;
    });
}

const getIacSettings = (stackId) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/states/policy/${stackId}`, "GET");

        const res = { stackId };
        if (req.ok) {
            res.ok = true;
            res.result = await req.json();
        } else {
            res.ok = false;
            res.result = null;
            res.status = req.status;
        }

        dispatch({
            type: GET_STATE_POLICY,
            payload: res
        });
        return res;
    });
}

const updateIacSettings = (stackId, selected, policy) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/states/policy/${stackId}`, "PUT", { selected, policy });

        const res = {
            stackId,
            ok: req.ok,
            status: req.status
        };

        dispatch({
            type: UPDATE_STATE_POLICY,
            payload: res
        });
        return res;
    });
}

export {
    getAllStates,
    upload,
    getLatestState,
    getIacSettings,
    updateIacSettings
}
