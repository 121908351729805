import { GET_ENVIRONMENTS, CREATE_ENVIRONMENT } from '../actions/environmentActions';

const initialState = {
  data: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ENVIRONMENTS:
      return { ...state, data: payload.data };

    case CREATE_ENVIRONMENT:
      return { ...state };

    default:
      return state;
  }
}
