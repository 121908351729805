import React, { PureComponent } from "react";
import _ from "lodash";
import "./menuObjects.scss";
import { withRouter, NavLink } from "react-router-dom";

class SideBar extends PureComponent {
  render() {
    return (
      <div
        className="sidebar-left open rtl-ps-none"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul className="navigation-left">{this.props.children}</ul>
      </div>
    );
  }
}

class MenuCategory extends React.Component {
  onClick = () => {
    const { history, link } = this.props;
    history.push(link || "#");
  };

  render() {
    const { name, icon, id, isActive, children, link, exact } = this.props;
    const dataItem = !_.isEmpty(this.props.dataItem) ? this.props.dataItem : id;
    const isHidden = _.isUndefined(this.props.isHidden) ? false : this.props.isHidden;

    return (
      <li
        className={`nav-item ${isActive ? "active" : ""} ${isHidden ? "hidden" : ""}`}
        data-item={dataItem}
      >
        <NavLink className="nav-item-hold" to={link} onClick={this.onClick} activeClassName="tab-active" exact={exact}>
          <i className="nav-icon" className={icon}></i>
          {_.isEmpty(children) ? null : children}
          <span className="nav-text">{name}</span>
        </NavLink>
        <div className="triangle"></div>
      </li>
    );
  }
}

class MenuItem extends PureComponent {
  render() {
    const { id, path, name, selected, icon, children } = this.props;

    return (
      <>
        <li className={id === selected ? "nav-item open" : "nav-item"}>
          <NavLink to={path}>
            {_.isEmpty(icon) ? null : (
              <i className="nav-icon" className={icon}></i>
            )}
            {_.isEmpty(children) ? null : children}
            &nbsp;<span className="item-name">{name}</span>
          </NavLink>
        </li>
      </>
    );
  }
}

MenuCategory = withRouter(MenuCategory);

export { SideBar, MenuCategory, MenuItem };
