
import React from 'react';

export default class PageButtonsLine extends React.Component {
    render() {
        return <div className="row">
            <div className="col-md-10"></div>
            <div className="col-md-2">
                {this.props.children}
            </div>
        </div>
    }
}