import CookiesManager from './cookiesManager';
import { BACKEND_URL } from '../consts/config';

class RequestWrapper {

    constructor() {
        this.cookies = new CookiesManager();
    }

    async sendRequest(url, method, payload={}, authRequired = true, headers = null) {
        method = method.toUpperCase();
        payload = payload || {};
        headers = headers || { 'Content-Type': 'application/json' };

        if (authRequired) {
            const cookieMgr = new CookiesManager();
            const accessToken = cookieMgr.getAccessToken();
            headers = { ...headers, 'Authorization': `Bearer ${accessToken}` };
        }

        if (!method in ["GET", "POST", "PUT", "DELETE"]) {
            throw new Error("HTTP Request must be [GET, POST, PUT, DELETE]");
        }

        let requestOptions = {
            method,
            headers
        };

        if (method == "POST" || method == "PUT") {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(payload)
            }
        }

        const response = await fetch(url, requestOptions);
        return response;
    }
}

export default RequestWrapper;