import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import {
  environmentReducer,
  stackReducer,
  variableReducer,
  orchestratorReducer,
  statesReducer,
  profilesReducer,
  keyPairsReducer,
  terraformReducer,
  vcsReducer,
  integrationsReducer,
  inventoryReducer,
  metricReducer,
  exclusionsReducer,
  homePageReducer,
  activeFeaturesReducer,
  customExclusionsReducer,
  driftsReducer,
  iacImportReducer,
  k8sIntegrationReducer,
  gcpIntegrationReducer,
  tfcIntegrationReducer,
} from "./redux/index";

const reducer = combineReducers({
  environmentReducer,
  stackReducer,
  variableReducer,
  orchestratorReducer,
  statesReducer,
  profilesReducer,
  keyPairsReducer,
  terraformReducer,
  vcsReducer,
  integrationsReducer,
  inventoryReducer,
  metricReducer,
  exclusionsReducer,
  homePageReducer,
  activeFeaturesReducer,
  customExclusionsReducer,
  driftsReducer,
  iacImportReducer,
  k8sIntegrationReducer,
  gcpIntegrationReducer,
  tfcIntegrationReducer,
  form: reduxFormReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));
export default store;
