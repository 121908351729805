import _ from 'lodash';

export const generateManualHCL = (selectedResource, generateProvider) => {
let hcl = "";

        const showTags = (selectedResource) => {
            const tags = _.isEmpty(selectedResource.awsObject.Tags) ? {} : _.mapValues(_.keyBy(selectedResource, 'Key'), 'Value');
            return JSON.stringify(tags);
        }

        if (!_.isEmpty(selectedResource) && !_.isEmpty(selectedResource.type)) {

            if(generateProvider && !_.isEmpty(selectedResource.region)){
                hcl +=
//THIS IS SUPER TEMPORARY, WRITTEN FOR HYPERWISE VC DEMO
//DO NOT WRITE EVEN SPACE IN ORDER TO AVOID BUGS
`provider "aws" {
    alias  = "${selectedResource.region}"
    region = "${selectedResource.region}"
}
`
            }

            switch (selectedResource.type) {
                case "aws_vpc":
                    hcl +=
`
resource "aws_vpc" "${selectedResource.resourceId}" {
	provider         = "aws.${selectedResource.region}"
	cidr_block       = "${selectedResource.awsObject.CidrBlock}"
    instance_tenancy = "${selectedResource.awsObject.InstanceTenancy}"
    tags             = ${showTags(selectedResource)}
}`
                    break

                case "aws_iam_user":
                    hcl +=
`
resource "aws_iam_user" "${selectedResource.resourceId}" {
	name = "${selectedResource.name}"
	path = "${selectedResource.awsObject.Path}"
	tags = ${showTags(selectedResource)}
}`
                    break;


                case "aws_subnet":
                    hcl +=
`
resource "aws_subnet" "${selectedResource.resourceId}" {
	provider                = "aws.${selectedResource.region}"
	vpc_id                  = "${selectedResource.awsObject.VpcId}"
	cidr_block              = "${selectedResource.awsObject.CidrBlock}"
	availability_zone       = "${selectedResource.awsObject.AvailabilityZone}"
	map_public_ip_on_launch = ${selectedResource.awsObject.MapPublicIpOnLaunch}
}`
                break;


                case "aws_internet_gateway":
                    const vpcAttachment = _.filter(selectedResource.awsObject.Attachments, x => x.State == "available");
                    hcl +=
`
resource "aws_internet_gateway" "${selectedResource.resourceId}" {
    provider = "aws.${selectedResource.region}"`
    if (!_.isEmpty(vpcAttachment)) {
hcl += `
    vpc_id   = "${vpcAttachment[0].VpcId}"`;
    }
hcl += `
}`
                break;

                case "aws_security_group":
                    hcl +=
`
resource "aws_security_group" "${selectedResource.resourceId}" {
    provider    = "aws.${selectedResource.region}"
    description = "${selectedResource.awsObject.Description}"
    name        = "${selectedResource.awsObject.GroupName}"
    vpc_id      = "${selectedResource.awsObject.GroupId}"
    tags        = ${showTags(selectedResource)}
    
    #egress {...} -  Coming soon
    #ingress {...} - Coming soon
    
    lifecycle {
        ignore_changes = [
            egress,
            ingress
        ]
      }
}`
                break;

                case "aws_s3_bucket":
                    hcl +=
`
resource "aws_s3_bucket" "${selectedResource.resourceId}" {
    arn           = "${selectedResource.arn}"
    bucket        = "${selectedResource.name}"
    force_destroy = false
  
    #grant {...} -  Coming soon
    lifecycle {
        ignore_changes = [
            grant
        ]
      }
}`
                break;

                case "aws_route53_zone":
                    hcl +=
`
resource "aws_route53_zone" "${selectedResource.resourceId}" {
    comment       = "${selectedResource.awsObject.Config.Comment}"
    force_destroy = false
    name          = "${selectedResource.awsObject.Name}"
}`
                break;


                case "aws_instance":
                    const instance = !_.isEmpty(selectedResource.awsObject.Instance)
                        ? selectedResource.awsObject.Instance :
                        //This is legacy and should be removed soon
                        _.first(selectedResource.awsObject.Instances);
                        
                    if(_.isEmpty(instance)){
                        hcl = "An error occurred while trying to translate instance";
                        break;
                    }
                    hcl +=
`
resource "aws_instance" "${instance.InstanceId}" {
    provider                   = "aws.${selectedResource.region}"
    ami                        = "${instance.ImageId}"
    associate_public_ip_address= ${!_.isEmpty(instance.PublicIpAddress)}
    availability_zone          = "${instance.Placement.AvailabilityZone}"
    cpu_core_count             = ${instance.CpuOptions.CoreCount}
    cpu_threads_per_core       = ${instance.CpuOptions.ThreadsPerCore}  
    ebs_optimized              = ${instance.EbsOptimized}
    hibernation                = ${instance.HibernationOptions.Configured}
    iam_instance_profile       = "${_.isEmpty(instance.IamInstanceProfile) || _.isEmpty(instance.IamInstanceProfile.Arn) ? "" : instance.IamInstanceProfile.Arn.split("/")[1]}"
    instance_type              = "${instance.InstanceType}"
    key_name                   = "${instance.KeyName}"
  
    metadata_options {
      http_endpoint            = "${instance.MetadataOptions.HttpEndpoint}"
      http_put_response_hop_limit = ${instance.MetadataOptions.HttpPutResponseHopLimit}
      http_tokens              = "${instance.MetadataOptions.HttpTokens}"
    }
  
    private_ip                 = "${instance.PrivateIpAddress || ""}"
    source_dest_check          = ${instance.SourceDestCheck || false}
    subnet_id                  = "${instance.SubnetId || ""}"
    tags                       = ${JSON.stringify(_.isEmpty(instance.Tags) ? {} : _.mapValues(_.keyBy(instance, 'Key'), 'Value'))}
  
    #user_data_base64 - not supported yet
    vpc_security_group_ids = [${_.map(instance.SecurityGroups || [], sg => `"${sg.GroupId}"`).join(", ")}]
}`
                    break;

                    case "aws_ssm_parameter":
                        hcl +=
`
resource "aws_ssm_parameter" "${selectedResource.resourceId}" {
    provider    = "aws.${selectedResource.region}"
    name        = "${selectedResource.awsObject.Name}"
    type        = "${selectedResource.awsObject.Type}"
    value       = "<ENCRYPTED_VALUE_MUST_BE_SET_MANUALLY>"
  }`
                    break;

                    case "aws_iam_role":
                        hcl +=
`resource "aws_iam_role" "${selectedResource.resourceId}" {`;
if(!_.isEmpty(selectedResource.awsObject.AssumeRolePolicyDocument)){
hcl+=`
      assume_role_policy = <<POLICY
    ${JSON.stringify(JSON.parse(decodeURIComponent(selectedResource.awsObject.AssumeRolePolicyDocument)), undefined, 2)}
POLICY`
}
hcl += `
    max_session_duration = ${selectedResource.awsObject.MaxSessionDuration}
    name                 = "${selectedResource.awsObject.RoleName}"
    path                 = "${selectedResource.awsObject.Path}"
    tags                 = ${showTags(selectedResource)}
}
`
                    break;

                    case "aws_lambda_function":
                        let lambdaEnv = "";
                        if(!_.isEmpty(selectedResource.awsObject.Stack)){
                            lambdaEnv += "environment {\r\n";
                                if(!_.isEmpty(selectedResource.awsObject.Stack.Variables)){
                                    lambdaEnv += `\t\tvariables = {${_.map(selectedResource.awsObject.Stack.Variables, (v, k) => `\t\t${k}="${v}"`).join("\n\t\t\t\t\t")}\n\t\t}`;
                                }
                                lambdaEnv += "\r\n}";
                        }

                        hcl +=
`resource "aws_lambda_function"  "${selectedResource.resourceId}" {
    provider                = "aws.${selectedResource.region}"
    function_name           = "${selectedResource.awsObject.FunctionName}"
    role                    = "${selectedResource.awsObject.Role}"
    handler                 = "${selectedResource.awsObject.Handler}"
  
    source_code_hash        = "${selectedResource.awsObject.CodeSha256}"
  
    runtime                 = "${selectedResource.awsObject.Runtime}"
  
${lambdaEnv}
  
    timeout                 = ${selectedResource.awsObject.Timeout}
    memory_size             = ${selectedResource.awsObject.MemorySize}
`
if(!_.isEmpty(selectedResource.awsObject.VpcConfig)){
hcl += `vpc_config {
    subnet_ids              = ${JSON.stringify(selectedResource.awsObject.VpcConfig.SubnetIds)}
    security_group_ids      = ${JSON.stringify(selectedResource.awsObject.VpcConfig.SecurityGroupIds)}  
  }`
}  

hcl +=`
     tags                   = ${showTags(selectedResource)}
}`;
                break;

                case "aws_ec2_transit_gateway":
                    hcl +=
`resource "aws_ec2_transit_gateway" "${selectedResource.resourceId}" {
    provider                          = "aws.${selectedResource.region}"
    description                       = "${selectedResource.awsObject.Description}"
    amazon_side_asn                   = ${_.isEmpty((selectedResource.awsObject.Option||{}).AmazonSideAsn)? 64512 : selectedResource.awsObject.Option.AmazonSideAsn}
    auto_accept_shared_attachments    = "${_.isEmpty((selectedResource.awsObject.Option||{}).AutoAcceptSharedAttachments)?"disable": selectedResource.awsObject.Option.AutoAcceptSharedAttachments}"
    default_route_table_association   = "${_.isEmpty((selectedResource.awsObject.Option||{}).DefaultRouteTableAssociation)?"enable": selectedResource.awsObject.Option.DefaultRouteTableAssociation}"
    default_route_table_propagation   = "${_.isEmpty((selectedResource.awsObject.Option||{}).DefaultRouteTablePropagation)?"enable": selectedResource.awsObject.Option.DefaultRouteTablePropagation}"
    dns_support                       = "${_.isEmpty((selectedResource.awsObject.Option||{}).DnsSupport)?"enable": selectedResource.awsObject.Option.DnsSupport}"
    tags                              = ${showTags(selectedResource)}
    vpn_ecmp_support                  = "${_.isEmpty((selectedResource.awsObject.Option||{}).VpnEcmpSupport)?"enable": selectedResource.awsObject.Option.VpnEcmpSupport}"
}`;
                    break;

                case "aws_ec2_transit_gateway_route_table":
                    hcl +=
`resource "aws_ec2_transit_gateway_route_table" "${selectedResource.resourceId}" {
    provider            = "aws.${selectedResource.region}"
    transit_gateway_id  = "${selectedResource.awsObject.TransitGatewayId}"
    tags                = ${showTags(selectedResource)}
}`;
                    break;

                case "aws_ec2_transit_gateway_multicast_domain":
                hcl +=`#Transit Gateway Multicase Domain is not supported in Terraform yet!`;
                break;

            case "aws_ec2_transit_gateway_vpc_attachment":
                hcl +=
`resource "aws_ec2_transit_gateway_vpc_attachment" "${selectedResource.resourceId}" {
    provider                = "aws.${selectedResource.region}"
    subnet_ids              = ${JSON.stringify(selectedResource.awsObject.SubnetIds)}
    transit_gateway_id      = "${selectedResource.awsObject.TransitGatewayId}"
    vpc_id                  = "${selectedResource.awsObject.VpcId}"
    appliance_mode_support  = "${_.isEmpty((selectedResource.awsObject.Option||{}).ApplianceModeSupport)?"disable": selectedResource.awsObject.Option.ApplianceModeSupport}"
    dns_support             = "${_.isEmpty((selectedResource.awsObject.Option||{}).DnsSupport)?"enable": selectedResource.awsObject.Option.DnsSupport}"
    ipv6_support            = "${_.isEmpty((selectedResource.awsObject.Option||{}).Ipv6Support)?"disable": selectedResource.awsObject.Option.Ipv6Support}"
    tags                    = ${showTags(selectedResource)}
}`;
                break;

            case "aws_ec2_transit_gateway_peering_attachment":
                //TransitGatewayPeeringAttachment
                hcl +=
`resource "aws_ec2_transit_gateway_peering_attachment" "${selectedResource.resourceId}_peer1" {
    peer_account_id         = "${selectedResource.awsObject.AccepterTgwInfo.OwnerId}"
    peer_region             = "${selectedResource.awsObject.AccepterTgwInfo.Region}"
    peer_transit_gateway_id = "${selectedResource.awsObject.AccepterTgwInfo.TransitGatewayId}"
    transit_gateway_id      = "${selectedResource.awsObject.PeeringTgwInfo.TransitGatewayId}"
    tags                    = ${showTags(selectedResource)}
}
resource "aws_ec2_transit_gateway_peering_attachment" "${selectedResource.resourceId}_peer2" {
    peer_account_id         = "${selectedResource.awsObject.PeeringTgwInfo.OwnerId}"
    peer_region             = "${selectedResource.awsObject.PeeringTgwInfo.Region}"
    peer_transit_gateway_id = "${selectedResource.awsObject.PeeringTgwInfo.TransitGatewayId}"
    transit_gateway_id      = "${selectedResource.awsObject.AccepterTgwInfo.TransitGatewayId}"
    tags                    = ${showTags(selectedResource)}
}
`;
                break;

                case "aws_lb":
                    hcl +=
`resource "aws_lb" "${selectedResource.name}" {
    name                        = "${selectedResource.awsObject.LoadBalancerName}"
    internal                    = ${(selectedResource.awsObject.Schema == "internal").toString()}
    load_balancer_type          = "${selectedResource.awsObject.Type}"
    security_groups             = ${JSON.stringify(selectedResource.awsObject.SecurityGroups)}
    #drop_invalid_header_fields will be supported soon
    #access_log will be supported soon
    ${selectedResource.awsObject.Type == "network" ? "": `
    subnets                     = ${JSON.stringify(_.map(selectedResource.awsObject.AvailabilityZones, x=>x.SubnetId))}`}
    #enable_cross_zone_load_balancing will be supported soon (for network-load-balancer only)
    ${_.isEmpty(selectedResource.awsObject.IpAddressType)? "" : `ip_address_type             = "${selectedResource.awsObject.IpAddressType}"`}
    tags                        = ${showTags(selectedResource)}
    #access_logs will be supported soon
}`;
                    break;

                case "aws_launch_configuration":
                    
                    const autoScalingGroupRootBlock = _.first(_.filter(selectedResource.awsObject.BlockDeviceMappings, x=>x.DeviceName == "/dev/sda1"));
                    //const autoScalingGroupAdditionalBlocks = _.filter(selectedResource.awsObject.BlockDeviceMappings);
                    
                    hcl +=
`resource "aws_launch_configuration" "${selectedResource.awsObject.LaunchConfigurationName}" {
    name                        = "${selectedResource.awsObject.LaunchConfigurationName}"
    image_id                    = "${selectedResource.awsObject.ImageId}"
    instance_type               = "${selectedResource.awsObject.InstanceType}"
    ${_.isEmpty(selectedResource.awsObject.IamInstanceProfile)?"":
`   iam_instance_profile        = "${selectedResource.awsObject.IamInstanceProfile}"`}
    key_name                    = "${selectedResource.awsObject.KeyName}"
    #metadata_options will be supported soon
    security_groups             = ${JSON.stringify(selectedResource.awsObject.SecurityGroups)}
    associate_public_ip_address = ${_.isEmpty(selectedResource.awsObject.AssociatePublicIpAddress) ? false : selectedResource.awsObject.AssociatePublicIpAddress.toString()}
    #vpc_classic_link_id will be supported soon
    #vpc_classic_link_security_groups will be supported soon
    user_data_base64            = "${selectedResource.awsObject.UserData}"
    ebs_optimized               = ${selectedResource.awsObject.EbsOptimized.toString()}
    ${_.isEmpty(autoScalingGroupRootBlock)? "":
    `root_block_device {
        volume_type            = "${autoScalingGroupRootBlock.Ebs.VolumeType}"
        volume_size            = ${autoScalingGroupRootBlock.Ebs.VolumeSize}
        delete_on_termination  = ${autoScalingGroupRootBlock.Ebs.DeleteOnTermination.toString()}
    }`}
    #ebs_block_device will be supported soon
    tags                        = ${showTags(selectedResource)}
}`;
                    break;
                    
                case "aws_elb":
                    hcl +=
`resource "aws_elb" "${selectedResource.awsObject.LoadBalancerName}" {
    name = "${selectedResource.awsObject.LoadBalancerName}"
    ${!_.isEmpty(selectedResource.awsObject.VPCId)? "#availability_zones is available just for classic ELB (ELB not under VPC)": `availability_zones = ${JSON.stringify(selectedResource.awsObject.AvailabilityZones)}`}
    ${_.isEmpty(selectedResource.awsObject.VPCId) ? "#security_groups are not available for classic ELB" : `security_groups = ${JSON.stringify(selectedResource.awsObject.SecurityGroups)}`}
    ${_.isEmpty(selectedResource.awsObject.VPCId) ? "#subnets are not available for classic ELB" : `subnets = ${JSON.stringify(selectedResource.awsObject.Subnets)}`}
    instances = ${JSON.stringify(_.map(selectedResource.awsObject.Instances, x=>x.InstanceId))}
    internal = ${(selectedResource.awsObject.Scheme == "intenal").toString()}
    ${_.map(selectedResource.awsObject.ListenerDescriptions, x => `
    listener {
        instance_port       = ${x.Listener.InstancePort}
        instance_protocol   = "${x.Listener.InstanceProtocol}"
        lb_port             = ${x.Listener.LoadBalancerPort}
        lb_protocol         = "${x.Listener.Protocol}"
        #ssl_certificate_id is not supported yet
    }`).join("\n")}
    ${_.isEmpty(selectedResource.awsObject.HealthCheck)? "" : `
    health_check {
        healthy_threshold   = ${selectedResource.awsObject.HealthCheck.HealthyThreshold}
        unhealthy_threshold = ${selectedResource.awsObject.HealthCheck.UnhealthyThreshold}
        timeout             = ${selectedResource.awsObject.HealthCheck.Timeout}
        target              = "${selectedResource.awsObject.HealthCheck.Target}"
        interval            = ${selectedResource.awsObject.HealthCheck.Interval}
    }`}
    #cross_zone_load_balancing is not supported yet
}`;
                        break;

                case "aws_autoscaling_group":
                    hcl +=
`resource "aws_autoscaling_group" "${selectedResource.awsObject.AutoScalingGroupName}" {
    name                = "${selectedResource.awsObject.AutoScalingGroupName}"
    max_size            = ${selectedResource.awsObject.MaxSize}
    min_size            = ${selectedResource.awsObject.MinSize}
    desired_capacity    = ${selectedResource.awsObject.DesiredCapacity}
    ${_.isEmpty(selectedResource.awsObject.TargetGroupARNs)? "": `target_group_arns = ${JSON.stringify(selectedResource.awsObject.TargetGroupARNs)}`}
    ${_.isEmpty(selectedResource.awsObject.TerminationPolicies)? "": `termination_policies = ${JSON.stringify(selectedResource.awsObject.TerminationPolicies)}`}
    tags = ${showTags(selectedResource)}
    service_linked_role_arn = "${selectedResource.awsObject.ServiceLinkedRoleARN}"
    launch_configuration    = "${selectedResource.awsObject.LaunchConfigurationName}"
    vpc_zone_identifier     = ${JSON.stringify(selectedResource.awsObject.VPCZoneIdentifier.split(","))}
}`;  
                    break;

                case "aws_ebs_volume":
                    hcl +=
`resource "aws_ebs_volume" "${selectedResource.name}" {
    availability_zone       = "${selectedResource.awsObject.AvailabilityZone}"
    encrypted               = ${selectedResource.awsObject.Encrypted}
${_.includes(["standard", "gp2", "gp3", "io1", "io2", "sc1", "st1"], selectedResource.awsObject.VolumeType) ?
`    iops                    = ${selectedResource.awsObject.Iops}` : null}
${!_.isBoolean(selectedResource.awsObject.MultiAttachEnabled)? "" : `    multi_attach_enabled    = ${selectedResource.awsObject.MultiAttachEnabled}`}
    size                    = ${selectedResource.awsObject.Size}
    snapshot_id             = "${selectedResource.awsObject.SnapshotId}"
    type                    = "${selectedResource.awsObject.VolumeType}"
    tags                    = ${showTags(selectedResource)}
}`;
                break;

                case "aws_acm_certificate":
                    hcl +=
`resource "aws_acm_certificate" "${selectedResource.awsObject.CertificateArn.split("/")[1]}" {
    domain_name =   "${selectedResource.awsObject.DomainName}"
    #Infralight is not exposed to: private_key, certificate_body, certificate_chain
}`;
                    break;

                case "aws_dynamodb_table":
                    const dynamoTableHashKey = _.first(_.filter(selectedResource.awsObject.KeySchema||[], x=>x.KeyType=="HASH")||[]);
                    const dynamoTableHRangeKey = _.first(_.filter(selectedResource.awsObject.KeySchema||[], x=>x.KeyType=="RANGE")||[]);
                    const dynamoTableBillingMode = _.isEmpty(selectedResource.awsObject.BillingModeSummary)?"PROVISIONED" : selectedResource.awsObject.BillingModeSummary.BillingMode;
                    hcl +=
`resource "aws_dynamodb_table" "${selectedResource.name}" {
    name            = "${selectedResource.awsObject.TableName}"
    billing_mode    = "${dynamoTableBillingMode}"
    hash_key        = "${_.isEmpty(dynamoTableHashKey) ? "HASH KEY IS A MANDATORY PARAMETER" : dynamoTableHashKey.AttributeName}"
    ${_.isEmpty(dynamoTableHRangeKey)? "" : `range_key        = "${dynamoTableHRangeKey.AttributeName}"`}
    write_capacity  = ${selectedResource.awsObject.ProvisionedThroughput.WriteCapacityUnits}
    read_capacity   = ${selectedResource.awsObject.ProvisionedThroughput.ReadCapacityUnits}
    ${_.map(selectedResource.awsObject.AttributeDefinitions, att=> 
`\r\n       attribute{
                name = "${att.AttributeName}"
                type = "${att.AttributeType}"
            }`
    ).join("")}
    #ttl is not supported yet
    ${_.map(selectedResource.awsObject.GlobalSecondaryIndexes||[], att=> 
    `global_secondary_index {
                    name                = "${att.IndexName}"
                    write_capacity      = ${att.ProvisionedThroughput.WriteCapacityUnits}
                    read_capacity       = "${att.ProvisionedThroughput.ReadCapacityUnits}"
                    hash_key            = "${(_.first(_.filter(selectedResource.awsObject.KeySchema||[], x=>x.KeyType=="HASH")||[])||{}).AttributeName||""}"
                    range_key           = "${(_.first(_.filter(selectedResource.awsObject.KeySchema||[], x=>x.KeyType=="RANGE")||[])||{}).AttributeName||""}"
                    projection_type     = "${att.Projection.ProjectionType}"
                }`
        ).join("")}
}`;
                    break;
                
                case "aws_db_instance":
                    hcl +=
`resource "aws_db_instance" "${selectedResource.name}" {
    allocated_storage                   = ${selectedResource.awsObject.AllocatedStorage}
    auto_minor_version_upgrade          = ${selectedResource.awsObject.AutoMinorVersionUpgrade}
    availability_zone                   = "${selectedResource.awsObject.AvailabilityZone}"
    backup_retention_period             = ${selectedResource.awsObject.BackupRetentionPeriod}
    backup_window                       = "${selectedResource.awsObject.PreferredBackupWindow}"
    ca_cert_identifier                  = "${selectedResource.awsObject.CACertificateIdentifier}"
    copy_tags_to_snapshot               = ${selectedResource.awsObject.CopyTagsToSnapshot}
    db_subnet_group_name                = "${selectedResource.awsObject.DBSubnetGroup.DBSubnetGroupName}"
    deletion_protection                 = ${selectedResource.awsObject.DeletionProtection}
    #domain is not supported yet    
    enabled_cloudwatch_logs_exports     = ${JSON.stringify(selectedResource.awsObject.EnabledCloudwatchLogsExports||[])}
    engine                              = "${selectedResource.awsObject.Engine}"
    engine_version                      = "${selectedResource.awsObject.EngineVersion}"
    iam_database_authentication_enabled = ${selectedResource.awsObject.IAMDatabaseAuthenticationEnabled}
    instance_class                      = "${selectedResource.awsObject.DBInstanceClass}"
${_.isEmpty(selectedResource.awsObject.KmsKeyId)?"": 
`    kms_key_id                          = "${selectedResource.awsObject.KmsKeyId}"`}
    license_model                       = "${selectedResource.awsObject.LicenseModel}"
${_.isUndefined(selectedResource.awsObject.MaxAllocatedStorage)?"":
`    max_allocated_storage               = ${selectedResource.awsObject.MaxAllocatedStorage}`}
    monitoring_interval                 = ${selectedResource.awsObject.MonitoringInterval}
    multi_az                            = ${selectedResource.awsObject.MultiAZ}
    name                                = "${selectedResource.awsObject.DBInstanceIdentifier}"
${_.isEmpty(_.first(selectedResource.awsObject.OptionGroupMemberships))?"": `    option_group_name                                = "${_.first(selectedResource.awsObject.OptionGroupMemberships).OptionGroupName}"`}
    performance_insights_enabled        = ${selectedResource.awsObject.PerformanceInsightsEnabled}
    port                                = ${selectedResource.awsObject.DbInstancePort}
    publicly_accessible                 = ${selectedResource.awsObject.PubliclyAccessible}
    vpc_security_group_ids              = ${JSON.stringify(_.map(_.filter(selectedResource.awsObject.VpcSecurityGroups, x=> x.Status=="active"), sg=> sg.VpcSecurityGroupId))}
    #security_group_names used in RDS classic
    storage_encrypted                   = ${selectedResource.awsObject.StorageEncrypted}
    storage_type                        = "${selectedResource.awsObject.StorageType}"
    tags                                = {
                                            ${_.map(selectedResource.awsObject.TagList, tag=>`"${tag.Key}"="${tag.Value}"`).join("\r\n\t\t\t\t\t\t\t\t\t\t\t\t")}
                                          }
    username                            = "${selectedResource.awsObject.MasterUsername}"
}`;
                    break;


                case "aws_iam_policy":
                    hcl +=
`resource "aws_iam_policy" "${selectedResource.resourceId}" {
	name = "${selectedResource.name}"
	path = "${selectedResource.awsObject.Path}"`;
if(!_.isEmpty(selectedResource.awsObject.policyVersion.Document)){
hcl+=`
    policy = <<POLICY
${JSON.stringify(JSON.parse(decodeURIComponent(selectedResource.awsObject.policyVersion.Document)), undefined, 2)}
POLICY
`
}
                    hcl +=
`}`
                    break;

                default:
                    break;


                case "aws_sqs_queue":
                    hcl +=
                        `
resource "aws_sqs_queue" "${selectedResource.resourceId}" {
    name           = "${selectedResource.name}"
    #delay_seconds will be supported soon
    #redrive_policy will be supported soon
}`
                    break;

                case "aws_sns_topic":
                    hcl +=
                        `
resource "aws_sns_topic" "${selectedResource.resourceId}" {
    name           = "${selectedResource.name}"
    #policy will be supported soon
}`
                    break;

                case "aws_apigatewayv2_api":
                    hcl +=
                        `
resource "aws_apigatewayv2_api" "${selectedResource.name}" {
    name           = "${selectedResource.name}"
    protocol_type  = "${selectedResource.awsObject.ProtocolType}"
}`
                    break;

                case "aws_api_gateway_rest_api":
                    hcl +=
                        `
resource "aws_api_gateway_rest_api" "${selectedResource.name}" {
    name           = "${selectedResource.awsObject.name}"
}`
                    break;

                case "aws_iam_group":
                    hcl +=
                        `
resource "aws_iam_group" "${selectedResource.resourceId}" {
    name           = "${selectedResource.name}"
    path           = "${selectedResource.awsObject.Path}"
}`
                    break;
                case "aws_eks_cluster":
                    hcl +=
                        `
resource "aws_eks_cluster" "${selectedResource.resourceId}" {
    name                      = "${selectedResource.name}"`
    if (!_.isEmpty(selectedResource.awsObject.resourcesVpcConfig)) {
        hcl += `
    role_arn                  = "${selectedResource.awsObject.roleArn}"`
    }
    if (!_.isEmpty(selectedResource.awsObject.resourcesVpcConfig)) {
        hcl += `
    version                   = "${selectedResource.awsObject.version}"`
    }
    if (!_.isEmpty(selectedResource.awsObject.resourcesVpcConfig)) {
        hcl += `
    vpc_config {
      ${_.isEmpty(selectedResource.awsObject.resourcesVpcConfig.securityGroupsIds)? "": `security_group_ids = ${JSON.stringify(selectedResource.awsObject.resourcesVpcConfig.securityGroupsIds)}`}
      ${_.isEmpty(selectedResource.awsObject.resourcesVpcConfig.subnetIds)? "": `subnet_ids = ${JSON.stringify(selectedResource.awsObject.resourcesVpcConfig.subnetIds)}`}
      endpoint_private_access = "${selectedResource.awsObject.resourcesVpcConfig.endpointPublicAccess}"
      endpoint_public_access  = "${selectedResource.awsObject.resourcesVpcConfig.endpointPrivateAccess}"
      ${_.isEmpty(selectedResource.awsObject.resourcesVpcConfig.publicAccessCidrs)? "": `public_access_cidrs = ${JSON.stringify(selectedResource.awsObject.resourcesVpcConfig.publicAccessCidrs)}`}
    }
    `
    }
hcl += `
}`
break;
}
}

return hcl;
}