import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BasicModal from '../../../../shared/basicModal';
import { Field } from 'redux-form';
import {create} from '../../../../redux/actions/variableActions';

class CreateVariable extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: false };
    }

    async componentDidMount() {
    }

    onSubmit = async (submittedInfo)  => {
        await this.props.create(submittedInfo.key, submittedInfo.value, submittedInfo.encrypted,  submittedInfo.description, this.props.stackId);
        this.props.onCreate();
    }

    render() {
        const { } = this.state;
        const { isOpen, onClose } = this.props;

        return (
            
                <BasicModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onSubmit={this.onSubmit}
                    header="Create Variable"
                    applyButton="Create"
                >
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="key">Key:</label>
                        <Field
                            className={"form-control"}
                            name="key"
                            id="key"
                            component="input"
                            type="text"
                            placeholder="Please fill the variable key"
                        />
                    </div>
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="value">Value:</label>
                        <Field
                            className={"form-control"}
                            name="value"
                            id="value"
                            component="input"
                            type="text"
                            placeholder="Please fill the variable value"
                        />
                        <div className="form-check">
                            <Field
                                className={"form-check-input"}
                                name="encrypted"
                                id="encrypted"
                                component="input"
                                type="checkbox"
                                placeholder="Please fill the variable value"
                            />
                            <label className="form-check-label ml-3" htmlFor="encrypted">Encrypted</label>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="description">Description:</label>
                            <Field
                                className={"form-control"}
                                name="description"
                                id="description"
                                component="input"
                                type="text"
                                placeholder="Please fill description(optional)"
                            />
                        </div>
                    </div>
                </BasicModal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    create
};


CreateVariable = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(CreateVariable);

export default withRouter(CreateVariable);
