import _ from 'lodash';

export default (inventory, excludedByResourceType, filteredAwsAccounts = null, filteredUnmanaged = true, filteredResourceTypes = null) => {

    const totalResources = [];
    inventory = inventory || [];
    excludedByResourceType = excludedByResourceType || {};

    for (const i in _.keys(inventory)) {
        const key = _.keys(inventory)[i];

        // Filtering out Resource Types
        //For example in the homepage someone clicks on the "IAM Category"
        if (!_.isEmpty(filteredResourceTypes) && !_.includes(filteredResourceTypes, key)) {
            continue;
        }

        const excludedResources = excludedByResourceType[key] || [];

        for (const j in inventory[key].resources) {
            const resource = inventory[key].resources[j];

            //filtered out unmanaged
            if (!filteredUnmanaged) {
                continue;
            }

            //Filtered AWS account Id
            if (!_.isEmpty(filteredAwsAccounts) && !_.includes(filteredAwsAccounts, resource.awsAccountId)) {
                continue;
            }

            //Enriching the data
            resource.icon = inventory[key].icon;
            resource.severity = inventory[key].severity;
            resource.tooltip = inventory[key].tooltip;

            //Excluded
            const isExcluded = _.includes(excludedResources, resource.id);
            if (isExcluded) {
                continue;
            }

            //Managed resource is filtered out
            const hasIac = !_.isEmpty(resource.iacObject);
            if (hasIac) {
                continue;
            }

            totalResources.push(resource);
        }
    }

    return _.sortBy(totalResources, "severity").reverse();
}