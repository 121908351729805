import React from 'react';

export default class PageLayout extends React.Component {
    render() {
        return <div className="row mb-12">
            <div className="col-md-12 mb-4">
                {this.props.children}
            </div>
        </div>
    }
}