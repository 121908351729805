import Cookies from 'js-cookie';

//uglify our cookie names for security purposes

export const ACCESS_TOKEN = "access_token";
class CookiesManager {


    getAccessToken(){
        return Cookies.get(ACCESS_TOKEN);
    }

    setAccessToken(val, expirationInDays=1, path="/"){
        Cookies.set(ACCESS_TOKEN, val, { expires: expirationInDays, path});
    }
}

export default CookiesManager;