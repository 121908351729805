import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../shared/loading';
import BasicModal from '../../shared/basicModal';
import { Field } from 'redux-form';
import UnmanagedResourcesTable from '../main/unmanagedResourcesTable';
import unamangedResourceFilter from '../inventory/unmanagedResourcesFilter';
import OnOff from '../../shared/toggle/onOffToggle';
import {
    createCustomExclusions,
    updateCustomExclusions,
    testCustomExclusions,
    getCustomExclusions
} from '../../redux/actions/customExclusionsActions';
import ReactJson from 'react-json-view';
import Editor from "@monaco-editor/react";
import Base64 from "../../utils/base64";


const initialCode = `package exclude
# The infralight boolean value will represent whether the input is excluded or not
default infralight = false
contains(arr, elem) {
  arr[_] = elem
}
# Use the keyword \`input\` as a reference to the asset you check
`;

class CustomExclusionModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            unmanaged: null,
            showExclusionsEnabled: true,
            selectedResources: [],
            testAsset: {},
            testPassed: null,
            code: initialCode,
            formName: null,
            formDescription: null,
            formCustomExclusionId: null
        };
    }

    //Updating selected resources in exclustion into state
    //When props is updated
    async componentWillReceiveProps(nextProps) {
        const { inventory, excludedByResourceType } = nextProps;

        if (!_.isEqual(this.props.selectedCustomExclusion, nextProps.selectedCustomExclusion)) {
            const next = nextProps.selectedCustomExclusion || {};
            let rego;
            if (!_.isEmpty(next)) {
                rego = Base64.decode(next.rego);
            } else {
                rego = "";
            }

            let tableUnmanagedResources = unamangedResourceFilter(inventory, excludedByResourceType);


            this.setState({
                formName: next.name,
                formDescription: next.description,
                formCustomExclusionId: next.id,
                code: initialCode + rego,
                unmanaged: tableUnmanagedResources
            });
        }
    }

    //Submitting form
    //TODO: need to handle with errors
    onSubmit = async (submittedInfo) => {
        const { selectedResources, formCustomExclusionId, formName, formDescription, code } = this.state;
        let resourceType = selectedResources[0].type;
        let rego = Base64.encode(code.replace(initialCode, ""));
        const { onClose } = this.props;

        this.setState({ isLoading: true });
        //Create
        if (_.isEmpty(formCustomExclusionId)) {
            await this.props.createCustomExclusions(formName, formDescription, rego, resourceType);
            await this.props.getCustomExclusions();
        }
        //Update
        else {
            await this.props.updateCustomExclusions(formCustomExclusionId, formName, formDescription, rego, resourceType);
            await this.props.getCustomExclusions();
        }
        this.setState({ isLoading: false, formCustomExclusionId: null, formName: null, formDescription: null, selectedResources: [] });
        onClose();
    }

    //Select Resource
    handleResourceSelected = async (resource) => {
        const { selectedResources} = this.state;
        selectedResources.push({ id: resource.id, type: resource.type, name: resource.name, arn: resource.arn, k8sObject: resource?.k8sObject, awsObject: resource?.awsObject});
        let newTestAsset = resource.awsObject || resource.k8sObject
        this.setState({ selectedResources, testAsset: newTestAsset});
    }

    //Select Resource
    handleTestRegoCode = async () => {
        const { code, selectedResources, formName, formDescription} = this.state;
        let resourceType = selectedResources[0].type;
        let rego = Base64.encode(code.replace(initialCode, ""));
        let resources = _.map(selectedResources, (resource) => {
            // Converting to OPA go struct format
            return {
                Name: resource.name,
                ARN: resource.arn,
                Type: resource.type,
                AWSObject: JSON.stringify(resource.awsObject),
                K8sObject: JSON.stringify(resource.k8sObject),
            };
        });
        const testPassed = await this.props.testCustomExclusions(formName, formDescription, rego, resourceType, resources);
        this.setState({ selectedResources, testPassed: testPassed});
    }

    //Unselect Resource
    handleResourceUnselected = async (resource) => {
        const selectedResources = this.state.selectedResources.slice();
        let newSelectedResources = _.differenceBy(selectedResources, [{ id: resource.id, type: resource.type, name: resource.name, arn: resource.arn, k8sObject: resource?.k8sObject, awsObject: resource?.awsObject }], "id")
        let newTestAsset = {}
        if (! _.isEmpty(newSelectedResources)){
            resource = _.last(newSelectedResources)
            newTestAsset = resource.awsObject || resource.k8sObject
        }
        this.setState({ selectedResources:  newSelectedResources, testAsset: newTestAsset});
    }

    //Changing On-Off toggle (Show Custom Exclusions)
    changeShowExclusionsEnabled = () => {
        this.setState({ showExclusionsEnabled: !this.state.showExclusionsEnabled });
    }

    // Changing input name
    onChangeName = async (e) => {
        this.setState({ formName: e.target.value });
    }

    // Changing input description
    onChangeDescription = async (e) => {
        this.setState({ formDescription: e.target.value });
    }

    render() {
        const { isLoading, unmanaged, showExclusionsEnabled, selectedResources, formName, formDescription, testAsset, code} = this.state;
        const { isOpen, onClose, selectedCustomExclusion } = this.props;

        const initialValues = {
            name: (selectedCustomExclusion || {}).name || "",
            description: (selectedCustomExclusion || {}).description || "",
            customExclusionId: (selectedCustomExclusion || {}).id || ""
        }

        let tableUnmanagedResources = unmanaged;
        //Marking checked T/F
        tableUnmanagedResources = _.map(tableUnmanagedResources, x => ({ ...x, checked: _.some(selectedResources, { id: x.id, type: x.type }) }));

        //Filtering exclusions if toggle is On
        if (!showExclusionsEnabled) {
            tableUnmanagedResources = _.map(tableUnmanagedResources, x => ({ ...x, hidden: _.some(selectedResources, { id: x.id, type: x.type }) }));
        }



        if (isLoading) {
            return <Loading />
        }

        return <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={this.onSubmit}
            header="Custom exclusion"
            applyButton="Exclude"
            initialValues={initialValues}
            isEnabled={true}
            isLarge={true}
            isShowFooter={true}
            isShowSubmitButton={true}
            isEnabled={!_.isEmpty(formName) && !_.isEmpty(formDescription)}
            enableReinitialize={true}
        >
            <p className="text-medium text-muted">Select resources to be excluded from presented as unmanaged in InfraLight</p>
            {isLoading ? <Loading /> :
                <>
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="name">Name:</label>
                        <Field
                            className={"form-control"}
                            name="name"
                            id="name"
                            component="input"
                            type="text"
                            placeholder="Please fill the exclusion name"
                            onChange={this.onChangeName}
                        />
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" htmlFor="description">Description</label>
                        <Field
                            className={"form-control"}
                            name="description"
                            id="description"
                            component="textarea"
                            type="text"
                            onChange={this.onChangeDescription}
                        />
                    </div>

                    <div className="rego-editor">

                        <Editor
                            height="30vh"
                            theme="vs-dark"
                            defaultLanguage="javascript"
                            defaultValue={code}
                            onChange={(value, event) => this.setState({code: value})}
                        />
                        <div className="rego-actions">
                            <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={this.handleTestRegoCode} disabled={_.isEmpty(selectedResources)}>Test rego</button>
                            {this.state.testPassed == null ? "" : this.state.testPassed?  "passed" : "failed"}
                        </div>
                    </div>

                    <div className="form-group">
                        <div>Show Selected resources <OnOff isLoading={false} isEnabled={showExclusionsEnabled} changeOnOffStatus={() => this.changeShowExclusionsEnabled()} /></div>
                        <UnmanagedResourcesTable
                            unmanaged={tableUnmanagedResources}
                            maxItemsInPage={3}
                            handleResourceSelected={this.handleResourceSelected}
                            handleResourceUnselected={this.handleResourceUnselected}
                            showSeverity={false}
                            showActions={false} />
                    </div>
                    <ReactJson src={testAsset} theme="ashes" />

                    <Field
                        name="customExclusionId"
                        id="customExclusionId"
                        component="input"
                        type="hidden"
                    />
                </>}
        </BasicModal>
    }
}


const mapStateToProps = (state) => {
    return {
        inventory: state.inventoryReducer,
        excludedByResourceType: state.exclusionsReducer.excludedByResourceType,
        selectedCustomExclusion: state.customExclusionsReducer.selectedExclusion
    };
};

const mapDispatchToProps = {
    createCustomExclusions,
    updateCustomExclusions,
    testCustomExclusions,
    getCustomExclusions
};

CustomExclusionModal = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(CustomExclusionModal);
export default withRouter(CustomExclusionModal);
