import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { sendMetric } from '../../redux/actions/metricActions';
import { GithubIcon } from '../../shared/icons';
import UnmanagedResourcesTable from './unmanagedResourcesTable';
import unamangedResourcesFilter from '../inventory/unmanagedResourcesFilter';
import ResourceImport from './resourceImport';
import PullRequest from './pullRequest';

class UnmanagedResources extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasData: false,
            unmanaged: [],
            selectedResources: {},
            itemsPerPage: 8,
            isMultiResourcesImportOpen: false,
            isPullRequestModalOpen: false
        };
    }

    componentDidMount() {
        let itemsPerPage = 8;
        if (window.innerWidth > 768 && window.innerWidth <= 1372) {
            itemsPerPage = 6;
        } else if (window.innerWidth > 1372 && window.innerWidth < 1440) {
            itemsPerPage = 7;
        }

        this.setState({ itemsPerPage });
    }

    async componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.loadedCategories, nextProps.loadedCategories) ||
            !_.isEqual(this.props.filteredResourceTypes, nextProps.filteredResourceTypes) ||
            !_.isEqual(this.props.filteredAwsAccounts, nextProps.filteredAwsAccounts) ||
            this.props.filteredUnmanaged != nextProps.filteredUnmanaged) {

            const { excludedByResourceType, inventory } = this.props;
            const { filteredAwsAccounts, filteredUnmanaged, filteredResourceTypes } = nextProps;

            const unmanaged = unamangedResourcesFilter(inventory, excludedByResourceType, filteredAwsAccounts, filteredUnmanaged, filteredResourceTypes);

            this.setState({ hasData: true, unmanaged });
        }
    }

    handleResourceSelected = (resource) => {
        const id = resource.id;
        const { selectedResources } = this.state;
        selectedResources[id] = resource;
        this.setState({ selectedResources });
    }

    handleResourceUnselected = (resource) => {
        const id = resource.id;
        const { selectedResources } = this.state;
        delete selectedResources[id];
        this.setState({ selectedResources });
    }

    handleMultiIacImportRequest = () => {
        const { selectedResources } = this.state;
        this.props.sendMetric(`[/main] user has clicked on Multi IaC Import request. resourcesToImport=${JSON.stringify(_.keys(selectedResources))}`);

        //no selected resources
        if (_.isEmpty(selectedResources)) {
            return;
        }

        this.setState({ isMultiResourcesImportOpen: true });
    }

    handleCreatePullRequest = () => {
        const { selectedResources } = this.state;
        this.props.sendMetric(`[/main] user has clicked on Create pull request. resourcesToImport=${JSON.stringify(_.keys(selectedResources))}`)

        if (_.isEmpty(selectedResources)) {
            return;
        }

        this.setState({ isPullRequestModalOpen: true });
    }

    handleSaveToFile = () => {
        const { selectedResources } = this.state;
        this.props.sendMetric(`[/main] user has clicked on Create save to file. resourcesToImport=${JSON.stringify(_.keys(selectedResources))}`)
    }

    onCloseMultiIacImportModal = () => {
        this.setState({ isMultiResourcesImportOpen: false });
    }

    onClosePullRequestModal = () => {
        this.setState({ isPullRequestModalOpen: false });
    }

    render() {
        const { unmanaged, hasData, itemsPerPage, isMultiResourcesImportOpen, isPullRequestModalOpen, selectedResources } = this.state;
        const { handleRefreshDashboard } = this.props;

        return <>
            <ResourceImport className="mb-4" isOpen={isMultiResourcesImportOpen} onClose={this.onCloseMultiIacImportModal} selectedResources={selectedResources} mode="multi" />
            <PullRequest className="mb-4" isOpen={isPullRequestModalOpen} onClose={this.onClosePullRequestModal} selectedResources={selectedResources} mode="multi" />

            <div className="card mb-4" id="unmanaged-resources">
                <div className="card-header d-flex align-items-center">
                    <h3 className="w-50 float-left card-title m-0">Unmanaged Resources</h3>
                    <div className="dropdown dropleft text-right w-50 float-right">
                        <button className="btn bg-gray-100" id="unmanaged-resources-refresh" type="button" onClick={handleRefreshDashboard}><i className="nav-icon i-Repeat-3"></i></button>
                        {hasData ?
                            <button className="btn bg-gray-100" id="unmanaged-resources-dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="nav-icon i-Gear-2"></i></button> :
                            null}

                        <div className="dropdown-menu" aria-labelledby="unmanaged-resources-dropdown">
                            <a className="dropdown-item" onClick={this.handleMultiIacImportRequest} href="#unmanaged-resources"><i className="i-Arrow-Right-in-Circle"> </i> Multi Codify</a>
                            <a className="dropdown-item" onClick={this.handleCreatePullRequest} href="#unmanaged-resources"><GithubIcon /> Create Pull-Request</a>
                            <a className="dropdown-item" onClick={this.handleSaveToFile} href="#unmanaged-resources"><i className="i-Data-Save"></i> Save to file</a>
                        </div>
                    </div>
                </div>

                <div>
                    {hasData ?
                        <UnmanagedResourcesTable
                            maxItemsInPage={itemsPerPage}
                            unmanaged={unmanaged}
                            handleResourceSelected={this.handleResourceSelected}
                            handleResourceUnselected={this.handleResourceUnselected} /> :

                        <div className="ul-widget-body">
                            <div className="ul-widget3">
                                <div className="">
                                    <div className="text-16 text-center mt-2 font-weight-bold">No data available</div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </>
    }
}


const mapStateToProps = (state) => {
    return {
        excludedByResourceType: state.exclusionsReducer.excludedByResourceType,
        inventory: state.inventoryReducer,
        loadedCategories: state.homePageReducer.loaded,
        filteredResourceTypes: state.homePageReducer.filtered,
        filteredAwsAccounts: state.homePageReducer.filteredAwsAccounts,
        filteredUnmanaged: state.homePageReducer.filteredUnmanaged,
    };
};

const mapDispatchToProps = {
    sendMetric
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(UnmanagedResources);