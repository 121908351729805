import React from "react";
import _ from "lodash";
import { Card, CardBody } from "../../../shared/layouts/cards";
import { Table } from "../../../shared/tables/tables";
import { connect } from "react-redux";
import { compose } from "redux";
import EmptyPage from "../../../shared/emptyPage/emptyPage";
import DiffChecker from './diffChecker';
import { sendMetric } from '../../../redux/actions/metricActions';
import { withRouter } from "react-router-dom";
import AssetIcon from './assetIcon';
import { selectDrift } from "../../../redux/actions/driftsActions";
import OopsPage from "../../../shared/emptyPage/oopsPage";

class Assets extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDriftChecker: false
        };
    }

    openDriftChecker = (item) => {
        this.props.selectDrift(item);
        this.setState({ showDriftChecker: true });
    }

    closeDriftChecker = () => {
        this.props.selectDrift(null);
        this.setState({ showDriftChecker: false });
    }

    clickOnExclude = () => {
        this.props.sendMetric(`[/drifts/${this.props.match.params.id}] user clicked on exclude button`);
        this.props.history.push('/exclusions/custom');
    }

    MoreOptions = (props) => {
        const { item } = props;
        return (
            <>
                <button className="btn bg-transparent _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="_dot _r_block-dot bg-dark"></span><span className="_dot _r_block-dot bg-dark"></span><span className="_dot _r_block-dot bg-dark"></span></button>
                <div className="dropdown-menu" x-placement="bottom-start">
                    <a className="dropdown-item" href="#" onClick={() => this.openDriftChecker(item)}><i className="nav-icon i-Split-Vertical text-success font-weight-bold mr-2"></i>Compare drift</a>
                    <a className="dropdown-item" href="#" onClick={this.clickOnExclude}><i className="nav-icon i-Close-Window text-danger font-weight-bold mr-2"></i>Exclude</a>
                </div>
            </>
        )
    }

    render() {

        const { configurationDrifts, resourceDrifts, selectedCategory, filterQuery } = this.props;
        const { showDriftChecker } = this.state;

        let drifts = [];
        let options = [];

        if (selectedCategory == "configuration") {
            drifts = _.map(_.union(configurationDrifts.new, configurationDrifts.drifts), x => ({ ...x, id: x.assetId }));
            options = _.map(configurationDrifts.new, x => ({ id: x.assetId, style: { background: "#ccb3e6" } }));

        } else if (selectedCategory == "resource") {
            drifts = _.map(_.union(resourceDrifts.new, resourceDrifts.drifts), x => ({ ...x, id: x.assetId }));
            options = _.map(resourceDrifts.new, x => ({ id: x.assetId, style: { background: "#ccb3e6" } }));
        }


        //SQL Parser - this shit must be replaced
        //Very limited - just for first value
        const parser = require('js-sql-parser');
        let ast = null;
        try {
            ast = parser.parse(filterQuery);
            if (!_.isEmpty(ast?.value?.where?.left) && ast?.value?.where?.left?.type != "Identifier") {
                return <Card classes="ul-contact-content"><CardBody><OopsPage>SQL query is not supported yet!</OopsPage></CardBody></Card>
            }
        } catch (ex) {
            return <Card classes="ul-contact-content"><CardBody><OopsPage>SQL query is not valid</OopsPage></CardBody></Card>
        }

        const limit = _.isEmpty(ast.value.limit?.value) || _.isEmpty(ast.value.limit.value[0]) ? drifts.length : parseInt(ast.value.limit.value[0]);
        drifts = _.filter(drifts, drift => {
            const leftValue = _.get(drift, ast?.value?.where?.left?.value, "");
            const rightValue = ast?.value?.where?.right?.value || "";

            if (ast?.value?.where?.type == "LikePredicate") {
                const rgxLike = _.trim(_.trim(rightValue.replace(/%/g, ".+"), "'"), '"');
                return !_.isEmpty(_.trim(_.trim(leftValue, "'"), '"').match(rgxLike));
            }

            if (ast?.value?.where?.type == "ComparisonBooleanPrimary") {
                return _.trim(_.trim(leftValue, "'"), '"') == _.trim(_.trim(rightValue, "'"), '"');
            }
            
            return true;
        });

        return (
            <>
                <DiffChecker
                    isOpen={showDriftChecker}
                    onClose={this.closeDriftChecker}
                />

                <div className="ul-contact-content">
                    <Card>
                        <CardBody>
                            <div className="float-left"><i className="nav-icon i-Align-Justify-All text-25 ul-contact-mobile-icon"></i></div>
                            <div className="tab-content ul-contact-list-table--label" id="nav-tabContent">
                                {/* <!-- all-contact--> */}
                                <div className="tab-pane fade active show" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                    <div className="text-left">
                                        <div className="table-responsive">
                                            {_.isEmpty(drifts) ? <EmptyPage>No Drifts detected!</EmptyPage> :
                                                <>
                                                    <h6 className="col-md-4 text-dark">Found {Math.min(limit, drifts.length)} drifts out of {drifts.length}</h6>
                                                    <Table
                                                        isFullWidth={true}
                                                        isBordeless={true}
                                                        className="ul-contact-list-table"
                                                        id="contact_list_table"

                                                        isHeaderPurple={true}
                                                        columns={
                                                            [
                                                                { id: 'icon', value: "", component: AssetIcon },
                                                                { id: 'assetId', value: 'Asset Id', component: "text" },
                                                                { id: 'createdAt', value: 'Created At', component: "time", style: { minWidth: '250px' } },
                                                                { id: 'action', value: 'Action', component: this.MoreOptions }
                                                            ]}

                                                        data={_.take(drifts, limit)}
                                                        options={options}
                                                    >
                                                    </Table>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </>)
    }
}


const mapStateToProps = (state) => {
    return {
        configurationDrifts: state.driftsReducer.configurationDrifts,
        resourceDrifts: state.driftsReducer.resourceDrifts,
        selectedCategory: state.driftsReducer.selectedCategory,
        inventory: state.inventoryReducer,
        filterQuery: state.driftsReducer.filterQuery
    };
};

const mapDispatchToProps = {
    sendMetric,
    selectDrift
};

Assets = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Assets);
export default withRouter(Assets);