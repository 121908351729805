import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import {
    getAwsInternetGateways,
    getAwsSubnets,
    getAwsVpcs,
    getAwsUsers,
    getAwsRoles,
    getAwsBuckets,
    getAwsSecurityGroups,
    getAwsEksClusters,
    getAwsSsmParameters,
    getAwsRoute53HostedZones,
    getAwsInstances,
    getAwsLambdaFunctions,
    getAwsTransitGateways,
    getAwsTransitGatewayRouteTables,
    getAwsTransitGatewayMulticastDomains,
    getAwsTransitGatewayVpcAttachments,
    getAwsTransitGatewayPeeringAttachments,
    getAutoScalingGroups,
    getLoadBalancers,
    getLoadBalancersV2,
    getLaunchConfigurations,
    getAcmCertificates,
    getEbsVolumes,
    getDynamodbTables,
    getRdsInstances,
    getAwsPolicies,
    getAwsSqsQueue,
    getAwsSnsTopic,
    getAwsGroups,
    getAwsApiGatewayRestApis,
    getAwsApiGatewayV2Apis,
    getAwsElasticsearchDomain,
    getAwsElastiCacheCluster,
    getAwsCloudFrontDistribution,
    getK8sNamespace,
    getK8sPod,
    getK8sConfigMap,
    getK8sNode,
    getK8sService,
    getK8sServiceAccount,
    getK8sPersistentVolume,
    getK8sPersistentVolumeClaim,
    getK8sSecret,
    getK8sDeployment,
    getK8sDaemonSet,
    getK8sReplicaSet,
    getK8sStatefulSet,
    getK8sJob,
    getK8sCronJob,
    getK8sIngress,
    getK8sClusterRole
} from '../../redux/actions/inventoryActions';
import {analyzeExcludedRules} from '../../redux/actions/exclusionsActions';

class InventoryLoader extends React.Component {
    constructor(props) {
        super(props);
    }
    
    async componentDidMount(){
        const {onLoadInventory} = this.props;
        const defaultExclusions = _.isUndefined(this.props.defaultExclusions) ? true : this.props.defaultExclusions;
        const customExclusions = _.isUndefined(this.props.customExclusions) ? true : this.props.customExclusions;

        if(defaultExclusions || customExclusions){
            await this.props.analyzeExcludedRules(defaultExclusions, customExclusions);
        }

        const resourcesCategories = [
            {
                id: "networking",
                header: "Networking",
                icon: "i-Internet",
                resourceTypes: ["aws_vpc", "aws_security_group", "aws_subnet", "aws_internet_gateway",
                                "aws_ec2_transit_gateway", "aws_ec2_transit_gateway_route_table",
                                "aws_ec2_transit_gateway_multicast_domain", "aws_ec2_transit_gateway_vpc_attachment",
                                "aws_ec2_transit_gateway_peering_attachment", "aws_route53_zone",
                                "aws_api_gateway_rest_api", "aws_apigatewayv2_api"
                    ],
                onLoad: async() => {
                    await Promise.all([
                        this.props.getAwsVpcs(),
                        this.props.getAwsSecurityGroups(),
                        this.props.getAwsSubnets(),
                        this.props.getAwsInternetGateways(),
                        this.props.getAwsTransitGateways(),
                        this.props.getAwsTransitGatewayRouteTables(),
                        this.props.getAwsTransitGatewayMulticastDomains(),
                        this.props.getAwsTransitGatewayVpcAttachments(),
                        this.props.getAwsTransitGatewayPeeringAttachments(),
                        this.props.getAwsRoute53HostedZones(),
                        this.props.getAwsApiGatewayRestApis(),
                        this.props.getAwsApiGatewayV2Apis(),
                    ])
                }
            },
            {
                id: "db_storage",
                header: "Database&Storage",
                icon: "i-Big-Data",
                resourceTypes: [
                    "aws_s3_bucket", "aws_dynamodb_table", "aws_db_instance", "aws_ebs_volume", "aws_elasticsearch_domain",
                    "aws_elasticache_cluster"
                ],
                onLoad: async() => {
                    await Promise.all([
                        this.props.getAwsBuckets(),
                        this.props.getDynamodbTables(),
                        this.props.getRdsInstances(),
                        this.props.getEbsVolumes(),
                        this.props.getAwsElasticsearchDomain(),
                        this.props.getAwsElastiCacheCluster(),
                    ])
                }
            },
            {
                id: "iam",
                header: "IAM",
                icon: "i-Key",
                resourceTypes: ["aws_iam_user", "aws_iam_role", "aws_iam_policy", "aws_iam_group"],
                onLoad: async() => {
                    await Promise.all([
                        this.props.getAwsUsers(),
                        this.props.getAwsRoles(),
                        this.props.getAwsPolicies(),
                        this.props.getAwsGroups(),
                    ])
                }
            },
            {
                id: "compute",
                header: "Compute",
                icon: "i-Computer-2",
                resourceTypes: ["aws_lambda_function", "aws_instance", "aws_eks_cluster", "aws_elb", "aws_lb",
                    "aws_autoscaling_group", "aws_ssm_parameter", "aws_acm_certificate", "aws_launch_configuration",
                    "aws_sqs_queue", "aws_sns_topic", "aws_cloudfront_distribution"],
                onLoad: async() => {
                    await Promise.all([
                        this.props.getAwsLambdaFunctions(),
                        this.props.getAwsInstances(),
                        this.props.getAwsEksClusters(),
                        this.props.getLoadBalancers(),
                        this.props.getLoadBalancersV2(),
                        this.props.getAutoScalingGroups(),
                        this.props.getAwsSsmParameters(),
                        this.props.getAcmCertificates(),
                        this.props.getLaunchConfigurations(),
                        this.props.getAwsSqsQueue(),
                        this.props.getAwsSnsTopic(),
                        this.props.getAwsCloudFrontDistribution()
                    ])
                }
            },
            {
                id: "k8s",
                header: "K8S",
                isDisabled: false,
                icon: "icon-kubernetes",
                resourceTypes: ["kubernetes_namespace","kubernetes_pod", "kubernetes_config_map","kubernetes_node",
                    "kubernetes_service", "kubernetes_service_account","kubernetes_persistent_volume",
                    "kubernetes_persistent_volume_claim","kubernetes_secret", "kubernetes_deployment",
                    "kubernetes_daemon_set", "kubernetes_replica_set","kubernetes_stateful_set","kubernetes_job",
                    "kubernetes_cron_job","kubernetes_ingress", "kubernetes_cluster_role"],
                onLoad: async() => {
                    await Promise.all([
                        this.props.getK8sNamespace(),
                        this.props.getK8sPod(),
                        this.props.getK8sConfigMap(),
                        this.props.getK8sNode(),
                        this.props.getK8sService(),
                        this.props.getK8sServiceAccount(),
                        this.props.getK8sPersistentVolume(),
                        this.props.getK8sPersistentVolumeClaim(),
                        this.props.getK8sSecret(),
                        this.props.getK8sDeployment(),
                        this.props.getK8sDaemonSet(),
                        this.props.getK8sReplicaSet(),
                        this.props.getK8sStatefulSet(),
                        this.props.getK8sJob(),
                        this.props.getK8sCronJob(),
                        this.props.getK8sIngress(),
                        this.props.getK8sClusterRole()
                    ])
                }
            }
        ]

        await onLoadInventory(resourcesCategories);
    }
    render() {
        return null
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    analyzeExcludedRules,
    getAwsVpcs,
    getAwsSubnets,
    getAwsInternetGateways,
    getAwsUsers,
    getAwsRoles,
    getAwsBuckets,
    getAwsSecurityGroups,
    getAwsRoute53HostedZones,
    getAwsEksClusters,
    getAwsSsmParameters,
    getAwsInstances,
    getAwsLambdaFunctions,
    getAwsTransitGateways,
    getAwsTransitGatewayRouteTables,
    getAwsTransitGatewayMulticastDomains,
    getAwsTransitGatewayVpcAttachments,
    getAwsTransitGatewayPeeringAttachments,
    getAutoScalingGroups,
    getLoadBalancers,
    getLoadBalancersV2,
    getLaunchConfigurations,
    getAcmCertificates,
    getEbsVolumes,
    getDynamodbTables,
    getRdsInstances,
    getAwsPolicies,
    getAwsSqsQueue,
    getAwsSnsTopic,
    getAwsGroups,
    getAwsApiGatewayRestApis,
    getAwsApiGatewayV2Apis,
    getAwsElasticsearchDomain,
    getAwsElastiCacheCluster,
    getAwsCloudFrontDistribution,
    getK8sNamespace,
    getK8sPod,
    getK8sConfigMap,
    getK8sNode,
    getK8sService,
    getK8sServiceAccount,
    getK8sPersistentVolume,
    getK8sPersistentVolumeClaim,
    getK8sSecret,
    getK8sDeployment,
    getK8sDaemonSet,
    getK8sReplicaSet,
    getK8sStatefulSet,
    getK8sJob,
    getK8sCronJob,
    getK8sIngress,
    getK8sClusterRole
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(InventoryLoader);