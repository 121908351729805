import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../shared/loading';
import {
    getAwsIntegrations,
    getSlackIntegrations,
    deleteSlackIntegration,
    getTeamsIntegrations,
    deleteTeamsIntegration,
    updateAwsIntegration,
} from '../../redux/actions/integrationsActions';
import {
    getAllGithubApps,
    deleteGithubApp,
} from '../../redux/actions/vcsActions';
import {
    getK8sIntegrations,
    deleteK8sIntegration
} from '../../redux/actions/k8sIntegrationActions';
import {
    getGcpIntegrations,
    deleteGcpIntegration,
} from '../../redux/actions/gcpIntegrationActions';
import {
    getTfcIntegrations,
    deleteTfcIntegration,
} from '../../redux/actions/tfcIntegrationActions'

import PageLayout from '../../shared/layouts/pageLayout';
import { Card, CardBody } from "../../shared/layouts/cards";
import EmptyPage from "../../shared/emptyPage/emptyPage";
import ListGroup from '../../shared/lists/listGroup';
import ListItem from '../../shared/lists/listItem';
import OnOff from '../../shared/toggle/onOffToggle';

import AwsIcon from "../../public/images/icons/aws.png";
import TfcIcon from "../../public/images/icons/tfc.png";
import GitHubIcon from "../../public/images/icons/github.svg";
import SlackIcon from "../../public/images/icons/slack.svg";
import TeamsIcon from "../../public/images/icons/teams.png";
import K8SIcon from "../../public/images/icons/k8s.png";
import GcpIcon from "../../public/images/icons/gcp.png";

import "./view.scss";
import { string } from 'prop-types';
import integrations from "../alerts/integrations";

class IntegrationView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            integrations: null,
            hasError: true
        };
    }

    async componentDidMount() {
        await this.reloadData();
    }

    async componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.githubApps, nextProps.githubApps) ||
            !_.isEqual(this.props.awsIntegrations, nextProps.awsIntegrations) ||
            !_.isEqual(this.props.slackIntegrations, nextProps.slackIntegrations) ||
            !_.isEqual(this.props.teamsIntegrations, nextProps.teamsIntegrations) ||
            !_.isEqual(this.props.k8sIntegrations, nextProps.k8sIntegrations)) {
            await this.reloadData();
        }
    }

    reloadData = async () => {
        this.setState({ isLoading: true });
        let integrations = [];

        try {
            await this.props.getAwsIntegrations();
            await this.props.getAllGithubApps();
            await this.props.getSlackIntegrations();
            await this.props.getK8sIntegrations();
            await this.props.getTeamsIntegrations();
            await this.props.getGcpIntegrations();
            await this.props.getTfcIntegrations();

            integrations = _.union(integrations, _.map(this.props.awsIntegrations || [], integ => ({
                id: integ.id,
                type: 'aws',
                icon: AwsIcon,
                header: integ.accountNumber,
                name: integ.name,
                fetchable: integ.fetchable,
                isEditEnabled: false,
                isDeleteEnabled: false,
                isStatusDisplayed: true,
                onEdit: () => { },
                onDelete: () => { },
                onStatus: () => {
                    this.props.history.push(`/integrations/aws/fetching/${integ.id}`)
                }
            })));

            integrations = _.union(integrations, _.map(this.props.githubApps || [], integ => ({
                id: integ.id,
                type: 'github',
                icon: GitHubIcon,
                header: "GitHub Application",
                name: integ.name,
                fetchable: false,
                isEditEnabled: false,
                isDeleteEnabled: true,
                onEdit: () => { },
                onDelete: async () => {
                    await this.props.deleteGithubApp(integ.id);
                    await this.props.getAllGithubApps()
                }
            })));

            integrations = _.union(integrations, _.map(this.props.slackIntegrations || [], integ => ({
                id: integ.id,
                type: 'slack',
                icon: SlackIcon,
                header: integ.credentials.webhookUrl,
                name: "Slack Application",
                fetchable: false,
                isEditEnabled: false,
                isDeleteEnabled: true,
                onEdit: () => { },
                onDelete: async () => {
                    await this.props.deleteSlackIntegration(integ.id);
                    await this.props.getSlackIntegrations();
                }
            })));

            integrations = _.union(integrations, _.map(this.props.teamsIntegrations || [], integ => ({
                id: integ.id,
                type: 'teams',
                icon: TeamsIcon,
                header: integ.credentials.webhookUrl,
                name: "Teams Application",
                fetchable: false,
                isEditEnabled: false,
                isDeleteEnabled: true,
                onEdit: () => {  },
                onDelete: async () => {
                    await this.props.deleteTeamsIntegration(integ.id);
                    await this.props.getTeamsIntegrations();
                }
            })));

            integrations = _.union(integrations, _.map(this.props.k8sIntegrations.integrations || [], integ => ({
                id: integ.id,
                type: 'k8s',
                icon: K8SIcon,
                header: `${integ.clusterId} Cluster, AccessKey is ${integ.accessKey}`,
                name: "K8s Collector",
                fetchable: false,
                isEditEnabled: false,
                isDeleteEnabled: true,
                onEdit: () => { },
                onDelete: async () => {
                    await this.props.deleteK8sIntegration(integ.clusterId);
                    await this.props.getK8sIntegrations();
                }
            })));

            integrations = _.union(integrations, _.map(this.props.gcpIntegrations || [], integ => ({
                id: integ.id,
                type: 'gcp',
                icon: GcpIcon,
                header: `Project ID: ${integ.projectId}`,
                name: integ.name,
                isEditEnabled: false,
                isDeleteEnabled: true,
                onEdit: () => {},
                onDelete: async () => {
                    await this.props.deleteGcpIntegration(integ.id);
                    await this.props.getGcpIntegrations();
                }
            })));

            integrations = _.union(integrations, _.map(this.props.tfcIntegrations || [], integ => ({
                id: integ.id,
                type: 'tfc',
                icon: TfcIcon,
                header: `${integ.name}`,
                name: integ.name,
                isEditEnabled: false,
                isDeleteEnabled: true,
                onEdit: () => {},
                onDelete: async () => {
                    await this.props.deleteTfcIntegration(integ.id);
                    await this.props.getTfcIntegrations();
                }
            })));

            console.log("success");
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ integrations, isLoading: false });
        }
    }


    moveToCreatePage = () => {
        const { history } = this.props;
        history.push(`/integrations/create`);
    }

    changeFetchableValue = async (integration) => {
        const { integrations } = this.state;
        const index = _.findIndex(integrations, x => x.id === integration.id);

        this.setState({ integrations, isLoading: true});

        const req = await this.props.updateAwsIntegration(integration.id, !integration.fetchable);

        this.setState({ integrations, isLoading: false});

        if (!req.ok) {
            console.warn("failed to update aws integration");
            this.setState({ integrations });
            return;
        }

        integrations[index].fetchable = !integrations[index].fetchable;
        this.setState({ integrations });
    }

    rightComponent = (props) => {
        const isEditEnabled = _.isUndefined(props.isEditEnabled) ? true : props.isEditEnabled;
        const isDeleteEnabled = _.isUndefined(props.isDeleteEnabled) ? true : props.isDeleteEnabled;
        const isStatusDisplayed = _.isUndefined(props.isStatusDisplayed) ? false : props.isStatusDisplayed;
        const isFetchable = _.isUndefined(props.fetchable) ? false : props.fetchable;

        return <div className="mt-2">
            {(props.type === "aws") && <OnOff isEnabled={isFetchable} changeOnOffStatus={() => this.changeFetchableValue(props.integration)} />}
            {isStatusDisplayed && <a className={"text-success mr-2"} href="#" onClick={props.onStatus}><i className="nav-icon i-Magnifi-Glass1 font-weight-bold"></i></a>}
            <a className={`${isEditEnabled ? "text-success" : "text-mute"} mr-2`} href="#" onClick={props.onEdit}><i className={`nav-icon i-Pen-2 font-weight-bold ${!isEditEnabled ? "not-allowed" : ""}`}></i></a>
            <a className={`${isDeleteEnabled ? "text-success" : "text-mute"} mr-2`} href="#" onClick={props.onDelete}><i className={`nav-icon i-Close-Window font-weight-bold ${!isDeleteEnabled ? "not-allowed" : ""}`}></i></a>
        </div>
    }

    leftComponent = (props) => {
        const { icon } = props;
        return <div className="mr-3 box-shadow-2 list-item-avatar">
            <div className="avatar mb-3">
                <img src={icon} alt="" />
            </div>
        </div>
    }

    render() {
        const { isLoading, integrations } = this.state;

        if (isLoading) {
            return (<Loading />);
        }

        return (
            <PageLayout>
                <div className="row">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                        <button className="btn btn-success btn-block mb-3" onClick={this.moveToCreatePage} type="button">Add Integration</button>
                    </div>
                </div>

                <Card>
                    <CardBody>
                        {_.isEmpty(integrations) ? <EmptyPage>No Integrations Configured!</EmptyPage> :
                            <ListGroup>
                                {_.map(integrations, integration => {
                                    return <ListItem key={integration.id}
                                        id={integration.id}
                                        header={integration.name}
                                        textAlign="center"
                                        leftComponent={< this.leftComponent icon={integration.icon} />}
                                        rightComponent={<this.rightComponent integration={integration} fetchable={integration.fetchable} type={integration.type} isEditEnabled={integration.isEditEnabled} isDeleteEnabled={integration.isDeleteEnabled} onDelete={integration.onDelete} onEdit={integration.onEdit} onStatus={integration.onStatus} isStatusDisplayed={integration.isStatusDisplayed} />}
                                    >
                                        {integration.header}
                                    </ListItem>
                                })}
                            </ListGroup>
                        }

                    </CardBody>
                </Card>
            </PageLayout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        awsHasError: state.integrationsReducer.aws.hasError,
        awsIntegrations: state.integrationsReducer.aws.integrations || [],
        slackIntegrations: state.integrationsReducer.slack,
        teamsIntegrations: state.integrationsReducer.teams,
        githubApps: state.vcsReducer.githubApps,
        k8sIntegrations: state.k8sIntegrationReducer.integrations,
        gcpIntegrations: state.gcpIntegrationReducer.integrations,
        tfcIntegrations: state.tfcIntegrationReducer.integrations,
    };
};

const mapDispatchToProps = {
    getAwsIntegrations,
    getAllGithubApps,
    deleteGithubApp,
    getSlackIntegrations,
    deleteSlackIntegration,
    getTeamsIntegrations,
    deleteTeamsIntegration,
    getK8sIntegrations,
    deleteK8sIntegration,
    getGcpIntegrations,
    deleteGcpIntegration,
    updateAwsIntegration,
    getTfcIntegrations,
    deleteTfcIntegration,
};


IntegrationView = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(IntegrationView);

export default withRouter(IntegrationView);