import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Loading from "../../shared/loading";
import { Card, CardBody } from "../../shared/layouts/cards";
import { getDrift } from "../../redux/actions/driftsActions";
import OopsPage from "../../shared/emptyPage/oopsPage";
import DriftFasset from "./viewer/fasset";
import DriftAssets from "./viewer/assets";
import DriftSearch from "./viewer/driftSearch";
import { updateResourceDrifts, updateConfigurationDrifts } from '../../redux/actions/driftsActions';

class DriftViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            id: this.props.match.params.id,
            resources: [],
            hasError: null,
            unmanaged: null
        };
    }

    async componentDidMount() {
        const { id } = this.state;
        const [driftId, version] = _.split(id, "_");


        const currentReq = await this.props.getDrift(id);
        if (!currentReq.ok) {
            this.setState({ hasError: "Oops! Could not load drift", isLoading: false });
            return;
        }

        const current = currentReq.drift;
        let previous = [];
        if (parseInt(version) > 1) {
            const previousReq = await this.props.getDrift(`${driftId}_${parseInt(version) - 1}`);
            if (!previousReq.ok) {
                this.setState({ hasError: "Oops! Could not load previous drift", isLoading: false });
                return;
            }

            previous = previousReq.drift;
        }


        await this.calculateResourceDrifts(previous, current);
        await this.calculateConfigurationDrifts(previous, current);

        this.setState({ isLoading: false });
    }

    async calculateResourceDrifts(previous, current) {
        const previousUnmanaged = _.filter(previous, x => x.state == "unmanaged");
        const previousUnmanagedIds = _.map(previousUnmanaged, x => x.assetId);

        const currentUnmanaged = _.filter(current, x => x.state == "unmanaged");
        const currentUnmanagedIds = _.map(currentUnmanaged, x => x.assetId);

        const resolvedUnamanged = _.difference(previousUnmanagedIds, currentUnmanagedIds);
        const newUnamanged = _.difference(currentUnmanagedIds, previousUnmanagedIds);

        const commonUnamanged = _.intersection(previousUnmanagedIds, currentUnmanagedIds);

        await this.props.updateResourceDrifts(
            _.filter(current, x => _.includes(newUnamanged, x.assetId)), //New Deltas
            _.filter(previous, x => _.includes(resolvedUnamanged, x.assetId)), //Resoved
            _.filter(current, x => _.includes(commonUnamanged, x.assetId))); //Current drifts
    }

    async calculateConfigurationDrifts(previous, current) {
        const previousModified = _.filter(previous, x => x.state == "modified");
        const previousModifiedMap = _.groupBy(previousModified, "assetId");

        const currentModified = _.filter(current, x => x.state == "modified");
        const currentModifiedMap = _.groupBy(currentModified, x => "assetId");

        const resolvedModifiedIds = _.difference(_.keys(previousModifiedMap), _.keys(currentModifiedMap));
        const newModifiedIds = _.difference(_.keys(currentModifiedMap), _.keys(previousModifiedMap));

        const deltas = _.filter(currentModified, x=>x.assetId in previousModifiedMap && x.hash != previousModifiedMap[x.assetId][0].hash);
        const common = _.filter(currentModified, x=>x.assetId in previousModifiedMap && x.hash == previousModifiedMap[x.assetId][0].hash);

        await this.props.updateConfigurationDrifts(
            _.union(deltas, _.map(newModifiedIds, id=> currentModifiedMap[id][0])), //New Deltas
            _.map(resolvedModifiedIds, id=>previousModifiedMap[id][0]), //Resoved
            common //Current drifts
        )
    }

    render() {
        const { } = this.props;
        const { isLoading, hasError, unmanaged } = this.state;
        return (
            isLoading ? <Loading /> :
                !_.isEmpty(hasError) ? <OopsPage>{hasError}</OopsPage> :
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <DriftSearch />
                                        <div className="card-body">
                                            <div className="ul-contact-list-body">
                                                <div className="ul-contact-main-content">
                                                    <DriftFasset />
                                                    <DriftAssets />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    getDrift,
    updateConfigurationDrifts,
    updateResourceDrifts
};

DriftViewer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(DriftViewer);


export default withRouter(DriftViewer);