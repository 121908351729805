import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

class NotFound extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {
        const { } = this.state;
        return (
            <>
                <div className="not-found-wrap text-center">
                    <h1 className="text-60">404</h1>
                    <p className="text-36 subheading mb-3">Error!</p>
                    <p className="mb-5 text-muted text-18">Sorry! The page you were looking for doesn&apos;t exist.</p><a className="btn btn-lg btn-primary btn-rounded" href="/">Go back to home</a>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
}
const mapDispatchToProps = {
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(NotFound);