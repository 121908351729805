import _ from 'lodash';
import React from 'react';

export default class TooltipLink extends React.Component {

    render() {
        const { id, link } = this.props;
        return _.isUndefined(link) || link === false ?
            <a data-tip data-for={id}>{this.props.children}</a> :
            <a href={link} data-tip data-for={id}>{this.props.children}</a>
    }
}