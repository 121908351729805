import { TERRAFORM_INIT, TERRAFORM_PLAN, TERRAFORM_APPLY, UPDATE_STEP } from '../actions/terraformActions';

const initialState = {
    run: null,
    plan: null,
    apply: null,
    hasError: null,
    isInitialized: false,
    isPlan: false,
    isApply: false,
    step: 1
};

export default (state = initialState, { type, payload }) => {
    let hasError = null;
    let run = null;

    switch (type) {
        case TERRAFORM_INIT:
            return { ...state, run: payload.run, hasError: payload.hasError, isInitialized: payload.isInitialized };

        case TERRAFORM_PLAN:
            return { ...state, run: payload.run, plan: payload.plan, hasError: payload.hasError, isPlan: payload.isPlan };

        case TERRAFORM_APPLY:
            return { ...state, run: payload.run, apply: payload.apply, hasError: payload.hasError, isApply:payload.isApply};

        case UPDATE_STEP:
            return { ...state, step: payload.step };

        default:
            return state;
    }
}
