import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
export const GET_ALL_PROFILES = "GET_ALL_PROFILES";
export const GET_CURRENT_PROFILE = "GET_CURRENT_PROFILE";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";

const getAllProfiles = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const profilesReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "GET"
    );
    const profiles = await profilesReq.json();
    dispatch({
      type: GET_ALL_PROFILES,
      payload: { profiles },
    });

    return profiles;
  });
};

const getCurrentProfile = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const currentReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users/current`,
      "GET"
    );
    const current = await currentReq.json();

    dispatch({
      type: GET_CURRENT_PROFILE,
      payload: { current },
    });

    return current;
  });
};

const createUser = (name, email) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      name,
      email,
    };

    const invitedUser = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "POST",
      payload
    );

    dispatch({
      type: CREATE_USER,
      payload: {},
    });

    return invitedUser;
  });
};

const deleteUser = (userId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deletedUser = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users/${userId}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_USER,
      payload: {},
    });

    return deletedUser;
  });
};

export { getAllProfiles, getCurrentProfile, createUser, deleteUser };
