import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Danger, Success, Info } from '../../../../../shared/alerts';
import Loading from '../../../../../shared/loading';
import { terraformPlan } from '../../../../../redux/actions/terraformActions';
import Ansi from '../../../../../shared/ansi';

class TerraformPlan extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            triggered: false,
            hasError: null
        };
    }

    triggerPlan = async () => {
        this.setState({ triggered: true, isLoading: true });
        await this.props.terraformPlan();
        this.setState({ isLoading: false });

        const {hasError, isPlan} = this.props;

        this.setState({ hasError })
        this.props.onFinishToPlan(isPlan, hasError);
    }

    render() {
        const { triggered, isLoading, hasError } = this.state;
        if (!triggered) {
            return (<button className="btn btn-primary" type="button" onClick={() => this.triggerPlan()}>Click here to plan terraform</button>);
        }

        if (isLoading) {
            return <Loading />
        }

        return (
            <>
                {!_.isEmpty(hasError) ? <Danger>An error occurred while trying to plan changes</Danger> :
                    <Success>Plan has been succeeded!</Success>}

                {_.isEmpty(this.props.plan) || _.isUndefined(this.props.plan.text) ? null :
                    <Ansi>
                        {this.props.plan.text}
                    </Ansi>}

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        hasError: state.terraformReducer.hasError,
        isPlan: state.terraformReducer.isPlan,
        plan: state.terraformReducer.plan
    };
};

const mapDispatchToProps = {
    terraformPlan
};

TerraformPlan = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TerraformPlan)

export default TerraformPlan;