import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from "../../consts/config";

export const GET_VCS_GITHUB = "GET_VCS_GITHUB";
export const DELETE_SPECIFIC_VCS_GITHUB = "DELETE_SPECIFIC_VCS_GITHUB";


const getAllGithubApps = () => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github`, "GET");

        const githubApps = await vcsReq.json();
        dispatch({
            type: GET_VCS_GITHUB,
            payload: { githubApps }
        });

    });
}

const deleteGithubApp = (id) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github/${id}`, "DELETE");

        dispatch({
            type: DELETE_SPECIFIC_VCS_GITHUB,
            payload: {}
        });
        return { ok: vcsReq.ok };
    });
}

export {
    getAllGithubApps,
    deleteGithubApp
}
