import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../../shared/loading';
import {
    getGcpIntegrations,
    getAllGcsBuckets,
    getAllGcsObjects
} from '../../../../redux/actions/gcpIntegrationActions';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';

export class GCSPolicy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            integrations: [],
            selectedIntegrationId: null,
            isIntegrationValid: null,
            integrationError: false,

            buckets: [],
            selectedBucket: null,
            isBucketValid: null,
            bucketStatusCode: null,

            bucketObjects: null,

            selectedGcsKey: null,
            isGcsKeyValid: null
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        const { initialValues } = this.props;

        if (!_.isEmpty(initialValues.gcpIntegration) && !_.isEmpty(initialValues.gcsBucket) && !_.isEmpty(initialValues.gcsKey)) {
            await this.onChangeIntegration(initialValues.gcpIntegration);
            await this.onChangeBucket(initialValues.gcsBucket);
            await this.onChangeGcsKeySelection(initialValues.gcsKey);
        }

        await this.props.getGcpIntegrations();
        this.setState({ isLoading: false, integrations: this.props.gcpIntegrations });
    }

    onChangeIntegration = async (integrationId) => {
        this.setState({ isLoading: true });

        const res = await this.props.getAllGcsBuckets(integrationId);

        this.setState({ isLoading: false, buckets: res.buckets, isIntegrationValid: res.ok, integrationError: res.ok, selectedIntegrationId: integrationId });
    }

    onChangeBucket = async (bucket) => {
        const { selectedIntegrationId } = this.state;
        this.setState({ isLoading: true, selectedGcsKey: null, isGcsKeyValid: null });

        const res = await this.props.getAllGcsObjects(selectedIntegrationId, bucket);
        const tfStateFiles = _.filter(res.objects, obj => obj.endsWith(".tfstate"));
        this.setState({ isLoading: false, selectedBucket: bucket, isBucketValid: res.ok, bucketStatusCode: res.status, bucketObjects: tfStateFiles });
    }

    onChangeGcsKeySelection = async (key) => {
        this.setState({ selectedGcsKey: key, isGcsKeyValid: !_.isEmpty(key) });
    }

    onSubmit = async () => {
        this.setState({ isLoading: true });

        const { selectedIntegrationId, selectedBucket, selectedGcsKey } = this.state;

        await this.props.onUpdatePolicy("gcs", { gcpIntegration: selectedIntegrationId, gcsBucket: selectedBucket, gcsKey: selectedGcsKey });

        this.setState({ isLoading: false });
    }

    render() {
        const { isLoading, integrations, buckets, selectedIntegrationId, isIntegrationValid, isBucketValid, bucketStatusCode, selectedBucket, bucketObjects, isGcsKeyValid } = this.state;

        const selectedIntegration = (_.first(integrations, integ => integ.id === selectedIntegrationId) || {}).projectId || "";
        const permissionPolicy = `https://cloud.google.com/storage/docs/access-control/iam-permissions`;

        return (
            isLoading ? <Loading /> :
                <>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="gcpIntegration">GCP Integration</label>
                        <div className="col-sm-10">
                            <Field
                                className={"form-control"}
                                name="gcpIntegration"
                                id="gcpIntegration"
                                component="select"
                                onChange={(e) => this.onChangeIntegration(e.target.value)}
                            >
                                <option value="">Please Choose GCP Integration..</option>
                                {_.map(integrations, integ => <option value={integ.id} key={integ.id}>{integ.name}</option>)}
                            </Field>
                            {isIntegrationValid == false ? <div className="text-14 font-weight-bold text-danger">integration is not valid</div> : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="gcsBucket">GCP GCS Bucket</label>
                        <div className="input-group mb-3 col-sm-10">
                            <div className="input-group-prepend"><span className="input-group-text" id="reload">
                                {isBucketValid == false ?
                                    <a href="#" onClick={() => this.onChangeBucket(selectedBucket)}><i className="i-Repeat-3"></i></a> :
                                    <i className="i-Repeat-3"></i>
                                }
                            </span>
                            </div>
                            <Field
                                className={"form-control"}
                                name="gcsBucket"
                                id="gcsBucket"
                                component="select"
                                disabled={isIntegrationValid != true}
                                onChange={(e) => this.onChangeBucket(e.target.value)}
                                aria-describedby="reload"
                            >
                                <option value="">Please Choose GCS Bucket..</option>
                                {_.map(buckets, bucket => <option value={bucket} key={bucket}>{bucket}</option>)}
                            </Field>
                        </div>
                        {isBucketValid === false ?
                            bucketStatusCode === 403 ?
                                <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Permission Denied<br /><a target="_blank" href={permissionPolicy}>Click here to grant permission</a></div></> :
                                <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Bucket integration is not valid</div></> : null}
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="gcpIntegration">GCP Terraform state (.tfstate file)</label>
                        <div className="col-sm-10">
                            <Field
                                className={"form-control"}
                                name="gcsKey"
                                id="gcsKey"
                                component="select"
                                disabled={isBucketValid !== true}
                                onChange={(e) => this.onChangeGcsKeySelection(e.target.value)}
                            >
                                <option value="">Please Choose Terraform state file.</option>
                                {_.map(bucketObjects, obj => <option value={obj} key={obj}>{obj}</option>)}
                            </Field>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <button className="btn btn-primary" type="submit" disabled={!(isIntegrationValid && isBucketValid && isGcsKeyValid)} onClick={() => this.onSubmit()}>Save</button>
                        </div>
                    </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    const configuredPolicy = ((state.statesReducer.selectedPolicy || {}).result || {}).policy || {};

    return {
        gcpIntegrations: (state.gcpIntegrationReducer || {}).integrations || [],
        policy: state.statesReducer.selectedPolicy,
        initialValues: { gcpIntegration: configuredPolicy.gcpIntegration || "", gcsBucket: configuredPolicy.gcsBucket || "", gcsKey: configuredPolicy.gcsKey }
    };
};

const mapDispatchToProps = {
    getGcpIntegrations,
    getAllGcsBuckets,
    getAllGcsObjects,
};

GCSPolicy = reduxForm({
    form: 'gcs-state-policy'
})(GCSPolicy);

GCSPolicy = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(GCSPolicy);


export default withRouter(GCSPolicy);