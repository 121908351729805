import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CopyToClipboard from '../../shared/copyToClipboard';
//import queryString from 'query-string';
import { Danger } from '../../shared/alerts';
import RequestWrapper from '../../utils/requestWrapper';
import Loading from '../../shared/loading';
import Step2 from './step2';
import Step3 from './step3';

import { BACKEND_URL } from '../../consts/config';

import '../../shared/smartWizard/css/smart_wizard.min.css';
import '../../shared/smartWizard/css/smart_wizard_theme_arrows.min.css';
import '../../shared/smartWizard/css/smart_wizard_theme_circles.min.css';
import '../../shared/smartWizard/css/smart_wizard_theme_dots.min.css';

const MAX_STEP = 3;

class GitHubInstallation extends React.Component {

    constructor(props) {
        super(props);

        this.requestWrapper = new RequestWrapper();
        this.state = { step: 1, oauthValid: null, vcsId: null, vcsState: null, client_id: null, client_secret: null, name: null, hasError: null };
    }

    async componentDidMount() {
    }

    nextStep = async () => {

        if (this.state.step == 1) {
            const githubVcsReq = await this.requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github`, "POST");

            if (!githubVcsReq.ok) {
                const hasError = "Unexpected error while trying to create github repo";
                this.setState({ hasError });
                return;
            }
            const githubVcs = await githubVcsReq.json();

            if (_.isEmpty(githubVcs) || _.isEmpty(githubVcs.id)) {
                const hasError = "An error occurred while trying to establish GitHub Project";
                this.setState({ hasError });
                return;
            }

            this.setState({ vcsId: githubVcs.id, vcsState: githubVcs.state });
        }

        if (this.state.step < MAX_STEP) {
            this.setState({ step: this.state.step + 1 })
        }
    }

    prevStep = () => {
        if (this.state.step > 1) {
            this.setState({ step: this.state.step - 1 })
        }
    }

    cancel = () => {
        this.setState({ step: 1, oauthValid: null })
    }

    validate = (submittedInfo) => {
        console.log(submittedInfo);
        this.setState({ oauthValid: false, client_id: submittedInfo.client_id, client_secret: submittedInfo.client_secret, name: submittedInfo.name });
        //https://developer.github.com/apps/building-oauth-apps/authorizing-oauth-apps/

        window.open(`https://github.com/login/oauth/authorize?client_id=${submittedInfo.client_id}&state=${this.state.vcsState}&scope=user,repo,admin:repo_hook`, "_blank");

        const validator = setInterval(async () => {
            if (this.state.oauthValid == false) {
                const githubVcsReq = await this.requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github/${this.state.vcsId}/status`, "GET");
                if (!githubVcsReq.ok) {
                    console.error("An error occured while trying to fetch VCS status");
                    this.setState({ oauthValid: null });
                    return;
                }
                const githubVcs = await githubVcsReq.json();

                if (githubVcs.isEnabled) {
                    this.setState({ oauthValid: true });
                }
            }
        }, 2000);
    }

    complete = async () => {

        const { step } = this.state;
        if (step < 3) {
            return;
        }

        const { oauthValid, client_id, client_secret, name } = this.state;

        if (oauthValid != true) {
            const hasError = "authentication has unexpected error";
            this.setState({ hasError });
            return;
        }

        const payload = {
            client_id,
            client_secret,
            name
        };

        const res = await this.requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github/${this.state.vcsId}/credentials`, "PUT", payload);

        //TODO: res.ok != true
        if (!res.ok) {
            this.setState({ hasError: "Unexpected error occurred while authenticating GitHub" });
            return;
        }
        this.setState({ step: 4 })
    }

    render() {
        const { step, oauthValid, vcsId, hasError } = this.state;

        if (hasError) {
            return <Danger closeEnabled={false}>{hasError}</Danger>
        }

        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        {/* <!--  SmartWizard html --> */}
                        <div id="smartwizard" className="sw-main sw-theme-default">
                            <ul className="nav nav-tabs step-anchor">
                                <li className={step == 1 ? "nav-item active" : "nav-item done"}><a href="#step-1" className="nav-link">Setup<br /><small>Setup GitHub application</small></a></li>
                                <li className={step == 2 ? "nav-item active" : "nav-item done"}><a href="#step-1" className="nav-link">Setup<br /><small>Setup GitHub application</small></a></li>
                                <li className={step == 3 ? "nav-item active" : "nav-item done"}><a href="#step-2" className="nav-link">Authentication<br /><small>Setup GitHub OAuth Authentication</small></a></li>
                                <li className={step == 4 ? "nav-item active" : "nav-item done"}><a href="#step-4" className="nav-link">Completed!<br /><small>GitHub application registered successfully</small></a></li>
                            </ul>

                            <div className="sw-container tab-content" style={{ minHeight: '124px' }}>
                                <div id="step-1" className="tab-pane step-content" style={step == 1 ? { display: 'block' } : { display: 'none' }}>
                                    <h5>
                                        InfraLight needs access to your version control system (VCS) provider.<br />
                                Use this page to configure OAuth authentication with your VCS provider.
                                    </h5>
                                </div>
                                <div id="step-2" className="tab-pane step-content" style={step == 2 ? { display: 'block' } : { display: 'none' }}>
                                    <Step2 vcsId={vcsId} />
                                </div>
                                <div id="step-3" className="tab-pane step-content" style={step == 3 ? { display: 'block' } : { display: 'none' }}>
                                    <Step3 onSubmit={this.validate}>
                                        {oauthValid == false ? <Loading /> : oauthValid == true ? <div>VCS Authorized</div> : null}
                                    </Step3>
                                </div>
                                <div id="step-4" className="tab-pane step-content" style={step == 4 ? { display: 'block' } : { display: 'none' }}>
                                    <h1>Completed</h1>
                                </div>
                            </div>
                            <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                                <div className="btn-group mr-2 sw-btn-group" role="group">
                                    <button className={step <= 1 ? 'btn btn-secondary sw-btn-prev disabled' : 'btn btn-secondary sw-btn-prev'} onClick={this.prevStep} type="button">Previous</button>
                                    <button className={step < MAX_STEP ? 'btn btn-secondary sw-btn-next' : 'btn btn-secondary sw-btn-next disabled'} onClick={this.nextStep} type="button">Next</button>
                                </div>

                                <div className="btn-group mr-2 sw-btn-group-extra" role="group">
                                    <button className={oauthValid == true ? 'btn btn-info' : 'btn btn-info disabled'} onClick={this.complete}>Complete</button>
                                    <button className="btn btn-danger" onClick={this.cancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = {
};


GitHubInstallation = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(GitHubInstallation);
export default withRouter(GitHubInstallation);