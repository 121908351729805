import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from "redux-form";
import { CardBody, Card, CardHeader } from "../../../shared/layouts/cards";
import { InputGroup, FormGroup } from "../../../shared/form/form";
import {
    createGcpIntegration,
} from "../../../redux/actions/gcpIntegrationActions";
import {compose} from "redux";
import {connect} from "react-redux";

const required = (value) =>
    value || typeof value === "number" ? undefined : "Required";

class GcpIntegration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isValid: null,
            projectId: null,
            serviceAccountKey: null,
            errorMessage: null,
            successMessage: "Created Successfully!",
            isCreated: false
        };
    }

    onChangeProject = async (project) => {
        const isValid = !_.isEmpty(project)
        this.setState({ isValid, isLoading: false, projectId: project, errorMessage: isValid ? null : "GCP Project ID can't be empty" });
    }

    onChangeServiceAccount = async (sa) => {
        const isValid = this.isJsonParsable(sa) && !_.isEmpty(sa)
        this.setState({ isValid, isLoading: false, serviceAccountKey: sa, errorMessage: isValid ? null : "Service Account json is not valid" });
    }

    isJsonParsable = (string) => {
        try {
            JSON.parse(string);
        } catch (e) {
            return false;
        }
        return true;
    }

    onSubmit = async (event) => {
        let result = {};
        event.preventDefault();
        this.setState({isLoading: true});

        const {projectId, serviceAccountKey, isValid} = this.state;
        debugger
        try {
            result = await this.props.createGcpIntegration(projectId, serviceAccountKey);
        } catch (e) {
            console.log("failed to create gcp integration");
        } finally {
            console.log(result);
            this.setState({
                isLoading: false,
                isValid: result.created,
                errorMessage: result.created ? null : result.message,
                isCreated: result.created,
                successMessage: result.created ? result.message : null
            });
        }

    };


    render() {
        const { isLoading, isValid, isCreated, errorMessage, successMessage } = this.state;

        return (
            <>
                <Card>
                    <CardBody>
                        <CardHeader>Google Cloud Platform GCP Integration</CardHeader>
                        <h5>Google Cloud Platform, offered by Google. using service account with s3 permissions.</h5>
                        <FormGroup>
                            <InputGroup>
                                <div className="col-sm-10">
                                    <Field
                                        className="form-control"
                                        name="projectId"
                                        id="projectId"
                                        component="input"
                                        onChange={(e) => this.onChangeProject(e.target.value)}
                                        placeholder="GCP Project ID"
                                        validate={[required]}
                                        type="text"
                                    />
                                </div>
                            </InputGroup>
                            <InputGroup>
                                <div className="col-sm-10">
                                    <Field
                                        className="form-control"
                                        name="serviceAccountKey"
                                        id="serviceAccountKey"
                                        component="textarea"
                                        onChange={(e) => this.onChangeServiceAccount(e.target.value)}
                                        placeholder="Service Account Json Key"
                                        validate={[required]}
                                        type="text"
                                    />
                                </div>
                            </InputGroup>

                            {isValid === false ? <InputGroup hasMarginBottom={false}><div className="text-medium text-danger m-0 font-weight-bold">{errorMessage}</div></InputGroup> : null}
                            {isCreated === true ? <InputGroup hasMarginBottom={false}><div className="text-medium text-success m-0 font-weight-bold">{successMessage}</div></InputGroup> : null}
                        </FormGroup>

                        <FormGroup>
                            <div className="col-sm-10">
                                <InputGroup>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        onClick={(event) => this.onSubmit(event)}
                                        disabled={!isValid || isLoading}>
                                        Save
                                    </button>
                                </InputGroup>
                            </div>
                        </FormGroup>
                    </CardBody>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    createGcpIntegration,
}

GcpIntegration = reduxForm({
    form: "gcp-integration",
})(GcpIntegration);

GcpIntegration = compose(connect(mapStateToProps, mapDispatchToProps))(GcpIntegration);

export default withRouter(GcpIntegration);