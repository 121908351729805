import {
    GET_EXCLUSIONS, UPDATE_EXCLUSION, ANALYZE_EXCLUDED_RULES
} from '../actions/exclusionsActions';
import _ from 'lodash';

const initialState = {
    rules: [],
    excludedResources: [],
    excludedByResourceType: {}
};

export default (state = initialState, { type, payload }) => {

    switch (type) {

        case GET_EXCLUSIONS:
            return { ...state, rules: payload.rules };

        case UPDATE_EXCLUSION:
            return { ...state };

        case ANALYZE_EXCLUDED_RULES:
            const excludedResources = _.map(payload.excludedResources, x=>x.id);
            const excludedByResourceType = _.chain(payload.excludedResources).groupBy("type").mapValues((group, key) => _.map(group, x=>x.id)).value();
            
            return {...state, excludedResources , excludedByResourceType}
        default:
            return state;
    }
}
