import React from 'react';

export default  class TabMenu extends React.Component{

    render(){
        const {children} = this.props;
        return <ul className="nav nav-tabs" id="myTab" role="tablist">
            {children}
        </ul>
    }
}
