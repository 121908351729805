import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_TFC_INTEGRATIONS = "GET_TFC_INTEGRATIONS";
export const CREATE_TFC_INTEGRATION = "CREATE_TFC_INTEGRATION";
export const DELETE_TFC_INTEGRATION = "DELETE_TFC_INTEGRATION";
export const GET_ALL_TFC_ORGANIZATIONS = "GET_ALL_TFC_ORGANIZATIONS";
export const GET_ALL_TFC_ORGANIZATION_WORKSPACES = "GET_ALL_TFC_ORGANIZATION_WORKSPACES";

const getTfcIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/tfc`,
        "GET"
    );

    const integrations = await integrationsReq.json();

    dispatch({
      type: GET_TFC_INTEGRATIONS,
      payload: { integrations },
    });
  });
};

const createTfcIntegration = (name, apiToken) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    let created = true;
    let message = `Terraform Cloud integration created success.`;

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/tfc/`,
        "POST",
        {
          name,
          apiToken
        }
    );

    if (res.status === 409) {
      created = false
      message = `Terraform Cloud API token already exist in your account`;
    } else if (res.status === 403) {
      created = false
      message = "Terraform Cloud API token not valid please check your terraform cloud API Token"
    }else if (res.status === 500) {
      created = false
      message = "failed to create Terraform Cloud integration please contact infralight support"
    }

    dispatch({
      type: CREATE_TFC_INTEGRATION,
      payload: {
        created: created,
        message: message
      },
    });

    return {
      created: created,
      message: message
    };
  });
};

const deleteTfcIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/tfc/${id}`,
        "DELETE"
    );

    dispatch({
      type: DELETE_TFC_INTEGRATION,
      payload: {},
    });

    return {};
  });
};

const getAllTfcOrganizations = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/tfc/${integration}/organizations`,
        "GET"
    );
    dispatch({
      type: GET_ALL_TFC_ORGANIZATIONS,
      payload: {},
    });

    if (!req.ok) {
      return { ok: false, organizations: null };
    }

    const organizations = await req.json();
    return { ok: true, organizations };
  });
};

const getAllTfcOrganizationsWorkspaces = (integration, organization) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/tfc/${integration}/organization/${organization}/workspaces`,
        "GET"
    );

    dispatch({
      type: GET_ALL_TFC_ORGANIZATION_WORKSPACES,
      payload: {},
    });

    let objects = null;
    if(res.ok){
      objects = await res.json();
    }
    return {objects, ok: res.ok, status: res.status};
  });
};

export {
  getTfcIntegrations,
  createTfcIntegration,
  deleteTfcIntegration,
  getAllTfcOrganizations,
  getAllTfcOrganizationsWorkspaces
};
