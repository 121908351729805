import React from "react";
import _ from "lodash";
import { CopyToClipboard as CopyToClipboardComponent } from "react-copy-to-clipboard";
import "./copyToClipboard.scss";

export default class CopyToClipboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { copied: false };
  }

  onCopy = () => {
    this.setState({ copied: true });
  };

  render() {
    const { text } = this.props;
    const { copied } = this.state;

    return (
      <CopyToClipboardComponent text={text} onCopy={this.onCopy}>
        <div className="ctb">
          <pre>{text}</pre>
          {copied ? (
            <div className="copied">Copied!</div>
          ) : (
            <i
              title="Copy to Clipboard"
              className="i-File-Clipboard-File--Text nav-icon cb-copy-icon"
            />
          )}
        </div>
      </CopyToClipboardComponent>
    );
  }
}
