import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from '../../consts/config';

export const GET_CUSTOM_EXCLUSIONS = "GET_ALL_CUSTOM_EXCLUSIONS";
export const DELETE_CUSTOM_EXCLUSIONS = "DELETE_CUSTOM_EXCLUSIONS";
export const UPDATE_CUSTOM_EXCLUSIONS = "UPDATE_CUSTOM_EXCLUSIONS";
export const CREATE_CUSTOM_EXCLUSIONS = "CREATE_CUSTOM_EXCLUSIONS";
export const TEST_CUSTOM_EXCLUSIONS = "TEST_CUSTOM_EXCLUSIONS";

export const CUSTOM_EXCLUSION_SELECTED = "CUSTOM_EXCLUSION_SELECTED";

const getCustomExclusions = () => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/custom`, "GET");
        const data = await req.json();
        dispatch({
            type: GET_CUSTOM_EXCLUSIONS,
            payload: { data }
        });
    });
}

const deleteCustomExclusions = (id) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/custom/${id}`, "DELETE");

        dispatch({
            type: DELETE_CUSTOM_EXCLUSIONS,
            payload: { ok: req.ok }
        });
    });
}

const updateCustomExclusions = (id, name, description, code, type) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/custom/${id}`, "PUT", { name, description, code, type });

        dispatch({
            type: UPDATE_CUSTOM_EXCLUSIONS,
            payload: { ok: req.ok }
        });
    });
}

const createCustomExclusions = (name, description, code, type) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/custom`, "POST", { name, description, code, type });

        dispatch({
            type: CREATE_CUSTOM_EXCLUSIONS,
            payload: { ok: req.ok }
        });
    });
}

const testCustomExclusions = (name, description, code, type, resources) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/exclusions/custom/test`, "POST", { name, description, code, type, resources });
        dispatch({
            type: TEST_CUSTOM_EXCLUSIONS,
            payload: { ok: req.ok }
        });
        return req.ok
    });
}

const customExclusionSelected = (selectedExclusion) => {
    return action(async dispatch => {

        dispatch({
            type: CUSTOM_EXCLUSION_SELECTED,
            payload: { selectedExclusion }
        });
    });
}



export {
    getCustomExclusions,
    deleteCustomExclusions,
    updateCustomExclusions,
    createCustomExclusions,
    testCustomExclusions,
    customExclusionSelected
}
