import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from "../../../shared/loading"
import {
    getStacksByEnvironmentId,
    deleteStackByEnvironmentId,
} from '../../../redux/actions/stackActions';
import Tab from '../../../shared/tabs/tab';
import TabMenu from '../../../shared/tabs/tabMenu';
import Oops from '../../../shared/emptyPage/oopsPage';

import OrchestratorVars from '../orchestrator/vars/vars';
import OrchestratorRuns from '../orchestrator/runs/runs';
import TerraformWizard from "../orchestrator/terraform/tfWizard";
import IacSettings from "./iacSettings";
import States from "./states";

import './stack.scss';

class Stack extends React.Component {
    constructor(props) {
        super(props);

        const {environmentId, stackId, tab} = this.props.match.params;

        this.state = {
            environmentId,
            stackId,
            tab: _.isUndefined(tab) ? "iacRepository" : tab,
            isLoading: false,
            stack: null,
            hasError: null
        };
    }

    async componentDidMount() {
        const{environmentId, stackId} = this.state;

        this.setState({isLoading: true});
        await this.props.getStacksByEnvironmentId(environmentId);
        this.setState({isLoading: false});

        const {stacks} = this.props;
        if(_.isEmpty(stacks) || _.filter(stacks, s=> s.id === stackId).length === 0){
            this.setState({hasError: "Could not find stack"});
            return;
        }
        this.setState({stack: _.find(stacks, (stack) => stack.id === stackId)});
    }

    handleBackToStackList = () => {
        this.props.history.push(`/environment/${this.state.environmentId}`);
    }

    moveToTab = (tab) => {
        const {stack} = this.state;
        const basePath = `/environment/${stack?.environmentId}/stack/${stack?.id}`;
        this.props.history.push(`${basePath}/${tab}`);
        this.setState({tab});
    }

    render() {
        const { isLoading, stack, hasError, tab } = this.state;
        if (isLoading === true) { return <Loading />; }
        if(hasError){ return <Oops>Could not load stack</Oops>}

        return (
            <>
                <button onClick={this.handleBackToStackList} className="btn btn-link Stack__header-backBtnLower" type="button" >
                    <span>Back</span>
                    <i className="i-Right1 Stack__header-backBtn-icon"></i>
                </button>
                <TabMenu>
                    <Tab isActive={tab == "iacRepository"} onClick={()=>this.moveToTab("iacRepository")} link="#" name="IaC Settings" icon="i-Upload-Window" />
                    <Tab isActive={tab == "states"} onClick={()=>this.moveToTab("states")} link="#" name="IaC States" icon="i-File-Cloud" />
                    { stack?.isVcsEnabled &&
                        <>
                            <Tab isActive={tab == "orchestratorRuns" || tab=="terraformWizard"} onClick={()=>this.moveToTab("orchestratorRuns")} link="#" name="Orchestrator" icon="i-Check" />
                            <Tab isActive={tab == "orchestratorVars"} onClick={()=>this.moveToTab("orchestratorVars")} link="#" name="Variables" icon="i-Key" />
                        </>
                    }
                </TabMenu>

                {!_.isEmpty(stack) && <>


                    {tab == "iacRepository" ? <IacSettings stack={stack}/> :
                    tab == "states" ? <States stack={stack} /> :
                    tab == "orchestratorRuns" ? <OrchestratorRuns stack={stack}/>:
                    tab == "orchestratorVars" ? <OrchestratorVars stack={stack}/>:
                    tab == "terraformWizard" ? <TerraformWizard stack={stack} />:
                        <></>
                    }
                </>}

            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        stacks: state.stackReducer.data,
        selectedStack: state.stackReducer.selected
    };
};

const mapDispatchToProps = {
    getStacksByEnvironmentId,
    deleteStackByEnvironmentId,
};

Stack = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Stack);
export default withRouter(Stack);