import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import InfraLightLogo from "../public/images/infralight-logo.png";
import "../public/css/auth_error.css";

const AuthError = (props) => {
  const { logout } = useAuth0();

  return (
    <div className="auth-layout-wrap">
      <div className="auth-content">
        <div className="text-center mt-4">
          <img src={InfraLightLogo} alt="InfraLight" />
        </div>
        <div className="flex-grow-1"></div>
        {props.error.message ===
        "Please verify your email before logging in." ? (
          <div className="alert-card alert-success">
            <p className="strong">Verify Your Email to Continue</p>
            <p>
              Thank you for signing up with InfraLight! A message has been sent
              to your email address, please click the link in the message to
              verify your address and continue to the InfraLight console.
            </p>
          </div>
        ) : (
          <div className="alert-card alert-danger">
            <div className="w-100 mb-4">{props.error.message}</div>
          </div>
        )}
        <a
          className="logout"
          href="#"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Sign out
        </a>
      </div>
    </div>
  );
};

export default AuthError;
