import action from "../middleware";

import { BACKEND_URL } from '../../consts/config';
import CookiesManager from '../../utils/cookiesManager';
import RequestWrapper from '../../utils/requestWrapper';

export const GET_ENVIRONMENTS = "GET_ENVIRONMENTS";
export const CREATE_ENVIRONMENT = "CREATE_ENVIRONMENT";
export const DELETE_ENVIRONMENT = "DELETE_ENVIRONMENT";

const getEnvironments = () => {
  return action(async dispatch => {

    const cookieMgr = new CookiesManager();
    const accessToken = cookieMgr.getAccessToken();

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const response = await fetch(`${BACKEND_URL}/environments`, requestOptions);

    if (response.status == 401) {
      cookieMgr.setAccessToken(null, 0);
      window.location.reload();
    }

    const data = await response.json();

    dispatch({
      type: GET_ENVIRONMENTS,
      payload: { data }
    });

  });
}

const createEnvironment = (name, owner) => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();

    const payload = {
      name,
      owner
    };

    const res = await requestWrapper.sendRequest( `${BACKEND_URL}/environments`, "POST", payload);
    dispatch({
      type: CREATE_ENVIRONMENT,
      payload: {}
    });

    if(res.ok){
      const json = await res.json();
      return {ok: res.ok, environment: json};
    }

    return {ok: false, environment: null};
  });
}


const deleteEnvironment = (environmentId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deletedEnvironmentReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/environments/${environmentId}`,
      "DELETE"
    );

    if(!deletedEnvironmentReq.ok){
      return {ok: false, response: null };
    }
    const data = await deletedEnvironmentReq.json();
    return {ok: true, response: data};
  });
};

export {
  getEnvironments,
  createEnvironment,
  deleteEnvironment
}
