import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import Loading from '../../shared/loading';
import { getAwsIntegrations } from '../../redux/actions/integrationsActions';
import { onAwsAccountFiltered, onAwsAccountFilteredOut } from '../../redux/actions/homePageActions';
import Accordion from '../../shared/accordion';
import WidgetHeader from '../../shared/widget/header';
import Selector from '../../shared/selector/selector';

import './accountFilter.scss';

class AccountFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            notSelected: null,
            selected: null
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
        await this.props.getAwsIntegrations();

        const { awsIntegrations } = this.props;

        this.setState({
            isLoading: false,
            notSelected: _.map(awsIntegrations, x => x.id),
            selected: []
        });
    }

    selectAwsIntegration = async (integration) => {
        let { selected, notSelected } = this.state;

        this.props.onAwsAccountFiltered(integration.accountNumber);

        selected.push(integration.id);
        notSelected = _.difference(notSelected, [integration.id]);

        this.setState({ selected, notSelected: notSelected.sort() });
    }

    unselectAwsIntegration = async (integration) => {
        let { selected, notSelected } = this.state;

        this.props.onAwsAccountFilteredOut(integration.accountNumber);

        selected = _.difference(selected, [integration.id]);
        notSelected.push(integration.id);

        this.setState({ selected: selected, notSelected: notSelected.sort() });
    }

    render() {
        const { isLoading, notSelected, selected } = this.state;
        const awsIntegrationById = _.groupBy((this.props.awsIntegrations || []), "id");

        const options = _.map(notSelected, integrationId => {
            const integ = _.first(awsIntegrationById[integrationId]);
            return { ...integ, key: integ.id, value: `${integ.name} (${integ.accountNumber})` };
        })

        if (isLoading) {
            return <Loading />
        }

        return <>
            <Selector options={options} onSelect={this.selectAwsIntegration} selectPlaceholder="Cloud Accounts (filter)" />

            {/* Tags */}
            {_.map(selected, integrationId => {
                const integ = _.first(awsIntegrationById[integrationId]);
                return <div key={integ.id} className="tag"><span>#</span> {integ.name} ({integ.accountNumber})
<a role="button" className="tag-i" onClick={() => this.unselectAwsIntegration(integ)}>×</a></div>
            })}
        </>
    }
}


const mapStateToProps = (state) => {
    return {
        awsIntegrations: state.integrationsReducer.aws.integrations
    };
};

const mapDispatchToProps = {
    getAwsIntegrations,
    onAwsAccountFiltered,
    onAwsAccountFilteredOut
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AccountFilter);
