import _ from 'lodash';
import React from 'react';
import Tooltip from '../../shared/tooltip/tooltip';
import { connect } from 'react-redux';
import { compose } from 'redux';

class TooltipResources extends React.Component {

    render() {
        const { id, resourceTypes} = this.props;
        return <Tooltip id={id}>
        <span key={id}>{_.map(resourceTypes, resourceType => {
            return <p className="text-primary mb-1 text-left" key={resourceType}>
                <i className="text-16 mr-1"><img src={this.props.inventory[resourceType].icon} style={{ height: '16px', width: '16px' }} /></i>{this.props.inventory[resourceType].tooltip}</p>
        })}</span>
    </Tooltip>
    }
}


const mapStateToProps = (state) => {
    return {
        inventory: state.inventoryReducer
    };
};

const mapDispatchToProps = {
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TooltipResources);