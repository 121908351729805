import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import Menu from "./menu";
import FullScreen from "./fullScreen";
import Profile from "./profile";
import InfraLightLogo from "../public/images/infralight-logo.png";
import AccountFilter from '../components/main/accountFilter';
import './layout.scss';

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sideNavOpen: null,
    };
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    this.setState({ sideNavOpen: window.innerWidth >= 1080 });
  };

  render() {
    const { sideNavOpen } = this.state;
    const { selected, breadcrumb } = this.props;
    const pathname = this.props?.location?.pathname;

    return (
      <>
        <div
          className={`app-admin-wrap layout-sidebar-compact sidebar-dark-purple clearfix ${sideNavOpen ? "sidenav-open" : ""
            }`}
        >
          <Menu selected={selected} />
          {/* <!--=============== Left side End ================--> */}
          <div className="main-content-wrap d-flex flex-column">
            <div className="main-header">
              <div className="menu-toggle">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <div className="d-flex align-items-center">
                <div className="breadcrumb mt-2">
                  <h1 className="mr-2 mt-2">
                    <img src={InfraLightLogo} style={{ height: "35px" }} />
                  </h1>
                  <ul>
                    {_.isEmpty(breadcrumb)
                      ? null
                      : _.map(breadcrumb, (sub, index) => {
                        return _.isString(sub) ? (
                          <li key={index}>{sub}</li>
                        ) : _.isObject(sub) ? (
                          <li key={index}>
                            <a href={sub.link || "#"}>{sub.text}</a>
                          </li>
                        ) : null;
                      })}
                  </ul>
                </div>

                {/* just in main page */}
                {!_.isEmpty(pathname) && (pathname == "/" || pathname.startsWith("/#")) ? <div className="home-page-search-bar ml-4"><AccountFilter /></div> : null}

                {/* <!--center --> */}
              </div>

              <div style={{ margin: "auto" }}></div>
              <div className="header-part-right">
                <FullScreen />
                {/* <Notifications /> */}
                {/* <!-- User avatar dropdown --> */}
                <Profile />
              </div>
            </div>
            {/* <!-- ============ Body content start ============= --> */}
            <div className="main-content">
              {this.props.children}
              {/* <!-- fotter end --> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout);
