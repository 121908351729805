/* THIS SHIT IS MUST BE DEELTED!!! */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BasicModal from '../../shared/basicModal';
import Loading from '../../shared/loading';

import { sendMetric } from '../../redux/actions/metricActions';
import { fetchGeneratedIaC } from '../../redux/actions/iacImportActions';
import { generateManualHCL } from './manualHCL';
import ActiveFeatures from '../../consts/activeFeatures';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { TerraformIcon, PulumiIcon, CloudFormationIcon, HelmIcon } from '../../shared/icons';

class ResourceImport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            generatedHcl: null
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true, generatedHcl: null });
    }

    async componentWillReceiveProps(nextProps) {
        const dynamicHclDisabled = _.filter(nextProps.activeFeatures, x => ActiveFeatures.dynamicHclDisabled.startsWith(x.path)).length > 0;

        //props has been changed
        if (nextProps.mode == "multi" || !_.isEqual(this.props.selectedResources, nextProps.selectedResources)) {
            this.setState({ isLoading: true });

            const generatedHclArray = [];

            //Manual HCL generation
            if (dynamicHclDisabled) {
                _.forEach(nextProps.selectedResources, resource => {
                    const shouldGenerateProvider = (nextProps.selectedResources||[]).length == 1;
                    const hclGeneratedManually = generateManualHCL(resource, shouldGenerateProvider);
                    generatedHclArray.push(hclGeneratedManually);
                })
                //Generated By API
            } else {
                const promises = [];
                for (const i in nextProps.selectedResources) {
                    const resource = nextProps.selectedResources[i];
                    promises.push(this.props.fetchGeneratedIaC(resource.type, resource.id));
                }

                const hclGeneratedByApi = await Promise.all(promises);
                _.forEach(hclGeneratedByApi, hcl => {generatedHclArray.push(hcl)})
            }

            this.setState({ isLoading: false, generatedHcl: generatedHclArray.join("\n\n") });
        }
    }

    onClickCft = () => {
        this.props.sendMetric("[/main/iac_import_modal] user clicked on cloud formation tab");
    }

    onClickPulumi = () => {
        this.props.sendMetric("[/main/iac_import_modal] user clicked on pulumi tab");
    }

    onClickHelm = () => {
        this.props.sendMetric("[/main/iac_import_modal] user clicked on Helm tab");
    }

    renderHclCode = (generatedHcl) => {
        if (_.isUndefined(generatedHcl)) {
            return 'No Data At The Moment..'
        }

        return (
            <SyntaxHighlighter
                useInlineStyles={true}
                showLineNumbers={true}
                language="hcl"
                lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}>
                {generatedHcl}
            </SyntaxHighlighter>
        )
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { generatedHcl } = this.state;

        return <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={this.onSubmit}
            header="Infrastructure-as-Code import"
            applyButton="Create"
            initialValues={{}}
            isEnabled={true}
            isLarge={true}
            isShowFooter={true}
            isShowSubmitButton={false}
            copyToClipboardText={generatedHcl} //if there is anything to copy..
        >

            <div className="card-body" style={{ maxHeight: '650px', overflowY: 'auto' }}>
                <ul className="nav nav-tabs" id="myIconTab" role="tablist">
                    <li className="nav-item"><a className="nav-link active show" id="terraform-icon-tab" data-toggle="tab" href="#terraformIcon" role="tab" aria-controls="terraformIcon" aria-selected="true"><TerraformIcon style={{ height: '16px' }} className="nav-icon" /> Terraform</a></li>
                    <li className="nav-item"><a className="nav-link" id="pulumi-icon-tab" data-toggle="tab" href="#pulumiIcon" role="tab" aria-controls="pulumiIcon" aria-selected="false" onClick={this.onClickPulumi}><PulumiIcon style={{ height: '16px' }} className="nav-icon" /> Pulumi</a></li>
                    <li className="nav-item"><a className="nav-link" id="cft-icon-tab" data-toggle="tab" href="#cftIcon" role="tab" aria-controls="cftIcon" aria-selected="false" onClick={this.onClickCft}><CloudFormationIcon style={{ height: '16px' }} className="nav-icon" /> Cloud Formation</a></li>
                    <li className="nav-item"><a className="nav-link" id="helm-icon-tab" data-toggle="tab" href="#helmIcon" role="tab" aria-controls="helmIcon" aria-selected="false" onClick={this.onClickHelm}><HelmIcon style={{ height: '16px' }} className="nav-icon" /> Helm</a></li>
                </ul>
                <div className="tab-content" id="myIconTabContent">
                    <div className="tab-pane fade active show" id="terraformIcon" role="tabpanel" aria-labelledby="terraform-icon-tab">
                        {
                            this.state.isLoading ? <Loading />
                                : this.renderHclCode(generatedHcl)
                        }
                    </div>
                    <div className="tab-pane fade" id="pulumiIcon" role="tabpanel" aria-labelledby="pulumi-icon-tab">
                        <div className="text-20">Coming Soon</div>
                    </div>

                    <div className="tab-pane fade" id="cftIcon" role="tabpanel" aria-labelledby="cft-icon-tab">
                        <div className="text-20">Coming Soon</div>
                    </div>

                    <div className="tab-pane fade" id="helmIcon" role="tabpanel" aria-labelledby="helm-icon-tab">
                        <div className="text-20">Coming Soon</div>
                    </div>
                </div>
            </div>

        </BasicModal>
    }
}

const mapStateToProps = (state) => {
    return {
        activeFeatures: state.activeFeaturesReducer.activeFeatures || []
    };
};

const mapDispatchToProps = {
    sendMetric,
    fetchGeneratedIaC,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(ResourceImport);