import _ from 'lodash';
import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from '../../consts/config';
import ActiveFeatures from '../../consts/activeFeatures';

const fetchGeneratedIaC = (resourceType, resourceId) => {
    return action(async (dispatch, getState) => {

        const {activeFeaturesReducer} = getState();
        const {activeFeatures} = activeFeaturesReducer;

        const requestWrapper = new RequestWrapper();

        const payload = {
            assetType: resourceType,
            assetId: resourceId,
            version: 1
        }

        const url = `${BACKEND_URL}/reverseLearning`;
        const req = await requestWrapper.sendRequest(url, "POST", payload);

        const data = await req.json();
        return JSON.parse(data);
    });
}

export {
    fetchGeneratedIaC
}
