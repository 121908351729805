import action from "../middleware";

export const CATEGORY_LOADED = "CATEGORY_LOADED";
export const CATEGORY_FILTERED = "CATEGORY_FILTERED";
export const CATEGORY_FILTERED_OUT = "CATEGORY_FILTERED_UT";
export const AWS_ACCOUNT_FILTERED = "AWS_ACCOUNT_FILTERED";
export const AWS_ACCOUNT_FILTERED_OUT = "AWS_ACCOUNT_FILTERED_OUT";

export const INIT_STACK_FILTERS = "INIT_STACK_FILTERS";
export const STACK_FILTERED = "STACK_FILTERED";
export const STACK_FILTERED_OUT = "STACK_FILTERED_OUT";

export const UNMANAGED_CHANGE_FILTER_STATUS =  "UNMANAGED_CHANGE_FILTER_STATUS";

const onCategoryLoaded = (categoryId) => {
    return action(async dispatch => {

        dispatch({
            type: CATEGORY_LOADED,
            payload: { categoryId }
        });
    });
}

const onCategoryFiltered = (resourceTypes) => {
    return action(async dispatch => {

        dispatch({
            type: CATEGORY_FILTERED,
            payload: { resourceTypes }
        });
    });
}

const onCategoryFilteredOut = (resourceTypes) => {
    return action(async dispatch => {

        dispatch({
            type: CATEGORY_FILTERED_OUT,
            payload: { resourceTypes }
        });
    });
}

const onAwsAccountFiltered = (awsAccount) => {
    return action(async dispatch => {

        dispatch({
            type: AWS_ACCOUNT_FILTERED,
            payload: { awsAccount }
        });
    });
}

const onAwsAccountFilteredOut = (awsAccount) => {
    return action(async dispatch => {

        dispatch({
            type: AWS_ACCOUNT_FILTERED_OUT,
            payload: { awsAccount }
        });
    });
}

const initStackFilters = (stackIds) => {
    return action(async dispatch => {

        dispatch({
            type: INIT_STACK_FILTERS,
            payload: { stackIds }
        });
    });
}

const onStackFiltered = (stackId) => {
    return action(async dispatch => {

        dispatch({
            type: STACK_FILTERED,
            payload: { stackId }
        });
    });
}

const onStackFilteredOut = (stackId) => {
    return action(async dispatch => {

        dispatch({
            type: STACK_FILTERED_OUT,
            payload: { stackId }
        });
    });
}

const onChangeUnmanagedFilterStatus = (filtered) => {
    return action(async dispatch => {

        dispatch({
            type: UNMANAGED_CHANGE_FILTER_STATUS,
            payload: { filteredUnmanaged: filtered }
        });
    });
}



export {
    onCategoryLoaded,
    onCategoryFiltered,
    onCategoryFilteredOut,
    onAwsAccountFiltered,
    onAwsAccountFilteredOut,
    onStackFiltered,
    onStackFilteredOut,
    initStackFilters,
    onChangeUnmanagedFilterStatus
}
