import React from 'react';
import _ from 'lodash';

import './activeSelector.scss';

export default class ActiveSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSelection: null
        };
    }

    componentDidMount() {
        if(this.props.options.length && this.props.options[0].value){
            this.setState({ activeSelection: this.props.options[0].value })
        }
    }

    handleSelected(option) {
        this.setState({ activeSelection: option.value });
        this.props.onSelect(option.key)
    }
    
    render() {
        const { options } = this.props;

        return <div className="ActiveSelector">
            <a 
                href="#" 
                className={`btn text-muted dropdown-toggle mr-3 ${this.state.activeSelection !== options[0].value && "active-selection"} `}
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
            >
                {
                    this.state.activeSelection !== options[0].value && 
                    <i className="active-selection-icon i-Filter-2"></i>
                }
                {this.state.activeSelection}
            </a>
            <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(0px, 33px, 0px)', top: '0px', left: '0px', willChange: 'transform' }}>
                {
                    _.map(options, option => {
                        return <a key={option.key} className="dropdown-item" href="#" onClick={() => this.handleSelected(option)}>{option.value}</a>
                    })
                }
            </div>
        </div>
    }
}
