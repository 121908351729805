import _, { values } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAllStates } from '../../redux/actions/statesActions';
import SimpleLine from '../../shared/charts/simpleLine';
import moment from 'moment';

const DAYS_AGGREGATION = 5;
const DAYS_BACK = 30;
class SummaryDeployments extends React.Component {
    constructor(props) {
        super(props);

        this.state = { keys: [], values: [] };
    }

    async componentDidMount() {
        const { stacks } = this.props;

        const dayNow = moment().startOf('day');

        const statesCountPerDay = {};

        for (let i = 0; i < DAYS_BACK; i++) {
            statesCountPerDay[i] = 0;
        }

        await Promise.all(
            stacks.map(async (stack) => {
                //Getting states for specific stack
                const stackStates = await this.props.getAllStates(stack.id);
                //filter 30d
                const stackStatesLastMonths = _.filter(stackStates, s => moment(s.createdAt).isAfter(moment().subtract(DAYS_BACK, 'days')));

                //adding sum to the statesCountPerDay
                stackStatesLastMonths.forEach(stackState => {
                    const diffDays = dayNow.diff(moment(stackState.createdAt).startOf('day'), 'days');
                    statesCountPerDay[DAYS_BACK -1 - diffDays]++;
                })
            })
        )

        //Aggregating by 5 days
        const keys = [];
        const values = [];
        for(let i =0; i< DAYS_BACK; i+=DAYS_AGGREGATION){
            keys.push(moment().startOf('day').subtract(DAYS_BACK-i, 'days').format("DD/MM"));
            const dayRanges = _.omitBy(statesCountPerDay, (value, key) => key < i || key >= i+DAYS_AGGREGATION);
            values.push(_.sum(Object.values(dayRanges)));
        }
        this.setState({keys, values});
    }

    render() {
        const {keys, values } = this.state;

        return <>
            <div className="card mb-4">
                <div className="card-body">
                    <h6 className="mb-1">Total IaC deployments last 30d</h6>
                    <SimpleLine
                        keys={keys}
                        values={values}
                        gridOptions={{ top: '15%', left: '10%', height: '50px' }} />
                </div>
            </div>
        </>
    }
}



const mapStateToProps = (state) => {
    return {
        stacks: state.stackReducer.data
    };
};

const mapDispatchToProps = {
    getAllStates,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SummaryDeployments);