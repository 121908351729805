import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import {changeFilter} from "../../../redux/actions/driftsActions";
import Loading from "../../../shared/loading";
import SelectInput from "@material-ui/core/Select/SelectInput";

class DriftSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }



    onChange = async (e) => {
        if (e.key !== 'Enter') {
            return;
        }
        
        await this.props.changeFilter(e.target.value);
        this.setState({isLoading: true});
        //Just for UI
        await new Promise(r => setTimeout(r, 650));
        this.setState({isLoading: false});
    }

    render() {
        const { filterQuery } = this.props;
        const {isLoading} = this.state;

        return (
            <div className="card-header gradient-purple-indigo 0-hidden pb-80">
                <div className="pt-4">
                    <div className="row">
                        <h4 className="col-md-4 text-white text-right"></h4>
                        <input className="form-control form-control-rounded col-md-4 ml-3 mr-3" type="text" defaultValue={filterQuery} onKeyDown={this.onChange} />
                        {isLoading ? <div className="loader-bubble loader-bubble-light text-left"></div> : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filterQuery: state.driftsReducer.filterQuery
    };
};

const mapDispatchToProps = {
    changeFilter
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(DriftSearch);