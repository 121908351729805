import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

class Integrations extends React.Component {

    constructor(props) {
        super(props);

        this.state = { };
    }

    async componentDidMount() {   
    }

    render() {
        return (<div>Integrations</div>)
    }
}

const mapStateToProps = (state) => {
    return {
       
    };
};

const mapDispatchToProps = {
};


Integrations = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Integrations);
export default withRouter(Integrations);