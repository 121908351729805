import React from "react";
import OopsImage from "../../public/images/oops.png";
import './emptyPage.scss';

export default class OopsPage extends React.Component{
    render(){

        return <div className="no-data">
        <img src={OopsImage} />
        <div className="description">{this.props.children}</div>
    </div>
    }
}