import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Modal from "react-modal";

class ConfirmationModal extends React.Component {
  static propTypes = {
    action: PropTypes.string.isRequired,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { isOpen, onYes, onNo, action } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        style={{
          content: {
            position: "absolute",
            maxWidth: "400px",
            textAlign: "center",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bottom: "unset",
          },
        }}
      >
        {this.props.customText ? this.props.customText : <p>
          Are you sure you want to <strong>{action}</strong>?
        </p>}
        <button
          className="btn btn-secondary"
          type="button"
          data-dismiss="modal"
          onClick={() => onNo()}
        >
          No
        </button>
        <button
          className="btn btn-primary ml"
          type="button"
          data-dismiss="modal"
          onClick={() => onYes()}
        >
          Yes
        </button>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

ConfirmationModal = compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfirmationModal
);

Modal.setAppElement(document.querySelector("body > main"));

export default withRouter(ConfirmationModal);
