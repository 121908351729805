import React, {useState} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../shared/loading'
import { getEnvironments, deleteEnvironment } from '../../redux/actions/environmentActions';
import CreateEnvironment from './createEnvironment';
import SeperatorLine from '../../shared/seperatorLine';
import ConfirmationModal from "../../shared/confirmationModal";
import './environment.scss';

class Environment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            environments: null,
            isLoading: true,
            isCreationOpen: false,
            confirmationModalOpen: false,
            confirmationModalAction: "none",
            confirmationModalHandler: _.noop,
            isLoadingDelete: false
        };

        this.getEnvironments.bind(this);
    }


    getEnvironments = async () => {
        await this.props.getEnvironments();
    }

    deleteEnvironments = async (environmentId) => {
        await this.props.deleteEnvironment(environmentId);
    }

    selectSpecificEnvironment = async (selectedEnvironment) => {
        this.props.history.push(`/environment/${selectedEnvironment}`);
    }

    async componentDidMount() {
        await this.getEnvironments();

        this.setState({
            environments: this.props.environments,
            isLoading: false,
        });
    }

    async componentWillReceiveProps(nextProps) {
        if (!_.isEqual(nextProps.environments, this.props.environments)) {
            this.setState({isLoading: true});

            this.setState({
                environments: nextProps.environments,
                isLoading: false,
            });
        }
    }

    openCreationModal = () => {
        this.setState({ isCreationOpen: true });
    }

    closeCreationModal = () => {
        this.setState({ isCreationOpen: false });
    }

    closeConfirmationModal = () => {
        this.setState({ confirmationModalOpen: false });
    };

    onCreateEnvironment = async (newEnvironmentId) => {
        this.closeCreationModal();
        await this.getEnvironments();
        this.setState({ environments: this.props.environments });
        this.props.history.push(`/environment/${newEnvironmentId}`);
    }


    confirmEnvironmentRemoval = (e, environmentId, environmentName) => {
        e.stopPropagation()
        this.setState({
            confirmationModalOpen: true,
            confirmationModalAction: `delete ${environmentName}`,
            confirmationModalHandler: async () => {
                this.setState({ isLoadingDelete: true });
                await this.deleteEnvironments(environmentId);
                await this.props.getEnvironments();

                this.setState({
                    confirmationModalOpen: false,
                    isLoadingDelete: false,
                });
            }
        });
    };

    renderEnvironmentsList = () => {
        return this.state.environments.map(env =>
            <div
                key={env.id}
                className={`card Environment__list-item`}
                onClick={() => this.selectSpecificEnvironment(env.id)}
            >
                <i className="i-Clouds Environment__list-item-icon mb-3"></i>
                <i
                    onClick={(e) => this.confirmEnvironmentRemoval(e, env.id, env.name)}
                    className="i-Remove Environment__list-item-icon-delete"></i>
                <span  className="card-title mb-0">{env.name}</span>
                <span  className="Environment__list-item-owner">{env.owner}</span>
            </div>
        )
    }


    render() {
        const { isLoading, isLoadingDelete, isCreationOpen } = this.state;

        if (isLoading == true) { return null; }

        if (isLoadingDelete) { return <Loading /> }


        return (
            <>
                <CreateEnvironment isOpen={isCreationOpen} onClose={this.closeCreationModal} onCreate={this.onCreateEnvironment} />

                <div>
                    <div className="Environment__header">
                        <span className="Environment__header-title font-weight-bold" >
                            Environments Management
                        </span>

                    </div>

                    <SeperatorLine />

                </div>

                <div className="Environment__list">
                    {this.renderEnvironmentsList()}
                    <div
                        className="card Environment__list-item new"
                        onClick={this.openCreationModal}
                    >
                        <i className="i-Add Environment__list-item-icon"></i>
                        <span className="card-title mb-0">Create</span>
                        <span className="card-title mb-0">Environment</span>
                    </div>
                </div>

                <ConfirmationModal
                    isOpen={this.state.confirmationModalOpen}
                    action={this.state.confirmationModalAction}
                    onYes={this.state.confirmationModalHandler}
                    onNo={this.closeConfirmationModal}
                />
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        environments: state.environmentReducer.data,
        selectedEnvironment: state.environmentReducer.selected
    };
};

const mapDispatchToProps = {
    getEnvironments,
    deleteEnvironment
};

Environment = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Environment);
export default withRouter(Environment);