import React, { Component } from 'react';
import _ from 'lodash';

import {
    Echart,
    Tooltip,
    Toolbox,
    SeriesPie,
    Legend,
    Event
} from 'react-echart';

export const EchartsColorEnum = [
    '#ee6666',
    '#91cc75',
    '#fac858',
    '#5470c6',
    '#73c0de',
    '#3ba272',
    '#fc8452',
    '#9a60b4',
    '#ea7ccc'
]



export default class EchartPie extends Component {
    render() {
        const height = _.isUndefined(this.props.height) ? 310 : this.props.height;
        const labelFormat = _.isUndefined(this.props.labelFormat) ? '{b}: ({d}%)' : this.props.labelFormat;

        const { name, series, labels, onLegendSelectChanged, colors } = this.props;
        const color = _.isUndefined(colors) ? EchartsColorEnum : colors;

        const data = _.map(labels, (label, i) => {
            const value = _.isInteger(series[i])?series[i]:series[i].value;
            const selected = _.isInteger(series[i])?false:series[i].selected === true;
            const itemStyle = _.isInteger(series[i])? {} : _.isUndefined(series[i].itemStyle) ? {} : series[i].itemStyle;
            return { 
                name: label,
                value,
                selected,
                itemStyle
            }
        });

        return <div style={{ height }}>
            <Echart className='echartPie' options={{ color }} showLoading={false} loadingType="" resize={true} resizeOption={{ silent: true }}>

                <Tooltip options={{ trigger: 'item',formatter: '{b} <br/>{c} ({d}%)' }} />
                <Legend options={{
                    type: 'scroll',
                    orient: 'horizontal',
                    bottom: '0',
                    left: 'center',
                    data: _.map(labels.reverse(), label => ({name: label}) )
                }} />

                <SeriesPie options={[{
                    name: name || "",
                    type: 'pie',
                    radius: ['55%', '70%'],
                    avoidLabelOverlap: false,
                    data,
                    height: '90%',
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 3
                    },
                    label: {
                        position: 'inner',
                        formatter: labelFormat,
                        fontSize: 14,
                        // show: false,
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '20',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    
                }]} />

                <Toolbox options={{
                    feature: {
                        saveAsImage: {},
                    },
                    showTitle: false
                }} />
                {
                    _.isUndefined(onLegendSelectChanged) ? null:
                    <Event type="legendselectchanged" handler={onLegendSelectChanged} />
                }
                {/* <Event type="click" handler={console.log} /> */}
                {/* <Event type="mouseout" handler={console.log} /> */}

            </Echart>
        </div>
    }
}