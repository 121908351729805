import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from "../../../shared/loading"
import { getEnvironments } from '../../../redux/actions/environmentActions';
import { getStacksByEnvironmentId, deleteStackByEnvironmentId } from '../../../redux/actions/stackActions';
import { getIacSettings } from "../../../redux/actions/statesActions";
import CreateStack from './createStackWizard/createStack';
import SeperatorLine from '../../../shared/seperatorLine';
import ConfirmationModal from "../../../shared/confirmationModal";

import './listStacks.scss';
import {Table} from "../../../shared/tables/tables";
import {TimeParser} from "../../../shared/parsers";
import {CardDescription, CardHeader} from "../../../shared/layouts/cards";
import ListItem from "../../../shared/lists/listItem";
import ListGroup from "../../../shared/lists/listGroup";

class ListStacks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            environmentId: this.props.match.params.environmentId,
            stacks: null,
            iacSettings: null,
            isLoading: true,
            selectedStack: null,
            isCreationOpen: false,
            confirmationModalOpen: false,
            confirmationModalAction: "none",
            confirmationModalHandler: _.noop,
            isLoadingDelete: false
        };

        this.getStacks.bind(this);
    }

    getStacks = async () => {
        await this.props.getStacksByEnvironmentId(this.state.environmentId);
    }

    getEnvironments = async () => {
        await this.props.getEnvironments();
        this.setState({environments: this.props.environments})
    }

    getIacSettings = async () => {
        const allIacSettings = []
        for (let stack of this.props.stacks) {
            await this.props.getIacSettings(stack.id);
            if (!_.isEmpty(this.props.iacSettings) && !_.isEmpty(this.props.iacSettings.result)) {
                allIacSettings.push(this.props.iacSettings.result)
            }
        }
        return allIacSettings
    }

    deleteStacks = async (stackId) => {
        await this.props.deleteStackByEnvironmentId(this.state.environmentId, stackId);
    }

    selectSpecificStack = async (selectedStack) => {
        this.props.history.push(`/environment/${this.state.environmentId}/stack/${selectedStack}`);
    }

    async componentDidMount() {
        await this.getStacks();
        await this.getEnvironments();
        let allIacSettings = await this.getIacSettings()

        this.setState({
            stacks: this.props.stacks,
            isLoading: false,
            iacSettings: allIacSettings

        });
    }

    changeStack = async (selected) => {
        this.setState({ selectedStack: selected });
        await this.selectSpecificStack(selected);
    }

    openCreationModal = () => {
        this.setState({ isCreationOpen: true });
    }

    closeCreationModal = async () => {
        this.setState({ isCreationOpen: false });
        this.setState({ isLoadingDelete: true });
        await this.getStacks(this.state.environmentId);
        let allIacSettings = await this.getIacSettings()
        this.setState({ stacks: this.props.stacks, iacSettings: allIacSettings, isLoadingDelete: false });
    }

    closeConfirmationModal = () => {
        this.setState({ confirmationModalOpen: false });
    };

    handleBackToStackList = () => {
        this.setState({ selectedStack: null })
        this.props.history.push(`/environment`);
    }

    confirmStackRemoval = (e, stackId, stackName) => {
        e.stopPropagation()
        this.setState({
            confirmationModalOpen: true,
            confirmationModalAction: `delete ${stackName}`,
            confirmationModalHandler: async () => {
                this.setState({ isLoadingDelete: true });
                await this.deleteStacks(stackId);
                await this.getStacks(this.state.environmentId);

                this.setState({
                    confirmationModalOpen: false,
                    stacks: this.props.stacks,
                    isLoadingDelete: false,
                });
            }
        });
    };

    renderStacksList = () => {
        const stacksTableColumns = [
            { id: 1, value: "Name" },
            { id: 2, value: "Type" },
            { id: 3, value: "Location" },
            { id: 5, value: "Last Update" },
        ];

        return <Table columns={stacksTableColumns} isMinimialPurple>
                {_.map(this.state.stacks, (stack) => {
                    const iacSettings = _.find(this.state.iacSettings, (setting) => setting.id == stack.id) || {};

                    return (
                        <tr className="Stack__list-row" key={stack.id}
                            onClick={() => this.changeStack(stack.id)}>
                            <td><span  className="card-title mb-0">{stack.name}</span></td>
                            <td>{<TimeParser value={"Terraform"} />}</td>
                            <td>{<TimeParser value={iacSettings?.selected} />}</td>
                            <td>{<TimeParser value={iacSettings?.policy?.lastModified} />}</td>
                            <td>
                                <a
                                    className="text-danger mr-2 alert-confirm pl-3"
                                    href="#"
                                    onClick={(e) => this.confirmStackRemoval(e, stack.id, stack.name)}
                                >
                                    <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                </a>
                            </td>
                        </tr>
                    );
                })}
            </Table>
    }


    render() {
        const { isLoading, isLoadingDelete, isCreationOpen, selectedStack } = this.state;
        const stack = _.first(_.filter(this.state.stacks, env => env.id == this.state.selectedStack)) || {};
        const environment = _.first(_.filter(this.state.environments, env => env.id == this.state.environmentId)) || {};

        if (isLoading == true) { return null; };

        if (isLoadingDelete) { return <Loading /> }

        const pathname = this.props.location.pathname;

        return (
            <>
                <CreateStack isOpen={isCreationOpen} onClose={this.closeCreationModal} />

                <div>
                    <div className="Stack__header">
                        <span className="Stack__header-title font-weight-bold" >

                            { environment.name } {!_.isEmpty(environment.owner)  && `| ${environment.owner}`}
                        </span>

                        <button onClick={this.handleBackToStackList} className="btn btn-link Stack__header-backBtn" type="button" >
                            <span>Back</span>
                            <i className="i-Right1 Stack__header-backBtn-icon"></i>
                        </button>
                    </div>
                    
                    <SeperatorLine />

                </div>

                {
                    _.isEmpty(selectedStack) ?
                        <div className="page"
                             style={{position: "relative", paddingTop: "70px", width: "100%"}}>
                            <div className="Stack__list"
                                 style={{width: "100%"}}>

                                <div className="card" style={{minWidth: "68%", padding: "20px"}}>
                                    <CardHeader>
                                        Managed IaC Stacks
                                        <div
                                            className="Stack__list-new"
                                            style={{ position: "absolute", top: "0", marginTop: "15px", marginRight: "15px", right: "0"}}
                                            onClick={this.openCreationModal}
                                        >
                                            <i className="i-Add Stack__list-new-icon"></i>
                                            <span className="card-title mb-0">{"Create"}</span>
                                            <span className="card-title mb-0">Stack</span>
                                        </div></CardHeader>
                                    <CardDescription>Manage Stacks in the Environment</CardDescription>
                                        {this.renderStacksList()}
                                </div>

                                <div className="card" style={{minWidth: "30%", padding: "20px"}}>
                                    <CardHeader>Providers used in these Stacks</CardHeader>
                                    <ListGroup>
                                            {
                                                _.map(this.state.stacks, stack => {
                                                if (!_.isEmpty(stack.integrations.aws)) {
                                                    return <ListItem key={stack.integrations.aws.id}
                                                                     id={stack.integrations.aws.id}
                                                                     header={"AWS " + stack.integrations.aws.externalId}
                                                                     textAlign="center"
                                                    >{"provided by " + stack.name + " stack"}</ListItem>
                                                }
                                                if (!_.isEmpty(stack.integrations.k8s)) {
                                                    return <ListItem key={stack.integrations.k8s.id}
                                                                     id={stack.integrations.k8s.id}
                                                                     header={"K8S " + stack.integrations.k8s.externalId}
                                                                     textAlign="center"
                                                    >{"provided by " + stack.name + " stack"}</ListItem>
                                                }
                                            })}
                                    </ListGroup>


                                </div>


                            </div>


                        </div>: null
                }
                <ConfirmationModal
                    isOpen={this.state.confirmationModalOpen}
                    action={this.state.confirmationModalAction}
                    onYes={this.state.confirmationModalHandler}
                    onNo={this.closeConfirmationModal}
                />
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        stacks: state.stackReducer.data,
        environments: state.environmentReducer.data,
        selectedStack: state.stackReducer.selected,
        iacSettings : state.statesReducer.selectedPolicy
    };
};

const mapDispatchToProps = {
    getStacksByEnvironmentId,
    deleteStackByEnvironmentId,
    getIacSettings,
    getEnvironments
};

ListStacks = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(ListStacks);
export default withRouter(ListStacks);