import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Wizard, WizardMenu, WizardMenuItem, WizardContainer, WizardPage, WizardBottom } from '../../../shared/smartWizard/wizard';
import Loading from '../../../shared/loading';
import AssumeRole from './assumeRole';
import AwsValidate from './validate';
import Base64 from '../../../utils/base64';
import PageLayout from "../../../shared/layouts/pageLayout";
import { CardBody, Card, CardHeader } from '../../../shared/layouts/cards';

class AwsInstallationWizard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            externalId: null,
            isLoading: true,
            isRoleValid: false
        };
    }

    async componentDidMount() {
        const externalId = Base64.encode(Math.random().toString()).replace(/=/g, '');
        this.setState({ isLoading: false, externalId });
    }


    isNextEnabled = () => {
        const { step } = this.state;
        if (step == 1 && true) {
            return true;
        }

        return false;
    }

    isPrevEnabled = () => {
        const { step } = this.state;

        if (step <= 1) {
            return false;
        }
        return true;
    }

    nextStep = async () => {
        if (!this.isNextEnabled()) {
            return;
        }

        this.setState({ step: this.state.step + 1 });
    }

    prevStep = () => {
        if (!this.isPrevEnabled()) {
            return;
        }

        this.setState({ step: this.state.step - 1 });
    }

    cancel = () => {
        //TODO: clean-up
        this.setState({ step: 1 });
    }

    complete = async () => {
        const { isRoleValid } = this.state;

        if (!isRoleValid) {
            return;
        }

        this.setState({ step: 3 });
    }

    updateRoleValid = () => {
        console.log("Valid!");
        this.setState({ isRoleValid: true })
    }

    render() {
        const { step, isLoading, externalId, isRoleValid } = this.state;

        if (isLoading) {
            return (<Loading />);
        }

        return (
            <PageLayout>
                <Card>
                    <CardBody>
                        <CardHeader>AWS Onboarding</CardHeader>
                        <Wizard>
                            <WizardMenu>
                                <WizardMenuItem isActive={step == 1} header="Create role" description="Creating readonly assume-role" />
                                <WizardMenuItem isActive={step == 2} header="Validate AWS integration" description="Validating AWS integration" />
                            </WizardMenu>


                            <WizardContainer>
                                <WizardPage id="step1" isActive={step == 1}>
                                    <AssumeRole externalId={externalId} />
                                </WizardPage>

                                <WizardPage id="step2" isActive={step == 2}>
                                    <AwsValidate externalId={externalId} updateRoleValid={this.updateRoleValid} />
                                </WizardPage>

                                <WizardPage id="step3" isActive={step == 3}>
                                    Completed!
                            </WizardPage>

                            </WizardContainer>

                            <WizardBottom
                                onNextStep={this.nextStep}
                                onPrevStep={this.prevStep}
                                onComplete={this.complete}
                                onCancel={this.cancel}
                                isPrevEnabled={this.isPrevEnabled()}
                                isNextEnabled={this.isNextEnabled()}
                                isCompleteEnabled={isRoleValid}
                            />
                        </Wizard>
                    </CardBody>
                </Card>
            </PageLayout>
        )
    }
}
export default withRouter(AwsInstallationWizard);