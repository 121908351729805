import React from "react";
import _ from "lodash";
import { TimeParser } from "../../shared/parsers";
import "./tables.scss";

class Table extends React.Component {
    render() {
        const isFullWidth = _.isUndefined(this.props.isFullWidth) ? true : this.props.isFullWidth;
        const isBordeless = _.isUndefined(this.props.isBordeless) ? false : this.props.isBordeless;
        const isDark = _.isUndefined(this.props.isDark) ? false : this.props.isDark;

        const id = _.isUndefined(this.props.id) ? "table" : this.props.id;
        const tableClass = _.isUndefined(this.props.className) ? "" : this.props.className;
        const isStriped = _.isUndefined(this.props.isStriped) ? false : this.props.isStriped;
        const isBoardered = _.isUndefined(this.props.isBoardered) ? false : this.props.isBoardered;
        const isSmall = _.isUndefined(this.props.isSmall) ? false : this.props.isSmall;
        const isHover = _.isUndefined(this.props.isHover) ? false : this.props.isHover;

        const isHeaderPurple = _.isUndefined(this.props.isHeaderPurple) ? false : this.props.isHeaderPurple;
        const isHeaderDark = _.isUndefined(this.props.isHeaderDark) ? false : this.props.isHeaderDark;
        const isMinimialPurple = _.isUndefined(this.props.isMinimialPurple) ? false : this.props.isMinimialPurple;

        const { columns, data } = this.props;
        const options = _.isUndefined(this.props.options) ? [] : this.props.options;

        //display table table-borderless ul-contact-list-table
        return (
            <table className={`display table ${isStriped ? 'table-striped' : ''} ${isHover ? 'table-hover' : ''} ${isSmall ? 'table-sm' : ''} ${isBoardered ? 'table-bordered' : ''} ${isBordeless ? 'table-borderless' : ''} ${isDark ? 'table-dark' : ''}  ${isFullWidth ? 'table-full-width' : ''} ${isMinimialPurple ? 'isMinimialPurple' : ''} ${tableClass}`} id={id}>
                <TableHead
                    isPurple={isHeaderPurple}
                    isDark={isHeaderDark}
                    columns={columns} />

                <tbody>
                    { data ? 
                        _.map(data, item => {
                            const optionsById = _.groupBy(options, "id");
                            const itemOptions = _.isEmpty(optionsById[item.id]) ? {} : optionsById[item.id][0];

                            return (<tr key={item.id} style={itemOptions.style}>
                                {_.map(columns, column => {
                                    const key = `${item.id}_${column.id}`;
                                    const RenderedComponent = column.component;

                                    switch (column.component) {
                                        case "text":
                                            return <td key={key} {...column}>{item[column.id]}</td>
                                        case "time":
                                            return <td key={key} {...column}>{<TimeParser value={item[column.id]} />}</td>
                                        default:
                                            if (!_.isUndefined(column.component) && _.isObject(column.component)) {
                                                return <td key={key} {...column}>{<RenderedComponent item={item} />}</td>;
                                            }
                                            
                                            return <td key={key}></td>
                                    }

                                })}
                            </tr>)
                        })
                        :
                        this.props.children
                    }
                </tbody>
            </table>)
    }
}

class TableHead extends React.Component {
    render() {
        const { columns } = this.props;
        const isDark = _.isUndefined(this.props.isDark) ? false : this.props.isDark;
        const isPurple = _.isUndefined(this.props.isPurple) ? false : this.props.isPurple;

        return (
            <thead className={isPurple ? "thead-purple" : isDark ? 'thead-dark' : ''}>
                <tr>
                    {_.map(columns, col => <th scope="col" key={col.id}>{col.value}</th>)}
                </tr>
            </thead>
        )
    }
}
export { Table }