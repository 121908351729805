import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../shared/loading';
import RequestWrapper from '../../../utils/requestWrapper';
import { BACKEND_URL } from '../../../consts/config';
import ReactJson from 'react-json-view'
import { TimeParser } from '../../../shared/parsers';


class StateViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            valid: null,
            stateId: this.props.match.params.stateId,
            environmentId: this.props.match.params.environmentId,
            stackId: this.props.match.params.stackId,
            stateResult: null
        };
    }

    async componentDidMount() {
        const requestWrapper = new RequestWrapper();

        const { stateId } = this.state;

        const stateRequest = await requestWrapper.sendRequest(`${BACKEND_URL}/states/${stateId}`, "GET");
        if (!stateRequest.ok) {
            this.setState({ valid: false });
            return;
        }

        const stateResult = await stateRequest.json();
        this.setState({ isLoading: false, stateResult });
    }


    render() {
        const {environmentId, stackId, valid, isLoading, stateResult} = this.state;

        if (valid == false) {
            return (<div>id is not valid</div>);
        }
        if (isLoading) {
            return (<Loading />);
        }

        return <>
            <a href={`/environment/${environmentId}/stack/${stackId}`}><i className="text-20 i-Back1"></i></a>
            <h3 className="mb-0 text-muted">Created at <TimeParser value={stateResult.createdAt} /></h3><br />
            <ReactJson src={JSON.parse(stateResult.file)} />
        </>
    }
}

const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = {
};

StateViewer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(StateViewer);
export default withRouter(StateViewer);