import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../../shared/loading';
import { getAwsIntegrations, getAllBuckets, getAllS3Objects } from '../../../../redux/actions/integrationsActions';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';

class S3Policy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            integrations: [],
            buckets: [],
            selectedIntegrationId: null,
            integrationError: false,
            isIntegrationValid: null,

            selectedBucket: null,
            bucketStatusCode: null,
            isBucketValid: null,

            bucketObjects: null,

            selectedS3Key: null,
            isS3KeyValid: null,
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        const { initialValues } = this.props;

        if (!_.isEmpty(initialValues.awsIntegration) && !_.isEmpty(initialValues.s3Bucket) && !_.isEmpty(initialValues.s3Key)) {
            await this.onChangeIntegration(initialValues.awsIntegration);
            await this.onChangeBucket(initialValues.s3Bucket);
            await this.onChangeS3KeySelection(initialValues.s3Key);
        }

        await this.props.getAwsIntegrations();
        this.setState({ integrations: this.props.awsIntegrations });

        this.setState({ isLoading: false });
    }

    onChangeIntegration = async (integrationId) => {
        this.setState({ isLoading: true });
        const res = await this.props.getAllBuckets(integrationId);

        this.setState({ isLoading: false, buckets: res.buckets, isIntegrationValid: res.ok, integrationError: res.ok, selectedIntegrationId: integrationId });
    }

    onChangeBucket = async (bucket) => {
        const { selectedIntegrationId } = this.state;
        this.setState({ isLoading: true, selectedS3Key: null, isS3KeyValid: null });

        const res = await this.props.getAllS3Objects(selectedIntegrationId, bucket);
        const tfStateFiles = _.filter(res.objects, obj => obj.Key.endsWith(".tfstate"));
        this.setState({ isLoading: false, selectedBucket: bucket, isBucketValid: res.ok, bucketStatusCode: res.status, bucketObjects: tfStateFiles });
    }

    onChangeS3KeySelection = async (key) => {
        this.setState({ selectedS3Key: key, isS3KeyValid: !_.isEmpty(key) });
    }

    onSubmit = async () => {
        this.setState({ isLoading: true });

        const { selectedIntegrationId, selectedBucket, selectedS3Key } = this.state;

        await this.props.onUpdatePolicy("s3", { awsIntegration: selectedIntegrationId, s3Bucket: selectedBucket, s3Key: selectedS3Key });

        this.setState({ isLoading: false });
    }

    render() {
        const { isLoading, integrations, buckets, selectedIntegrationId, isIntegrationValid, isBucketValid, bucketStatusCode, selectedBucket, bucketObjects, isS3KeyValid } = this.state;

        const selectedIntegration = (_.first(integrations, integ => integ.id == selectedIntegrationId) || {}).roleArn || "";
        const permissionPolicy = `https://us-east-1.console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=https://infralight-templates-public.s3.amazonaws.com/s3bucket-readonly/template.yml&param_BucketName=${selectedBucket}&stackName=infralight-${selectedBucket}-${selectedIntegrationId}&param_RoleArn=${selectedIntegration}`;

        return (
            isLoading ? <Loading /> :
                <>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="awsIntegration">AWS Integration</label>
                        <div className="col-sm-10">
                            <Field
                                className={"form-control"}
                                name="awsIntegration"
                                id="awsIntegration"
                                component="select"
                                onChange={(e) => this.onChangeIntegration(e.target.value)}
                            >
                                <option value="">Please Choose AWS Integration..</option>
                                {_.map(integrations, integ => <option value={integ.id} key={integ.id}>{integ.name} ({integ.accountNumber})</option>)}
                            </Field>
                            {isIntegrationValid == false ? <div className="text-14 font-weight-bold text-danger">integration is not valid</div> : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="s3Bucket">AWS S3 Bucket</label>
                        <div className="input-group mb-3 col-sm-10">
                            <div className="input-group-prepend"><span className="input-group-text" id="reload">
                                {isBucketValid == false ?
                                    <a href="#" onClick={() => this.onChangeBucket(selectedBucket)}><i className="i-Repeat-3"></i></a> :
                                    <i className="i-Repeat-3"></i>
                                }
                            </span>
                            </div>
                            <Field
                                className={"form-control"}
                                name="s3Bucket"
                                id="s3Bucket"
                                component="select"
                                disabled={isIntegrationValid != true}
                                onChange={(e) => this.onChangeBucket(e.target.value)}
                                aria-describedby="reload"
                            >
                                <option value="">Please Choose S3 Bucket..</option>
                                {_.map(buckets, bucket => <option value={bucket.Name} key={bucket.Name}>{bucket.Name}</option>)}
                            </Field>
                        </div>
                        {isBucketValid == false ?
                            bucketStatusCode == 403 ?
                                <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Permission Denied<br /><a target="_blank" href={permissionPolicy}>Click here to grant permission</a></div></> :
                                <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Bucket integration is not valid</div></> : null}
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="awsIntegration">AWS Terraform state (.tfstate file)</label>
                        <div className="col-sm-10">
                            <Field
                                className={"form-control"}
                                name="s3Key"
                                id="s3Key"
                                component="select"
                                disabled={isBucketValid != true}
                                onChange={(e) => this.onChangeS3KeySelection(e.target.value)}
                            >
                                <option value="">Please Choose Terraform state file..</option>
                                {_.map(bucketObjects, obj => <option value={obj.Key} key={obj.Key}>{obj.Key}</option>)}
                            </Field>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <button className="btn btn-primary" type="submit" disabled={!(isIntegrationValid && isBucketValid && isS3KeyValid)} onClick={() => this.onSubmit()}>Save</button>
                        </div>
                    </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    const configuredPolicy = ((state.statesReducer.selectedPolicy || {}).result || {}).policy || {};

    return {
        awsIntegrations: (state.integrationsReducer.aws || {}).integrations || [],
        policy: state.statesReducer.selectedPolicy,
        initialValues: { awsIntegration: configuredPolicy.awsIntegration || "", s3Bucket: configuredPolicy.s3Bucket || "", s3Key: configuredPolicy.s3Key }
    };
};

const mapDispatchToProps = {
    getAwsIntegrations,
    getAllBuckets,
    getAllS3Objects,
};

S3Policy = reduxForm({
    form: 's3-state-policy'
})(S3Policy);

S3Policy = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(S3Policy);


export default withRouter(S3Policy);