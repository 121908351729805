import {
} from '../actions/iacImportActions';

const initialState = {

};

export default (state = initialState, { type, payload }) => {

    switch (type) {
     
        default:
            return state;
    }
}
