import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import BasicModal from "../../../shared/basicModal";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

class DiffChecker extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: false };
    }

    async componentDidMount() { }

    onSubmit = async (submittedInfo) => {
    };

    render() {
        const { isOpen, onClose, selectedDrift } = this.props;
        return (
            <BasicModal
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={this.onSubmit}
                header="Drift Checker"
                isLarge={true}
                isShowSubmitButton={false}
            >
                <div>
                    <ReactDiffViewer
                        oldValue={JSON.stringify(selectedDrift?.stateItem ?? {}, null, 2)}
                        leftTitle="IaC Object"
                        newValue={JSON.stringify(selectedDrift?.inventoryItem ?? {}, null, 2)}
                        rightTitle="Cloud Object"
                        compareMethod={DiffMethod.WORDS}
                        splitView={true}
                        styles={{
                            line: {
                                wordBreak: 'break-word'
                            },
                        }}
                    />
                </div>
            </BasicModal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedDrift: state.driftsReducer.selectedDrift
    };
};

const mapDispatchToProps = {
};

DiffChecker = compose(connect(mapStateToProps, mapDispatchToProps))(DiffChecker);

export default withRouter(DiffChecker);
