import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Loading from "../../../shared/loading";
import S3Policy from "./policies/s3Policy";
import TfcPolicy from "./policies/tfcPolicy";
import {
    getIacSettings,
    updateIacSettings,
} from "../../../redux/actions/statesActions";
import { Warning } from "../../../shared/alerts";
import ManualPolicy from "./policies/manualPolicy";
import { GCSPolicy } from "./policies/gcsPolicy";

class StatePolicy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            environmentId: this.props.match.params.environmentId,
            isLoading: false,
            selectedPolicy: null,
            updatedSuccessful: null,
        };
    }

    async componentDidMount() {
        this.setState({stackId: this.props.stack.id})
        await this.props.getIacSettings(this.props.stack.id);
        this.setState({
            selectedPolicy: ((this.props.policy || {}).result || {}).selected || null,
        });
    }


    onUpdatePolicy = async (selected, policy) => {
        const res = await this.props.updateIacSettings(
            this.state.stackId,
            selected,
            policy
        );
        this.setState({ updatedSuccessful: res.ok });
        await this.props.getIacSettings(this.state.stackId);
    };

    onChangeSelectedPolicy = (value) => {
        this.setState({ selectedPolicy: value });
    };

    RadioButton = (props) => {
        const { selectedPolicy } = this.state;
        const checked = selectedPolicy == props.value;
        const disabled = _.isUndefined(props.disabled) ? false : props.disabled;

        return (
            <div className={`form-check ${disabled ? "disabled" : ""}`}>
                <input
                    className="form-check-input"
                    id={props.id}
                    type="radio"
                    name="policies"
                    value={props.value}
                    checked={checked}
                    disabled={disabled}
                    onChange={() => this.onChangeSelectedPolicy(props.value)}
                />
                <label className="form-check-label ml-3" htmlFor={props.id}>
                    {props.description}
                </label>
            </div>
        );
    };

    render() {
        const { selectedPolicy, isLoading, updatedSuccessful } = this.state;
        const actualPolicy = this.props.policy;

        if (isLoading) {
            return <Loading />;
        }
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-5">
                            <div className="card-body">
                                {!_.isEmpty(actualPolicy) && !actualPolicy.ok ? (
                                    <Warning closeEnabled={false}>
                                        Error occurred in policy, contact InfraLight
                                    </Warning>
                                ) : null}
                                <form>
                                    <fieldset className="form-group">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <this.RadioButton
                                                    id="s3"
                                                    checked={true}
                                                    value="s3"
                                                    description="AWS S3 Bucket"
                                                />

                                                <this.RadioButton
                                                    id="gcs"
                                                    checked={true}
                                                    value="gcs"
                                                    description="GCP GCS Bucket"
                                                />

                                                <this.RadioButton
                                                    id="terraform-cloud"
                                                    value="tfc"
                                                    description="Terraform Cloud"
                                                />

                                                <this.RadioButton
                                                    id="manual"
                                                    value="manual"
                                                    description="Manual Upload"
                                                />

                                                <this.RadioButton
                                                    id="hashicorp"
                                                    disabled={true}
                                                    value="hashicorp"
                                                    description="Hashicorp Consul"
                                                />

                                                <this.RadioButton
                                                    id="state-management"
                                                    disabled={true}
                                                    value="state-management"
                                                    description="InfraLight state management"
                                                />
                                            </div>
                                            <div className="col-form-label col-sm-2 pt-0"></div>
                                        </div>
                                    </fieldset>

                                    {selectedPolicy === "manual" ? (
                                        <ManualPolicy stack={this.props.stack} onUpdatePolicy={this.onUpdatePolicy} />
                                    ) : selectedPolicy === "s3" ? (
                                        <S3Policy onUpdatePolicy={this.onUpdatePolicy} />
                                    ) : selectedPolicy === "tfc" ? (
                                        <TfcPolicy onUpdatePolicy={this.onUpdatePolicy} />
                                    ) : selectedPolicy === "gcs" ? (
                                        <GCSPolicy onUpdatePolicy={this.onUpdatePolicy} />
                                    ) : null}

                                    {updatedSuccessful === true ? (
                                        <div className="text-14 font-weight-bold text-success mt-2">
                                            Updated Successfully
                                        </div>
                                    ) : updatedSuccessful === false ? (
                                        <div className="text-14 font-weight-bold text-danger mt-2">
                                            Unhandled error occurred while trying to update policy
                                        </div>
                                    ) : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        policy: state.statesReducer.selectedPolicy,
    };
};

const mapDispatchToProps = {
    getIacSettings,
    updateIacSettings,
};

StatePolicy = compose(connect(mapStateToProps, mapDispatchToProps))(
    StatePolicy
);

export default withRouter(StatePolicy);
