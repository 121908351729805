import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BasicModal from '../../shared/basicModal';
import Loading from '../../shared/loading';

import { withRouter } from "react-router-dom";
import { Field } from 'redux-form';
import { sendMetric } from '../../redux/actions/metricActions';
import { getAllGithubApps } from '../../redux/actions/vcsActions';
import Empty from "../../shared/emptyPage/emptyPage";
import Oops from "../../shared/emptyPage/oopsPage";
import { BACKEND_URL } from '../../consts/config';
import RequestWrapper from '../../utils/requestWrapper';

class PullRequest extends React.Component {
    constructor(props) {
        super(props);

        this.requestWrapper = new RequestWrapper();

        this.state = {
            isLoading: false,
            selectedResources: [],
            hasError: null,
            repos: null,
            selectedVcs: null,
            pullRequestUrl: null
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
        await this.props.getAllGithubApps();
        this.setState({ isLoading: false });
    }


    async componentWillReceiveProps(nextProps) {
        //props has been changed
        if (nextProps.mode == "multi" || !_.isEqual(this.props.selectedResources, nextProps.selectedResources)) {
            this.setState({
                selectedResources: nextProps.selectedResources,
                hasError: null,
                repos: null,
                selectedVcs: null,
                pullRequestUrl: null
            });
        }
    }

    moveToGitHubPage = () => {
        this.props.history.push("/integrations/vcs/github");
    }

    onChangeVcs = async (e) => {

        const vcsId = e.target.value;

        const isVcsValid = !_.isEmpty(vcsId);
        if (!isVcsValid) {
            return;
        }

        this.setState({ isLoading: true });

        const reposReq = await this.requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github/${vcsId}/repos`, "GET");
        if (!reposReq.ok) {
            console.log('Could not find repos');
            this.setState({ selectedVcs: null, repos: null, isLoading: false });
            return;
        }

        const repos = await reposReq.json();
        this.setState({ selectedVcs: vcsId, repos, isLoading: false });
    }

    onChangeRepo = async (e) => {

        const repo = e.target.value;

        if (_.isEmpty(repo)) {
            return;
        }
        this.setState({ isLoading: true });

        const { selectedVcs } = this.state;
        const { selectedResources } = this.props;


        const pullRequest = await this.requestWrapper.sendRequest(`${BACKEND_URL}/reverseLearning/pullRequest?version=1`, "POST",
            {
                repository: repo,
                vcsId: selectedVcs,
                resources: _.map(selectedResources, resource => ({ assetId: resource.id, assetType: resource.type }))
            });

        if (!pullRequest.ok) {
            this.setState({ isLoading: false, hasError: "Something went wrong!" });
            return;
        }

        const result = await pullRequest.json();
        this.setState({ isLoading: false, pullRequestUrl: result.url });
    }

    render() {
        const { isOpen, onClose, githubApps } = this.props;
        const { isLoading, hasError, repos, pullRequestUrl } = this.state;

        return <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={this.onSubmit}
            header="Open Pull Request"
            applyButton="Create"
            initialValues={{}}
            isEnabled={true}
            isLarge={true}
            isShowFooter={true}
            isShowSubmitButton={false}
        >
            {isLoading ? <Loading /> :
                _.isEmpty(githubApps) ? <Empty><a href="#" onClick={() => this.moveToGitHubPage()}>Click here to create GitHub integration</a></Empty> :
                    !_.isEmpty(hasError) ? <Oops>{hasError}</Oops> :
                        <div className="card-body" style={{ maxHeight: '650px', overflowY: 'auto' }}>
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="vcs">VCS</label>
                                <Field
                                    className={"form-control"}
                                    name="vcs"
                                    id="vcs"
                                    component="select"
                                    onChange={this.onChangeVcs}
                                >
                                    <option value="">Please choose a VCS..</option>
                                    {_.map(githubApps || [], app => <option value={app.id} key={app.id}>{app.name}</option>)}
                                </Field>
                            </div>
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="repo">Repository</label>
                                <Field
                                    className={"form-control"}
                                    name="repo"
                                    id="repo"
                                    component="select"
                                    disabled={_.isEmpty(repos)}
                                    onChange={this.onChangeRepo}
                                >
                                    <option value="">Please choose a Repo..</option>
                                    {_.map(repos || [], repo => <option value={repo.fullName} key={repo.fullName}>{repo.fullName}</option>)}
                                </Field>
                            </div>

                            {!_.isEmpty(pullRequestUrl) && <a href={pullRequestUrl} target="_blank">Move to Pull Request</a>}
                        </div>
            }

        </BasicModal>
    }
}

const mapStateToProps = (state) => {
    return {
        activeFeatures: state.activeFeaturesReducer.activeFeatures || [],
        githubApps: state.vcsReducer.githubApps,
    };
};

const mapDispatchToProps = {
    sendMetric,
    getAllGithubApps
};

PullRequest = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(PullRequest)
export default withRouter(PullRequest);