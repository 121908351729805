import { GET_ALL_PROFILES, GET_CURRENT_PROFILE, CREATE_USER, DELETE_USER } from '../actions/profilesActions';

const initialState = {
    profiles: [],
    current: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_ALL_PROFILES:
            return { ...state, profiles: payload.profiles };

        case GET_CURRENT_PROFILE:
            return { ...state, current: payload.current };

        case CREATE_USER:
            return { ...state };

        case DELETE_USER:
            return { ...state };

        default:
            return state;
    }
}
