import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from '../../consts/config';
import _ from "lodash";

export const GET_DRIFTS = "GET_DRIFTS";
export const UPDATE_RESOURCE_DRIFTS = "UPDATE_RESOURCE_DRIFTS";
export const UPDATE_CONFIGURATION_DRIFTS = "UPDATE_CONFIGURATION_DRIFTS";
export const CHANGE_SELECTED_CATEGORY = "CHANGE_SELECTED_CATEGORY";
export const CHANGE_FILTER = "CHANGE_FILTER";
export const SELECT_DRIFT = "SELECT_DRIFT";

const getDrifts = (onlyDelta = false) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/drifts?onlyDelta=${onlyDelta}`, "GET");
        const data = await req.json();

        dispatch({
            type: GET_DRIFTS,
            payload: { data }
        });
    });
}

const getDrift = (id) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/drifts/${id}`, "GET");
        if (!req.ok) {
            return { ok: false, drift: null }
        }

        const drift = await req.json();
        return { ok: true, drift }
    });
}

const updateResourceDrifts = (newDrifts, resolvedDrifts, drifts) => {
    return action(async dispatch => {
        dispatch({
            type: UPDATE_RESOURCE_DRIFTS,
            payload: { new: newDrifts, resolved: resolvedDrifts, drifts }
        });
    });
}

const updateConfigurationDrifts = (newDrifts, resolvedDrifts, drifts) => {
    return action(async dispatch => {
        dispatch({
            type: UPDATE_CONFIGURATION_DRIFTS,
            payload: { new: newDrifts, resolved: resolvedDrifts, drifts }
        });
    });
}

const changeSelectedCategory = (category) => {
    return action(async dispatch => {
        dispatch({
            type: CHANGE_SELECTED_CATEGORY,
            payload: { category }
        });
    });
}

const changeFilter = (filterQuery) => {
    return action(async dispatch => {
        dispatch({
            type: CHANGE_FILTER,
            payload: { filterQuery }
        });
    });
}

const selectDrift = (selectedDrift) => {
    return action(async dispatch => {
        dispatch({
            type: SELECT_DRIFT,
            payload: { selectedDrift }
        });
    });
}

export {
    getDrifts,
    getDrift,
    updateResourceDrifts,
    updateConfigurationDrifts,
    changeSelectedCategory,
    changeFilter,
    selectDrift
}
