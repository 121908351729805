import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field, isValid } from "redux-form";
import Loading from '../../../shared/loading';
import { CardBody, Card, CardHeader } from "../../../shared/layouts/cards";
import { InputGroup, FormGroup } from "../../../shared/form/form";
import { testTeamsIntegration, createTeamsIntegration } from "../../../redux/actions/integrationsActions";

const required = (value) =>
    value || typeof value === "number" ? undefined : "Required";

class TeamsIntegration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isValid: null,
            webhookUrl: null,
            errorMessage: null,
            isCreated: false
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: false });
    }

    onChangeWebhook = async (webhookUrl) => {
        const isValidUrl = /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(webhookUrl);
        if (!isValidUrl) {
            console.error("Invalid webhook URL: ", webhookUrl);
            return;
        }

        this.setState({ isLoading: true });
        const isValid = await this.props.testTeamsIntegration(webhookUrl);
        this.setState({ isValid, isLoading: false, webhookUrl, errorMessage: isValid ? null : "Teams Webhook URL is not valid" });
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });

        const { webhookUrl } = this.state;

        const isValid = await this.props.createTeamsIntegration(webhookUrl);
        this.setState({ isLoading: false, isValid, errorMessage: isValid ? null : "Error occurred while creating teams integration", isCreated: isValid });
    };


    render() {
        const { isLoading, isValid, isCreated, errorMessage } = this.state;

        return (
            <>
                <Card>
                    <CardBody>
                        <CardHeader>Microsoft Teams Integration</CardHeader>
                        {/* <TeamsIntegrationWizard /> */}
                        <h5>To use InfraLight with Microsoft Teams, you’ll need to generate a <a target="_blank" href="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"> WebHook URL</a>.</h5>
                        <FormGroup>
                            <InputGroup hasMarginBottom={false}>
                                <Field
                                    className="form-control"
                                    name="webhookUrl"
                                    id="webhookUrl"
                                    component="input"
                                    onChange={(e) => this.onChangeWebhook(e.target.value)}
                                    placeholder="Put Teams Webhook URL"
                                    validate={[required]}
                                />
                            </InputGroup>

                            {isValid === false ? <InputGroup hasMarginBottom={false}><div className="text-medium text-danger m-0 font-weight-bold">{errorMessage}</div></InputGroup> : null}
                            {isCreated === true ? <InputGroup hasMarginBottom={false}><div className="text-medium text-success m-0 font-weight-bold">Created Successfully!</div></InputGroup> : null}
                        </FormGroup>

                        <FormGroup>
                            <InputGroup>
                                <div className="input-group-prepend">
                                    <a
                                        className="input-group-text"
                                        disabled={isLoading}
                                        href="#"
                                    >
                                        <i className={isLoading ? "i-Repeat-3 spin" : isValid ? "i-Yes" : "i-Repeat-3"}></i>
                                    </a>
                                </div>
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    onClick={(event) => this.onSubmit(event)}
                                    disabled={!isValid || isLoading}
                                >
                                    Save
                                 </button>
                            </InputGroup>
                        </FormGroup>
                    </CardBody>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    testTeamsIntegration,
    createTeamsIntegration
}

TeamsIntegration = reduxForm({
    form: "teams-integration",
})(TeamsIntegration);

TeamsIntegration = compose(connect(mapStateToProps, mapDispatchToProps))(TeamsIntegration);

export default withRouter(TeamsIntegration);