import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field } from 'redux-form';
import './createStack.scss';
import cloudFormation  from '../../../../public/images/providersIcons/cloudFormation.svg'
import terraform  from '../../../../public/images/providersIcons/terraform.svg'
import pulumi  from '../../../../public/images/providersIcons/pulumi.svg'
import helm  from '../../../../public/images/providersIcons/helm.svg'


export class SelectIacProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  onChangeStackProvider = (value) => {
    this.props.handleSelectResourceUpdate("stackProvider", value)
    this.setState({ selected: true })
  }

  render() {

    return (
      <div className="CreateStack">
        <div>Choose Iac Provider </div>
          <div className="form-group CreateStack__form-radioTile">
            <div className={`card selectIacProvider ${this.state.selected && "active"}`} onClick={() => this.onChangeStackProvider("Terraform")}>
              <img src={terraform} alt="terraform"/>
              <span>Terraform</span>
            </div>
            <div className={`card selectIacProvider disabled`}>
              <img src={cloudFormation} alt="Cloud Formation"/>
              <span>Cloud Formation (soon)</span>
            </div>
            <div className={`card selectIacProvider disabled`}>
              <img src={pulumi} alt="Pulumi"/>
              <span>Pulumi (soon)</span>
            </div>
            <div className={`card selectIacProvider disabled`}>
              <img src={helm} alt="Helm"/>
              <span>Helm (soon)</span>
            </div>
          </div>
      </div>
    );
  }
}


SelectIacProvider = compose(
)(SelectIacProvider);

export default withRouter(SelectIacProvider);
