import React from 'react';
import _ from "lodash";
class InputGroup extends React.Component{
    render(){
        const hasMarginBottom = _.isUndefined(this.props.hasMarginBottom) ? true : this.props.hasMarginBottom;
        return <div className={`input-group ${hasMarginBottom?"mb-3":""} col-sm-12 col-md-8 col-lg-10`}>
            {this.props.children}
        </div>
    }
}

class FormGroup extends React.Component{
    render(){
        return <div className="form-group row">
            {this.props.children}
        </div>
    }
}

export {
    InputGroup,
    FormGroup
}