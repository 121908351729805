import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BasicModal from '../../shared/basicModal';
import { Field } from 'redux-form';
import { createEnvironment, getEnvironments } from '../../redux/actions/environmentActions';
import { getAllProfiles } from "../../redux/actions/profilesActions";
import { Warning } from '../../shared/alerts';
import Loading from '../../shared/loading';
import RequestWrapper from '../../utils/requestWrapper';
import './createEnvironment.scss';


class CreateEnvironment extends React.Component {
    constructor(props) {
        super(props);

        this.requestWrapper = new RequestWrapper();

        this.state = {
            isOpen: false,
            isLoading: true,
            isNameValid: false,
            isVcsValid: false,
            isRepoValid: false,
            profiles: null,
            userOwnerSelect: null
        };
    }

    async componentDidMount() {

        await Promise.all([
            this.props.getAllProfiles(),
        ])

        const { profiles } = this.props;
        this.setState({ isLoading: false, profiles });
    }

    onSubmit = async (submittedInfo) => {
        const res = await this.props.createEnvironment(submittedInfo.name, submittedInfo.owner);
        if (res.ok) {
            await this.props.getEnvironments();
        }
        this.props.onCreate(res.environment.id);
    }

    onChangeName = async (e) => {
        await this.props.getEnvironments();
        if (_.includes(_.map(this.props.environments, (environment) => environment.name), e.target.value)) {
            this.setState({isNameAlreadyUsed: true, isNameValid: false});
            return
        }
        const isNameValid = !_.isEmpty(e.target.value);
        this.setState({ isNameValid, isNameAlreadyUsed: false });
    }

    onChangeOwner = (e) => {
        this.setState({ userOwnerSelect: e.target.value });
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { isLoading, githubApps, repos, isNameValid, profiles } = this.state;

        return (

            <BasicModal
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={this.onSubmit}
                header="Create Environment"
                applyButton="Create"
                initialValues={{ path: '/', branch: 'master' }}
                isEnabled={ isNameValid }
            >
                {isLoading ? <Loading /> :
                    <div className="CreateEnvironment">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="name">Name:</label>
                            <Field
                                className={"form-control"}
                                name="name"
                                id="name"
                                component="input"
                                type="text"
                                placeholder="Please fill the environment name"
                                onChange={this.onChangeName}
                            />
                            {!_.isUndefined(this.state.isNameAlreadyUsed) && this.state.isNameAlreadyUsed === true ?
                                <Warning closeEnabled={false}>Environment name is already used. Pleas choose a different name.</Warning> : null
                            }
                        </div>

                        <div className="form-group">
                            <label className="col-form-label" htmlFor="owner">Owner</label>
                            <Field
                                className={"form-control"}
                                name="owner"
                                id="owner"
                                component="select"
                                onChange={this.onChangeOwner}
                            >
                                <option value="">Please choose Owner</option>
                                {_.map(profiles || [],
                                    profile => <option value={profile.id} key={profile.id}>
                                        {profile.name}
                                    </option>
                                )}
                            </Field>
                        </div>

                    </div>}
            </BasicModal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        environments: state.environmentReducer.data,
        profiles: state.profilesReducer.profiles,
    };
};

const mapDispatchToProps = {
    getAllProfiles,
    createEnvironment,
    getEnvironments
};


CreateEnvironment = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(CreateEnvironment);

export default withRouter(CreateEnvironment);
