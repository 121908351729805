import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import {GET_ALL_BUCKETS, GET_ALL_S3_OBJECTS} from "./integrationsActions";
import integrations from "../../components/alerts/integrations";

export const GET_GCP_INTEGRATIONS = "GET_GCP_INTEGRATIONS";
export const CREATE_GCP_INTEGRATION = "CREATE_GCP_INTEGRATION";
export const DELETE_GCP_INTEGRATION = "DELETE_GCP_INTEGRATION";
export const GET_ALL_GCS_BUCKETS = "GET_ALL_GCS_BUCKETS";
export const GET_ALL_GCS_OBJECTS = "GET_ALL_GCS_OBJECTS";

const getGcpIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp`,
        "GET"
    );

    const integrations = await integrationsReq.json();

    dispatch({
      type: GET_GCP_INTEGRATIONS,
      payload: { integrations },
    });
  });
};

const createGcpIntegration = (projectId, serviceAccountKey) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    let created = true;
    let message = `Google Cloud integration created success for project ${projectId}`;

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/`,
        "POST",
        {
          projectId,
          serviceAccountKey
        }
    );

    if (res.status === 409) {
      created = false
      message = `project ${projectId} already exist in your account`;
    } else if (res.status === 500) {
      created = false
      message = "failed to create Google Cloud integration please contact infralight support"
    }

    dispatch({
      type: CREATE_GCP_INTEGRATION,
      payload: {
        created: created,
        message: message
      },
    });

    return {
      created: created,
      message: message
    };
  });
};

const deleteGcpIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/${id}`,
        "DELETE"
    );

    dispatch({
      type: DELETE_GCP_INTEGRATION,
      payload: {},
    });

    return {};
  });
};

const getAllGcsBuckets = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/${integration}/buckets`,
        "GET"
    );
    dispatch({
      type: GET_ALL_GCS_BUCKETS,
      payload: {},
    });

    if (!req.ok) {
      return { ok: false, buckets: null };
    }

    const buckets = await req.json();
    return { ok: true, buckets };
  });
};

const getAllGcsObjects = (integration, bucket) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/${integration}/bucket/${bucket}/objects`,
        "GET"
    );

    dispatch({
      type: GET_ALL_GCS_OBJECTS,
      payload: {},
    });

    let objects = null;
    if(res.ok){
      objects = await res.json();
    }
    return {objects, ok: res.ok, status: res.status};
  });
};

export { getGcpIntegrations, createGcpIntegration, deleteGcpIntegration, getAllGcsBuckets, getAllGcsObjects };
