import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import { CopyToClipboard as CopyToClipboardComponent } from "react-copy-to-clipboard";

class BasicModal extends React.Component {

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            copied: false
        };
    }

    async componentDidMount() {
        this.setState({ copied: false })
    }

    onCopy = () => {
        this.setState({ copied: true });
      };

    render() {
        const { isOpen, onClose, style, header, applyButton, children, handleSubmit, copyToClipboardText } = this.props;
        const isEnabled = _.isUndefined(this.props.isEnabled) ? true : this.props.isEnabled;

        const formClassName = _.isEmpty(this.props.formClassName) ? "form" : this.props.formClassName;
        //@This flag is deprecated - should be deleted
        const isLarge = _.isUndefined(this.props.isLarge) ? false : this.props.isLarge;

        const isShowFooter = _.isUndefined(this.props.isShowFooter) ? true : this.props.isShowFooter;
        const isShowSubmitButton = _.isUndefined(this.props.isShowSubmitButton) ? true : this.props.isShowSubmitButton;

        let modalSize = "";
        if(isLarge){
            modalSize = "modal-lg";
        }else if(!_.isEmpty(this.props.modalSize)){
            modalSize = this.props.modalSize;
        }

        const customStyles = {
            overlay: {zIndex: 100},
          };

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                style={{...style, ...customStyles}}
            >
                <form className={formClassName} onSubmit={handleSubmit}>
                    <div className="modal fade show" id="verifyModalContent" tabIndex="-1" role="dialog" aria-labelledby="verifyModalContent" style={{ display: 'block' }}>
                        <div className={`modal-dialog ${modalSize}`} role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="verifyModalContent_title">{header}</h5>
                                    <button className="close" type="button" data-dismiss="modal" onClick={() => onClose()}><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body" style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                    {children}
                                </div>
                                {
                                    !isShowFooter ? null :
                                        <div className="modal-footer">

                                        { !_.isUndefined(copyToClipboardText) && !this.state.copied && 
                                            <CopyToClipboardComponent text={copyToClipboardText} onCopy={this.onCopy}>
                                                <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={(() => {return false})}>Copy To Clipbord</button>
                                            </CopyToClipboardComponent>
                                        }

                                        {this.state.copied && <div className="copied">Copied!</div>}
                                            
                                            <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => onClose()}>Close</button>
                                            {
                                                !isShowSubmitButton ? null :
                                                    <input type="submit" value={applyButton} className="btn btn-primary" disabled={!isEnabled} />
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </form>
            </Modal>

        );
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};


BasicModal = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(BasicModal);

BasicModal = reduxForm({
    form: 'form',
})(BasicModal);

Modal.setAppElement('body');

export default withRouter(BasicModal);
