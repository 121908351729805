import {
  GET_GCP_INTEGRATIONS,
  CREATE_GCP_INTEGRATION,
  DELETE_GCP_INTEGRATION,
} from "../actions/gcpIntegrationActions";

const initialState = {
  integrations: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_GCP_INTEGRATIONS:
      return { ...state, integrations: payload.integrations };

    case CREATE_GCP_INTEGRATION:
      return { ...state };

    case DELETE_GCP_INTEGRATION:
      return { ...state };

    default:
      return state;
  }
}
