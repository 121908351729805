import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getByStack, deleteById } from '../../../../redux/actions/variableActions';
import Loading from '../../../../shared/loading';
import CreateVariable from './createVariable';
import { TimeParser } from '../../../../shared/parsers';
import CopyToClipboard from '../../../../shared/copyToClipboard';

class Vars extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            variables: null,
            isLoading: true,
            creationModalOpen: false
        };
    }

    async componentDidMount() {

        this.setState({isLoading: true});
        await this.refreshVariables();
        this.setState({isLoading: false});
    }

    async refreshVariables() {
        this.setState({isLoading: true});

        const {stack} = this.props;
        await this.props.getByStack(stack.id);

        this.setState({ variables: this.props.variables, isLoading: false });
    }

    removeVariable = async (id) => {
        this.setState({isLoading: true});

        const { variables } = this.state;
        await this.props.deleteById(id);

        this.setState({ variables: _.reject(variables, { id }) });
        this.setState({isLoading: false});
    }

    closeCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    openCreationModal = () => {
        this.setState({ creationModalOpen: true });
    };

    createVariable = async () => {
        this.setState({ isLoading: true, creationModalOpen: false });
        await this.refreshVariables();
        this.setState({isLoading: false});
    }

    render() {
        const { variables, isLoading, creationModalOpen } = this.state;
        const {stack} = this.props;

        if (isLoading) {
            return (<Loading />);
        }

        return (
            <>
                <div className="mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <h4 className="card-title mb-3">Workspace variables</h4>
                            <p>These are the workspace variables managed by InfraLight.</p>

                            <div className="row">
                                <div className="col-md-10"></div>
                                <div className="col-md-2">
                                    <button className="btn btn-success btn-block mb-3" onClick={this.openCreationModal} id="alert-success" type="button">Add Variable</button>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Key</th>
                                            <th scope="col">Value</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(variables, variable =>
                                            <tr key={variable.id}>
                                                <td>{variable.key}</td>
                                                {variable.encrypted ?
                                                    <td><span className="badge badge-dark">encrypted</span></td> :
                                                    <td><CopyToClipboard text={variable.value} /></td>
                                                }
                                                <td>{variable.description}</td>
                                                <td>{<TimeParser value={variable.createdAt} />}</td>
                                                <td ><a className="text-danger mr-2 alert-confirm" href="#" onClick={() => this.removeVariable(variable.id)}><i className="nav-icon i-Close-Window font-weight-bold"></i></a></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateVariable stackId={stack.id} isOpen={creationModalOpen} onClose={this.closeCreationModal} onCreate={() => { this.createVariable() }} />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        variables: state.variableReducer.data
    };
};

const mapDispatchToProps = {
    getByStack,
    deleteById
};


Vars = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Vars);
export default withRouter(Vars);