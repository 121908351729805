import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getAllKeyPairs,
  createKeyPair,
} from "../../../redux/actions/keyPairsActions";
import { createK8sIntegration, getK8sIntegrations } from "../../../redux/actions/k8sIntegrationActions";
import {
  Wizard,
  WizardMenu,
  WizardMenuItem,
  WizardContainer,
  WizardPage,
  WizardBottom,
} from "../../../shared/smartWizard/wizard";
import Loading from "../../../shared/loading";
import PageLayout from "../../../shared/layouts/pageLayout";
import { CardBody, Card, CardHeader } from "../../../shared/layouts/cards";
import CopyToClipboard from "../../../shared/copyToClipboard";
import { BACKEND_URL } from "../../../consts/config";

class K8sOnboardingWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      step: 1,
      existingKeyPairs: null,
      createNewKeyPair: false,
      clusterId: "default",
      schedule: "*/15 * * * *",
      accessKey: "",
      secretKey: "",
      keyPairError: false,
      integError: false,
      collectSecrets: false,
    };
  }

  handleKeyPairChoice = (e) => {
    this.setState({
      createNewKeyPair: e.target.value === "create",
    });
  };

  handleKeyPairSelection = (e) => {
    this.setState({
      accessKey: e.target.value,
    });
  };

  handleClusterIdChange = (e) => {
    this.setState({
      clusterId: e.target.value,
    });
  };

  handleScheduleChange = (e) => {
    this.setState({
      schedule: e.target.value,
    });
  };

  handleSecretsChange = (e) => {
    this.setState({
      collectSecrets: e.target.checked,
    });
  };

  createKeyPair = async () => {
    this.setState({ isLoading: true });
    try {
      const keypair = await this.props.createKeyPair();
      this.setState({
        isLoading: false,
        accessKey: keypair.accessKey,
        secretKey: keypair.secretKey,
      });
    } catch (ex) {
      this.setState({
        isLoading: false,
        keyPairError: true,
      });
    }
  };

  createIntegration = async () => {
    this.setState({ isLoading: true });
    try {
      await this.props.createK8sIntegration(
        this.state.clusterId,
        this.state.accessKey
      );
      this.setState({ isLoading: false });
    } catch (ex) {
      this.setState({
        isLoading: false,
        integError: true,
      });
    }

    await this.props.getK8sIntegrations();
  };

  isNextEnabled = () => {
    return (
      this.state.step < 4 &&
      !this.state.isLoading &&
      !(
        this.state.step === 2 &&
        !this.state.createNewKeyPair &&
        !this.state.accessKey
      ) &&
      !(this.state.step === 3 && !this.state.clusterId && !this.state.schedule)
    );
  };

  isPrevEnabled = () => {
    if (this.state.isLoading) return false;
    return this.state.step > 1 && this.state.step < 4;
  };

  nextStep = async () => {
    if (!this.isNextEnabled()) {
      return;
    }

    if (this.state.step === 1) {
      this.setState({ isLoading: true });
      const keyPairs = await this.props.getAllKeyPairs();
      this.setState({
        isLoading: false,
        existingKeyPairs: keyPairs,
      });
    } else if (this.state.step === 2 && this.state.createNewKeyPair) {
      await this.createKeyPair();
    } else if (this.state.step === 3) {
      await this.createIntegration();
    }

    this.setState({ step: this.state.step + 1 });
  };

  prevStep = () => {
    if (!this.isPrevEnabled()) {
      return;
    }

    this.setState({ step: this.state.step - 1 });
  };

  cancel = () => {
    this.setState({ step: 1 });
  };

  complete = async () => {
    this.setState({ step: 3 });
  };

  render() {
    const {
      step,
      isLoading,
      createNewKeyPair,
      existingKeyPairs,
      keyPairError,
      integError,
      accessKey,
      secretKey,
      clusterId,
      schedule,
      collectSecrets,
    } = this.state;

    return (
      <PageLayout>
        <Card>
          <CardBody>
            <CardHeader>Kubernetes Onboarding</CardHeader>
            <Wizard>
              <WizardMenu>
                <WizardMenuItem
                  isActive={step == 1}
                  header="Overview"
                  description="Overview and requirements for the Infralight Kubernetes Collector"
                />
                <WizardMenuItem
                  isActive={step == 2}
                  header="Access Keypair"
                  description="Create an access keypair for the Infralight API"
                />
                <WizardMenuItem
                  isActive={step == 3}
                  header="Configuration"
                  description="Modify default configuration values"
                />
                <WizardMenuItem
                  isActive={step == 4}
                  header="Final Instructions"
                />
              </WizardMenu>

              <WizardContainer>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <WizardPage id="step1" isActive={step === 1}>
                      <p>
                        Infralight's Kubernetes Integration relies on the
                        installation of our Kubernetes Collector to target
                        clusters. Once installed, Infralight will be able to
                        analyze clusters for drifts.
                      </p>
                      <p>
                        The collector is distributed as a{" "}
                        <a
                          href="https://helm.sh/"
                          target="_blank"
                          rel="external"
                        >
                          Helm Chart
                        </a>
                        , and must be installed once per-cluster. This wizard
                        will assist you in the onboarding of the collector on a
                        cluster.
                      </p>
                      <p>
                        The Infralight Kubernetes Collector is compliant with{" "}
                        <a
                          href="https://kubernetes.io/"
                          target="_blank"
                          rel="external"
                        >
                          Kubernetes v1.15+
                        </a>{" "}
                        and Helm v3.5+.
                      </p>
                    </WizardPage>

                    <WizardPage id="step2" isActive={step === 2}>
                      {keyPairError && (
                        <div className="alert-card alert-danger" role="alert">
                          <p>
                            Keypair creation failed, please try again or contact
                            Infralight for support.
                          </p>
                        </div>
                      )}
                      <div className="form-group">
                        <label htmlFor="select_keypair">
                          <input
                            type="radio"
                            id="select_keypair"
                            name="keypair_choice"
                            checked={!createNewKeyPair}
                            value="select"
                            onChange={this.handleKeyPairChoice}
                          />{" "}
                          Use existing keypair
                        </label>{" "}
                        <select
                          onChange={this.handleKeyPairSelection}
                          disabled={createNewKeyPair}
                        >
                          <option value="">Select Access Key</option>
                          {_.map(existingKeyPairs, (pair) => (
                            <option
                              key={pair.access_key}
                              value={pair.access_key}
                            >
                              {pair.access_key}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="create_keypair">
                          <input
                            type="radio"
                            id="create_keypair"
                            name="keypair_choice"
                            checked={createNewKeyPair}
                            value="create"
                            onChange={this.handleKeyPairChoice}
                          />{" "}
                          Create a new keypair
                        </label>
                      </div>
                    </WizardPage>

                    <WizardPage id="step3" isActive={step === 3}>
                      <div className="form-group">
                        <label htmlFor="cluster_id">Cluster ID</label>
                        <input
                          type="text"
                          id="cluster_id"
                          className="form-control"
                          onChange={this.handleClusterIdChange}
                          value={clusterId}
                          required
                        />
                        <p className="help-text">
                          Provide a unique identifier to the target cluster
                        </p>
                      </div>
                      <hr />
                      <div className="form-group">
                        <label htmlFor="schedule">Schedule</label>
                        <input
                          type="text"
                          id="schedule"
                          className="form-control"
                          onChange={this.handleScheduleChange}
                          value={schedule}
                          required
                        />
                        <p className="help-text">
                          Select the collector's execution schedule (cron
                          syntax)
                        </p>
                      </div>
                      <hr />
                      <div className="form-group">
                        <label htmlFor="collect_secrets">
                          <input
                            type="checkbox"
                            id="collect_secrets"
                            onChange={this.handleSecretsChange}
                            checked={collectSecrets}
                          />{" "}
                          Collect Secrets
                        </label>
                        <p>
                          By default, the collector will not analyze Kubernetes
                          Secrets for drifts.
                        </p>
                      </div>
                    </WizardPage>

                    <WizardPage id="step4" isActive={step === 4}>
                      {integError ? (
                        <div className="alert-card alert-danger" role="alert">
                          <p>
                            Integration creation failed, please try again or
                            contact Infralight for support.
                          </p>
                        </div>
                      ) : (
                        <>
                          <p>
                            The wizard is complete. To install the collector on
                            your Kubernetes Cluster, please execute the
                            following commands:
                          </p>
                          <ol>
                            <li>
                              Add the Infralight Kubernetes Collector Helm
                              repository:
                              <CopyToClipboard text="helm repo add infralight https://infralight.github.io/k8s-collector" />
                            </li>
                            <li>
                              Install the Infralight Kubernetes Collector:
                              <CopyToClipboard
                                text={
                                  "helm install infralight infralight/infralight-k8s-collector --set accessKey=" +
                                  accessKey +
                                  " --set secretKey=" +
                                  (secretKey || "<secret_key>") +
                                  ' --set apiEndpoint="' +
                                  BACKEND_URL +
                                  '" --set clusterId="' +
                                  clusterId +
                                  '" --set schedule="' +
                                  schedule +
                                  '" --set collectSecrets=' +
                                  collectSecrets
                                }
                              />
                            </li>
                          </ol>
                        </>
                      )}
                    </WizardPage>
                  </>
                )}
              </WizardContainer>

              <WizardBottom
                onNextStep={this.nextStep}
                onPrevStep={this.prevStep}
                onComplete={this.complete}
                onCancel={this.cancel}
                isPrevEnabled={this.isPrevEnabled()}
                isNextEnabled={this.isNextEnabled()}
                isCancelCompleteDisplay={false}
              />
            </Wizard>
          </CardBody>
        </Card>
      </PageLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    keyPairs: state.keyPairsReducer.keyPairs,
  };
};

const mapDispatchToProps = {
  getAllKeyPairs,
  createKeyPair,
  createK8sIntegration,
  getK8sIntegrations
};

K8sOnboardingWizard = compose(connect(mapStateToProps, mapDispatchToProps))(
  K8sOnboardingWizard
);
export default withRouter(K8sOnboardingWizard);
