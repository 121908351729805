import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../../shared/loading';
import { reduxForm } from 'redux-form';
import Dropzone from 'react-dropzone';
import ArrayBufferToString from 'arraybuffer-to-string';
import ReactJson from 'react-json-view'
import { upload } from '../../../../redux/actions/statesActions';

class ManualPolicy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isLoading: true,
            file: null,
            hasError: null
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: false, stackId: this.props.stack.id });
    }

    onSubmit = async () => {
        this.setState({ isLoading: true });
        const { file } = this.state;
        const res = await this.props.upload(this.props.stack.id, file.tfState);

        if (!_.isEmpty(res) || res.status >= 200 && res.status <= 299) {
            this.setState({ file: null });
            await this.props.onUpdatePolicy("manual", {});
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false, hasError: "An error occurred while trying to upload tfstate.", file: null });
    }

    onDrop = async (acceptedFiles) => {
        this.setState({ isLoading: true });
        const file = acceptedFiles[0];

        const reader = new FileReader()

        reader.onabort = () => this.setState({ hasError: 'file reading was aborted', file: null })
        reader.onerror = () => this.setState({ hasError: 'file reading has failed', file: null })
        reader.onload = () => {
            // Do whatever you want with the file contents
            try {
                const binaryStr = reader.result;
                file.tfState = ArrayBufferToString(binaryStr);
                file.tfStateJson = JSON.parse(file.tfState);
                this.setState({ hasError: null, file });
            } catch (ex) {
                this.setState({ hasError: `terraform state file is not valid!`, file: null });
            }

            this.setState({ isLoading: false });
        }

        reader.readAsArrayBuffer(file)
    }


    render() {
        const { file, isLoading, hasError } = this.state;

        return (
            isLoading ? <Loading /> :
                <>
                    {_.isEmpty(file) || _.isEmpty(file.tfState) ?
                        <>
                            <div className="dropzone dz-clickable col-sm-12">

                                <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div className="dz-default dz-message line-height-5">Drop Terraform state file here to upload</div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </> :
                        <>
                            <div className="dz-preview dz-file-preview dz-processing dz-error dz-complete">
                                <div className="dz-image"><img data-dz-thumbnail="" /></div>
                                <div className="dz-details">
                                    <div className="dz-size">
                                        <span data-dz-size=""><strong>{file.size / 1024}</strong> KB</span></div>
                                    <div className="dz-filename"><span data-dz-name="">{file.name}</span></div>
                                </div>

                                <div className="dz-progress">
                                    <span className="dz-upload" data-dz-uploadprogress=""></span>
                                </div>
                            </div>
                            {!_.isEmpty(file.tfStateJson) ? <div className="col-sm-12"><ReactJson src={file.tfStateJson} /> </div> : null}
                        </>
                    }
                    {!_.isEmpty(hasError) ? <div className="text-14 font-weight-bold text-danger mt-2">{hasError}</div> : null}

                    <div className="col-sm-12 mt-3">
                        <button className="btn btn-primary" type="submit" disabled={_.isEmpty(file)} onClick={() => this.onSubmit()}>Upload</button>
                    </div>
                </>
        )
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    upload
};


ManualPolicy = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(ManualPolicy);

ManualPolicy = reduxForm({
    form: 'manual-state-policy',
})(ManualPolicy);

export default withRouter(ManualPolicy);