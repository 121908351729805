import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../../../shared/loading';
import {
    getGcpIntegrations,
    getAllGcsBuckets,
    getAllGcsObjects,
} from '../../../../../redux/actions/gcpIntegrationActions';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';
import Select from 'react-select'


export class GCSPolicy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            integrations: [],
            selectedIntegrationId: null,
            isIntegrationValid: null,
            integrationError: false,

            buckets: [],
            selectedBucket: null,
            isBucketValid: null,
            bucketStatusCode: null,

            bucketObjects: null,

            selectedGcsKey: null,
            isGcsKeyValid: null
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        await this.props.getGcpIntegrations();

        this.setState({ integrations: this.props.gcpIntegrations });
        this.setState({ isLoading: false });
    }

    onChangeIntegration = async (integrationId) => {
        this.setState({ isLoading: true });
        const res = await this.props.getAllGcsBuckets(integrationId);

        this.props.handleSelectResourceUpdate("gcsPolicyIntegrationId", integrationId);

        this.setState({ isLoading: false, buckets: res.buckets, isIntegrationValid: res.ok, integrationError: res.ok, selectedIntegrationId: integrationId });
    }

    onChangeBucket = async (bucket) => {
        const { selectedIntegrationId } = this.state;
        this.setState({ isLoading: true, selectedGcsKey: null, isGcsKeyValid: null });

        const res = await this.props.getAllGcsObjects(selectedIntegrationId, bucket);
        const tfStateFiles = _.filter(res.objects, obj => obj.endsWith(".tfstate"));

        const bucketObjects = _.map(tfStateFiles, (stateFile) => { return { value: stateFile, label: stateFile }})

        this.props.handleSelectResourceUpdate("gcsPolicyBucket", bucket);

        this.setState({ isLoading: false, selectedBucket: bucket, isBucketValid: res.ok, bucketStatusCode: res.status, bucketObjects: bucketObjects });
    }

    onChangeGCSKeySelection = async (keys) => {
        this.props.handleSelectResourceUpdate("gcsPolicyKeys", keys);
        this.setState({ selectedGcsKey: keys, isGcsKeyValid: !_.isEmpty(keys) });
    }

    render() {
        const {
            isLoading,
            integrations,
            buckets,
            selectedIntegrationId,
            isIntegrationValid,
            isBucketValid,
            bucketStatusCode,
            selectedBucket,
            bucketObjects,
        } = this.state;

        const selectedIntegration = (_.first(integrations, integ => integ.id === selectedIntegrationId) || {}).id || "";
        const permissionPolicy = "https://cloud.google.com/storage/docs/access-control/iam-permissions";

        return (
            isLoading ? <Loading /> :
                <>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="gcpIntegration">GCP Integration</label>
                        <div className="col-sm-10">
                            <Field
                                className={"form-control"}
                                name="gcpIntegration"
                                id="gcpIntegration"
                                component="select"
                                onChange={(e) => this.onChangeIntegration(e.target.value)}
                            >
                                <option value="">Please Choose GCP Integration..</option>
                                {_.map(integrations, integ => <option value={integ.id} key={integ.id}>{integ.name}</option>)}
                            </Field>
                            {isIntegrationValid === false ? <div className="text-14 font-weight-bold text-danger">integration is not valid</div> : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="s3Bucket">GCP GCS Bucket</label>
                        <div className="input-group mb-3 col-sm-10">
                            <div className="input-group-prepend"><span className="input-group-text" id="reload">
                                {isBucketValid == false ?
                                    <a href="#" onClick={() => this.onChangeBucket(selectedBucket)}><i className="i-Repeat-3"></i></a> :
                                    <i className="i-Repeat-3"></i>
                                }
                            </span>
                            </div>
                            <Field
                                className={"form-control"}
                                name="s3Bucket"
                                id="s3Bucket"
                                component="select"
                                disabled={isIntegrationValid !== true}
                                onChange={(e) => this.onChangeBucket(e.target.value)}
                                aria-describedby="reload"
                            >
                                <option value="">Please Choose GCS Bucket..</option>
                                {_.map(buckets, bucket => <option value={bucket} key={bucket}>{bucket}</option>)}
                            </Field>
                        </div>
                        {isBucketValid == false ?
                            bucketStatusCode == 403 ?
                                <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Permission Denied<br /><a target="_blank" href={permissionPolicy}>Click here to grant permission</a></div></> :
                                <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Bucket integration is not valid</div></> : null}
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="gcpIntegration">GCP Terraform state (.tfstate file)</label>
                        <div className="col-sm-10">

                            <Select     isMulti
                                        name="colors"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(e) => this.onChangeGCSKeySelection(e)}
                                        options={bucketObjects} />
                        </div>
                    </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        gcpIntegrations: state.gcpIntegrationReducer.integrations,
    };
};

const mapDispatchToProps = {
    getGcpIntegrations,
    getAllGcsBuckets,
    getAllGcsObjects
};

GCSPolicy = reduxForm({
    form: 'gcs-state-policy'
})(GCSPolicy);

GCSPolicy = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(GCSPolicy);

export default withRouter(GCSPolicy);