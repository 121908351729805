import React from 'react';
import _ from 'lodash';

class Accordion extends React.Component {

    async componentDidMount(){
    }

    render() {
        const { header, children, id, onOpen, className } = this.props;
        const parentId = _.isUndefined(this.props.parentId) ? "accordionRightIcon": this.props.parentId;
        
        const defaultOpen = this.props.defaultOpen || false;
        return (
            <div className={`card ${_.isEmpty(className)? "" : className}`}>
                <div className="card-header header-elements-inline" onClick={onOpen}>
                    <h6 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                        <a className="text-default collapsed" data-toggle="collapse" href={`#${id}`}>{header}</a></h6>
                </div>
                <div className={defaultOpen ? null : "collapse"} id={id} data-parent={`#${parentId}`}>
                    <div className="card-body">
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

export default Accordion;