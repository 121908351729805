import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { createStackByEnvironmentId, getStacksByEnvironmentId } from '../../../../redux/actions/stackActions';
import { getAllGithubApps } from '../../../../redux/actions/vcsActions';
import { getAwsIntegrations } from '../../../../redux/actions/integrationsActions';
import { getAllProfiles } from "../../../../redux/actions/profilesActions";
import { updateIacSettings } from "../../../../redux/actions/statesActions";
import {Success, Warning} from '../../../../shared/alerts';
import RequestWrapper from '../../../../utils/requestWrapper';
import './createStack.scss';

export class SelectNamesAndSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.requestWrapper = new RequestWrapper();

    this.state = {
      environmentId: this.props.environmentId,
      wizardSharedData: this.props.wizardSharedData,
      isOpen: false,
      githubApps: null,
      repos: null,
      isNameValid: false,
      isVcsValid: false,
      isRepoValid: false,
      isTerraformVersionValid: false,
      isAwsIntegrationValid: false,
      awsIntegrations: null,
      profiles: null,
      userSelectOrcastrator: false,
      userOwnerSelect: null,
      stackName: ""
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    let {wizardSharedData, stackName} = this.state

    let vcs = null;
    if (wizardSharedData.stackSource.sourceType === "orchestrator") {
      vcs = {
        vcsId: wizardSharedData.stackSource.orchestratorConfig.vcs,
        repo: wizardSharedData.stackSource.orchestratorConfig.repo,
        branch: wizardSharedData.stackSource.orchestratorConfig.branch,
        path: wizardSharedData.stackSource.orchestratorConfig.path
      }
    }
    const orchestratorConfig = wizardSharedData.stackSource.orchestratorConfig

    if (wizardSharedData.selectedPolicy === "" && wizardSharedData.stackSource.sourceType === "orchestrator") {
      // create the stack itself
      if (_.isEmpty(stackName)) {
        stackName = `orchestrator stack`
      }
      const res = await this.props.createStackByEnvironmentId(this.state.environmentId, stackName, vcs,
        orchestratorConfig.awsIntegration, orchestratorConfig.terraformVersion, orchestratorConfig.isTerragrunt);

      this.setState({createdSuccessfully: res.ok})
    }

    if (wizardSharedData.selectedPolicy === "s3") {
      _.forEach(wizardSharedData["s3Policy"]["keys"], async (key) => {
        // create the stack itself
        const res = await this.props.createStackByEnvironmentId(this.state.environmentId, key.label, vcs,
          orchestratorConfig.awsIntegration, orchestratorConfig.terraformVersion, orchestratorConfig.isTerragrunt);

        this.setState({createdSuccessfully: res.ok})
        if (res.ok) {
          // use the stack to create iac settings
          const stackId = res.stack.id
          await this.updatePolicy(stackId, "s3", {
            awsIntegration: wizardSharedData.s3Policy.integrationId, s3Bucket:
            wizardSharedData.s3Policy.bucket, s3Key: key.value
          });
        }
      })
    }

    if (wizardSharedData.selectedPolicy === "gcs") {
      _.forEach(wizardSharedData["gcsPolicy"]["keys"], async (key) => {
        // create the stack itself
        console.log(key);
        const res = await this.props.createStackByEnvironmentId(
            this.state.environmentId,
            key.label,
            vcs,
            null,
            orchestratorConfig.terraformVersion,
            orchestratorConfig.isTerragrunt
        );

        this.setState({createdSuccessfully: res.ok})
        if (res.ok) {
          // use the stack to create iac settings
          const stackId = res.stack.id
          await this.updatePolicy(stackId, "gcs", {
            gcpIntegration: wizardSharedData.gcsPolicy.integrationId,
            gcsBucket: wizardSharedData.gcsPolicy.bucket,
            gcsKey: key.value
          });
        }
      })
    }

    if (wizardSharedData.selectedPolicy === "tfc") {
        // create the stack itself
      const res = await this.props.createStackByEnvironmentId(
          this.state.environmentId,
          wizardSharedData.tfcPolicy.workspace,
          vcs,
          null,
          orchestratorConfig.terraformVersion,
          orchestratorConfig.isTerragrunt
      );

      this.setState({createdSuccessfully: res.ok})
      if (res.ok) {
        // use the stack to create iac settings
        const stackId = res.stack.id
        await this.updatePolicy(stackId, "tfc", {
          tfcIntegration: wizardSharedData.tfcPolicy.integrationId,
          tfcWorkspace: wizardSharedData.tfcPolicy.workspace,
          tfcOrganization: wizardSharedData.tfcPolicy.organization
        });
      }
    }

    if (wizardSharedData.selectedPolicy === "skip") {
      // create the stack itself
      if (_.isEmpty(stackName)) {
        stackName = `${wizardSharedData.selectedPolicy} stack`
      }
      const res = await this.props.createStackByEnvironmentId(this.state.environmentId, stackName, vcs,
        orchestratorConfig.awsIntegration, orchestratorConfig.terraformVersion, orchestratorConfig.isTerragrunt);

      this.setState({createdSuccessfully: res.ok})
    }
  }

  updatePolicy = async (stackId, selected, policy) => {
      await this.props.updateIacSettings(
          stackId,
          selected,
          policy
      );
  }

  onChangeS3StackName = async (e) => {
    const { wizardSharedData } = this.state
    const keyToUpdate = e.currentTarget.id
    const newName = e.target.value
    _.forEach(wizardSharedData["s3Policy"]["keys"], (key) => {
      if (key.value === keyToUpdate) {
        key.label = newName
      }
    })
    this.setState({ wizardSharedData })
  }

  onChangeGCSStackName = async (e) => {
    const { wizardSharedData } = this.state
    const keyToUpdate = e.currentTarget.id
    const newName = e.target.value
    _.forEach(wizardSharedData["gcsPolicy"]["keys"], (key) => {
      if (key.value === keyToUpdate) {
        key.label = newName
      }
    })
    this.setState({ wizardSharedData })
  }

  onChangeStackName = async (e) => {
    const stackName = e.target.value
    this.setState({ stackName })
  }

  render() {
    const { environmentId, wizardSharedData} = this.state;
    const isFinalPageReady = this.props.isFinalPageReady()

    return (
          <div>
            {isFinalPageReady ?
              <div>
                <div className="wizardSubmitLine">Creating new stacks for your environment.</div>
                <div className="wizardSubmitLine"> the Iac source will be: <b>{wizardSharedData.stackSource.sourceType.toString()}</b></div>

                {/* S3 handler */}
                {wizardSharedData.selectedPolicy === "s3" &&

                <div>
                  <div className="wizardSubmitLine"> you want to use the provider: <b>{wizardSharedData.stackProvider.toString()}</b></div>
                  <div className="wizardSubmitLine"> the policy for the iac setting is using the
                    S3 Bucket: <b>{wizardSharedData["s3Policy"]["bucket"]}</b></div>
                  <div className="wizardSubmitLine-files"> the following Stacks will be created in your environment:
                  </div>

                  {_.map(wizardSharedData["s3Policy"]["keys"], (key) =>
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="branch"><b>{key.value}</b></label>
                      <Field
                        className={"form-control"}
                        name={key.value}
                        id={key.value}
                        component="input"
                        type="text"
                        placeholder={`${key.value}`}
                        onChange={this.onChangeS3StackName}
                      />
                    </div>
                  )}
                </div>
                }

                {/* GCS handler */}
                {wizardSharedData.selectedPolicy === "gcs" &&

                <div>
                  <div className="wizardSubmitLine"> you want to use the provider: <b>{wizardSharedData.stackProvider.toString()}</b></div>
                  <div className="wizardSubmitLine"> the policy for the iac setting is using the
                    GCS Bucket: <b>{wizardSharedData["gcsPolicy"]["bucket"]}</b></div>
                  <div className="wizardSubmitLine-files"> the following Stacks will be created in your environment:
                  </div>

                  {_.map(wizardSharedData["gcsPolicy"]["keys"], (key) =>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="branch"><b>{key.value}</b></label>
                        <Field
                            className={"form-control"}
                            name={key.value}
                            id={key.value}
                            component="input"
                            type="text"
                            placeholder={`${key.value}`}
                            onChange={this.onChangeGCSStackName}
                        />
                      </div>
                  )}
                </div>
                }

                {/* tfc handler */}
                {wizardSharedData.selectedPolicy === "tfc" &&
                <div>
                  <div className="wizardSubmitLine"> you want to use the provider: <b>{wizardSharedData.stackProvider.toString()}</b></div>
                  <div className="wizardSubmitLine"> the policy for the iac setting is using the
                    Terraform Cloud organization: <b>{wizardSharedData["tfcPolicy"]["organizationId"]}</b></div>
                  <div className="wizardSubmitLine-files"> the following Stack will be created in your environment:
                  </div>

                  <div className="form-group">
                    <label className="col-form-label"
                           htmlFor="branch"><b>{`${wizardSharedData["tfcPolicy"]["organizationId"]}-${wizardSharedData["tfcPolicy"]["workspace"]}`}</b></label>
                    <Field
                      className={"form-control"}
                      name={`${wizardSharedData["tfcPolicy"]["organizationId"]}-${wizardSharedData["tfcPolicy"]["workspace"]}`}
                      id={`${wizardSharedData["tfcPolicy"]["organizationId"]}-${wizardSharedData["tfcPolicy"]["workspace"]}`}
                      component="input"
                      type="text"
                      placeholder={`${wizardSharedData["tfcPolicy"]["organizationId"]}-${wizardSharedData["tfcPolicy"]["workspace"]}`}
                      onChange={this.onChangeStackName}
                    />
                  </div>
                </div>
                }

                {/* skip iac settings handler */}
                {wizardSharedData.selectedPolicy === "skip" &&
                <div>
                  <div className="wizardSubmitLine">
                    you chose to skip setting Iac Settings for your Stack at the moment.
                    you will be able to define Iac Settings at any time after the Stack creation
                  </div>
                  <div className="wizardSubmitLine-files"> the following Stack will be created in your environment:
                  </div>

                  <div className="form-group">
                    <label className="col-form-label"
                           htmlFor="branch"><b>Stack name:</b></label>
                    <Field
                      className={"form-control"}
                      name="name"
                      id="name"
                      component="input"
                      type="text"
                      placeholder={`Type Stack name`}
                      onChange={this.onChangeStackName}
                    />
                  </div>
                </div>
                }

                {/* no settings handler - for creating orchestrator managed stack */}
                {(wizardSharedData.selectedPolicy === "" && wizardSharedData.stackSource.sourceType === "orchestrator") &&
                <div>
                  <div className="wizardSubmitLine-files"> the following Stack will be created in your environment:
                  </div>

                  <div className="form-group">
                    <label className="col-form-label"
                           htmlFor="branch"><b>Stack name:</b></label>
                    <Field
                      className={"form-control"}
                      name="name"
                      id="name"
                      component="input"
                      type="text"
                      placeholder={`Type Stack name`}
                      onChange={this.onChangeStackName}
                    />
                  </div>
                </div>
                }

                <div className="form-group row">
                  <div className="col-sm-10">
                    <button className="btn btn-primary" type="submit" disabled={this.state.createdSuccessfully} onClick={(e) => this.onSubmit(e)}>Create Stacks</button>
                  </div>
                </div>

                {!_.isUndefined(this.state.createdSuccessfully) && this.state.createdSuccessfully === true ?
                  <Success closeEnabled={false}>Creation completed successfully.</Success> : null
                }

                {!_.isUndefined(this.state.createdSuccessfully) && this.state.createdSuccessfully === false ?
                  <Warning closeEnabled={false}>Cloud not create Stacks. Contact Infralight.</Warning> : null
                }

              </div> : null}
          </div>




    );
  }
}


const mapStateToProps = (state) => {
  return {
    githubApps: state.vcsReducer.githubApps,
    awsIntegrations: state.integrationsReducer.aws.integrations || [],
    activeFeatures: state.activeFeaturesReducer.activeFeatures || [],
    profiles: state.profilesReducer.profiles,
  };
};

const mapDispatchToProps = {
  getAllProfiles,
  createStackByEnvironmentId,
  getAllGithubApps,
  getAwsIntegrations,
  getStacksByEnvironmentId,
  updateIacSettings
};


SelectNamesAndSubmit = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SelectNamesAndSubmit);

export default withRouter(SelectNamesAndSubmit);
