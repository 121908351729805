import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "redux-form";
import { reduxForm } from "redux-form";
import {
  getAllTfcOrganizations,
  getTfcIntegrations,
  getAllTfcOrganizationsWorkspaces,
} from "../../../../../redux/actions/tfcIntegrationActions";
import Loading from "../../../../../shared/loading";

class TfcPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      integrations: [],
      selectedIntegrationId: null,
      isIntegrationValid: null,
      integrationError: false,

      organizations: [],
      selectedOrganization: null,
      isOrganizationValid: null,
      organizationStatusCode: null,

      workspaces: [],
      selectedWorkspace: null,
      isWorkspaceValid: null,
      workspaceStatusCode: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    await this.props.getTfcIntegrations();

    this.setState({ integrations: this.props.tfcIntegrations });
    this.setState({ isLoading: false });
  }

  onChangeIntegration = async (integrationId) => {
    this.setState({ isLoading: true });
    const res = await this.props.getAllTfcOrganizations(integrationId);

    this.props.handleSelectResourceUpdate("tfcPolicyIntegrationId", integrationId);

    this.setState({ isLoading: false, organizations: res.organizations?.data, isOrganizationValid: res.ok, integrationError: res.ok, selectedIntegrationId: integrationId });
  }

  onChangeOrganization = async (organization) => {
    const { selectedIntegrationId } = this.state;
    this.setState({ isLoading: true, selectedOrganization: null, isOrganizationValid: null });

    const res = await this.props.getAllTfcOrganizationsWorkspaces(selectedIntegrationId, organization);

    this.props.handleSelectResourceUpdate("tfcPolicyOrganization", organization);

    this.setState({ isLoading: false, selectedOrganization: organization, isOrganizationValid: true, workspaces: res.objects?.data, isWorkspaceValid: res.ok, workspaceStatusCode: res.status });
  }

  onChangeWorkspace = async (workspace) => {
    this.setState({ isLoading: true });

    this.props.handleSelectResourceUpdate("tfcPolicyWorkspace", workspace);

    this.setState({ isLoading: false, selectedWorkspace: workspace, isWorkspaceValid: true });
  }

  render() {
    const {
      isLoading,
      integrations,
      selectedIntegrationId,
      isIntegrationValid,
      organizations,
      selectedOrganization,
      isOrganizationValid,
      organizationStatusCode,
      workspaces,
      selectedWorkspace,
      isWorkspaceValid,
      workspaceStatusCode,
    } = this.state;

    return (
        isLoading ? <Loading /> :
            <>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="gcpIntegration">Terraform Cloud Integration</label>
                <div className="col-sm-10">
                  <Field
                      className={"form-control"}
                      name="tfcIntegration"
                      id="tfcIntegration"
                      component="select"
                      onChange={(e) => this.onChangeIntegration(e.target.value)}
                  >
                    <option value="">Please Choose GCP Integration..</option>
                    {_.map(integrations, integ => <option value={integ.id} key={integ.id}>{integ.name}</option>)}
                  </Field>
                  {isIntegrationValid === false ? <div className="text-14 font-weight-bold text-danger">integration is not valid</div> : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="tfcOrg">Terraform Cloud Organization</label>
                <div className="input-group mb-3 col-sm-10">
                  <Field
                      className={"form-control"}
                      name="tfcOrg"
                      id="tfcOrg"
                      component="select"
                      disabled={isOrganizationValid !== true}
                      onChange={(e) => this.onChangeOrganization(e.target.value)}
                      aria-describedby="reload"
                  >
                    <option value="">Please Choose Organization...</option>
                    {_.map(organizations, (o) => (
                        <option value={o.id} key={o.id}>
                          {o.id}
                        </option>
                    ))}
                  </Field>
                </div>
                {isOrganizationValid === false ?
                    organizationStatusCode === 403 ?
                        <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Permission Denied<br /></div></> :
                        <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Bucket integration is not valid</div></> : null}
              </div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="tfcOrgWorkspace">Terraform Cloud Organizatio's Workspace</label>
                <div className="input-group mb-3 col-sm-10">
                  <Field
                      className={"form-control"}
                      name="tfcOrgWorkspace"
                      id="tfcOrgWorkspace"
                      component="select"
                      disabled={isWorkspaceValid !== true}
                      onChange={(e) => this.onChangeWorkspace(e.target.value)}
                      aria-describedby="reload"
                  >
                    <option value="">Please Choose Workspace...</option>
                    {_.map(workspaces, (w) => (
                        <option value={w.id} key={w.id}>
                          {w.attributes.name} ({w.id})
                        </option>
                    ))}
                  </Field>
                </div>
                {isOrganizationValid === false ?
                    organizationStatusCode === 403 ?
                        <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Permission Denied<br /></div></> :
                        <><div className="col-sm-2"></div><div className="text-14 font-weight-bold text-danger col-sm-10">Bucket integration is not valid</div></> : null}
              </div>
            </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tfcIntegrations: state.tfcIntegrationReducer.integrations,
  };
};

const mapDispatchToProps = {
  getTfcIntegrations,
  getAllTfcOrganizations,
  getAllTfcOrganizationsWorkspaces,
};

TfcPolicy = reduxForm({
  form: "tfc-state-policy",
})(TfcPolicy);

TfcPolicy = compose(connect(mapStateToProps, mapDispatchToProps))(TfcPolicy);

export default withRouter(TfcPolicy);
