import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Loading from "../../../../shared/loading";
import S3Policy from "./selectPolicies/s3Policy";
import TfcPolicy from "./selectPolicies/tfcPolicy";
import { GCSPolicy } from "./selectPolicies/gcsPolicy";
import {
  getIacSettings,
  updateIacSettings,
} from "../../../../redux/actions/statesActions";

export class SelectIacSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      environmentId: this.props.environmentId,
      isLoading: false,
      selectedPolicy: null,
      updatedSuccessful: null,
    };
  }

  async componentDidMount() {
    this.props.handleSelectResourceUpdate("formatPolicies", {})
  }

  onChangeSelectedPolicy = (value) => {
    this.props.handleSelectResourceUpdate("selectedPolicy", value)
    this.props.handleSelectResourceUpdate("formatPolicies", {})
    this.setState({selectedPolicy: value});
  };

  RadioButton = (props) => {
    const {selectedPolicy} = this.state;
    const checked = selectedPolicy == props.value;
    const disabled = _.isUndefined(props.disabled) ? false : props.disabled;

    return (
      <div className={`form-check ${disabled ? "disabled" : ""}`}>
        <input
          className="form-check-input"
          id={props.id}
          type="radio"
          name="policies"
          value={props.value}
          checked={checked}
          disabled={disabled}
          onChange={() => this.onChangeSelectedPolicy(props.value)}
        />
        <label className="form-check-label ml-3" htmlFor={props.id}>
          {props.description}
        </label>
      </div>
    );
  };

  render() {
    const { selectedPolicy, isLoading } = this.state;

    if (isLoading) {
      return <Loading/>;
    }
    return (
      <div className="CreateStack">
        <div>Choose Iac Settings</div>
        <div className="form-group CreateStack__form-radioBox">
          <this.RadioButton
            id="skip"
            checked={true}
            value="skip"
            description="Skip Iac Settings for now"
          />

          <this.RadioButton
            id="s3"
            checked={true}
            value="s3"
            description="AWS S3 Bucket"
          />

          <this.RadioButton
            id="gcs"
            checked={true}
            value="gcs"
            description="GCP GCS Bucket"
          />

          <this.RadioButton
            id="terraform-cloud"
            value="tfc"
            description="Terraform Cloud"
          />

          <this.RadioButton
            id="hashicorp"
            disabled={true}
            value="hashicorp"
            description="Hashicorp Consul"
          />

          <this.RadioButton
            id="state-management"
            disabled={true}
            value="state-management"
            description="InfraLight state management"
          />
          <div className="col-form-label col-sm-2 pt-0"></div>

          {selectedPolicy === "s3" ? (
            <S3Policy handleSelectResourceUpdate={this.props.handleSelectResourceUpdate}/>
          ) : selectedPolicy === "tfc" ? (
            <TfcPolicy handleSelectResourceUpdate={this.props.handleSelectResourceUpdate}/>
          ) : selectedPolicy ===  "gcs" ? (
              <GCSPolicy handleSelectResourceUpdate={this.props.handleSelectResourceUpdate}/>
          ) : null }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    policy: state.statesReducer.selectedPolicy,
  };
};

const mapDispatchToProps = {
  getIacSettings,
  updateIacSettings,
};

SelectIacSettings = compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectIacSettings
);

export default withRouter(SelectIacSettings);
