import {
    SEND_METRIC
} from '../actions/metricActions';

const initialState = {
};

export default (state = initialState, { type, payload }) => {

    switch (type) {

        case SEND_METRIC:
            return { ...state };
            
        default:
            return state;
    }
}
