import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from "redux-form";
import { CardBody, Card, CardHeader } from "../../../shared/layouts/cards";
import { InputGroup, FormGroup } from "../../../shared/form/form";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    createTfcIntegration,
} from "../../../redux/actions/tfcIntegrationActions";

const required = (value) =>
    value || typeof value === "number" ? undefined : "Required";

class TfcIntegration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isValid: null,
            apiToken: null,
            name: null,
            errorMessage: null,
            successMessage: "Created Successfully!",
            isCreated: false
        };
    }

    onChangeApiToken = async (token) => {
        const isValid = !_.isEmpty(token)
        this.setState({ isValid, isLoading: false, apiToken: token, errorMessage: isValid ? null : "API Token is not valid or empty" });
    }

    onChangeName = async (name) => {
        this.setState({isLoading: false, name });
    }

    onSubmit = async (event) => {
        let result = {};
        event.preventDefault();
        this.setState({isLoading: true});

        const {name, apiToken, isValid} = this.state;

        try {
            result = await this.props.createTfcIntegration(_.isEmpty(name) ? "Terraform Cloud" : name, apiToken);
        } catch (e) {
            console.log("failed to create tfc integration");
        } finally {
            this.setState({
                isLoading: false,
                isValid: result.created,
                errorMessage: result.created ? null : result.message,
                isCreated: result.created,
                successMessage: result.created ? result.message : null
            });
        }

    };


    render() {
        const { isLoading, isValid, isCreated, apiToken, name, errorMessage, successMessage } = this.state;

        return (
            <>
                <Card>
                    <CardBody>
                        <CardHeader>Terraform Cloud Integration</CardHeader>
                        <h5>Hashicorp's Terraform SaaS Orchestrator.</h5>
                        <FormGroup>
                            <InputGroup>
                                <label
                                    className="col-sm-12 col-md-4 col-lg-2 col-form-label"
                                    htmlFor="tfcName"
                                >
                                    Name
                                </label>
                                <div className="col-sm-10">
                                    <Field
                                        className="form-control"
                                        name="tfcName"
                                        id="apiToken"
                                        onChange={(e) => this.onChangeName(e.target.value)}
                                        component="input"
                                        type="text"
                                        validate={null}
                                        value={name}
                                    />
                                </div>
                            </InputGroup>

                            <InputGroup>
                                <label
                                    className="col-sm-12 col-md-4 col-lg-2 col-form-label"
                                    htmlFor="tfcApiToken"
                                >
                                    API Token
                                </label>
                                <div className="col-sm-10">
                                    <Field
                                        className="form-control"
                                        name="apiToken"
                                        id="apiToken"
                                        onChange={(e) => this.onChangeApiToken(e.target.value)}
                                        component="input"
                                        type="password"
                                        validate={[required]}
                                        value={apiToken}
                                    />
                                </div>
                            </InputGroup>

                            {isValid === false ? <InputGroup hasMarginBottom={false}><div className="text-medium text-danger m-0 font-weight-bold">{errorMessage}</div></InputGroup> : null}
                            {isCreated === true ? <InputGroup hasMarginBottom={false}><div className="text-medium text-success m-0 font-weight-bold">{successMessage}</div></InputGroup> : null}
                        </FormGroup>

                        <FormGroup>
                            <div className="col-sm-10">
                                <InputGroup>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        onClick={(event) => this.onSubmit(event)}
                                        disabled={!isValid || isLoading}>
                                        Save
                                    </button>
                                </InputGroup>
                            </div>
                        </FormGroup>
                    </CardBody>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    createTfcIntegration,
}

TfcIntegration = reduxForm({
    form: "tfc-integration",
})(TfcIntegration);

TfcIntegration = compose(connect(mapStateToProps, mapDispatchToProps))(TfcIntegration);

export default withRouter(TfcIntegration);