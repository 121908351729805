import React from 'react';
import _ from 'lodash';

export default class WidgetHeader  extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
        const { icon, text } = this.props;
        return <div className="ul-widget__head">
            <div className="ul-widget__head-label">
                <h3 className="ul-widget__head-title">
                    {_.isEmpty(icon) ? null :
                        <span><i className={`ul-accordion__font ${icon}`}> </i></span>
                    }
                    {text || ""}</h3>
            </div></div>
    }
}