import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

class FullScreen extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {
        const { } = this.state;
        return (
            <>
                <i className="i-Full-Screen header-icon d-none d-sm-inline-block" data-fullscreen></i>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
}
const mapDispatchToProps = {
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(FullScreen);
