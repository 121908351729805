import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CopyToClipboard from '../../shared/copyToClipboard';
import { BACKEND_URL } from '../../consts/config';

class Step2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = { vcsId: null };
    }

    async componentDidMount() {
        const { vcsId } = this.props;
        this.setState({ vcsId });
    }

    componentDidUpdate(prevProps) {
        if (this.props.vcsId !== prevProps.vcsId) {
            const vcsId = this.props.vcsId;
            this.setState({ vcsId });
        }
    }


    render() {
        const { vcsId } = this.state;

        return (
            <>
                <h3 className="border-bottom border-gray pb-2">Setup GitHub application</h3><br />
                <h5>On GitHub, <a target="_blank" href="https://github.com/settings/applications/new">register a new OAuth Application.</a>  Enter the following information: </h5>

                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th scope="col">Application Name</th>
                                <td>
                                    <CopyToClipboard text="InfraLight-App" />
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Homepage URL</th>
                                <td>
                                    <CopyToClipboard text="https://infralight.cloud" />
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Description</th>
                                <td>
                                    <CopyToClipboard text="IaC managed by InfraLight" />
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Authorization callback URL</th>
                                <td>
                                    <CopyToClipboard text={`${BACKEND_URL}/vcs/github/${vcsId}/callback`} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = {
};


Step2 = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Step2);
export default withRouter(Step2);