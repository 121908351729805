import { GET_BY_STACK } from '../actions/variableActions';

const initialState = {
    data: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_BY_STACK:
            return {...state, data: payload.data};

        default:
            return state;
    }
}
