import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Info } from '../../shared/alerts';
import { getAllGithubApps } from '../../redux/actions/vcsActions';
import Loading from '../../shared/loading';
import GithubInstallationWizard from './githubInstallationWizard';
import {Card, CardBody, CardHeader} from '../../shared/layouts/cards';

import './github.scss';

class GitHub extends React.Component {

    constructor(props) {
        super(props);

        this.state = { githubApps: null, isLoading: true };
    }

    async componentDidMount() {
        await this.props.getAllGithubApps();
        this.setState({ isLoading: false, githubApps: this.props.githubApps });
    }

    render() {

        const { isLoading, githubApps } = this.state;
        return (
            <Card>
                <CardBody>
                    <CardHeader>GitHub Integration</CardHeader>
                {
                    isLoading ? <Loading /> :<GithubInstallationWizard />
                }
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        githubApps: state.vcsReducer.githubApps
    };
};

const mapDispatchToProps = {
    getAllGithubApps
};


GitHub = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(GitHub);
export default withRouter(GitHub);