import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import BasicModal from "../../shared/basicModal";
import { Field } from "redux-form";
import {
  createUser,
  getAllProfiles,
} from "../../redux/actions/profilesActions";

class CreateUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  async componentDidMount() {}

  onSubmit = async (submittedInfo) => {
    const res = await this.props.createUser(
      submittedInfo.name,
      submittedInfo.email
    );
    if (res.ok) {
      this.props.getAllProfiles();
      this.props.onCreate();
    }
  };

  render() {
    const {} = this.state;
    const { isOpen, onClose } = this.props;

    return (
      <BasicModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={this.onSubmit}
        header="Invite User"
        applyButton="Create"
      >
        <div className="form-group">
          <label className="col-form-label" htmlFor="name">
            Name:
          </label>
          <Field
            className={"form-control"}
            name="name"
            id="name"
            component="input"
            type="text"
            placeholder="Please fill the user's name"
            required
          />
        </div>
        <div className="form-group">
          <label className="col-form-label" htmlFor="email">
            Email:
          </label>
          <Field
            className={"form-control"}
            name="email"
            id="email"
            component="input"
            type="email"
            placeholder="Please fill the email"
            required
          />
        </div>
      </BasicModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  createUser,
  getAllProfiles,
};

CreateUser = compose(connect(mapStateToProps, mapDispatchToProps))(CreateUser);

export default withRouter(CreateUser);
