import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { onCategoryLoaded, onCategoryFilteredOut, onCategoryFiltered } from '../../redux/actions/homePageActions';
import TooltipLink from '../../shared/tooltip/tooltipLink';
import TooltipResources from './tooltipResources';
import './summaryWindowCard.scss';
class SummaryWindowCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = { terraformCount: 0, totalCount: 0, excludedCount: 0, isLoading: false, filtered: false };
    }

    async componentDidMount() {
        const { id } = this.props;

        this.setState({ isLoading: true });

        await this.props.onLoad();
        this.props.onCategoryLoaded(id);

        const { inventory, excludedByResourceType, resourceTypes, filteredAwsAccounts, filteredStacks, filteredUnmanaged } = this.props;
        await this.calculateInvetory(inventory, excludedByResourceType, resourceTypes, filteredAwsAccounts, filteredStacks, filteredUnmanaged);

        this.setState({ isLoading: false });
    }

    async componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.filteredAwsAccounts, nextProps.filteredAwsAccounts) ||
            !_.isEqual(this.props.filteredStacks, nextProps.filteredStacks) ||
            this.props.filteredUnmanaged != nextProps.filteredUnmanaged) {

            this.setState({ isLoading: true });

            const { inventory, excludedByResourceType, resourceTypes, filteredAwsAccounts, filteredStacks, filteredUnmanaged } = nextProps;
            await this.calculateInvetory(inventory, excludedByResourceType, resourceTypes, filteredAwsAccounts, filteredStacks, filteredUnmanaged);

            this.setState({ isLoading: false });
        }

    }

    calculateInvetory = async (inventory, excludedByResourceType, resourceTypes, filteredAwsAccounts, filteredStacks, filteredUnmanaged) => {
        this.setState({ isLoading: true });
        let terraformCount = 0;
        let totalCount = 0;
        let excludedCount = 0;

        for (const i in resourceTypes) {
            const resourceType = resourceTypes[i];
            let resources = inventory[resourceType].resources;

            if (!_.isEmpty(filteredAwsAccounts)) {
                resources = _.filter(resources, resource => _.includes(filteredAwsAccounts, resource.awsAccountId));
            }

            //Filter By stack
            resources = _.filter(resources, resource => {
                if (!_.isEmpty(resource.iacObject)) {
                    if (resource.type.startsWith("aws")) {
                        //Has stack
                        return _.includes(filteredStacks, resource.iacObject[0].stackId);
                    }
                    // Kubernetes object currently doesn't have stack id.
                    return true;
                } else {
                    //Filter unmanaged
                    return filteredUnmanaged;
                }
            });

            const excludedResources = excludedByResourceType[resourceType] || [];
            const resourceTerraformCount = _.filter(resources, x => !_.isEmpty(x.iacObject) && !_.includes(excludedResources, x.id)).length;
            const resourceExcludedCount = _.filter(resources, x => _.includes(excludedResources, x.id)).length;

            totalCount += (resources.length - resourceExcludedCount);
            terraformCount += resourceTerraformCount;
            excludedCount += resourceExcludedCount;
        }


        this.setState({ totalCount, terraformCount, excludedCount });
        this.setState({ isLoading: false });
    }
    onChangeFilteredStatus = (id, resourceTypes) => {
        const {isDisabled} = this.props;
        if(isDisabled){
            return;
        }

        const { filtered } = this.state;
        if (filtered) {
            this.props.onCategoryFilteredOut(resourceTypes);
        } else {
            this.props.onCategoryFiltered(resourceTypes);
        }

        this.setState({ filtered: !filtered })
    }

    render() {
        const { id, header, icon, resourceTypes, isDisabled } = this.props;
        const { terraformCount, totalCount, excludedCount, isLoading, filtered } = this.state;

        return <div className="col-lg-12 col-md-12 col-sm-12" onClick={() => this.onChangeFilteredStatus(id, resourceTypes)}>
            <div className={`card card-icon mb-4 list-group-item-action ${filtered?"bg-primary":""} ${isDisabled ? "card-disabled" : ""}`}>
                <div className="card-box text-center"><i className={filtered ? `${icon} text-white` : icon}></i>
                    <p className={filtered ? "text-white mt-2 mb-2" : "text-muted mt-2 mb-2"}>{header}</p>
                    {isLoading ? <div className="loader-bubble loader-bubble-primary m-2"></div> :
                        <p className={filtered ? "text-white text-24 line-height-1 m-0" : "text-primary text-24 line-height-1 m-0"}>{terraformCount}/{totalCount} <span className={filtered ? "text-white text-12 font-weight-bold" : "text-muted text-12 font-weight-bold"}>IaC/total</span></p>
                    }
                    <TooltipResources id={`tooltip_${id}`} resourceTypes={resourceTypes} />

                    {excludedCount > 0 ?
                        <span className={filtered ? "badge badge-light" : "badge badge-dark"}>{excludedCount} excluded</span>
                        : <span>&nbsp;</span>}
                </div>
                <TooltipLink id={`tooltip_${id}`} link="#">
                    <div className="card-info mt-2 mr-2">
                        {!isDisabled?
                            <i className={filtered ? "i-Information text-white" : "i-Information"}></i>
                            : null}

                    </div>
                </TooltipLink>
            </div>
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        excludedByResourceType: state.exclusionsReducer.excludedByResourceType || [],
        inventory: state.inventoryReducer,
        filteredAwsAccounts: state.homePageReducer.filteredAwsAccounts,
        filteredStacks: state.homePageReducer.filteredStacks,
        filteredUnmanaged: state.homePageReducer.filteredUnmanaged
    };
};

const mapDispatchToProps = {
    onCategoryLoaded,
    onCategoryFiltered,
    onCategoryFilteredOut
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SummaryWindowCard);