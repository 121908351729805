import React from "react";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Loading from "../../shared/loading";
import { Point, Timeline, ShowMore } from "../../shared/timelime/timeline";
import { Card, CardBody, CardHeader } from "../../shared/layouts/cards";
import { getDrifts } from "../../redux/actions/driftsActions";
import EmptyPage from "../../shared/emptyPage/emptyPage";
import OnOff from '../../shared/toggle/onOffToggle';

const COUNT_OF_ITEMS = 5;

class DriftHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            countOfDrifts: 0,
            renderedDrifts: [],
            hasOnlyDelta: false
        };
    }

    async componentDidMount() {
        await this.props.getDrifts();

        const { data } = this.props;
        const countOfDrifts = (data || []).length;
        const renderedDrifts = _.slice((data || []), 0, COUNT_OF_ITEMS);
        this.setState({ isLoading: false, countOfDrifts, renderedDrifts });
    }

    showMore = () => {
        const { data } = this.props;
        const { renderedDrifts } = this.state;
        const temp = _.union(renderedDrifts, _.slice((data || []), renderedDrifts.length, renderedDrifts.length + COUNT_OF_ITEMS));
        this.setState({ renderedDrifts: temp });
    }

    moveToDriftViewer = async (driftId) => {
        const { history } = this.props;
        history.push(`/${driftId}`);
    }

    changeEnableStatus = async () => {
        const {hasOnlyDelta } = this.state;
        this.setState({isLoading: true});

        await this.props.getDrifts(!hasOnlyDelta);
        
        const { data } = this.props;
        const countOfDrifts = (data || []).length;
        const renderedDrifts = _.slice((data || []), 0, COUNT_OF_ITEMS);
        this.setState({ isLoading: false, countOfDrifts, renderedDrifts, hasOnlyDelta: !hasOnlyDelta });
    }

    render() {
        const { data } = this.props;
        const { isLoading, countOfDrifts, renderedDrifts, hasOnlyDelta } = this.state;

        const isShowMore = renderedDrifts.length < countOfDrifts;

        return (
            isLoading ? <Loading /> :
                <Card>
                    <CardBody>
                        {
                            _.isEmpty(data) ?
                                <EmptyPage>No drifts detected</EmptyPage>
                                :
                                <>
                                    <CardHeader>Drifts management</CardHeader>
                                    Only Delta&nbsp;<OnOff isLoading={isLoading} isEnabled={hasOnlyDelta} changeOnOffStatus={() => this.changeEnableStatus()} />
                                    <Timeline>
                                        {
                                            _.map(renderedDrifts, drift => {
                                                const createdAtToMilliseconds = drift.createdAt.toString().length <= 10 ? drift.createdAt * 1000 : drift.createdAt;
                                                const createdAt = moment(createdAtToMilliseconds);

                                                return <Point key={drift.driftId} onClick={() => this.moveToDriftViewer(drift.driftId)}
                                                    time={createdAt.toDate()}
                                                    badge="primary"
                                                    text={<span>Drift scan found <span className="font-weight-bold">{drift.total - drift.managedCount}</span> resources<br />
                                                        <li className="list-group-item border-0">
                                                            {/* <span className="badge badge-success w-badge">{drift.managedCount} Managed</span> */}
                                                            <span className="badge badge-light w-badge text-dark"><span className="font-weight-bold">{drift.unmanagedCount}</span> Resource drifts</span>
                                                            <span className="badge badge-dark w-badge"><span className="font-weight-bold">{drift.modifiedCount}</span> Configuration drifts</span>
                                                        </li>
                                                    </span>} />
                                            })
                                        }
                                    </Timeline>
                                    {isShowMore ? <ShowMore onClick={this.showMore} /> : null}
                                </>
                        }
                    </CardBody>
                </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.driftsReducer.data
    };
};

const mapDispatchToProps = {
    getDrifts
};

DriftHistory = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(DriftHistory);


export default withRouter(DriftHistory);