import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Loading from '../../../shared/loading';

const CFT_TEMPLATE = 'https://infralight-templates-public.s3.amazonaws.com/config_template.yml';
const STACK_NAME = 'infralight-readonly';
const RESOURCE_NAME_PREFIX = 'infralight';
const REGION = 'us-east-1';

class AssumeRole extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }

    async componentDidMount() {
    }

    onClick = () => {
        const {externalId} = this.props;
        window.open(`https://${REGION}.console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=${CFT_TEMPLATE}&param_ExternalID=${externalId}&stackName=${STACK_NAME}&param_ResourceNamePrefix=${RESOURCE_NAME_PREFIX}`, "_blank");
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return (<Loading />);
        }

        return (
            <div className="col-sm-10">
                <button className="btn btn-primary" type="submit" onClick={this.onClick}>Click here to assume role in AWS</button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
}
const mapDispatchToProps = {
};

AssumeRole = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AssumeRole);


export default withRouter(AssumeRole);