import React from 'react';
import _ from 'lodash';
import AnsiComp from "ansi-to-react";
import './ansi.scss';

export default class Ansi extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        const { children } = this.props;
        return (
            <div className="ansi-code">
                <AnsiComp>{children}</AnsiComp>
            </div>
        )
    }
}