import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BasicModal from '../../../../shared/basicModal';
import { createStackByEnvironmentId, getStacksByEnvironmentId } from '../../../../redux/actions/stackActions';
import { getAllGithubApps } from '../../../../redux/actions/vcsActions';
import { getAwsIntegrations } from '../../../../redux/actions/integrationsActions';
import { getAllProfiles } from "../../../../redux/actions/profilesActions";
import RequestWrapper from '../../../../utils/requestWrapper';
import './createStack.scss';
import {
    Wizard,
    WizardBottom,
    WizardContainer,
    WizardPage
} from "../../../../shared/smartWizard/wizard";

import {SelectIacSource} from './selectIacSource'
import {SelectIacProvider} from './selectIacProvider'
import {SelectIacSettings} from './selectIacSettings'
import {SelectNamesAndSubmit} from './selectNamesAndSubmit'


class CreateStack extends React.Component {
    constructor(props) {
        super(props);

        this.requestWrapper = new RequestWrapper();

        this.state = {
            step: 1,
            environmentId: this.props.match.params.environmentId,
            isOpen: false,
            isLoading: true,
            githubApps: null,
            repos: null,
            isNameValid: false,
            isVcsValid: false,
            isRepoValid: false,
            isTerraformVersionValid: false,
            isAwsIntegrationValid: false,
            awsIntegrations: null,
            profiles: null,
            userSelectOrchestrator: false,
            userOwnerSelect: null,

            wizardSharedData: {
                stackSource: {
                    sourceType: "",
                    orchestratorConfig: {
                        branch: "master",
                        path: "/",
                        isTerragrunt: false
                    }
                },
                stackProvider: "Terraform",
                selectedPolicy: "",
                s3Policy: {},
                tfcPolicy: {},
                gcsPolicy: {}
            },
            isCreationOpen: false
        };
    }

    async componentDidMount() {

        await Promise.all([
            this.props.getAllGithubApps(),
            this.props.getAwsIntegrations(),
            this.props.getAllProfiles(),
        ])

        const { githubApps, awsIntegrations, profiles } = this.props;
        this.setState({ isLoading: false, step: 1, githubApps, awsIntegrations, profiles,
            wizardSharedData: {
                stackSource: {
                    sourceType: "",
                    orchestratorConfig: {
                        branch: "master",
                        path: "/",
                        isTerragrunt: false
                    }
                },
                stackProvider: "",
                selectedPolicy: "",
                s3Policy: {},
                tfcPolicy: {},
                gcsPolicy: {}
            }
        });
    }

    isStepOneDone = () => {
        const { wizardSharedData } = this.state;
        if (wizardSharedData.stackSource.sourceType == "managed") {
            return true
        } else if (wizardSharedData.stackSource.sourceType == "orchestrator") {
            if (!_.isEmpty(wizardSharedData.stackSource.orchestratorConfig.vcs) &&!_.isEmpty(wizardSharedData.stackSource.orchestratorConfig.repo)
                && !_.isEmpty(wizardSharedData.stackSource.orchestratorConfig.branch) && !_.isUndefined(wizardSharedData.stackSource.orchestratorConfig.isTerragrunt)
                && !_.isEmpty(wizardSharedData.stackSource.orchestratorConfig.path) && !_.isEmpty(wizardSharedData.stackSource.orchestratorConfig.awsIntegration)
                && !_.isEmpty(wizardSharedData.stackSource.orchestratorConfig.terraformVersion) ) {
                return true
            }
        }
        return false
    }

    isStepTwoDone = () => {
        const { wizardSharedData } = this.state;
        return !_.isEmpty(wizardSharedData.stackProvider)
    }

    isStepThreeDone = () => {
        const { wizardSharedData } = this.state;

        if (wizardSharedData.selectedPolicy === "tfc") {
            if (!_.isEmpty(wizardSharedData.tfcPolicy.organization) && !_.isEmpty(wizardSharedData.tfcPolicy.workspace)
                && !_.isEmpty(wizardSharedData.tfcPolicy.integrationId)) {
                return true
            }
        }

        if (wizardSharedData.selectedPolicy === "s3") {
            if (!_.isEmpty(wizardSharedData.s3Policy.keys) && !_.isEmpty(wizardSharedData.s3Policy.bucket)
                && !_.isEmpty(wizardSharedData.s3Policy.integrationId)) {
                return true
            }
        }

        if (wizardSharedData.selectedPolicy === "gcs") {
            if (!_.isEmpty(wizardSharedData.gcsPolicy.keys) && !_.isEmpty(wizardSharedData.gcsPolicy.bucket)
                && !_.isEmpty(wizardSharedData.gcsPolicy.integrationId)) {
                return true
            }
        }

        if (wizardSharedData.selectedPolicy === "skip") {
            return true
        }

        return false
    }


    isNextEnabled = () => {
        const { step } = this.state;
        if (step == 1 && this.isStepOneDone()) {
            return true;
        }

        if (step == 2 && this.isStepTwoDone()) {
            return true;
        }

        if (step == 3 && this.isStepThreeDone()) {
            return true;
        }

        return false;
    }

    isPrevEnabled = () => {
        const { step } = this.state;

        if (step <= 1) {
            return false;
        }
        return true;
    }

    nextStep = async () => {
        if (!this.isNextEnabled()) {
            return;
        }

        // if source is orchestrator we should move from step 1 to step 4
        const {wizardSharedData, step} = this.state
        if (step === 1 && wizardSharedData.stackSource.sourceType === "orchestrator") {
            this.setState({ step: 4 });
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    }

    prevStep = () => {
        if (!this.isPrevEnabled()) {
            return;
        }

        // if source is orchestrator we should move from step 4 to step 1
        const {wizardSharedData, step} = this.state
        if (step === 4 && wizardSharedData.stackSource.sourceType === "orchestrator") {
            this.setState({ step: 1 });
        } else {
            this.setState({ step: this.state.step - 1 });
        }
    }


    handleSelectResourceUpdate = (filedName, filedValue) => {
        const { wizardSharedData } = this.state

        // step one
        if (filedName === "stackType") {
            wizardSharedData["stackSource"]["sourceType"] = filedValue
        }
        else if (filedName === "branch") {
            wizardSharedData["stackSource"]["orchestratorConfig"]["branch"] = filedValue
        }
        else if (filedName === "path") {
            wizardSharedData["stackSource"]["orchestratorConfig"]["path"] = filedValue
        }
        else if (filedName === "isTerragrunt") {
            wizardSharedData["stackSource"]["orchestratorConfig"]["isTerragrunt"] = filedValue
        }
        else if (filedName === "awsIntegration") {
            wizardSharedData["stackSource"]["orchestratorConfig"]["awsIntegration"] = filedValue
        }
        else if (filedName === "terraformVersion") {
            wizardSharedData["stackSource"]["orchestratorConfig"]["terraformVersion"] = filedValue
        }
        else if (filedName === "repo") {
            wizardSharedData["stackSource"]["orchestratorConfig"]["repo"] = filedValue
        }
        else if (filedName === "vcs") {
            wizardSharedData["stackSource"]["orchestratorConfig"]["vcs"] = filedValue
        }

        // step two
        else if (filedName === "stackProvider") {
            wizardSharedData["stackProvider"] = filedValue
        }

        // step three
        else if (filedName === "selectedPolicy") {
            wizardSharedData["selectedPolicy"] = filedValue
        }

        else if (filedName === "s3PolicyKeys") {
            wizardSharedData["s3Policy"]["keys"] = filedValue
        }
        else if (filedName === "s3PolicyBucket") {
            wizardSharedData["s3Policy"]["bucket"] = filedValue
        }
        else if (filedName === "s3PolicyIntegrationId") {
            wizardSharedData["s3Policy"]["integrationId"] = filedValue
        }
        else if (filedName === "tfcPolicyWorkspace") {
            wizardSharedData["tfcPolicy"]["workspace"] = filedValue
        }
        else if (filedName === "tfcPolicyOrganization") {
            wizardSharedData["tfcPolicy"]["organization"] = filedValue
        }
        else if (filedName === "tfcPolicyIntegrationId") {
            wizardSharedData["tfcPolicy"]["integrationId"] = filedValue
        }
        else if (filedName === "gcsPolicyKeys") {
            wizardSharedData["gcsPolicy"]["keys"] = filedValue
        }
        else if (filedName === "gcsPolicyBucket") {
            wizardSharedData["gcsPolicy"]["bucket"] = filedValue
        }
        else if (filedName === "gcsPolicyIntegrationId") {
            wizardSharedData["gcsPolicy"]["integrationId"] = filedValue
        }

        else if (filedName === "formatPolicies") {
            wizardSharedData["s3Policy"] = filedValue
            wizardSharedData["tfcPolicy"] = filedValue
            wizardSharedData["gcsPolicy"] = filedValue
        }


        this.setState({wizardSharedData})
    }

    isFinalPageReady = () => {
        const { step } = this.state;
        if (step == 4 ) {
            return true;
        }

        return false;
    }

    innerOnClose = () => {
        const wizardSharedData = {
            stackSource: {
                sourceType: "",
                orchestratorConfig: {
                    branch: "master",
                    path: "/",
                    isTerragrunt: false
                }
            },
            stackProvider: "",
            selectedPolicy: "",
            s3Policy: {},
            tfcPolicy: {},
            gcsPolicy: {}
        }
        this.setState({
            step: 1,
            wizardSharedData
        })
        this.props.getStacksByEnvironmentId(this.state.environmentId);
        this.props.onClose()
    }
    render() {
        const { isOpen } = this.props;
        const { wizardSharedData, environmentId, step } = this.state;

        return (

            <BasicModal isShowFooter={false}
                isOpen={isOpen}
                onClose={this.innerOnClose}
                header="Create Stack"
                applyButton="Create"
                modalSize = "modal-xlg"
            >
                <Wizard>

                    <WizardContainer>
                        <WizardPage id="step1" isActive={step == 1}>
                            <SelectIacSource environmentId={environmentId} handleSelectResourceUpdate={this.handleSelectResourceUpdate} />
                        </WizardPage>

                        <WizardPage id="step2" isActive={step == 2}>
                            <SelectIacProvider environmentId={environmentId} handleSelectResourceUpdate={this.handleSelectResourceUpdate} />
                        </WizardPage>

                        {step == 3 &&
                        <WizardPage id="step3" isActive={step == 3}>
                            <SelectIacSettings environmentId={environmentId}
                                               handleSelectResourceUpdate={this.handleSelectResourceUpdate}/>
                        </WizardPage>
                        }

                        { step == 4 &&
                            <WizardPage id="step4" isActive={step == 4}>
                                <SelectNamesAndSubmit isFinalPageReady={this.isFinalPageReady} environmentId={environmentId}
                                                      wizardSharedData={wizardSharedData}
                                                      handleSelectResourceUpdate={this.handleSelectResourceUpdate}/>
                            </WizardPage>
                        }

                    </WizardContainer>

                    <WizardBottom
                        onNextStep={this.nextStep}
                        onPrevStep={this.prevStep}
                        onComplete={this.complete}
                        onCancel={this.cancel}
                        isPrevEnabled={this.isPrevEnabled()}
                        isNextEnabled={this.isNextEnabled()}
                        isCancelCompleteDisplay={false}
                    />
                </Wizard>

            </BasicModal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        githubApps: state.vcsReducer.githubApps,
        awsIntegrations: state.integrationsReducer.aws.integrations || [],
        activeFeatures: state.activeFeaturesReducer.activeFeatures || [],
        profiles: state.profilesReducer.profiles,
    };
};

const mapDispatchToProps = {
    getAllProfiles,
    createStackByEnvironmentId,
    getAllGithubApps,
    getAwsIntegrations,
    getStacksByEnvironmentId
};


CreateStack = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(CreateStack);

export default withRouter(CreateStack);
