
import React from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';

class InitForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isEnabled: false
        };
    }
    onDescriptionChange = (e) => {
        this.setState({ isEnabled: !_.isEmpty(e.target.value) });
    }


    render() {
        const { stack, handleSubmit } = this.props;
        const { isEnabled } = this.state;

        return (
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="text-center"><h4>Initialization</h4></div>
                    <div className="card mb-5">
                        <div className="card-body">
                            <form className="init-plan" onSubmit={handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" >Stack</label>
                                    <div className="col-sm-10">
                                        <p className="text-18 font-weight-light mb-1">{stack.name}</p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Repository</label>
                                    <div className="col-sm-10">
                                        <p className="text-18 font-weight-light mb-1">{stack.vcs.repo}</p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" >Branch</label>
                                    <div className="col-sm-10">
                                        <p className="text-18 font-weight-light mb-1">{stack.vcs.branch}</p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="inputDescription">Description</label>
                                    <div className="col-sm-10">
                                        <Field
                                            className={"form-control"}
                                            name="description"
                                            id="description"
                                            component="textarea"
                                            placeholder="Please fill the description"
                                            onChange={this.onDescriptionChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="form-group row">
                                        <div className="col-sm-10">
                                            <button className="btn btn-primary" type="submit" disabled={!isEnabled}>Initialize</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default reduxForm({
    form: 'init-plan',
})(InitForm);