import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_K8S_INTEGRATIONS = "GET_K8S_INTEGRATIONS";
export const CREATE_K8S_INTEGRATION = "CREATE_K8S_INTEGRATION";
export const DELETE_K8S_INTEGRATION = "DELETE_K8S_INTEGRATION";

const getK8sIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/k8s`,
      "GET"
    );

    const integrations = await integrationsReq.json();

    dispatch({
      type: GET_K8S_INTEGRATIONS,
      payload: { integrations },
    });

    return integrations;
  });
};

const createK8sIntegration = (clusterId, accessKey) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/k8s`,
      "POST",
      { clusterId, accessKey }
    );

    dispatch({
      type: CREATE_K8S_INTEGRATION,
      payload: {},
    });

    return {};
  });
};

const deleteK8sIntegration = (clusterId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/k8s/${clusterId}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_K8S_INTEGRATION,
      payload: {},
    });

    return {};
  });
};

export { getK8sIntegrations, createK8sIntegration, deleteK8sIntegration };
