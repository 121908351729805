import {GET_AWS_INTEGRATIONS, GET_SLACK_INTEGRATIONS, GET_TEAMS_INTEGRATIONS} from '../actions/integrationsActions';

const initialState = {
    aws: {
        hasError: null,
        integrations: null,
        slack: null,
        teams: null
    }
};

export default (state = initialState, { type, payload }) => {

    switch (type) {

        case GET_AWS_INTEGRATIONS:
            return { ...state, aws: { hasError: payload.hasError, integrations: payload.integrations } };

        case GET_SLACK_INTEGRATIONS:
            return { ...state, slack: payload.integrations }

        case GET_TEAMS_INTEGRATIONS:
            return { ...state, teams: payload.integrations }

        default:
            return state;
    }
}
