import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from '../../../../shared/loading';
import RequestWrapper from '../../../../utils/requestWrapper';
import Ansi from '../../../../shared/ansi';
import { V, X, Slash } from '../../../../shared/iconStatus';
import Accordion from '../../../../shared/accordion';
import { getAllProfiles } from '../../../../redux/actions/profilesActions';
import { TimeParser } from '../../../../shared/parsers';
import { BACKEND_URL } from '../../../../consts/config';

class Runs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profiles: [],
            isLoading: true,
            id: this.props.id,
            valid: null,
            run: null,
            planLoaded: null,
            applyLoaded: null,
            planDocument: null,
            applyDocument: null };
    }

    async componentDidMount() {
        const requestWrapper = new RequestWrapper();

        const { id } = this.state;
        if (_.isEmpty(id)) {
            this.setState({ valid: false });
            return;
        }

        const runRequest = await requestWrapper.sendRequest(`${BACKEND_URL}/orchestrator/${id}`, "GET");
        if (!runRequest.ok) {
            this.setState({ valid: false });
            return;
        }

        const profiles = await this.props.getAllProfiles();
        this.setState({ profiles });

        const run = await runRequest.json();
        this.setState({ isLoading: false, run });
    }

    loadPlan = async (runId) => {
        const { run } = this.state;
        if (run.isPlan == false && run.hasError == false) {
            console.log("not yet planned");
            this.setState({ planDocument: "NOT PLANNED" })
            return;
        }

        const requestWrapper = new RequestWrapper();

        this.setState({ planLoaded: false });
        const docReq = await requestWrapper.sendRequest(`${BACKEND_URL}/orchestrator/${runId}/plan`, "GET");

        if (!docReq.ok) {
            this.setState({ planLoaded: true, planDocument: "An error occurred while trying to load plan document" });
            return;
        }

        const doc = await docReq.json();
        this.setState({ planLoaded: true, planDocument: doc.text });
    }

    loadApply = async (runId) => {
        const { run } = this.state;
        if (run.isApply == false && run.hasError == false) {
            console.log("not yet applied");
            this.setState({ applyDocument: "NOT APPLIED" })
            return;
        }
        const requestWrapper = new RequestWrapper();

        this.setState({ applyLoaded: false });
        const docReq = await requestWrapper.sendRequest(`${BACKEND_URL}/orchestrator/${runId}/apply`, "GET");

        if (!docReq.ok) {
            this.setState({ applyLoaded: true, applyDocument: 'An error occurred while trying to load apply document' });
            return;
        }
        const doc = await docReq.json();
        this.setState({ applyLoaded: true, applyDocument: doc.text });
    }

    render() {
        const { isLoading, valid, run, planLoaded, applyLoaded, planDocument, applyDocument, profiles } = this.state;

        if (valid == false) {
            return (<div>id is not valid</div>);
        }
        if (isLoading) {
            return (<Loading />);
        }

        const DetailsHeader = () => { return (<><span><i className="i-Information ul-accordion__font"> </i></span> Details</>) };
        const PlanHeader = () => { return (<> {run.isPlan == true ? <V /> : run.hasError && !run.isDestroy ? <X /> : <Slash />} Plan</>) };
        const ApplyHeader = () => { return (<> {run.isApply == true ? <V /> : run.hasError && !run.isDestroy ? <X /> : <Slash />} Apply</>) };


        const profilesById = _.keyBy(profiles, "id");
        const profile = profilesById[run.userId];
        const runBy = _.isEmpty(profile) ? "N/A" : `${profile.firstName} ${profile.lastName}`;
        return (
            <>
                <div className="mb-3">
                    <div className="accordion" id="accordionRightIcon">

                        <Accordion defaultOpen={true} id="details" header={<DetailsHeader />}>
                            <h6 className="mb-0 text-muted">Description</h6>
                            <p className="text-18 font-weight-light mb-1">{run.description}</p>
                            <br />
                            <h6 className="mb-0 text-muted">Creation Time</h6>
                            <p className="text-18 font-weight-light mb-1">{<TimeParser value={run.createdAt} />}</p>
                            <br />
                            <h6 className="mb-0 text-muted">Run By</h6>
                            <p className="text-18 font-weight-light mb-1">{runBy}</p>
                            <br />
                            <h6 className="mb-0 text-muted">Branch</h6>
                            <p className="text-18 font-weight-light mb-1">{run.branch}</p>
                            <br />
                            {run.commit ?
                                <><h6 className="mb-0 text-muted">Commit</h6>
                                <p className="text-18 font-weight-light mb-1">{run.commit}</p></>
                           : null}
                        </Accordion>

                        <Accordion id="plan" header={<PlanHeader />} onOpen={() => this.loadPlan(run.id)}>
                            {planLoaded == false ? <Loading /> :
                                <div className="ansi-code">
                                    {_.isEmpty(planDocument) ? null : <Ansi>{planDocument}</Ansi>}
                                </div>
                            }
                        </Accordion>

                        <Accordion id="apply" header={<ApplyHeader />} onOpen={() => this.loadApply(run.id)}>
                            {applyLoaded == false ? <Loading /> :
                                <div className="ansi-code">
                                    {_.isEmpty(applyDocument) ? null : <Ansi>{applyDocument}</Ansi>}
                                </div>
                            }
                        </Accordion>
                    </div>
                </div>
            </>)
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    getAllProfiles
};


Runs = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Runs);
export default withRouter(Runs);